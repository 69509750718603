import React from "react";
import {Divider} from "@material-ui/core";

function AppSeparator({height = '10px'}) {
  return (
    <Divider style={{opacity: 0, height}}/>
  )
}

export default AppSeparator;
