import React from "react";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttonCancel: {
    backgroundColor: '#ED9936',
    '&:hover': {
      backgroundColor: '#9e5d13 !important'
    }
  },
  hidden: {
    '& .MuiSvgIcon-root': {
      visibility: 'hidden'
    },
    '& .buttonLabel': {
      visibility: 'hidden'
    },
  }
}))

const AppButton = ({isLoading,children, cancelButton, variant = 'contained', color = 'secondary', ...rest}) => {
  const classes = useStyles();

  return (
    <Button variant={variant} color={color} className={clsx({[classes.buttonCancel]: cancelButton, [classes.hidden]: isLoading})} {...rest}>
      {isLoading && <CircularProgress style={{position: 'absolute'}} size={24} color={'inherit'}/>}
      <span className={'buttonLabel'} style={{opacity: isLoading ? 0 : 1}}>
        {children}
      </span>
    </Button>
  )
}

export default AppButton
