import React, {useEffect} from 'react'
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.variables.errorTextColor,
  }
}));


function AppCheckbox({ getField = () => {}, onChange = () => {}, getError = () => {}, clearError = () => {}, name, label, color = 'primary', style, value = true, offValue = false, inArray, cmpMethod = 'standard', defaultValue = undefined, ...rest }) {
  const classes = useStyles();

  useEffect(() => {
    if (defaultValue !== undefined && !getField(name, null)) {
      onChange(null, name, defaultValue);
    }
  }, [])

  function handleChange(e, eValue) {
    let newValue = eValue ? value : offValue;
    if (inArray) {
     newValue = newValue === value ? [...getField(name, []), value] : getField(name, []).filter((item) => item !== value)
    }
    clearError(name);
    onChange(null, name, newValue);
  }

  const checked = () => {
    if (inArray) {
      return getField(name).includes(value);
    }
    if (cmpMethod === 'compare') {
      return getField(name) === value;
    }
    return !!getField(name)
  }

  return (
    <FormControlLabel
      style={style}
      className={clsx({[classes.error]: getError(name)})}
      control={
        <Checkbox
          checked={checked()}
          onChange={handleChange}
          name={name}
          color={color}
          {...rest}
        />
      }
    label={label}
    />
  )
}

export default AppCheckbox
