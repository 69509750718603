import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Modules for EN language
import common from './en/common.json';
import companies from './en/companies.json';
import dashboard from './en/dashboard.json';
import navigation from './en/navigation.json';
import employees from './en/employees.json';
import errorMsg from './en/errorMsg.json';
import tracker from './en/tracker.json';
import status from './en/status.json';
import user from './en/user.json';
import notifications from './en/notifications.json';

const resources = {
  en: {
    common,
    companies,
    dashboard,
    navigation,
    employees,
    errorMsg,
    tracker,
    status,
    user,
    notifications,
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbacks: true,
    lng: 'en',
    otherLanguages: ['en'],
    initImmediate: false,
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    }
  })

export default i18n;
