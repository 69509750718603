import React, { Fragment, useState, useContext } from 'react'
import { Grid, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CloudUpload, CancelOutlined } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import AppButton from "../../../../../../components/AppButton";
import AppTextField from "../../../../../../components/AppTextField";
import clsx from "clsx";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import {EmployeeContext} from "../../../../EmployeesContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
    paddingBottom: '9px',
  },
  padding: {
    paddingTop: '18px !important',
  },
  buttonCancel: {
    backgroundColor: '#ED9936',
    '&:hover': {
      backgroundColor: '#9e5d13 !important'
    }
  },
  buttonDelete: {
    backgroundColor: "#BD1C1A",
    color: "#fff"
  },
  dialogPosition: {
    position: 'absolute'
  },
  textPadding: {
    paddingBottom: '16px !important'
  },
  textEllipsis: {
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textField: {
    minWidth: '200px',
    marginBottom: '-12px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '180px',
    }
  },
  gridCenter: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  }
}));

function AppUploadDocument(props) {
  const { name, size, index, getField, file, onChange, label, clearError = () => { }, turnOffSpinner, disabled = false, approveOnUpload = false, getAsset, addError = () => {}, Context = EmployeeContext} = props;
  const { uploadFile = () => { }, onCancel = () => { }, isPromise = () => { }, getError = () => { } } = props;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const docTypes = getAsset('documentTypes', []);
  const {uploadedDocuments} = useContext(Context);
  const [disableUpload, setDisableUpload] = useState(false);

  const uploadedTypes = uploadedDocuments.map(doc => doc.upload_type);

  function onUpload() {
    let error = false;

    if (!getField(name)) {
      addError(name, 'Field Name is Required');
      error = true;
    }
    if (!getField(`${name}-doc-type`)) {
      addError(`${name}-doc-type`, 'Field Type is Required');
      error = true;
    }

    if (error) return;

    const res = uploadFile({ file, name: getField(name), upload_type: getField(`${name}-doc-type`), index, errorName: `${name}-doc-type`, approveOnUpload })

    if (isPromise(res)) {
      setLoading(true);
      res.then(() => {
        if (turnOffSpinner) setLoading(false);
      })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const handleChangeUploadType =(event, name_doc_type, value) => {
    if (uploadedTypes.includes(value) && value !== 'others') {
      const text = ((docTypes || []).find(type => type.value === value) || {}).text || '';
      addError(name_doc_type, 'This File Type Already Exist', `File ${text} Already Exist, if you like to upload this file type, first remove file with this type from documents.`);
      setDisableUpload(true);
    } else if(disableUpload) {
      clearError(name);
      clearError(name_doc_type)
      setDisableUpload(false);
    }
    onChange(event, name_doc_type, value);
  }

  function handleCancel() {
    clearError(name);
    onCancel(index);
  }

  return (
    <Fragment>
      <Grid className={clsx([classes.root, classes.gridCenter])} container spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={12} lg={8} xl={7} className={classes.padding} style={{ paddingBottom: isMdDown ? '0' : '18px' }}>
          <Grid container className={classes.gridCenter} alignItems={"center"} spacing={3}>
            <Grid item xs={6} sm={'auto'} className={clsx([classes.textPadding])}>
              <Typography variant="subtitle1"> {label}</Typography>
            </Grid>
            <Grid item xs={6} sm={'auto'} align={'end'} className={classes.textPadding}>
              <Typography variant="subtitle1"> {size}</Typography>
            </Grid>
            <Grid style={{flexGrow: 1}} item xs={12} sm={'auto'}>
              <AppTextField
                className={classes.textField}
                getError={getError}
                clearError={clearError}
                getField={getField}
                onChange={onChange}
                name={name}
                label="Enter Document Name" />
            </Grid>
            <Grid style={{flexGrow: 1}} item xs={12} sm={'auto'}>
              <AppAutocomplete
                // itemValueUnit={'title'}
                style={{marginBottom: "3px"}}
                className={classes.textField}
                getOptionLabel={(option) => option.text}
                itemValueUnit={'value'}
                items={docTypes}
                name={`${name}-doc-type`}
                label={'Type'}
                getField={getField}
                getError={getError}
                clearError={clearError}
                onChange={handleChangeUploadType}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={"auto"} md={12} lg={'auto'} style={{ maxWidth: '410px' }}>
          <Grid container justify={isMdDown ? "center" : "flex-start"} alignItems={"center"} spacing={2} style={{ marginBottom: '1px' }}>
              <Grid item>
                <AppButton disabled={!!getError(name) || !!getError(`${name}-doc-type`) || (disabled && !isLoading)} isLoading={isLoading} color="default" startIcon={<CloudUpload />} onClick={onUpload} >
                  {t('tracker:button_upload')}
                </AppButton>
              </Grid>
              <Grid item>
                <AppButton onClick={handleCancel} disabled={isLoading || disabled} startIcon={<CancelOutlined />} className={classes.buttonCancel}>
                  {t('tracker:button_cancel')}
                </AppButton>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Fragment>
  )
}

export default React.memo(AppUploadDocument);
