import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select,
  useMediaQuery
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import AppTextField from "components/AppTextField";
import useDialog from "Hooks/useDialog";
import AppImageUpload from "../../../../../components/AppImageUpload";
import useForm from "../../../../../Hooks/useForm";
import AppAutocomplete from "../../../../../components/AppAutocomplete";
import AppPickDateField from "../../../../../components/AppPickDateField";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

function DependanceDialog({text, title, onClose = () => {}, onConfirm = () => {}, getError = () => {}, clearError, isEmail}, ref) {
  const { onChange, addAsset, fields, getAsset, addError, setFields, getField, getLocalError, clearLocalError, clearErrors, clearAssets } = useForm();
  const isNew = getAsset('isNew', true);
  const {t} = useTranslation();
  const classes = useStyles();
  const displayTitle = isNew ? t('employees:add_new_dependance') : t('employees:edit_dependance');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, toggle] = useDialog();

  const getMergedError = (field) => {
    return getError(`dependent_${field}`) || getLocalError(field);
  };
  const clearErrorCmp = (field) => {
    clearLocalError(field);
    clearError(`dependent_${field}`);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    confirm: handleSubmit
  }));

  function handleOpen(params = {}) {
    clearErrors();
    clearAssets();
    toggle()
    const {image, ...rest} = params;
    if (params) addAsset('isNew', false);
    if (image) addAsset('image', (image));
    setFields(() => (rest));
  }

  function handleClose() {
    setFields(() => ({}));
    toggle();
    onClose();
  }

  function handleSubmit() {
    if (validate()) return
    const newDependent = {...fields};
    if (getField('error')) {
      delete newDependent.error;
    }
    onConfirm(newDependent);
    setFields(() => ({}));
    clearAssets();
    toggle();
  }

  function validate() {
    let error = false;
    const fields = ['passport_number', 'first_name', 'last_name'];

    const messages = {}


    if (getField('current_residency') === 'in') {
      fields.push('uid_number');
      fields.push('eid_number');
      fields.push('eid_expiry')
    }

    // Expand salary info because of possible validation errors
    addAsset('salaryExpand', true);

    if (!getField('email') || !isEmail(getField('email'))) {
      addError('email', 'Please enter a valid email')
      error = true;
    }

    fields.forEach(field => {
      if (!getField(field)) {
        let displayField = field.replaceAll('_', ' ');
        displayField = `${t('required_field')} ${displayField.charAt(0).toUpperCase() + displayField.slice(1)}`;
        addError(field, `${messages[field] || displayField}`, 'Fill All Required Fields');
        error = true;
      }
    })

    return error;
  }



  return (
    <Dialog open={open} fullScreen={fullScreen} aria-labelledby="dialog-title" disableBackdropClick disableEscapeKeyDown PaperProps={{style: {minWidth: '320px'}}} >
      <DialogTitle className={classes.dialogTitle}>{displayTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <AppImageUpload baseUrl={process.env.REACT_APP_API_URL} getError={getError} onChange={onChange} onBlur={onChange} name="file" imageUrl={getAsset('image')} imageFile={getField('file', null)}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="first_name" label={t('employees:first_name')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="last_name" label={t('employees:last_name')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="gender">{t('employees:gender')}</InputLabel>
              <Select labelId="gender" name="gender" value={getField('gender')} onChange={onChange}>
                <MenuItem>-</MenuItem>
                <MenuItem value={t('employees:gender_male')}>{t('employees:gender_male')}</MenuItem>
                <MenuItem value={t('employees:gender_female')}>{t('employees:gender_female')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppPickDateField noDatesInFuture getError={getMergedError} getField={getField} onChange={onChange} name="date_of_birth" label={t('employees:date_of_birth')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField getError={getMergedError} getField={getField} onChange={onChange} name="place_of_birth" label={t('employees:place_of_birth')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField getError={getMergedError} getField={getField} onChange={onChange} name="mothers_name" label={t('employees:mothers_name')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField getError={getMergedError} getField={getField} onChange={onChange} name="nationality" label={t('employees:nationality')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField getError={getMergedError} getField={getField} onChange={onChange} name="religion" label={t('employees:religion')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="phone" label={t('employees:phone')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="email" label={t('employees:email')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="passport_number" label={t('employees:passport_number')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppPickDateField clearError={clearErrorCmp} noDatesInFuture getError={getMergedError} getField={getField} onChange={onChange} name="passport_issue" label={t('employees:passport_issue_date')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppPickDateField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="passport_expiry" label={t('employees:passport_expiry_date')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="uid_number" label={t('employees:uid_no')} helperText={t('employees:uid_no_helper_text')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="eid_number" label={t('employees:eid_no')} helperText={t('employees:uid_no_helper_text')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppPickDateField clearError={clearErrorCmp} getError={getMergedError} getField={getField} onChange={onChange} name="eid_expiry" label={t('employees:eid_expiry_date')} helperText={t('employees:uid_no_helper_text')} />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default forwardRef(DependanceDialog);
