import React, {Fragment, useEffect} from "react";
import usePushNotifications from "../Hooks/usePushNotifications";
import {useSelector} from "react-redux";

const LoadPushNotifications = ({children}) => {
  const { initPush } = usePushNotifications();
  const loggedInUser = useSelector((state) => state.auth.userData);

  useEffect(() => {
   if (loggedInUser) {
     initPush();
   }
  }, [loggedInUser]);

  return (
    <Fragment>
      {children}
    </Fragment>
  )
};

export default LoadPushNotifications;
