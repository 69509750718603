/* eslint-disable react-hooks/exhaustive-deps */
import {useReducer, useState} from "react";
import {useSnackbar} from "notistack";

function useForm(init = {}) {
  const [localErrors, setLocalErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();


  /* Use Reducer for Fields */
  const fieldsReducer = (state, action = {}) => {
    switch (action.type) {
      case 'addField':
        return {...state, ...action.payload};
      case 'clearField':
        return {...state, [action.payload]: null};
      case 'deleteField':
        const newState = {...state};
        delete newState[action.payload];
        return newState;
      case 'clearAllFields':
        const newStateWithExceptions = {};
        ((action.payload || {}).exceptions || []).forEach(exception => {
          newStateWithExceptions[exception] = state[exception];
        });
        return newStateWithExceptions;
      case 'clearMultipleFields':
        const fieldsToClear = {};
        action.payload.forEach(field => {
          fieldsToClear[field] = null;
        })
        return {...state,  ...fieldsToClear};
      default:
        return { ...action.payload };
    }
  }

  const [fields, dispatchFields] = useReducer(fieldsReducer, {});

  const setFields = (stateCallback) => {
    const newState = stateCallback({});
    dispatchFields({payload: newState, type: 'setField'});
  }

  const addMultipleFields = (payload = {}) => {
    dispatchFields({ type: 'addField', payload })
  }

  /* Use Reducer for Assets */
  const assetReducer = (state, action = {}) => {
    switch (action.type) {
      case 'addAsset':
        return {...state, ...action.payload};
      case 'clearAsset':
        return {...state, [action.payload]: null};
      case 'clearAllAssets':
        const newStateWithExceptions = {};
        ((action.payload || {}).exceptions || []).forEach(exception => {
          newStateWithExceptions[exception] = state[exception];
        });
        return newStateWithExceptions;
      case 'addToArray':
        return {...state, [action.property]: [ ...(state[action.property] || []), action.payload ]};
      case 'deleteFromArrayById':
        return {...state, [action.property]: [ ...state[action.property].filter((item) => (item.local_id || item.id) !== action.payload) ]};
      case 'deleteElementFromArray':
        return {...state, [action.property]: [ ...state[action.property].filter((item) => item !== action.payload) ]};
      case 'updateArrayElementByObject':
        const id = action.payload.local_id || action.payload.id;
        return {...state, [action.property]: [ ...state[action.property].map((item) => (item.local_id || item.id) == id ? action.payload : item ) ]};
      case 'clearMultipleAssets':
        const assetsToClear = {};
        action.payload.forEach(asset => {
          assetsToClear[asset] = null;
        })
        return {...state,  ...assetsToClear};
      default:
        return { ...action.payload };
    }
  }

  const [assets, dispatchAssets] = useReducer(assetReducer, {});

  const setAssets = (stateCallback) => {
    const newState = stateCallback({});
    dispatchAssets({payload: newState, type: 'addAsset'});
  }

  const addMultipleAssets = (objectWithAssets = {}) => {
    dispatchAssets({ type: 'addAsset', payload: objectWithAssets })
  }

  const addToArrayInAssets = (property, itemToAdd) => {
    dispatchAssets({ type: 'addToArray', property,  payload: itemToAdd})
  }

  const updateArrayElementByObjectInAssets = (property, object) => {
    dispatchAssets({ type: 'updateArrayElementByObject', property,  payload: object})
  }

  const deleteItemFromArrayByIdInAssets = (property, id) => {
    dispatchAssets({ type: 'deleteFromArrayById', property, payload: id })
  }

  const deleteElementFromArrayInAssets = (property, element) => {
    dispatchAssets({ type: 'deleteElementFromArray', property, element });
  }

  const onChange = (event, coreName, coreValue) => {
    const field = ((event || {}).target || {}).name || coreName;
    const value = coreValue ?? ((event || {}).target || {}).value; // ?? is undefined / null protection
    dispatchFields({type: 'addField', payload: {[field]: value}})
  };

  const addAsset =(name,value) => {
    //setAssets((prevState) => ({...prevState, [name]: value}));
    dispatchAssets({type: 'addAsset', payload: { [name]: value }});
  };

  const addField =(name,value) => {
    //setFields((prevState) => ({...prevState, [name]: value}));
    dispatchFields({type: 'addField', payload: { [name]: value }});
  };

  const getAsset =(asset = 'default', reserved = null) => {
    return assets[asset] ?? reserved;
  };

  const addAssetGroup = (group = {}) => {
    addMultipleAssets(group);
  };

  const getField = (field, reserved = '') => {
    return fields[field] ?? reserved;
  };

  const addError = (field, message, bubbleMessage) => {
   if (typeof message === 'string' || bubbleMessage) {
     enqueueSnackbar(bubbleMessage || message, { variant: "error" });
   }
    setLocalErrors(pervState => ({...pervState, [field]: message}));
  }

  const getLocalError = (field, reserved) => {
    return localErrors[field] || reserved
  }

  const clearLocalError = (field) => {
    const errors = {...localErrors};
    delete errors[field];
    setLocalErrors((pervErrors => ({...pervErrors, [field]: null})));
  }

  const clearFields = ({exceptions = []} = {}) => dispatchFields({type: 'clearAllFields', payload: {exceptions}});
  const clearAssets = ({exceptions = []} = {}) => dispatchAssets({type: 'clearAllAssets', payload: {exceptions}});
  const clearFiled = (name) => dispatchFields({type: 'clearField', payload: name});
  const deleteField = (name) => dispatchFields({type: 'deleteField', payload: name});
  const clearErrors = () => setLocalErrors({});
  const clearMultipleFields = (fields) => dispatchFields(({type: 'clearMultipleFields', payload: fields}));
  const clearMultipleAssets = (assets) => dispatchFields(({type: 'clearMultipleAssets', payload: assets}));

  return {
    clearFields,
    setAssets,
    addAsset,
    clearAssets,
    getAsset,
    getField,
    fields,
    onChange,
    assets,
    setFields,
    clearFiled,
    deleteField,
    addField,
    clearMultipleFields,
    clearMultipleAssets,
    addError,
    getLocalError,
    clearLocalError,
    addAssetGroup,
    addMultipleFields,
    addMultipleAssets,
    addToArrayInAssets,
    deleteItemFromArrayByIdInAssets,
    updateArrayElementByObjectInAssets,
    deleteElementFromArrayInAssets,
    clearErrors,
  }
}

export default useForm;

