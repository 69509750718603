import { useState } from 'react';
import {useLocalStorage} from "react-use";
import {authorize, getMe} from "../services/loginService";
import { useSelector, useDispatch } from "react-redux";
import { login as loginAction, logout as logoutAction } from 'redux/actions/authActions';
import useAPI from "./useAPI";
import { Capacitor } from '@capacitor/core';
import {logoutService} from "../services/userService";

function useAuth() {
  const { sendRequest } = useAPI();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', false);
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = token === null ? null : !!token;

  const clear = (error = null) => {
    setIsLoading(false);
    // console.log((error || {}).body, (error || {}).message)
    setError(true);
    dispatch(logoutAction());
  };

   const clearError = () => setError(false);


  const login = async (authData) => {
    setIsLoading(true)
    try {
      const { data } = await authorize({data: authData});
      saveUser(data);
      return data;
    } catch (error) {
      clear(error);
    }
  }

  const logout = () => {
    if (Capacitor.platform !== 'web') {
      sendRequest(logoutService);
    }
    localStorage.clear();
    sessionStorage.clear();
    dispatch(logoutAction());
  }

  const loadFromLocalStorage = () => {
    const payload = {};
    payload.token = localStorage.getItem('token') || '';
    payload.authData = JSON.parse(localStorage.getItem('userData'));
    return payload;
  }

  const loadFromSessionStorage = () => {
    const payload = {};
    payload.token = sessionStorage.getItem('token') || '';
    payload.userData = JSON.parse(sessionStorage.getItem('userData'));
    return payload;
  }

  const saveUser = ({token, ...userData}) => {
    dispatch(loginAction({token, userData}))
    if (rememberMe) {
      localStorage.setItem('token', token);
    } else {
      sessionStorage.setItem('token', token);
    }
  }

  const loadUser = async () => {
    let payload = rememberMe ? loadFromLocalStorage() : loadFromSessionStorage();
    try {
      if (!payload.token) throw new Error('No auth data')
      const { data } = await getMe();
      payload.userData = data;
      dispatch(loginAction(payload));
      return data;
    } catch (err) {
      clear(err)
    }
  }

  const checkUserStatus = () => {
    if (token === null) loadUser();
  }



  return {isLoggedIn, login, logout, isLoading, error, clearError, loadUser, userData, rememberMe, setRememberMe, checkUserStatus}
}

export default useAuth;
