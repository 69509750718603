import React, {Fragment} from 'react';
import {Avatar, Hidden, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import {AccountCircleOutlined, ArrowDropDown} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import useRedirect from 'Hooks/useRedirect';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '65px',
    width: '155px',
    borderRadius: 0,
    boxShadow: 'rgba(0, 0, 0, 0.5) 7px 7px 15px 3px'
  },
  userButton: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  userImage: {
    marginRight: '11px',
    width: '40px',
    height: '40px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      paddingRight: 0
    }
  },
  dropdownArrow: {}
}))

function UserMenu({logout, userData = {}}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { goToUserProfile } = useRedirect();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onLogout() {
    handleClose();
    logout();
  }

  function onProfile() {
    handleClose();
    goToUserProfile();
  }

  return (
    <Fragment>
      <IconButton onClick={handleMenu} color="inherit" className={classes.userButton}>
        {userData.imageUrl && <Avatar alt="user-image" src={userData.imageUrl} className={classes.userImage}/> }
        {!userData.imageUrl && <AccountCircleOutlined fontSize={"large"} className={classes.userImage}/>}
        <Hidden xsDown>
          <Typography>
            {userData.first_name}
            &nbsp;
            {userData.last_name}
          </Typography>
        </Hidden>
        <Hidden xsDown>
          <ArrowDropDown className={classes.dropdownArrow}/>
        </Hidden>
      </IconButton>
      <Menu
        id="menu-appbar"
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.paper
        }}
        variant="menu"
        keepMounted
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={onProfile}>Profile</MenuItem>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserMenu
