import React, {useEffect, useState} from 'react'
import { Card, CardHeader, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightOutlined } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppAutocomplete from 'components/AppAutocomplete';
import AppApproveDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppApproveDocument';
import AppStepper from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppStepper';
import AppAlertDialog from "../../../../../../components/Dialogs/AppAlertDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import {useEffectOnce} from "react-use";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  iconStyle: {
    height: '40px',
    width: '40px'
  },
  root: {
    paddingTop: '16px',
    paddingBottom: '25px',
  },
  optionSelectMargin: {
    marginLeft: '150px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px'
    }
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Documents Upload',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Documents Review',
    hint: 'Current Step',
    current: true
  }
];

// Main component
function StepReviewDocuments({ getAsset, addAsset, getField, onChange, getError, addError, clearError, getUsers, approveOrRejectDocument, nextStep, assignTypistSupervisor, isPromise, clearUserIdField, isLoading, deleteDocument, approveDocumentApi }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoadingLocal, setLoading] = useState(false);

  const documents = getAsset('uploadedDocuments', []);
  const documentsToReview = documents.filter((item) => (item.approval !== 1 || item.approvedLocally === 1));
  const [confirm, toggleConfirm] = useDialog();
  const [alert, toggleAlert] = useDialog();
  const reviewDocumentsError = getError('review_documents_error');

  const onConfirm = async () => {
    toggleConfirm();
    setLoading(true);
    try {
      let resubmission = documentsToReview.length > 0 ? 0 : 1;
      const fields = [];

      documentsToReview.forEach(item => {
        if (item.approval === 0) {
          resubmission = 1;
          fields.push( {
            field: 'rejection_reason',
            value: `${item.name} - ${item.rejection_reason}`,
          })
        } else {
          fields.push({
            field: 'document_id',
            value: item.id,
          })
        }
      })

      Promise.all(documentsToReview.filter(document => document.approval === 0).map(async (file) => {
       return deleteDocument({ documentId: file.id });
      }))

      Promise.all(documentsToReview.filter(document => document.approval === 1).map(async (file) => {
        return approveDocumentApi({ documentId: file.id });
      }))

      fields.push({
        field: 'candidate_document_status',
        value: resubmission === 0 ? 'approved' : 'rejected',
      })

      const messages = resubmission === 0 ? ['All document approved'] : ['Some or all documents are rejected']

      nextStep({content: JSON.stringify({fields, messages}), resubmission: resubmission, additional_documents: 0});
    } catch (err) {
      setLoading(false);
    }
  }

  function validate() {
    let error = false;
    const notReviewedFile = documentsToReview.find(item => item.approval !== 0 && item.approval !== 1);

    if (notReviewedFile) {
      addError('review_documents_error', 'Please Review All Documents');
      error = true;
    }
    return error;
  }

  function onNextStep() {
   const error = validate();
   if (!error) toggleConfirm();
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={5} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} breakPoint={'sm'} />
        </Grid>
        <Hidden mdDown>
          <Grid item align={'center'} xs={6}>
            <AppButton
              disabled={isLoading}
              isLoading={isLoadingLocal}
              className={classes.nextStepButton}
              endIcon={<ChevronRightOutlined style={{ fontSize: '24px' }} />}
              onClick={onNextStep}>
              {t('tracker:button_go_to_next_step')}
            </AppButton>
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography variant="body1" className={clsx({[classes.error]: reviewDocumentsError})}>
            {t('tracker:check_the_uploaded_documents')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {documentsToReview.map((document) =>
            <AppApproveDocument
              approve={approveOrRejectDocument}
              reject={approveOrRejectDocument}
              onDelete={deleteDocument}
              file={document}
              key={document.id}
              name={document.name}
              size={document.size}
              getAsset={getAsset}
              addAsset={addAsset}
              onChange={onChange}
              getField={getField}
              approved={document.approval}
              rejection_reason={document.rejection_reason}
              getError={getError}
              addError={addError}
              clearError={clearError}
              isPromise={isPromise}
              onReview={() => (clearError('review_documents_error'))}/>
          )}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <AppButton disabled={isLoading} isLoading={isLoadingLocal} onClick={onNextStep} fullWidth>{t('tracker:button_go_to_next_step')}</AppButton>
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialogs */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
      <AppAlertDialog open={alert} onOk={toggleAlert} text={t('tracker:tracker_step_2_b_dialog_content_text')}/>
    </Card>
  )
}

export default StepReviewDocuments;
