import React from 'react'
import {Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppButton from "../../../components/AppButton";
import AppCard from "../../../components/AppCard";
import AppTextField from "../../../components/AppTextField";
import { stepsAndRoles } from '../assets';
import AppCheckbox from "../../../components/AppCheckbox";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "20px"
  }
}));

function NotificationForm({onChange, getField, getAsset, isLoading, getStepData, saveNotification, getTypeString}) {
  const classes = useStyles();
  const step = getStepData();
  const roles = ((step || {}).roles || []).filter(role => role.slug !== 'super_admin');
  const saveLoading = getAsset('SaveNotificationLoading', false);

  const filteredStepsAndRoles = stepsAndRoles.filter(st => st.step_id == step.id);

  function getStepField(fieldName) {
    return step[fieldName] || '';
  }

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <AppCard elevation={1}>
          <Grid container style={{padding: '24px'}}>
            <Grid item xs={12}>
              <Typography variant={'h5'} >
                {getStepField('title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'}>
                Step ID: {getStepField('id')}
              </Typography>
            </Grid>
          </Grid>
        </AppCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <AppCard elevation={1} title={'EMAIL NOTIFICATIONS'}>
          <Grid style={{padding: '0 24px 24px 24px', marginTop: 0}} container spacing={2} className={classes.gridContainer}>
            { filteredStepsAndRoles.map((step, index) =>
              <Grid container key={`${step.role.id}${getTypeString(step)}-email`}>
                <Grid item xs={6}>
                  <AppCheckbox cmpMethod={'compare'} getField={getField} value={0} offValue={1} onChange={onChange} name={`email_send-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Disable'}/>
                </Grid>
                <Grid item align={"end"} xs={6}>
                  <AppCheckbox cmpMethod={'compare'} getField={getField} value={1} offValue={0} onChange={onChange} name={`candidate-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Candidate'}/>
                </Grid>
                { step.title &&
                <Grid item xs={12}>
                  <Typography style={{marginBottom: '10px'}}>{step.title}</Typography>
                </Grid> }
                <Grid item xs={12}>
                  <Typography>Email CC for {step.role.title}</Typography>
                  <AppTextField type={'email'} getField={getField} onChange={onChange} name={`email_cc-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'CC'}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Email Title for {step.role.title}</Typography>
                  <AppTextField getField={getField} onChange={onChange} name={`email_title-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Title'}/>
                </Grid>
                <Grid item xs={12} key={step.role.slug}>
                  <Typography>Email Message for {step.role.title}</Typography>
                  <AppTextField getField={getField} onChange={onChange} name={`email_message-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={`Message`} multiline rows={4}/>
                </Grid>
              </Grid>
            ) }
          </Grid>
        </AppCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <AppCard elevation={1} title={'PUSH NOTIFICATIONS'}>
            <Grid style={{padding: '0 24px 24px 24px', marginTop: 0}} container spacing={2} className={classes.gridContainer}>
              { filteredStepsAndRoles.map((step) =>
                !step.noPush &&
                <Grid container key={`${step.role.id}${getTypeString(step)}-push`}>
                  <Grid item xs={6}>
                    <AppCheckbox cmpMethod={'compare'} getField={getField} value={0} offValue={1} onChange={onChange} name={`push_send-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Disable'}/>
                  </Grid>
                  <Grid item align={"end"} xs={6}>
                    <AppCheckbox cmpMethod={'compare'} getField={getField} value={1} offValue={0} onChange={onChange} name={`candidate-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Candidate'}/>
                  </Grid>
                  { step.title &&
                  <Grid item xs={12}>
                    <Typography style={{marginBottom: '10px'}}>{step.title}</Typography>
                  </Grid> }
                  <Grid item xs={12}>
                    <Typography>Notification Title for {step.role.title}</Typography>
                    <AppTextField getField={getField} onChange={onChange} name={`push_title-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={`Title`}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Notification Message for {step.role.title}</Typography>
                    <AppTextField getField={getField} onChange={onChange} name={`push_message-${step.step_id}-${step.role.id}${getTypeString(step)}`} label={'Message'}/>
                  </Grid>
                </Grid>
              ) }
            </Grid>
        </AppCard>
      </Grid>

      <Grid item xs={12}>
        <AppCard elevation={1}>
          <Grid container style={{padding: '24px'}}>
            <Grid item xs={12} sm={12} lg={12}>
              <AppButton onClick={saveNotification} fullWidth isLoading={isLoading || saveLoading}>
                Save Notifications
              </AppButton>
            </Grid>
          </Grid>
        </AppCard>
      </Grid>
    </Grid>
  )
}

export default NotificationForm;
