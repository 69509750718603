import React, {Fragment, useState, forwardRef, useImperativeHandle} from "react";
import {Button} from "@material-ui/core";
import {PostAdd} from "@material-ui/icons";
import useUpload from "../Hooks/useUpload";
import {useTranslation} from "react-i18next";
import _uniqueId from "lodash/uniqueId";


function AppUploadFile({onChange, addAsset, name, onUpload = () => {}, ...rest}, ref) {
  const {t} = useTranslation();
  const { handleUpload } = useUpload(onChange, addAsset || localAddAsset, { uploadAssetName: name });
  const [file, setFile] = useState(null);
  const [id] = useState(_uniqueId('app-upload-file'));

  useImperativeHandle(ref, () => ({
    getFile: () => file,
  }))

  function localAddAsset(assetName, file) {
    setFile(file);
  }

  function handleUploadEvent(event) {
   const files = handleUpload(event);
   onUpload(files);
  }

  return (
    <Fragment>
      <input hidden={true} id={id} type="file" onChange={handleUploadEvent}/>
      <label htmlFor={id}>
        <Button {...rest} variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
          {t('tracker:upload_document_button')}
        </Button>
      </label>
    </Fragment>
  )
}

export default forwardRef(AppUploadFile);
