import React, {useContext, useState} from "react";
import {useEffectOnce} from "react-use";
import StepList from "./StepList";
import Spinner from "../../../../../components/Spinner";
import {makeStyles} from "@material-ui/core/styles";
import {EmployeeContext} from "../../../EmployeesContainer";
import DynamicStep from "./DynamicStep";
import StepRestartOnboarding from "./Steps/StepRestartOnboarding";
import StepDefault from "./Steps/StepDefault";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: '50%',
    transform: 'translateY(-50%)'
  }
}))

function Tracker() {
  const classes = useStyles();
  const context = useContext(EmployeeContext);
  const { getAsset, getStep, goToNextStep, cycleEmployee, getEmployeeById, updateEmployee } = context;
  const [isLoading, setLoading] = useState(false);
  const stepSlug = getAsset('stepSlug');
  const stepAccess = getAsset('stepAccess', null);
  const stepGroup = getAsset('stepGroup', '');
  const stepOrder = stepAccess === true ? stepSlug : stepAccess === false ? 'default_step' : undefined;

  useEffectOnce(() => {
    getStep();
  })

  async function handleNextStep(data, employeePayload, { skipGet } = { skipGet: false }) {
    try {
      setLoading(true);
      if (employeePayload)  await updateEmployee(employeePayload);
      await goToNextStep(data, skipGet);
      if (!skipGet) await getStep();
      setLoading(false);
    } catch (e) {
      console.log(e)
      await getStep();
      setLoading(false);
    }
  }

  async function handleCycleEmployee(data) {
    try {
      setLoading(true);
      await cycleEmployee(data);
      await getEmployeeById();
      await getStep();
      setLoading(false);
    } catch (e) {
      await getStep();
      setLoading(false);
    }
  }

  const processProps = {
    stepOrder,
    handleCycleEmployee,
    handleNextStep,
    isLoading
  }

  return (
    <div style={{position: "relative", minHeight: (isLoading || stepOrder === undefined) ? '250px' : '0'}}>

      { !isLoading && stepGroup === 'first' &&
          <StepList  {...processProps} {...context}/>
      }

      {
        !isLoading && stepGroup && stepGroup !== 'first' && stepOrder !== 'process_completed_second' && (stepOrder !== 'default_step') &&
          <DynamicStep {...processProps} {...context}/>
      }

      {
        !isLoading && stepGroup && stepGroup !== 'first' && stepOrder === 'process_completed_second' &&
          <StepRestartOnboarding {...context} nextStep={handleNextStep} cycleEmployee={handleCycleEmployee} />
      }

      {
        !isLoading && stepGroup && stepGroup !== 'first' && stepOrder === 'default_step' &&
          <StepDefault {...context}/>
      }

      { (isLoading || stepOrder === undefined) &&
          <Spinner className={classes.spinner}/>
      }
    </div>
  )
}


export default Tracker;
