import {createMuiTheme} from "@material-ui/core";

const variables = createMuiTheme({
  variables: {
    errorTextColor: 'red',
    stepNoteColor: '#ED9936',
    secondaryBackgroundColor: 'rgba(0,0,0,0.05)',
    drawerWidth: 262,
    drawerPoint: 800
  },
})

const theme = createMuiTheme({
  ...variables,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          overflow: 'hidden',
        },
        '.MuiTableHead-root': {
          '& .MuiTableCell-head': {
            color: 'rgba(0, 0, 0, 0.56)',
            fontWeight: 400,
        }
        }
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      ss: 400,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#0874BC'
    },
    secondary: {
      main: '#1A97AD'
    }
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: 14
  },
});

export default theme;
