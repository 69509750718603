import React from 'react'
import UsersContainer from './UsersContainer'


function index() {
  return (
    <UsersContainer />
  )
}

export default index
