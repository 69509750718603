import React, {forwardRef, Fragment, useImperativeHandle, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import UploadEmployeesCsv from "./UploadEmployeesCsv";
import PrepareForImportEmployees from "./PrepareForImportEmployees";
import ImportEmployeesResponseTable from "./ImportEmployeesResponseTable";
import {Hidden, makeStyles} from "@material-ui/core";
import {GroupAdd} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  createNewEmployeeButton: {
    marginRight: '10px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '6px',
    },
    verticalAlign: 'middle'
  },
}))

function ImportEmployeesDialog ({addField, getField, importEmployees, exportErrorsToCsv}, ref) {
  const classes = useStyles();
  const uploadElem = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [flags, setFlagsRaw] = useState({
    isLoading: false,
    isFileUploaded: false,
  })

  function setFlag(newFlags = {}) {
    setFlagsRaw({ ...flags, ...newFlags });
  }

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleClickOpen,
  }));


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFlag({isFileUploaded: false, isLoading: false});
    setFile(null);
    setOpen(false);
    setData([]);
  };

  function onChoose(file) {
    if(!file) return;
    setFile(file[0])
  }

  function onUpload() {
    setFlag({isLoading: true});
    importEmployees({file}).then(({data}) => {
      if(data.length === 0) handleClose();
      setFlag({isFileUploaded: true, isLoading: false});
      setData(data);
    }).catch(() => {
      setFlag({isLoading: false});
    })
  }

  function onCancel() {
    setFile(null);
  }

  function onExport() {
    exportErrorsToCsv().then((res) => {

    })
  }

  function onOk() {
    handleClose();
  }

  return (
    <label>
      <Fragment>
        <Hidden smDown>
          {
            <Button className={classes.createNewEmployeeButton} variant="contained" color="secondary" component="span" onClick={handleClickOpen}>
              Import New Employees
            </Button>
          }
        </Hidden>
        <Hidden mdUp>
          {
            <GroupAdd focusable className={classes.createNewEmployeeButton} onClick={handleClickOpen} fontSize={"large"}/>
          }
        </Hidden>
      </Fragment>
      <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{"Import New Employees"}</DialogTitle>
          {
            !file && <UploadEmployeesCsv ref={uploadElem} onUpload={onChoose}/>
          }
          {
            file && !flags.isFileUploaded && <PrepareForImportEmployees onCancel={onCancel} file={file} onUpload={onUpload} isLoading={flags.isLoading}/>
          }

          {
            flags.isFileUploaded && <ImportEmployeesResponseTable data={data} onExport={onExport} onOk={onOk} />
          }

      </Dialog>
    </label>
  );
}

export default forwardRef(ImportEmployeesDialog);
