import React, {Fragment, useEffect} from "react";
import {Accordion, AccordionSummary, AccordionDetails, Card, CardHeader, Grid, useTheme, useMediaQuery} from "@material-ui/core";
import AppButton from "../../../../../components/AppButton";
import {makeStyles} from "@material-ui/core/styles";
import AppAutocomplete from "../../../../../components/AppAutocomplete";
import {ArrowDropDown} from "@material-ui/icons";
import AppPickDates from "../../../../../components/AppPickDates";
import AppTextField from "../../../../../components/AppTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '24px',
  },
  gridRoot: {
    padding: '24px',
  },
  header: {
    borderBottom: '1px solid #E0E0E0',
    '& .MuiCardHeader-title': {
      fontSize: '0.88rem',
      textTransform: 'uppercase',
    }
  },
  expandButton: {
    height: '56px',
    width: '56px',
    minWidth: '56px'
  },
  dropdown: {
    boxShadow: 'none',
    padding: 0,
  }
}));

function EmployeesFilter({t, getAsset, addAsset, employeeTableNumber, runSearch = () => {}, exportByCompany, canExport}) {
  const classes = useStyles();
  const query = getAsset('query', {});
  const isEmployeesFilterRunning = getAsset('isEmployeesFilterRunning');
  const steps = getAsset('steps', []);
  const unasignedUser = {
    id: '000',
    first_name: 'Unassigned',
    last_name: '',
  }
  const users = [...getAsset('data_entrists', []), ...getAsset('medical_insurances', []), ...getAsset('visa_admins', []), ...getAsset('pros', []), ...getAsset('typist_supervisors', []), ...getAsset('typists', []), unasignedUser ];
  const customSteps = [];
  const processes = getAsset('processes', []);
  const stepsDropdown = [...(steps || []), ...customSteps];

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    runSearch();
    //eslint-disable-next-line
  }, [isEmployeesFilterRunning, employeeTableNumber])

  function onClear() {
    addAsset('query', {});
    addAsset('isEmployeesFilterRunning', false);
    if (!isEmployeesFilterRunning) runSearch();
  }

  function onSearch() {
    addAsset('isEmployeesFilterRunning', true);
    if (isEmployeesFilterRunning) runSearch();
  }

  function getQuery(field, reserved = '') {
    return getAsset('query', {})[field] || reserved;
  }

  function onChangeQuery (event, coreName, coreValue) {
    const field = ((event || {}).target || {}).name || coreName;
    const value = coreValue || ((event || {}).target || {}).value;
    addAsset('query', {...query, [field]: value});
  }

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header} title={"Search"} action={
        <Fragment>
          {canExport() && <AppButton onClick={exportByCompany} style={{marginBottom: '-7px', marginRight: '17px'}}> Export By Company</AppButton>}
        </Fragment>
      }/>
      <Grid className={classes.gridRoot} container spacing={4}>
        <Grid item xs={12} style={{paddingBottom: 0, paddingTop: '10px'}}>
          <Grid container spacing={4} alignItems="center" >
            <Grid item xs={12} md={12} lg={3} style={ {...(isMd && {order: 1, marginTop: '24px'})} }>
              <AppTextField getField={getQuery} onChange={onChangeQuery} name={'full_name'} label={t('employees:employee_name')}/>
            </Grid>
            <Grid item xs={12} md={12} lg={3} style={ {...(isMd && {order: 1, marginTop: '24px'})} }>
              <AppAutocomplete getField={getQuery} items={users} label={'User'} name={'user_id'} onChange={onChangeQuery} getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }/>
            </Grid>
            <Grid item xs={12} md={12} lg={6} align={'flex-end'}>
              <Grid container alignItems={"flex-end"} justify={"flex-end"} spacing={2}>
                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <AppButton fullWidth onClick={onSearch}>{t('search')}</AppButton>
                </Grid>
                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <AppButton onClick={onClear} fullWidth>{t('clear')}</AppButton>
                </Grid>
                <Grid item xs={12} align={'end'}>
                  <Grid container spacing={2} justify={'flex-end'}>
                    <Grid item xs={6} sm={4} md={2} lg={2}>
                      <AppButton size={"small"} fullWidth color={"primary"} onClick={() => addAsset('employeesFilterExpanded', !getAsset('employeesFilterExpanded'))}>
                        <ArrowDropDown/>
                      </AppButton >
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} justify={"center"} alignItems={"center"} style={{marginTop: '-8px'}}>
            <Grid item xs={12} md={12} lg={3}>
              <AppTextField
                getField={getQuery}
                items={processes}
                label={'Business Unit'}
                name={'business_unit'}
                onChange={onChangeQuery}/>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <AppAutocomplete
                getField={getQuery}
                items={processes}
                label={'Process'}
                name={'process_id'}
                onChange={onChangeQuery}
                getOptionLabel={(option) => `${option.title}` }/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppAutocomplete
                getField={getQuery}
                items={stepsDropdown}
                label={t('Status')}
                name={'status'}
                onChange={onChangeQuery}
                getOptionLabel={(option) => option.title }/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppAutocomplete
                getField={getQuery}
                getOptionLabel={(option) => option.title }
                items={getAsset('companies', [])}
                onChange={onChangeQuery}
                name={'company_id'}
                label={t('companies:company')}/>
            </Grid>
            <Grid item xs={12}>
             <Accordion className={classes.dropdown} expanded={!!getAsset(['employeesFilterExpanded'])}>
               <AccordionSummary style={{display: "none"}}/>
               <AccordionDetails className={classes.dropdown}>
                 <Grid container spacing={3}>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"passport_expiry"} label={t('employees:passport_expiry')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"visa_expiry"} label={t('employees:visa_expiry')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"labor_card_expiry"} label={t('employees:labor_expiry')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"insurance_expiry"} label={t('employees:insurance_expiry')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"eid_expiry"} label={t('employees:eid_expiry')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                   <Grid item xs={12} md={6} lg={6}>
                     <AppPickDates name={"date_step"} label={t('employees:activity_date')} getField={getQuery} onChange={onChangeQuery}/>
                   </Grid>
                 </Grid>
               </AccordionDetails>
             </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default EmployeesFilter;
