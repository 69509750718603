import React, {Fragment, useState} from 'react'
import {Grid, Typography, Divider, useMediaQuery, Hidden} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import {useTranslation} from "react-i18next";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppButton from "../../../../../../components/AppButton";
import useUpload from "../../../../../../Hooks/useUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
    paddingBottom: '9px',
  },
  padding: {
    paddingTop: '18px !important',
  },
  nameTypeSize: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  buttonCancel: {
    backgroundColor: "#FFA000",
    color: "#fff"
  },
  buttonDelete: {
    backgroundColor: "#BD1C1A",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#941513 !important'
    }
  },
  dialogPosition: {
    position: 'absolute'
  },
  textPadding: {
    paddingBottom: '16px !important'
  }
}));


function UploadedDocument({getAsset, addAsset, name, size, index, file, label, turnOffSpinner, disabled = false, onDelete = () => {}, isPromise = () => {}}) {
  const [isLoading, setLoading] = useState(false);
  const { convertSize } = useUpload();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const key = `deleteDialog-${name}-${index}`;

  const docTypes = getAsset('documentTypes', []);

  function getDocType(value) {
    const type = docTypes.find(t => t.value === value);
    return (type || {}).text || '-';
  }

  function toggleDialog() {
    addAsset(key, !getAsset(key))
  }

  function cancelDelete() {
    toggleDialog();
  }

  const confirmDelete = () => {
    toggleDialog();
    const result = onDelete({documentId: file.id, index});
    if (isPromise(result)) {
      setLoading(true);
      result.then(() => {
        if (turnOffSpinner) setLoading(false);
      }).catch(err => {
        setLoading(false);
      })
    }
  }

  return (
    <Fragment>
      <Grid className={classes.root} container spacing={3} alignItems={"center"}>
        <Grid item xs={12} sm={'auto'} className={classes.padding}>
          <Grid container alignItems={"center"} spacing={3} style={{marginBottom: 0}}>
            <Grid item xs={12} sm={"auto"} className={classes.nameTypeSize}>
              <Typography variant="subtitle1">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={'auto'}>
                    <span> {label} </span>
                  </Grid>
                  <Grid item xs={12} sm={'auto'}>
                    <span> Type: {getDocType(file.upload_type)} </span>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={"auto"} align={isXsDown ? 'start' : 'end'} className={classes.nameTypeSize}>
              <Typography variant="subtitle1">
                <Hidden smUp>
                  <span>Size: </span>
                </Hidden>
                {convertSize(size)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{maxWidth: '410px'}}>
          <Grid container justify={isMdDown ? "center" : "flex-start"} alignItems={"center"} spacing={2} style={{marginBottom: '1px'}}>
            <Grid item>
              <AppButton isLoading={isLoading} disabled={disabled && !isLoading} startIcon={<DeleteOutlineOutlined />} className={classes.buttonDelete} onClick={toggleDialog}>
                {t('tracker:button_delete')}
              </AppButton>
              </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Divider />

      {/* Dialog */}
      <AppConfirmDialog
        text={t('tracker:dialog_content_text') + label + ' file?' || 'some_file.ext?'}
        open={!!getAsset(key)}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}/>

    </Fragment>
  )
}

export default UploadedDocument;
