import React, {Fragment, useEffect, useState} from "react";
import useAuth from "../Hooks/useAuth";
import {useEffectOnce, useNetwork} from "react-use";
import useUser from "../Hooks/useUser";
import useRedirect from "../Hooks/useRedirect";

const LoadUser = ({children}) => {
  const [onlineStatus, setOnlineStatus] = useState(true);
  const {loadUser, checkUserStatus} = useAuth();
  const {online} = useNetwork();
  const { isRecruiter } = useUser();
  const { goToEmployees } = useRedirect();

  useEffect(() => {
    if (!onlineStatus && online) {
      checkUserStatus();
      setOnlineStatus(true);
    }
    if (online === false) setOnlineStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  useEffectOnce(() => {
    loadUser().then((res) => {
      if (res) {
        if (isRecruiter(res)) goToEmployees();
      }
    })
  });

  return (
    <Fragment>
      {children}
    </Fragment>
  )
};

export default LoadUser;
