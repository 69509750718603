import React, {useState, Fragment} from "react";
import ExportView from "./ExportView";
import {useEffectOnce} from "react-use";
import useAPI from "../../Hooks/useAPI";
import {exportCandidatesService} from "../../services/employeesService";

const  ExportContainer = function() {
  const { sendRequest, downloadFileFromUrl } = useAPI();
  const [downloadStatus, setDownloadStatus] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffectOnce(() => {
    handleDownload();
  })


  const downloadFile = () => sendRequest(exportCandidatesService,
    { download: true, fileName: `Export Candidates By Company.csv` })
    .then((res) => {
      setDownloadStatus(1);
      setDownloadLoading(false);
    }).catch((err) => {
      console.log(err)
      setDownloadLoading(false);
      setDownloadStatus(2);
    })

  const handleDownload = () => {
    setDownloadLoading(true);
    setDownloadStatus(3);
    downloadFile();
  }

  const downloadProps = {
      downloadFile: handleDownload,
      downloadStatus,
      downloadLoading,
      downloadFileFromUrl
    }

  return (
    <Fragment>
      <ExportView {...downloadProps} />
    </Fragment>

  )
}

export default ExportContainer;
