import React, { Fragment } from 'react'
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core"
import useStyles from "./styles";
import logo from 'assets/img/WFC.svg'
import AppButton from 'components/AppButton';
import AppTextField from 'components/AppTextField';

function ResetPasswordView({ t, onChange, getField, getError, clearError, addError, confirmResetPassword, isLoading }) {
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const buttonSize = isXs ? 'large' : 'medium';

  function validate() {
    let error = false;
    let fields = ['email', 'password', 'password_confirmation'];
    const messages = {};
    fields.forEach(field => {
      if (!getField(field)) {
        let displayField = field.replaceAll('_', ' ');
        displayField = displayField.charAt(0).toUpperCase() + displayField.slice(1) + ' is required';
        console.log(`${messages[field] || displayField}`)
        addError(field, `${messages[field] || displayField}`);
        error = true;
      }
    })
    if (getField('password') !== getField('password_confirmation')) {
      addError('password_confirmation', 'Passwords must be equal!');
      error = true;
    }
    return error;
  }

  function onResetPassword() {
    const error = validate();
    if (!error) confirmResetPassword();
  }

  return (
    <Fragment>
      <Card elevation={1} className={classes.root}>
        <CardHeader title="RESET PASSWORD" />
        <CardContent>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid className={classes.logo} item xs={12} align="center">
              <img src={logo} alt="logo" />
            </Grid>
            <Grid item xs={12} sm={9}>
              <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name={"email"} label={t('email')} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <AppTextField type="password" clearError={clearError} getError={getError} getField={getField}  onChange={onChange} name={"password"} label={t('password')} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <AppTextField type="password" clearError={clearError} getError={getError} getField={getField} onChange={onChange} name={"password_confirmation"} label={t('password_confirmation')} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container>
                <Grid item xs={12} sm={3} className={classes.resetButton}>
                  <AppButton isLoading={isLoading} size={buttonSize} fullWidth variant="contained" color="secondary" onClick={onResetPassword}>{t('submit')}</AppButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}

export default ResetPasswordView
