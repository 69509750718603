import React, {Fragment} from "react";
import {Box, DialogContent, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import {CancelOutlined, CloudUpload} from "@material-ui/icons";
import AppButton from "../../../../components/AppButton";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  documentName: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  box: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  }
}))

function PrepareForImportEmployees({file, onCancel, onUpload, isLoading}) {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <DialogContent className={classes.content}>
      <Box className={classes.box} mt={2} mb={5} mx={2}>
        <Grid container direction="row" justify={"space-between"} alignItems={"center"}>
          <Grid item xs={12} sm={"auto"} align={'middle'} className={classes.documentName}>
            <Typography>
              { file.name }
            </Typography>
          </Grid>
          <Grid item align={'middle'} xs={12} sm={"auto"}>
            <AppButton onClick={onUpload} style={{marginRight: '10px'}} isLoading={isLoading} startIcon={<CloudUpload/>}>
              Upload
            </AppButton>
            <AppButton disabled={isLoading} onClick={onCancel} startIcon={<CancelOutlined />} cancelButton={true}>
              {t('tracker:button_cancel')}
            </AppButton>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{marginBottom: '40px'}}/>
    </DialogContent>
  )
}

export default PrepareForImportEmployees;
