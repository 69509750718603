import React, {useState} from 'react';
import {Card, Grid, CardHeader} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppStepper from "../components/AppStepper";
import AppButton from "../../../../../../components/AppButton";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import {useEffectOnce} from "react-use";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppAlertDialog from "../../../../../../components/Dialogs/AppAlertDialog";
import useDialog from "../../../../../../Hooks/useDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
}));

const steps = [
  {
    label: 'Confirm Employee Residency',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Assign PRO',
    hint: 'Current Step',
    current: true
  }
];

function StepAssignPro({t, onChange, getField, getError, nextStep, addError, getUsers, getAsset, assignPro = () => {}, isPromise, clearUserIdField, clearError}) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [confirm, toggleConfirm] = useDialog();
  const [alert, toggleAlert] = useDialog();

  useEffectOnce(() => {
    getUsers('pro');
    clearUserIdField();
  })

  function handleNextStep() {
    if (!validate()) return;
    toggleConfirm();
  }

  function onConfirm() {
    toggleConfirm();
    setLoading(true);
    const result = assignPro('pro_id',getField('user_id'));
    if (isPromise(result)) {
      result.then(() => {
        const fields = [{
          field: 'assign_pro',
          value: getField('user_id'),
        }];
        const messages = ['Assigning PRO to a candidate'];

         nextStep({ content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0, });
      }).catch((err) => {
        setLoading(false);
      });
    }
  }

  function validate() {
    let error = false;
    if (!getField('user_id')) {
      addError('user_id', 'Required Field', t('required_field', {name: t('tracker:assign_pro') }));
      error = true;
    }
    return !error
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={5} alignItems={"center"} justify={"center"}>
        <Grid item xs={12} md={12} lg={7}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <AppAutocomplete
            label={t('tracker:assign_pro')}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
            onChange={onChange}
            getError={getError}
            clearError={clearError}
            getField={getField}
            items={getAsset('pros', [])}
            name={'user_id'}/>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <AppButton isLoading={isLoading} fullWidth onClick={handleNextStep}>
            {t('tracker:button_go_to_next_step')}
          </AppButton>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
      <AppAlertDialog open={alert} onOk={toggleAlert} text={t('tracker:you_didnt_assign_typist')}/>
    </Card>
  )
}

export default StepAssignPro;
