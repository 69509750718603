import React from "react";
import ActivityLogsView from "./ActivityLogsView";

function ActivityLogsContainer() {

  return (
   <ActivityLogsView/>
  )
}

export default ActivityLogsContainer;
