import React from "react";
import StepUploadDocuments from "./Steps/StepUploadDocuments";
import StepDefault from "./Steps/StepDefault";
import StepReviewDocuments from "./Steps/StepReviewDocuments";
import StepAssignTypist from "./Steps/StepAssignTypist";
import StepCreateMolJobOffer from "./Steps/StepCreateMolJobOffer";
import StepJobMolOfferReview from "./Steps/StepJobMolOfferReview";
import SubstepUploadDocumentsResubmission from "./Substeps/SubstepUploadDocumentsResubmission";
import StepMolJobOfferResubmission from "./Steps/StepMolJobOfferResubmission";
import SubstepReviewDocumentsMolJobOfferResubmission from "./Substeps/SubstepReviewDocumentsMolJobOfferResubmission";
import StepUploadSignedMolJobOfferDocument from "./Steps/StepUploadSignedMolJobOfferDocument";
import StepCreateWorkPermitApprovalOnMohre from "./Steps/StepCreateWorkPermitApprovalOnMohre";
import StepWorkPermitReview from "./Steps/StepWorkPermitReview";
import SubstepDocumentReviewRequireWorkPermitResubmission from "./Substeps/SubstepDocumentReviewRequireWorkPermitResubmission";
import SubstepWorkPermitResubmission from "./Substeps/SubstepWorkPermitResubmission";
import StepCreateVisaApplication from "./Steps/StepCreateVisaApplication";
import StepVisaApplicationReview from "./Steps/StepVisaApplicationReview";
import SubstepRequireVisaApplicationResubmission from "./Substeps/SubstepRequireVisaApplicationResubmission";
import SubstepDocumentReviewVisaApprovalResubmission from "./Substeps/SubstepDocumentReviewVisaApprovalResubmission";
import SubstepVisaApplicationResubmission from "./Substeps/SubstepVisaApplicationResubmission";
import StepEmployeeEnterTheCountry from "./Steps/StepEmployeeEnterTheCountry";
import StepAdviseVisaAdminToInitiateChangeStatusProcedure from "./Steps/StepAdviseVisaAdminToInitiateChangeStatusProcedure";
import StepAssignPro from "./Steps/StepAssignPro";
import StepArrangeMedicalExam from "./Steps/StepArrangeMedicalExam";
import StepMeetCandidateAtMedicalCenter from "./Steps/StepMeetCandidateAtMedicalCenter";
import SubstepReArrangeMedicalExam from "./Substeps/SubstepReArrangeMedicalExam";
import StepMedicalExam from "./Steps/StepMedicalExam";
import SubstepRequireNewMedicalExam from "./Substeps/SubstepRequireNewMedicalExam";
import StepUploadForStamping from "./Steps/StepUploadForStamping";
import StepVisaStamping from "./Steps/StepVisaStamping";
import StepSendPassportToImmigration from "./Steps/StepSendPassportToImmigration";
import StepCollectEid from "./Steps/StepCollectEid";
import StepOnboardingCompleted from "./Steps/StepOnboardingCompleted";
import SubstepResubmitStatusProcedure from "./Substeps/SubstepResubmitStatusProcedure";
import StepCollectPassport from "./Steps/StepCollectPassport";
import StepMedicalInsuranceCard from "./Steps/StepMedicalInsuranceCard";
import StepRestartOnboarding from "./Steps/StepRestartOnboarding";
import SubstepRequireWorkPermitResubmission from "./Substeps/SubstepRequireWorkPermitResubmission";
import StepCompleteBioApplication from "./Steps/StepCompleteBioApplication";
import StepArrangeBioScan from "./Steps/StepArrangeBioScan";
import StepBioScan from "./Steps/StepBioScan";
import StepReArrangeBioScan from "./Steps/StepReArrangeBioScan";
import StepAssignMedical from "./Steps/StepAssignMedical";
import StepSubmitContract from "./Steps/StepSubmitContract";
import StepContractReview from "./Steps/StepContractReview";
import StepSubmitSignedLocalContract_47 from "./Steps/StepSubmitSignedLocalContract_47";
import StepSponsorCancellationForm from "./Steps/StepSponsorCancellationForm";
import StepSubmitSignedSponsorCancellation from "./Steps/StepSubmitSignedSponsorCancellation";
import StepLabourCardCancellation from "./Steps/StepLabourCardCancellation";
import StepResidencePermitCancellation from "./Steps/StepResidencePermitCancellation";
import StepInsuranceCancellation from "./Steps/StepInsuranceCancellation";
import StepIssueLocalContractId72 from "./Steps/StepIssueLocalContractId72";
import StepLabourCardSubmission from "./Steps/StepLabourCardSubmission";
import StepLabourCardApproval from "./Steps/StepLabourCardApproval";
import SubstepRequestLabourCardResubmissionId56 from "./Substeps/SubstepRequestLabourCardResubmissionId56";
import SubstepDocumentsReviewLabourCardResubmissionId57 from "./Substeps/SubstepDocumentsReviewLabourCardResubmissionId57";
import SubstepLabourCardResubmissionId58 from "./Substeps/SubstepLabourCardResubmissionId58";
import StepEidRenewalId63 from "./Steps/StepEidRenewalId63";
import StepCollectResidencePermitId64 from "./Steps/StepCollectResidencePermitId64";
import StepAssignDataEntrist73 from "./Steps/StepAssignDataEntrist73";
import StepSendDataToArchive74 from "./Steps/StepSendDataToArchive74";
import StepRestartOnboardingOld from "./Steps/StepRestartOnboardingOld";

function StepList({handleNextStep, assignUserToEmployee, handleCycleEmployee, stepOrder, ...rest}) {
    switch (stepOrder) {
      case 'default_step':
        return (<StepDefault {...rest}/>)
      case 'documents_upload':
        return (<StepUploadDocuments nextStep={handleNextStep} {...rest}/>)
      case 'documents_review':
        return (<StepReviewDocuments nextStep={handleNextStep} assignTypistSupervisor={assignUserToEmployee} {...rest}/>)
      case 'documents_resubmission':
        return (<StepUploadDocuments nextStep={handleNextStep} {...rest}/>)
      case 'assign_typist':
        return (<StepAssignTypist nextStep={handleNextStep} assignTypist={assignUserToEmployee} {...rest}/>)
      case 'create_job_mol_offer':
        return (<StepCreateMolJobOffer {...rest} nextStep={handleNextStep}/>)
      case 'job_mol_offer_review':
        return (<StepJobMolOfferReview {...rest} nextStep={handleNextStep}/>)
      case 'require_job_mol_offer_resubmission':
        return (<SubstepUploadDocumentsResubmission {...rest} nextStep={handleNextStep}/>)
      case 'documents_review_job_mol_offer_resubmission':
        return (<SubstepReviewDocumentsMolJobOfferResubmission {...rest} nextStep={handleNextStep}/>)
      case 'job_mol_offer_resubmission':
        return (<StepMolJobOfferResubmission {...rest} nextStep={handleNextStep}/>)
      case 'submit_signed_job_mol_offer':
        return (<StepUploadSignedMolJobOfferDocument {...rest} nextStep={handleNextStep} />)
      case 'work_permit_approval':
        return (<StepCreateWorkPermitApprovalOnMohre {...rest} nextStep={handleNextStep}/>)
      case 'work_permit_review':
        return (<StepWorkPermitReview {...rest} nextStep={handleNextStep} />)
      case 'require_work_permit_resubmission':
        return (<SubstepRequireWorkPermitResubmission {...rest} nextStep={handleNextStep} />)
      case 'documents_review_work_permit_resubmission':
        return (<SubstepDocumentReviewRequireWorkPermitResubmission {...rest} nextStep={handleNextStep} />)
      case 'work_permit_resubmission':
        return (<SubstepWorkPermitResubmission {...rest} nextStep={handleNextStep} />)
      case 'visa_application':
        return (<StepCreateVisaApplication {...rest} nextStep={handleNextStep} />)
      case 'visa_application_review':
        return (<StepVisaApplicationReview {...rest} nextStep={handleNextStep} />)
      case 'require_visa_application_resubmission':
        return (<SubstepRequireVisaApplicationResubmission {...rest} nextStep={handleNextStep} />)
      case 'documents_review_visa_application_resubmission':
        return (<SubstepDocumentReviewVisaApprovalResubmission {...rest} nextStep={handleNextStep} />)
      case 'visa_application_resubmission':
        return (<SubstepVisaApplicationResubmission {...rest} nextStep={handleNextStep} />)
      case 'confirm_employee_residency':
        return (<StepEmployeeEnterTheCountry {...rest} nextStep={handleNextStep} />)
      case 'change_visa_status':
        return (<StepAdviseVisaAdminToInitiateChangeStatusProcedure {...rest} nextStep={handleNextStep} />)
      case 'visa_status_resubmission':
        return (<SubstepResubmitStatusProcedure {...rest} nextStep={handleNextStep}/>)
      case 'assign_pro':
        return (<StepAssignPro {...rest} nextStep={handleNextStep} assignPro={assignUserToEmployee}/>)
      case 'arrange_medical_exam':
        return (<StepArrangeMedicalExam {...rest} nextStep={handleNextStep} />)
      case 'meet_employee_for_medical_exam_or_bio_scan':
        return (<StepMeetCandidateAtMedicalCenter {...rest} nextStep={handleNextStep} />)
      case 'rearrange_medical_exam':
        return (<SubstepReArrangeMedicalExam {...rest} nextStep={handleNextStep} />)
      case 'medical_exam':
        return (<StepMedicalExam {...rest} nextStep={handleNextStep} />)
      case 'arrange_repeated_medical_exam':
        return (<SubstepRequireNewMedicalExam {...rest} nextStep={handleNextStep} />)
      case 'complete_bio_application':
        return (<StepCompleteBioApplication {...rest} nextStep={handleNextStep} />)
      case 'arrange_bio_scan':
        return (<StepArrangeBioScan {...rest} nextStep={handleNextStep} />);
      case 'bioscan':
        return (<StepBioScan {...rest} nextStep={handleNextStep} />)
      case 'rearrange_bio_scan':
        return (<StepReArrangeBioScan {...rest} nextStep={handleNextStep} />)
      case 'assign_insurance_person':
        return (<StepAssignMedical{...rest} nextStep={handleNextStep} assignMedical={assignUserToEmployee} />)
      case 'medical_insurance_card':
        return (<StepMedicalInsuranceCard {...rest} nextStep={handleNextStep} />)
      case 'upload_for_visa_stamping':
        return (<StepUploadForStamping {...rest} nextStep={handleNextStep} />)
      case 'visa_stamping':
        return (<StepVisaStamping {...rest} nextStep={handleNextStep} />)
      case 'send_passport_to_immigration':
        return (<StepSendPassportToImmigration {...rest} nextStep={handleNextStep} />)
      case 'collect_eid':
        return (<StepCollectEid {...rest} nextStep={handleNextStep} />)
      case 'collect_passport':
        return (<StepCollectPassport {...rest} nextStep={handleNextStep} />)
      case 'completed':
        return (<StepOnboardingCompleted {...rest} nextStep={handleNextStep}/>)
      case 'issue_local_contract':
        return (<StepSubmitContract {...rest} nextStep={handleNextStep} />)
      case 'contract_review':
        return (<StepContractReview {...rest} nextStep={handleNextStep} />)
      case 'submit_signed_local_contract':
        return (<StepSubmitSignedLocalContract_47 {...rest} nextStep={handleNextStep} />)
      case 'labour_card_submission':
        return (<StepLabourCardSubmission {...rest} nextStep={handleNextStep}/>) /* Mold Step - upload filed + 3 fields */
      case 'labour_card_approval':
        return (<StepLabourCardApproval {...rest} nextStep={handleNextStep}/>) /* Mold Step - approve/reject */
      case 'request_labour_card_resubmission':
        return (<SubstepRequestLabourCardResubmissionId56 {...rest} nextStep={handleNextStep}/>) /* Mold Step - resubmit documents */
      case 'documents_review_labour_card_resubmission':
        return (<SubstepDocumentsReviewLabourCardResubmissionId57 {...rest} nextStep={handleNextStep}/>) /* Mold Step - review documents substep */
      case 'labour_card_resubmission':
        return (<SubstepLabourCardResubmissionId58 {...rest} nextStep={handleNextStep}/>) /* Mold Step - resubmit substeps with date and comments */
      case 'eid_renewal_submission':
        return (<StepEidRenewalId63 {...rest} nextStep={handleNextStep}/>) /* Mold Step - upload file, date field, 1 text filed */
      case 'collect_residence_permit':
        return (<StepCollectResidencePermitId64 {...rest} nextStep={handleNextStep}/>) /* Mold Step - upload multiple files, 2 date fields, confirm checkbox */
      case 'sponsor_cancellation_form':
        return (<StepSponsorCancellationForm {...rest} nextStep={handleNextStep}/>)
      case 'submit_signed_sponsorship_cancellation':
        return (<StepSubmitSignedSponsorCancellation {...rest} nextStep={handleNextStep}/>)
      case 'labour_card_cancellation':
        return (<StepLabourCardCancellation {...rest} nextStep={handleNextStep}/>)
      case 'residence_permit_cancellation':
        return (<StepResidencePermitCancellation {...rest} nextStep={handleNextStep}/>)
      case 'insurance_cancellation':
        return (<StepInsuranceCancellation {...rest} nextStep={handleNextStep}/>)
      case 'issue_local_contract_labour_card_renewal':
        return (<StepIssueLocalContractId72 {...rest} nextStep={handleNextStep}/>) /* Mold step - upload file + 1 text filed */
      case 'assign_data_entrist':
        return (<StepAssignDataEntrist73 assignDataEntrist={assignUserToEmployee} {...rest} nextStep={handleNextStep}/>)
      case 'archiving':
        return ((<StepSendDataToArchive74 {...rest} nextStep={handleNextStep} />))
      case 'restart_process':
        return (<StepRestartOnboardingOld {...rest} nextStep={handleNextStep} cycleEmployee={handleCycleEmployee} />)
      default:
        return <div/>
    }
}


export default StepList;
