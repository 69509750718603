import { createStore, combineReducers, applyMiddleware } from 'redux'
//import logger from 'redux-logger';
import auth from "./reducers/authReducer";
import info from "./reducers/infoReducer";

const reducers = combineReducers({ auth, info })
const middlewares = applyMiddleware();


const store = createStore(reducers, {}, middlewares);

export default store;
