import React from 'react';
import {Card, Grid, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppButton from "components/AppButton";
import AppConfirmDialog from "components/Dialogs/AppConfirmDialog";
import useDialog from "Hooks/useDialog";
import AppStepper from "../components/AppStepper";
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppTextField from "../../../../../../components/AppTextField";
import AppTimePickerField from "../../../../../../components/AppTimePickerField";
import AppCheckbox from "../../../../../../components/AppCheckbox";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Meet Employee at Medical Center',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Rearrange Medical Exam',
    hint: 'Current Step',
    current: true
  }
];

function SubstepReArrangeMedicalExam({t, getField, onChange, nextStep, addError, getError, clearError, clearFields, getAsset}) {
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const isSkipped = getField('skip_this_step', false);
  const isSkipEnabled = getField('process', [{}])[0].slug !== 'visa_card_renewal';

  console.log()

  function onConfirm() {
    toggleConfirm();
    const fields = [];

    if (!isSkipped) {
      fields.push({
        field: 'date_medical_exam',
        value: getField('date_medical_exam'),
      })

      fields.push({
        field: 'time_medical_exam',
        value: getField('time_medical_exam'),
      })

      fields.push({
        field: 'medical_center_address',
        value: getField('medical_center_address'),
      })

      fields.push({
        field: 'contact_person_number',
        value: getField('contact_person_number'),
      })

      fields.push({
        field: 'medical_exam_paid_fees',
        value: getField('medical_exam_paid_fees'),
      })
    }

    fields.push({
      field: 'skip_this_step',
      value: getField('skip_this_step', false),
    })

    const messages = ['Medical exam re-arranged'];

    nextStep({ content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0 });
    clearFields(['date_medical_exam', 'medical_center_address', 'contact_person_number', 'time_medical_exam', 'medical_exam_paid_fees'])
  }

  function validate() {
    let error = false
    if(isSkipped) return error

    if (!getField('date_medical_exam')) {
      addError('date_medical_exam', 'Field Required', t('required_field', {name: t('tracker:date_of_medical_exam') }));
      error = true;
    }
    if (!getField('medical_center_address')) {
      addError('medical_center_address', 'Field Required', t('required_field', {name: t('tracker:medical_center_address') }));
      error = true;
    }
    if (!getField('contact_person_number')) {
      addError('contact_person_number', 'Field Required', t('required_field', {name: t('tracker:contact_person_phone_number') }));
      error = true;
    }
    if (!getField('time_medical_exam')) {
      addError('time_medical_exam', 'Field Required', t('required_field', {name: t('tracker:time_of_medical_exam') }));
    }
    if (!getField('medical_exam_paid_fees')) {
      addError('medical_exam_paid_fees', 'Field Required', t('required_field', {name: 'Paid fees' }));
    }
    if (getError('medical_exam_paid_fees')) {
      error = true;
    }

    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }


  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInPast getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="date_medical_exam" label={t('tracker:date_of_medical_exam')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTimePickerField ampm={false} getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="time_medical_exam" label={t('tracker:time_of_medical_exam')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="medical_center_address" label={t('tracker:medical_center_address')} placeholder={t('tracker:medical_center_address')} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="contact_person_number" label={t('tracker:contact_person_phone_number')} placeholder={t('tracker:contact_person_phone_number')} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField price noNegative type={'number'} name={'medical_exam_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
            </Grid>
            { isSkipEnabled &&
              <Grid item xs={12}>
                <AppCheckbox getError={getError} clearError={clearError} getField={getField} onChange={onChange} name={'skip_this_step'} label={'Candidate already did Medical Exam - skip this step'} />
              </Grid>
            }
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default SubstepReArrangeMedicalExam;
