import { v4 as uuid } from 'uuid';

function useHelpers() {
  const varToString = varObj => Object.keys(varObj)[0];

  const daysRemaining = (date) => {
    const nowDate = new Date();
    const pastDate = new Date(date);
    const difference = pastDate - nowDate;
    return Math.floor( difference / (24*60*60*1000));
  }

  const findById = (arrayOfObjects = [] , id, idName= 'id') => {
    return arrayOfObjects.find(item => item[idName] === id) || {};
  }

  const download = (url, filename, baseUrl = process.env.REACT_APP_API_URL) => {
    fetch(`${baseUrl}${url}`).then(function(t) {
      return t.blob().then((b)=>{
          const a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", filename);
          a.click();
        }
      );
    });
  }

  const isPromise = (p) => {
    return p && Object.prototype.toString.call(p) === "[object Promise]";
  }

  const makeAsync = (callback, time = 0) => {
    setTimeout(() => {
      callback();
    }, time);
  }

  const convertToFloatAndCalculate = (...args) => {
    let total = 0;
    let NaNOnly = true;
    args.forEach(arg => {
      const res = parseFloat(arg);
      if (!isNaN(res)) NaNOnly = false;
      total += !isNaN(res) ? res : 0;
    })
    if (NaNOnly) return '';
    return total;
  }

  const getUUID = () => {
    return uuid();
  }

  const isEmail = (str) => {
    const regex = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$');
    return regex.test(str);
  }

  const validatePassword = (password, confirmPassword) => {
    const returnOpt = { error: false, message: '', type: null };
    if (password || confirmPassword) {

      if (password.length < 8) {
        returnOpt.error = true;
        returnOpt.type = 'length';
        returnOpt.message = 'Password must be at least 8 characters!';
      }

      if (password !== confirmPassword) {
        returnOpt.error = true;
        returnOpt.type = 'equality';
        returnOpt.message = 'Passwords must be equal!';
      }
    }
    return returnOpt;
  }

  const parseErrors = ({errors, error_name_prefix = ''}) => {
    const errorsObj = {};
    errors.forEach(item => {
      const field = `${error_name_prefix}${item.field}`;
      let msg = item.message;
      if (msg) {
        msg = msg.replaceAll('_', ' ');
        msg = msg.charAt(0).toUpperCase() + msg.slice(1);
        if (errorsObj[field]) msg = `${errorsObj[field]}, ${msg}`
      }
      errorsObj[field] = msg
    })
    return errorsObj
  }

  const printToCSV = (rows) => {
    // const rows = [
    //   ["name1", "city1", "some other info"],
    //   ["name2", "city2", "more info"]
    // ];
    return "data:text/csv;charset=utf-8," + rows.map(e => e.join(", ")).join("\n");
  }

  return {
    varToString, daysRemaining,
    findById, download,
    isPromise, makeAsync,
    convertToFloatAndCalculate, getUUID,
    isEmail, validatePassword,
    parseErrors, printToCSV
  };
}

export default useHelpers;
