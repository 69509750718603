import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppTextField from "../../../../../../components/AppTextField";
import AppCamera from "../../../../../../components/AppCamera";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Meet Employee at Medical Center',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Medical Exam',
    hint: 'Current Step',
    current: true
  }
];

// STEP 9bc
function StepMedicalExam({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, approveDocumentApi, addToArrayInAssets, clearFields }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const [confirm, toggleConfirm] = useDialog();
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const medical_exam = getField('medical_exam');

  const uploadDocumentsError = getError('medical_exam_upload_document_error');

  function onConfirm() {
    const passed = medical_exam === 'pass' ? true : medical_exam === 'failed' ? false : undefined;
    let error = validate(passed);

    if (!error) {
      let file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id });

      const approvedFields = [
        {
          field: 'medical_exam_status',
          value: getField('medical_exam'),
        },
        {
          field: 'file',
          upload_type: (file || {}).upload_type,
          value: (file || {}).name,
        },
        {
          field: 'medical_exam_approval_date',
          value: getField('medical_exam_approval_date'),
        }
      ];

      const rejectedFields = [
        {
          field: 'file',
          upload_type: (file || {}).upload_type,
          value: (file || {}).name,
        },
        {
          field: 'medical_exam_status',
          value: getField('medical_exam'),
        },
        // {
        //   field: 'medical_exam_rejection_reason',
        //   value: getField('medical_exam_rejection_reason',),
        // },
        // {
        //   field: 'rejection_reason',
        //   value: getField('medical_exam_rejection_reason',),
        // },
        {
          field: 'rejection_comments',
          value: getField('rejection_comments'),
        },
        {
          field: 'medical_exam_rejection_date',
          value: getField('medical_exam_rejection_date'),
        }
      ];
      const fields = passed ? approvedFields : rejectedFields;
      const messages = passed ? ['Candidate passed medical exam'] : ['Candidate failed medical exam'];

      nextStep({content: JSON.stringify({fields, messages}), resubmission: passed ? 0 : 1, additional_documents: 0});
      clearFields(['medical_exam', 'medical_exam_approval_date', 'medical_exam_rejection_reason', 'medical_exam_rejection_date', 'rejection_comments']);
      return;
    }
    toggleConfirm();
  }

  function validate(passed) {
    let error = false;

    if (passed === undefined) return true;

    // Rejected validations
    if (passed === false && !getField('medical_exam_rejection_date')) {
      addError('medical_exam_rejection_date', 'Required Field', t('required_field', {name: t('tracker:date_of_medical_exam_rejection') }));
      error = true;
    }

    // Approved validations
    if (passed && !getField('medical_exam_approval_date')) {
      addError('medical_exam_approval_date', 'Required Field', t('required_field', {name: t('tracker:date_of_medical_exam_approval') }));
      error = true
    }

    if ( (passed || passed === false ) && uploadedDocuments.length === 0) {
      addError('medical_exam_upload_document_error', t('tracker:please_upload_document'))
      error = true;
    }

    return error
  }

  function onUpload(data) {
    clearError('medical_exam_upload_document_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const passed = medical_exam === 'pass' ? true : medical_exam === 'failed' ? false : undefined;
    const error = validate(passed);
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
                {t('tracker:upload_document_button')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AppCamera onCapture={handleUpload} />
            </Grid>
          </Grid>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {t('tracker:medical_exam')}
          </Typography>
          <RadioGroup name="medical_exam" value={getField('medical_exam')} onChange={onChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'pass'} control={<Radio color="primary" />} label={t('tracker:radio_pass')} />
              <FormControlLabel value={'failed'} control={<Radio color="primary" />} label={t('tracker:radio_failed')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {(medical_exam === 'pass' || medical_exam === 'failed') &&
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {(documents.length === 0 && uploadedDocuments.length === 0) &&
            <UploadFile/>
            }
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                addAsset={addAsset}
                getAsset={getAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onDelete={handleDocumentDelete}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
        </Fragment>
        }

        {/* APPROVED */}
        {medical_exam === 'pass' &&
        <Fragment>
          <Grid item xs={12} lg={3} style={{ marginTop: '20px' }}>
            <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} clearError={clearError} getField={getField} name="medical_exam_approval_date" label={t('tracker:date_of_medical_exam_approval')} />
          </Grid>
        </Fragment>
        }
        {/* REJECTED */}
        {medical_exam === 'failed' &&
        <Fragment>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} lg={4} xl={3}>
                <AppTextField name={'rejection_comments'} label={'Comments of rejection'} onChange={onChange} getField={getField} multiline rows={3}/>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="medical_exam_rejection_date" label={t('tracker:date_of_medical_exam_rejection')} />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepMedicalExam;
