import React, {Fragment, useContext} from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BasicInfo from "./components/BasicInfo";
import GeneralInfo from "./components/GeneralInfo/index";
import ContactAndPassportInfo from "./components/ContactAndPassportInfo";
import SalaryInfo from "./components/SalaryInfo";
import {useEffectOnce} from "react-use";
import {EmployeeContext} from "../../EmployeesContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: '10px',
  },
}));

function EmployeeProfileForm() {
  const classes = useStyles();
  const {getUsersByRoles, getCompanies, isSuperAdmin, isVisaAdmin, isDataEntrist, isTypistSupervisor, getAllProcesses} = useContext(EmployeeContext);

  useEffectOnce(() => {
   if (isSuperAdmin() || isVisaAdmin()) {
     getUsersByRoles(['recruiter', 'visa_admin', 'pro', 'typist', 'medical_insurance', 'data_entrist']);
     getCompanies(true);
     getAllProcesses();
   } else if (isTypistSupervisor() || isDataEntrist()) {
     getUsersByRoles(['typist', 'data_entrist']);
     getCompanies(true);
     getAllProcesses();
   }
  })

  return (
    <Fragment>
      <Grid className={classes.root} container>
        <Grid item xs={12}>
          <BasicInfo/>
        </Grid>
        <Grid item xs={12}>
          <Grid className={classes.container} container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <ContactAndPassportInfo/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <GeneralInfo/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SalaryInfo/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default EmployeeProfileForm;
