import React, {forwardRef, useImperativeHandle, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
  useMediaQuery
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import AppTextField from "../AppTextField";
import useDialog from "../../Hooks/useDialog";
import AppAutocomplete from "../AppAutocomplete";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

function AppConfirmDialog({text, title, onClose = () => {}, onConfirm = () => {}, onUpdate, onChange, addError, clearError, getField, getAsset, getError, uploadedTypes = []}, ref) {
  const {t} = useTranslation();
  const classes = useStyles();
  const displayTitle = title || 'Upload Document';
  const displayText = text || 'Enter File Name'
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, toggle] = useDialog();
  const [params, setParams] = useState({});
  const docTypes = getAsset('documentTypes', []);
  const [disableSubmit, setDisableSubmit] = useState(false);


  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    confirm: handleSubmit,
  }));

  function handleOpen({name, disableName, document, setName, update}) {
    toggle()
    if (document && document.upload_type) {
      onChange(null, `${name}-doc-type`, document.upload_type)
      if (document.upload_type && !isDocTypeUnique(document.upload_type)) {
        addError(`${name}-doc-type`, 'This File Type Already Exist')
        setDisableSubmit(true);
      }
    };
    if (setName) onChange(null, name, document.name);
    setParams({name: `${name}`, disableName, document, update});
  }

  function handleClose() {
    setDisableSubmit(false);
    clearError(`${(params).name}-doc-type`);
    setParams({});
    toggle();
    onClose();
  }

  function handleSubmit() {
    let error = false;

    if (!getField((params || {}).name)) {
      addError((params || {}).name, 'Field Name is Required');
      error = true;
    }
    if (!getField(`${(params || {}).name}-doc-type`)) {
      addError(`${(params || {}).name}-doc-type`, 'Field Type is Required');
      error = true;
    }

    if (error) return;


    setParams({});
    toggle();
    if (params && params.update) {
      onUpdate({ document: params.document, upload_type: getField(`${(params || {}).name}-doc-type`)})
      return;
    }
    onConfirm();
  }

  const handleChangeUploadType = (event, name, value) => {
    if (uploadedTypes.includes(value) && value !== 'others') {
      addError(name, 'This File Type Already Exist');
      setDisableSubmit(true);
    } else if(disableSubmit) {
      clearError(name);
      setDisableSubmit(false);
    }
    onChange(event, name, value);
  }

  function isDocTypeUnique(value) {
    return !(uploadedTypes.filter(type => type === value).length > 1 && value !== 'others')
  }

  return (
    <Dialog open={open} fullScreen={fullScreen} aria-labelledby="dialog-title" disableBackdropClick disableEscapeKeyDown PaperProps={{style: {minWidth: '400px'}}} >
      <DialogTitle className={classes.dialogTitle}>{displayTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppTextField
              label={displayText}
              onChange={onChange}
              clearError={clearError}
              name={(params || {}).name}
              getField={getField}
              getError={getError}
              disabled={(params || {}).disableName}
            />
          </Grid>
          <Grid style={{flexGrow: 1}} item xs={12}>
            <AppAutocomplete
              itemValueUnit={'title'}
              style={{marginBottom: "3px"}}
              getOptionLabel={(option) => option.text}
              itemValueUnit={'value'}
              items={docTypes}
              name={`${(params || {}).name}-doc-type`}
              label={'Type'}
              getField={getField}
              getError={getError}
              clearError={clearError}
              onChange={handleChangeUploadType}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button disabled={disableSubmit} onClick={handleSubmit} color="primary">
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default forwardRef(AppConfirmDialog);
