
// structure of this file must not be changed !!!!, It is only for Select type fields.
export default {
    medical_insurance_card_category: {
        medical_insurance_card_provider: {
            DAMAN: ['ENHANCED GOLD', 'ENHANCED SILVER',
                    'CORE SILVER', 'CORE BRONZE',
                    'CORE BRONZE BASIC', 'SELECT GOLD',
                    'SELECT SILVER', 'SECURE SILVER',
                    'SECURE BRONZE', 'CLASSIC CHROME BASIC'],
            ADNIC: ['GOLD', 'PLATINUM']
        }
    }
}
