import {SET_GLOBAL_LOADING, SET_NAV_INFO} from "../CONSTANTS";

const initialState = {
  additionalNavText: '',
  globalLoading: false,
}

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_INFO:
      return {
        ...state,
        additionalNavText: action.payload,
    }
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      }
    default: return state;
  }
}

export default infoReducer;
