import {useState} from "react";

function usePagination({initLimit} = {}) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(initLimit || 10);

  function nextPage(_page = page + 1) {
    setPage(_page);
  }

  function changeLimit(limit) {
    setLimit(limit);
  }

  function getPaginationQuery({_page = page, _limit = limit} = {}) {
    return { page: _page + 1, limit: _limit  }
  }

  return {
    page,
    limit,
    changeLimit,
    nextPage,
    getPaginationQuery
  }
}

export default usePagination;
