import React, {Fragment} from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from "../../../../../../components/AppPickDateField";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppTextArea from "../../../../../../components/AppTextArea";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: 'red',
  }
}));

const steps = [
  {
    label: 'Create Job MOL Offer',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Job MOL Offer Review',
    hint: 'Current Step',
    current: true
  }
];

const rejection_reasons = [
  {
    value: 'Mismatching terms & conditions of MOHRE',
  },
  {
    value: 'Tawteen Locked',
  },
  {
    value: 'License expired',
  },
  {
    value: 'License not updated',
  },
  {
    value: 'No Quota Available',
  },
  {
    value: 'Others',
  }
]


// STEP 9bc
function StepJobMolOfferReview({ t, getAsset, addAsset, getField, onChange, getError, nextStep, uploadFile, isLoading, deleteDocument, isPromise, addError, clearError, addToArrayInAssets, approveDocumentApi, clearFields }) {
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const job_mol_offer_status = getField('job_mol_offer_status');
  const step_id = getAsset('candidate_step_id');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const documents = getAsset('documents', []);

  function onUpload(data) {
    clearError('job_mol_signed_file')
    return uploadFile(data)
  }

  function handleDocumentDelete(data) {
     return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function onNextStep() {
    const approved = job_mol_offer_status === 'approved' ? true : job_mol_offer_status === 'rejected' ? false : undefined
    const error = validate(approved);
    if (!error) toggleConfirm();
  }

  function handleNextStep() {
    toggleConfirm();
    const approved = job_mol_offer_status === 'approved'
    const additional_documents = getField('job_mol_offer_adding_data_entrist') ? 1 : 0;
    let file = null;
    if (approved) {
      file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id });
    }

    const approvedFields = [
      {
        field: 'job_mol_offer_status',
        value: getField('job_mol_offer_status'),
      },
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name,
      },
      {
        field: 'job_mol_offer_approval_date',
        value: getField('job_mol_offer_approval_date'),
      },
      {
        field: 'job_mol_offer_st_number',
        value: getField('job_mol_offer_st_number'),
      },
      {
        field: 'job_mol_offer_paid_fees',
        value: getField('job_mol_offer_paid_fees'),
      },
      {
        field: 'document_id',
        value: (file || {}).id,
      }
    ];

    const rejectedFields = [
      {
        field: 'job_mol_offer_status',
        value: getField('job_mol_offer_status'),
      },
      {
        field: 'rejection_reason',
        value: getField('job_mol_offer_rejection_reason',)
      },
      {
        field: 'rejection_comments',
        value: getField('rejection_comments'),
      },
      {
        field: 'job_mol_offer_rejection_date',
        value: getField('job_mol_offer_rejection_date'),
      },
      {
        field: 'job_mol_offer_paid_fees',
        value: getField('job_mol_offer_paid_fees'),
      },
      {
        field: 'job_mol_offer_adding_data_entrist',
        value: additional_documents
      }
    ];
    const fields = approved ? approvedFields : rejectedFields;
    const messages = approved ? ['Job mol offer approved'] : ['Job mol offer rejected'];

    nextStep({content: JSON.stringify({fields, messages}) , resubmission: approved ? 0 : 1, additional_documents});
    clearFields(['job_mol_offer_status', 'job_mol_offer_rejection_date', 'job_mol_offer_rejection_reason', 'job_mol_offer_approval_date', 'rejection_comments', 'job_mol_offer_adding_data_entrist', 'job_mol_offer_paid_fees', 'job_mol_offer_st_number'])
  }

  function validate(approved) {
    let error = false;

    if (approved === undefined) return true;

    // Rejected validations
    if (approved === false && !getField('job_mol_offer_rejection_date')) {
      addError('job_mol_offer_rejection_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_job_mol_rejection') }));
      error = true;
    }
    if (approved === false && !getField('job_mol_offer_rejection_reason')) {
      addError('job_mol_offer_rejection_reason', 'Required Field', t('required_field', {name: t('tracker:reason_for_rejection') }));
      error = true
    }

    // Approved validations
    if (approved && !getField('job_mol_offer_approval_date')) {
      addError('job_mol_offer_approval_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_job_mol_approval') }));
      error = true
    }

    if (approved && !getField('job_mol_offer_st_number')) {
      addError('job_mol_offer_st_number', 'Required Field', t('required_field', {name: 'St Number' }));
      error = true
    }

    if (approved && uploadedDocuments.length === 0) {
      addError('job_mol_signed_file', 'Please Upload Document')
      error = true;
    }

    if (!getField('job_mol_offer_paid_fees')) {
      addError('job_mol_offer_paid_fees', 'Required Field', t('required_field', {name: 'Paid fees' }));
      error = true
    }

    if (getError('job_mol_offer_paid_fees')) {
      error = true;
    }

    return error
  }

  function NextButton(props) {
    return (<AppButton disabled={!job_mol_offer_status || isLoading}{...props} onClick={onNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button"  type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {t('tracker:job_mol_offer')}
          </Typography>
          <RadioGroup aria-label="job_mol_offer_status" name="job_mol_offer_status" value={job_mol_offer_status} onChange={onChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'approved'} control={<Radio color="primary" />} label={t('tracker:radio_approved')} />
              <FormControlLabel value={'rejected'} control={<Radio color="primary" />} label={t('tracker:radio_rejected')} />
            </Grid>
          </RadioGroup>
        </Grid>

        {/* APPROVED */}
        { job_mol_offer_status === 'approved' &&
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: getError('job_mol_signed_file')})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            { (documents.length === 0 && uploadedDocuments.length === 0) &&
              <UploadFile/>
            }
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                getAsset={getAsset}
                addAsset={addAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                getField={getField}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} getField={getField} clearError={clearError} name="job_mol_offer_approval_date" label={t('tracker:date_of_the_job_mol_approval')} />
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <AppTextField name={'job_mol_offer_st_number'} label={'St Number'} onChange={onChange} getField={getField} getError={getError} clearError={clearError}/>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <AppTextField price noNegative type={'number'} name={'job_mol_offer_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
          </Grid>
        </Fragment>
        }

        {/* REJECTED */}
        {job_mol_offer_status === 'rejected' &&
        <Fragment>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4} xl={3}>
                    <AppAutocomplete
                      getError={getError}
                      getField={getField}
                      items={rejection_reasons}
                      clearError={clearError}
                      onChange={onChange}
                      itemValueUnit={'value'}
                      name={'job_mol_offer_rejection_reason'}
                      label={t('tracker:reason_for_rejection')}
                      getOptionLabel={(option) => option.value}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} xl={3}>
                    <AppTextField price noNegative type={'number'} name={'job_mol_offer_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <AppTextField name={'rejection_comments'} label={'Comments Of Rejection'} onChange={onChange} getField={getField} multiline rows={3}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4} xl={3}>
                    <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} getField={getField} clearError={clearError} name="job_mol_offer_rejection_date" label={t('tracker:date_of_the_job_mol_rejection')} />
                  </Grid>
                  <Grid item xs={12} lg={5} xl={4}>
                    <AppCheckbox style={{ marginTop: '6px' }} getField={getField} onChange={onChange} name={'job_mol_offer_adding_data_entrist'} label={'Send to Data Entrist - adding additional documents'}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialogs */}
      <AppConfirmDialog open={confirm} title={t('tracker:go_to_next_step')} onCancel={toggleConfirm} onConfirm={handleNextStep}/>
    </Card>
  )
}

export default StepJobMolOfferReview;
