export const stepsAndRoles = [
 /* {
    step_id: 1,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2}
  },*/
  {
    step_id: 2,
    role: {id: 6, slug: "typist_supervisor", title: "Typist Supervisor", laravel_through_key: 4},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 2,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 3,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
  },
  {
    step_id: 4,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
  },
  {
    step_id: 6,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    title: 'If APPROVED',
    noPush: true,
    type: 'approved'
  },
  {
    step_id: 6,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 7,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
  },
  {
    step_id: 8,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 8,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 9,
    role: {id: undefined, slug: "candidate", title: "Candidate"},
    title: 'If APPROVED',
    noPush: true,
    type: 'approved'
  },
  {
    step_id: 9,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 12,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 10,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
  },
  {
    step_id: 13,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
  },
  {
    step_id: 14,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 14,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 17,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    title: 'If APPROVED',
    type: 'approved',
    noPush: true,
  },
  {
    step_id: 17,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 18,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
  },
  {
    step_id: 19,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 19,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 43,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 44,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
  },
  {
    step_id: 45,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 45,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
    title: 'If REJECTED',
    type: 'rejected'
  },
  {
    step_id: 21,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    type: 'entered_the_country'
  },
  {
    step_id: 22,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    title: 'If APPROVED',
    type: 'changed'
  },
  {
    step_id: 23,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    title: 'If APPROVED',
    type: 'approved'
  },
  {
    step_id: 24,
    role: {id: 5, slug: "pro", title: "PRO", laravel_through_key: 38}
  },
  {
    step_id: 25,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    noPush: true,
  },
  {
    step_id: 27,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    noPush: true,
  },
  {
    step_id: 28,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
    title: 'If APPROVED',
    type: 'pass'
  },
  {
    step_id: 30,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    title: 'EID with BIO',
    type: 'eid_with_bio'
  },
  {
    step_id: 30,
    role: {id: 5, slug: "pro", title: "PRO", laravel_through_key: 38},
    title: 'EID without BIO',
    type: 'eid_without_bio'
  },
  {
    step_id: 29,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    noPush: true,
  },
  {
    step_id: 31,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    noPush: true,
  },
  {
    step_id: 33,
    role: {id: 0, slug: "candidate", title: "Candidate"},
    noPush: true,
  },
  {
    step_id: 34,
    role: {id: 3, slug: "medical_insurance", title: "Medical Insurance", laravel_through_key: 35},
  },
  {
    step_id: 32,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    title: 'ATTEND'
  },
  /*
  {
    step_id: 32,
    role: {id: 5, slug: "pro", title: "PRO", laravel_through_key: 38},
    title: 'Without EID'
  },*/
  {
    step_id: 35,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
  },
  {
    step_id: 37,
    role: {id: 5, slug: "pro", title: "PRO", laravel_through_key: 38},
  },
  {
    step_id: 40,
    role: {id: 4, slug: "visa_admin", title: "Visa Admin", laravel_through_key: 2},
    type: 'confirm_passport'
  },
  {
    step_id: 40,
    role: {id: 0, slug: "recruiter", title: "Recruiter"},
    type: 'recruiter'
  },
  {
    step_id: 41,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    title: 'If APPROVED',
    type: 'confirm_completed'
  },
  {
    step_id: 41,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    title: 'If REJECTED',
    type: 'confirm_rejected'
  },
  {
    step_id: 67,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  {
    step_id: 69,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 70,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  {
    step_id: 71,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  {
    step_id: 81,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 83,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 84,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 85,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  {
    step_id: 92,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 93,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 94,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  {
    step_id: 97,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 98,
    role: {id: 0, slug: "everyone", title: "Everyone"},
  },
  {
    step_id: 99,
    role: {id: 0, slug: "everyone", title: "Everyone"},
    type: "rejected"
  },
  /*{
    step_id: 41,
    role: {id: 8, slug: "recruiter", title: "Recruiter"},
  },
  {
    step_id: 41,
    role: {id: 5, slug: "pro", title: "PRO", laravel_through_key: 38},
  },
  {
    step_id: 41,
    role: {id: 7, slug: "typist", title: "Typist", laravel_through_key: 11},
  },
  {
    step_id: 41,
    role: {id: 2, slug: "data_entrist", title: "Data Entrist", laravel_through_key: 1},
  },
  {
    step_id: 41,
    role: {id: 6, slug: "typist_supervisor", title: "Typist Supervisor", laravel_through_key: 4},
  }*/

]
