import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import AppAutocomplete from 'components/AppAutocomplete';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Submit Contract',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Contract Review',
    hint: 'Current Step',
    current: true
  }
];

// STEP 9bc
function StepContractReview({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, addToArrayInAssets, approveDocumentApi, clearFields, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const [confirm, toggleConfirm] = useDialog();
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);

  const uploadDocumentsError = getError('contract_approve_document_error');

  async function onConfirm() {
    toggleConfirm();
    let file = uploadedDocuments[0];
    const additional_documents = getField('contract_adding_data_entrist') ? 1 : 0;
    approveDocumentApi({ documentId: file.id});

    const approvedFields = [
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name,
      },
      {
        field: 'contract_date_approve',
        value: getField('contract_date_approve'),
      },
    ];

    const fields = approvedFields
    const messages = ['Visa application approved'];
    try {
      nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents});
      clearFields(['visa_status', 'contract_date_approve',])
    } catch (e) {

    }
  }

  function validate() {
    let error = false;

    if (!getField('contract_date_approve')) {
      addError('contract_date_approve', 'Required Field', t('required_field', {name: t('tracker:date_of_the_visa_application_approval') }));
      error = true
    }
    if (uploadedDocuments.length === 0) {
      addError('contract_approve_document_error', t('tracker:please_upload_document'));
      error = true;
    }
    return error
  }

  function onUpload(data) {
    clearError('contract_approve_document_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
   return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            { (documents.length === 0 && uploadedDocuments.length === 0) &&
              <UploadFile/>
            }
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                addAsset={addAsset}
                getAsset={getAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onDelete={handleDocumentDelete}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} lg={4} xl={3}>
                <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="contract_date_approve" label={'Date of Local Contract Approval'} />
              </Grid>
            </Grid>
          </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepContractReview;
