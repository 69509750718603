import React, {useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function AppIconWithLoading({children, onClick= () => {}, size= 24, color= 'secondary', marginRight = '20px', marginLeft = 0, style}) {
  const [loading] = useState(false);


  function handleClick() {
    onClick();
    /*setLoading(true);
    setTimeout(() => {
      onClick()
        .catch(() => {
        setLoading(false);
      })
    }, 100);*/
  }

  const loadingStyle = {marginLeft, marginRight, display: 'inline-block'}

  if (loading) {
    return (
      <div style={loadingStyle}>
        <CircularProgress style={style} color={color} size={size} />
      </div>
    )
  }
  return (
    <span onClick={handleClick}>
      {children}
    </span>
  )
}

export default AppIconWithLoading;
