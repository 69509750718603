import React, {useEffect} from "react";
import CandidateFormView from "./CandidateFormView";
import useRouteParams from "../../Hooks/useRouteParams";
import useAPI from "../../Hooks/useAPI";
import {CANDIDATE_FORM, CANDIDATE_UPLOAD} from "../../navigation/CONSTANTS";
import useForm from "../../Hooks/useForm";
import {candidateUploadInfoService, candidateUploadService} from "../../services/employeesService";
import Spinner from "../../components/Spinner";
import {uploadDocumentService} from "../../services/documentsService";
import { v4 as uuid } from 'uuid';

export const CandidateUploadContext = React.createContext(null);

const CandidateFormContainer = function() {
  const { sendRequest, getApiError, clearApiError } = useAPI();
  const { onChange, addToArrayInAssets, getAsset, addAsset, addError, getField, getLocalError, clearLocalError } = useForm();

  const {candidate_id: upload_candidate_id, token: upload_token} = useRouteParams(`/${CANDIDATE_UPLOAD}`, {});
  const {candidate_id: form_candidate_id, token: form_token} = useRouteParams(`/${CANDIDATE_FORM}`, {});

  const candidate_id = upload_candidate_id ?? form_candidate_id;
  const token = upload_token ?? form_token;

  useEffect(() => {
    addAsset('isLoading', true)
    if(candidate_id && token) getCandidateUploadInfo();
  }, [candidate_id, token]);

  function getError(field, reserved) {
    return getApiError(field) || getLocalError(field) || reserved;
  }

  function clearError(field) {
    clearLocalError(field);
    clearApiError(field);
  }

  async function getCandidateUploadInfo() {
    try {
      addAsset('isLoading', true)
      addAsset('candidate_id', candidate_id);
      addAsset('token', token);
      const {data} = await sendRequest(candidateUploadInfoService, {params: {token, candidate_id}});
      const parsedData = JSON.parse(data);
      const formType = parsedData.form_type;
      const contractForm = parsedData.form_subtype === 'contract'
      console.log(parsedData)

      if (formType === 'document_upload') addAsset('filesToUpload', parsedData.fields.filter(field => field.type === 'file').map(field => {
        field.name = `${field.name}-uuid-${uuid()}`
        return field;
      }));

      if (contractForm) addAsset('isFormContract', true);

      if (formType === 'insurance') addAsset('formFields', parsedData.fields);

      addAsset('formType', formType);
      addAsset('isLoading', false);
    } catch (e) {
      addAsset('isLoading', false);
      addAsset('isFormError', true);
      //closeApp();
    }
  }

  function prepareFilesIdsToSend()  {
    const filesToSend = {};
    getAsset('filesToUpload', []).forEach(fileToUpload => {
      const fileName = fileToUpload.name.split('-uuid-')[0];
      const isTypeOthers = fileName === 'others' // files with type "others" should always be sent as array
      const file = getAsset(fileToUpload.name);
      delete file.preview;
      if (filesToSend[fileName]) {
        filesToSend[fileName] = [filesToSend[fileName], file].flat();
      } else if(isTypeOthers) {
        filesToSend[fileName] = [file];
      } else {
        filesToSend[fileName] = file;
      }
    });
    return filesToSend;
  }

  function prepareInsuranceToSend() {
    const formFields = getAsset('formFields', []);
    if (!formFields[0]) return null;
    return getField(formFields[0].name);
  }

  const uploadFile = async ({file, name, upload_type, errorName, noStep}) => {
    const {data} = await sendRequest(uploadDocumentService, {payload: {file, name, upload_type, ...(!noStep && { candidate_step_id: getAsset('candidate_step_id', 1) }), candidate_id}, loading: true, useFormData: true, errorName});
    return data;
  };

  async function submitForm() {
    try {
      addAsset('isLoadingUpload', true);
      const formType = getAsset('formType');
      let formData = null;

      if (formType === 'insurance') formData = prepareInsuranceToSend();
      if (formType === 'document_upload') formData = prepareFilesIdsToSend();

      console.log(formData);

      await sendRequest(candidateUploadService,
        {
          payload: {
            id: candidate_id,
            hash: token,
            form_type: getAsset('formType'),
            form_data: formData,
          },
          useFormData: true,
        })

      addAsset('isDone', true);
      addAsset('isLoadingUpload', false);
    } catch (e) {
      addAsset('isLoadingUpload', false);
      console.error(e)
    }
  }

  const props = {
    onChange, addToArrayInAssets,
    getAsset, addAsset, addError,
    getField, getError, clearError,
    submitForm, uploadFile
  }

  const contextProps = {
    uploadedDocuments: getAsset('uploadedDocuments', [])
  }

  return (
    <CandidateUploadContext.Provider value={contextProps}>
      {getAsset('isLoading', null) === false ? <CandidateFormView {...props} /> : <Spinner/>}
    </CandidateUploadContext.Provider>
  )
}

export default CandidateFormContainer;
