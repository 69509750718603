import React from 'react'
import { useEffectOnce } from 'react-use';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import AppAutocomplete from "../../../components/AppAutocomplete";

const useStyles = makeStyles((theme) => ({
  cardPadding: {
    padding: "10px 30px 24px 30px"
  },
  gridContainer: {
    marginTop: "20px"
  }
}));

function CreateCompany({onChange, getField, getError, clearError, getAsset, saveCompany, getRecruiters, t, isLoading}) {
  const classes = useStyles();

  useEffectOnce(() => {
    getRecruiters()
  }, )

  return (
    <Card elevation={1} className={classes.cardPadding}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="title" label={t('companies:title')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="email" label={t('companies:email')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="phone" label={t('companies:phone')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="website" label={t('companies:website')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="address" label={t('companies:address')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="address2" label={t('companies:address2')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="city" label={t('companies:city')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="postal_code" label={t('companies:postal_code')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="state" label={t('companies:state')}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppAutocomplete
            clearError={clearError}
            getError={getError}
            getField={getField}
            items={getAsset('recruiters', [])}
            onChange={onChange}
            name="recruiter_id"
            label={t('companies:select_recruiter')}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="description" label={t('companies:description')} multiline rows={12}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppButton fullWidth onClick={saveCompany} isLoading={isLoading}>
            {t('companies:button_save_company')}
          </AppButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CreateCompany
