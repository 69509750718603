import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.variables.secondaryBackgroundColor,
    zIndex: 10
  },
  blur: {
    backgroundColor: "#ededede0",
    // backgroundColor: "#ffffff",
    zIndex: '99999'
  }
}));

function Spinner ({fullScreen, className, blur}) {
  const classes = useStyles();
  const rootClasses = clsx({
    [classes.root]: fullScreen,
    [classes.blur]: blur
  })

  return (
    <Grid container justify="center" alignItems="center" className={clsx([rootClasses, className])}>
      <CircularProgress color="primary" />
    </Grid>
  );
}

export default Spinner;
