import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppStepper from '../components/AppStepper';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppCheckbox from "../../../../../../components/AppCheckbox";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
}));

const steps = [
  {
    label: 'Collect Passport',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Onboarding Completed!',
    hint: 'Current Step',
    current: true
  }
];

// STEP 28
function StepOnboardingCompleted({ getAsset, getField, onChange, addError, getError, nextStep, clearError }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const stepFields = getAsset('stepFields');
  const [confirm, toggleConfirm] = useDialog();
  const process = getField('process', [{}]);

  function onConfirm() {
    toggleConfirm();
    const fields = stepFields.map(step => {
      return {field: step.name, value: getField(step.name)}
    });
    const messages = ['Onboarding completed']

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
  }

  function validate() {
    let error = false;

    if (!getField('confirm_completed')) {
      addError('confirm_completed', `Please Confirm ${process[0].title || ''} Completion`);
      error = true;
    }

    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }


  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '140px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={12}>
          <AppCheckbox getField={getField} clearError={clearError} getError={getError} name={'confirm_completed'} onChange={onChange} label={`Confirm ${process[0].title || ''} Completion`}/>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog open={confirm} onConfirm={onConfirm} onCancel={toggleConfirm} title={`${process[0].title || ''} Completed!`} text={`Are you sure you want to complete ${process[0].title || ''} for this candidate?`}/>
    </Card>
  )
}

export default StepOnboardingCompleted
