import React from "react";
import MaintenanceView from "./MaintenanceView";

function MaintenancePage () {
  return(
    <MaintenanceView/>
  )
}

export default MaintenancePage;
