import {SET_GLOBAL_LOADING, SET_NAV_INFO} from "../CONSTANTS";

export const setNavInfo = (payload) => {
  return {
    type: SET_NAV_INFO,
    payload,
  }
}

export const setGlobalLoading = (payload) => {
  return {
    type: SET_GLOBAL_LOADING,
    payload,
  }
}
