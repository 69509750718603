import React from "react";
import CompaniesContainer from "./CompaniesContainer";

function Companies() {

  return (
    <CompaniesContainer/>
  )
}

export default Companies;
