import React, {useEffect, useCallback} from "react";
import NotificationsView from "./NotificationsView";
import useForm from "../../Hooks/useForm";
import {useTranslation} from "react-i18next";
import useAPI from "../../Hooks/useAPI";
import {
  getStepNotificationsService,
  getSingleNotificationService,
  getNotificationsListService, createNotificationService, updateNotificationService
} from "../../services/notifications";
import useRedirect from "../../Hooks/useRedirect";
import useRouteParams from "../../Hooks/useRouteParams";
import {EDIT_NOTIFICATION} from "../../navigation/CONSTANTS";
import {stepsAndRoles} from "./assets";

function NotificationContainer() {
  const { t } = useTranslation();
  const { sendRequest, isLoading } = useAPI();
  const { getAsset, getField, addAsset, getLocalError, clearLocalError, onChange, clearFields, clearAssets, addToArrayInAssets, fields, updateArrayElementByObjectInAssets, addField } = useForm();
  const { goToEditNotification } = useRedirect();
  const editPageProp = useRouteParams(`/${EDIT_NOTIFICATION}`);
  const { stepId } = editPageProp || {};
  const steps = getAsset('steps');
  const notifications = getAsset('notifications');

  useEffect(() => {
    clearAssets();
    getNotificationsList();
    getNotificationSteps();
  }, []);

  useEffect(() => {
    clearFields();
    if (stepId) getStepData(true);
  }, [stepId])

  useEffect(() => {
    if (!getAsset('NotificationLoaded', false) && stepId && steps && notifications) {
      addAsset('NotificationLoaded', true);
      getStepData(true);
    }
  }, [steps, notifications])

  const getNotificationSteps = () => sendRequest(getStepNotificationsService,
    {
      loading: true
    }).then(({data}) => {
      const allSteps = [];

      data.forEach(step => {
        allSteps.push(step);
      })

      addAsset('steps', allSteps);
  })

  const getStepData = useCallback((getNotification) => {
    const step = getAsset('steps', []).find((step) => step.id == stepId);
    if (step && getNotification) {
      const roles = stepsAndRoles.filter(st => st.step_id == stepId).map(st => st.role);
      const notifications = getAsset('notifications', []);
      roles.forEach((role) => {
        notifications.forEach((not) => {
          if (not.step_id == stepId && not.role_id == role.id && (!role.type || role.type === not.type)) {
            console.log('not => ', not)
            addField(`email_title-${stepId}-${role.id}${getTypeString(not)}`, not.email_title);
            addField(`email_message-${stepId}-${role.id}${getTypeString(not)}`, not.email_message);
            addField(`email_send-${stepId}-${role.id}${getTypeString(not)}`, not.email_send);
            addField(`email_cc-${stepId}-${role.id}${getTypeString(not)}`, not.email_cc);
            addField(`push_title-${stepId}-${role.id}${getTypeString(not)}`, not.push_title);
            addField(`push_message-${stepId}-${role.id}${getTypeString(not)}`, not.push_message);
            addField(`push_send-${stepId}-${role.id}${getTypeString(not)}`, not.push_send);
            addField(`candidate-${stepId}-${role.id}${getTypeString(not)}`, not.candidate);
          }
        })
      })
    }
    return step || {};
  })

  const getNotificationsList = () => sendRequest(getNotificationsListService).then(({data}) => {
    addAsset('notifications', data);
  })

  const getSingleNotification = (notification_id) => sendRequest(getSingleNotificationService,
    {
      params: { notification_id },
    }).then(({data}) => {
      console.log(data);
  })

  const createNotification = (payload) => sendRequest(createNotificationService,{
    payload
  }).then(({data}) => {
    addToArrayInAssets('notifications', data);
  })

  const updateNotification = (payload) => sendRequest(updateNotificationService,{
    payload,
    params: { notification_id: payload.id }
  }).then(({data}) => {
     updateArrayElementByObjectInAssets('notifications', data);
  })

  const saveNotification = () => {
    try {
      addAsset('SaveNotificationLoading', true)
      const oldNotifications = getAsset('notifications', []);
      const notificationsForUpdate = [];
      const notificationsForCreation = [];
      Object.keys(fields).forEach(key => {
        const parsedkey = key.split('-');
        const fieldName = parsedkey[0];
        const step_id = parsedkey[1];
        const role_id = parsedkey[2];
        const type = parsedkey[3] ? parsedkey[3] : '';

        // Update notification
        const oldNot = oldNotifications.find((not) => not.step_id == step_id && not.role_id == role_id && (!type || type === not.type))
        if (oldNot) {
          const updateNotIndex = notificationsForUpdate.findIndex((not) => not.step_id == step_id && not.role_id == role_id && (!type || type === not.type));
          if (updateNotIndex > -1) {
            notificationsForUpdate[updateNotIndex][fieldName] = fields[key];
          } else {
            notificationsForUpdate.push({ step_id, role_id, [fieldName]: fields[key], id: oldNot.id, ...(type && { type }), group: "second" });
          }

          // Created Notification
        } else {
          const createNotIndex = notificationsForCreation.findIndex((not) => not.step_id == step_id && not.role_id == role_id && (!type || type === not.type));
          if (createNotIndex > -1) {
            notificationsForCreation[createNotIndex][fieldName] = fields[key];
          } else {
            notificationsForCreation.push({ step_id, role_id, [fieldName]: fields[key], ...(type && { type }), group: "second" });
          }
        }
      })
      const promises = [];

      notificationsForCreation.forEach((not) => {
        promises.push( createNotification(not));
      })
      notificationsForUpdate.forEach((not) => {
        promises.push(updateNotification(not));
      })

      Promise.all(promises).then(() => {
        addAsset('SaveNotificationLoading', false);
      })
    } catch (e) {
      addAsset('SaveNotificationLoading', false)
    }
  }

  function getTypeString(step) {
    if(step && step.type) {
      return `-${step.type}`
    }
    return '';
  }

  const propsToForward = {
    getNotificationSteps,
    getSingleNotification,
    getStepData,
    saveNotification,
    getAsset,
    getField,
    isLoading,
    getError: getLocalError,
    clearError: clearLocalError,
    goToEditNotification,
    addToArrayInAssets,
    onChange,
    getTypeString,
    t,
  };
  return (
    <NotificationsView {...propsToForward}/>
  )
}

export default NotificationContainer;
