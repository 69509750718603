import React, {useRef, Fragment, useContext} from 'react'
import {Card, Grid, MenuItem, InputLabel, Select, FormControl, CardHeader, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppTextField from "../../../../../../components/AppTextField";
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import AppButton from "../../../../../../components/AppButton";
import DependanceDialog from "../DependanceDialog";
import {EditOutlined, DeleteOutlined} from "@material-ui/icons";
import { maritalStatuses, nationalityItems, religionItems, sectItems } from "./GeneralInfoDropdownItems"
import clsx from "clsx";
import {EmployeeContext} from "../../../../EmployeesContainer";


const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bolder",
    fontSize: "14px",
    color: "#222222",
    padding: "10px 10px",
    textTransform: "uppercase"
  },
  gender: {
    marginLeft: "10px",
    paddingTop: "9px"
  },
  textFields: {
    padding: "24px"
  },
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  },
  icon: {
    cursor: 'pointer',
    '&:first-of-type': {
      marginRight: '15px',
    }
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

function showDependance(value) {
  if (!value || value === maritalStatuses[1].name) return false;
  return !!maritalStatuses.find(status => status.name === value);
}

function GeneralInfo() {
  const {t, onChange, getField, getError, addToArrayInAssets, getAsset, getUUID, updateArrayElementByObjectInAssets, deleteItemFromArrayByIdInAssets, clearError, isEmail} = useContext(EmployeeContext);
  const classes = useStyles();
  const dependanceDialog = useRef(null);
  const dependents = getAsset('dependents', []);

  function openDependanceModal(dependant) {
    dependanceDialog.current.open(dependant)
  }

  function onSaveDependent(dependent) {
    if (dependent.id || dependent.local_id) {
      updateArrayElementByObjectInAssets('dependents', dependent);
    } else {
      addToArrayInAssets('dependents', { ...dependent, local_id: getUUID() });
    }
  }

  function onDeleteDependant(dependant) {
    const id = dependant.id || dependant.local_id;
    console.log(dependant, id);
    deleteItemFromArrayByIdInAssets('dependents', id);
    if (dependant.id) {
      addToArrayInAssets('dependentsToDelete', id);
    }
  }

  return (
    <Card elevation={1}>
      <CardHeader className={classes.header} title={t('employees:general_info')} />
      <Grid container spacing={3} className={classes.textFields}>
        <Grid item xs={12} sm={12} lg={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="gender">{t('employees:gender')}</InputLabel>
            <Select labelId="gender" name="gender" value={getField('gender')} onChange={onChange}>
              <MenuItem>-</MenuItem>
              <MenuItem value={t('employees:gender_male')}>{t('employees:gender_male')}</MenuItem>
              <MenuItem value={t('employees:gender_female')}>{t('employees:gender_female')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} noDatesInFuture getError={getError} getField={getField} onChange={onChange} name="date_of_birth" label={t('employees:date_of_birth')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="place_of_birth" label={t('employees:place_of_birth')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="mothers_name" label={t('employees:mothers_name')} />
        </Grid>
       {/* <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="nationality" label={t('employees:nationality')} />
        </Grid>*/}
        <Grid item xs={12} sm={12} lg={12}>
          <AppAutocomplete
            clearError={clearError}
            getError={getError}
            getField={getField}
            items={nationalityItems}
            onChange={onChange}
            itemValueUnit={'Nationality'}
            getOptionLabel={(option) => option.Nationality}
            name="nationality"
            label={t('employees:nationality')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppAutocomplete
            clearError={clearError}
            getError={getError}
            getField={getField}
            items={religionItems}
            onChange={onChange}
            itemValueUnit={'Religion'}
            getOptionLabel={(option) => option.Religion}
            name="religion"
            label={t('employees:religion')}/>
        </Grid>
       {/* <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="religion" label={t('employees:religion')} />
        </Grid>*/}
       {/* <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="sect" label={t('employees:sect')} />
        </Grid>*/}
        <Grid item xs={12} sm={12} lg={12}>
          <AppAutocomplete
            clearError={clearError}
            getError={getError}
            getField={getField}
            items={sectItems}
            onChange={onChange}
            itemValueUnit={'Sect'}
            getOptionLabel={(option) => option.Sect}
            name="sect"
            label={t('employees:sect')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppAutocomplete
            clearError={clearError}
            items={maritalStatuses}
            getError={getError}
            itemValueUnit={'name'}
            getField={getField}
            onChange={onChange}
            name="marital_status"
            label={t('employees:martial_status')} />
        </Grid>
        { showDependance(getField('marital_status')) &&
          <Fragment>
            <Grid item xs={12}>
              <AppButton onClick={() => openDependanceModal()}> Add Dependance Person </AppButton>
            </Grid>
            {dependents.map(dependant => (
              <Grid item xs={12} key={(dependant.id || dependant.local_id)}>
                <Grid container spacing={3}>
                  <Grid item xs={6} align={'start'}>
                    <Typography className={clsx({ [classes.error]: dependant.error })} variant="subtitle2" style={{fontWeight: 600}}>{dependant.first_name} {dependant.last_name}</Typography>
                  </Grid>
                  <Grid item xs={6} align={'end'}>
                    <EditOutlined onClick={() => openDependanceModal(dependant)} className={classes.icon} color={"secondary"}/>
                    <DeleteOutlined onClick={() => onDeleteDependant(dependant)} className={classes.icon} color={"secondary"}/>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Fragment>

        }
      </Grid>
      <DependanceDialog ref={dependanceDialog} onConfirm={onSaveDependent} getUUID={getUUID} getError={getError} clearError={clearError} isEmail={isEmail} />
    </Card>
  )
}

export default GeneralInfo
