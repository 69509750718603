import React from "react";
import ActivityLogsContainer from "./ActivityLogsContainer";

function ActivityLogs() {

  return (
    <ActivityLogsContainer/>
  )
}

export default ActivityLogs;
