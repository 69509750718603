import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import AppAutocomplete from 'components/AppAutocomplete';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Work Permit Approval',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Work Permit Review',
    hint: 'Current Step',
    current: true
  }
];

const rejection_reasons = [
  {
    value: 'Title under category 1, requested bachelor degree and MOFA\n' +
      'attestation',
  },
  {
    value: 'Degree is not clear',
  },
  {
    value: 'MOFA attestation required',
  },
  {
    value: 'Job offer must be signed',
  },
  {
    value: 'Thumb print not available',
  },
  {
    value: 'Picture not clear',
  },
  {
    value: 'Establishment has overstaying employees',
  },
  {
    value: 'Data Errors (DOB, Passport Number, Nationality, Employee Labor\n' +
      'Information)'
  },
  {
    value: 'Cars registration certificate',
  },
  {
    value: 'Others',
  },
]


// STEP 9bc
function StepWorkPermitReview({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, addToArrayInAssets, approveDocumentApi, clearFields, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getFileSize } = useUpload();
  const [confirm, toggleConfirm] = useDialog();
  const { handleUpload } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const work_permit = getField('work_permit_status');

  const uploadDocumentsError = getError('work_permit_approval_documents_error');

  async function onConfirm() {
    const approved = work_permit === 'approved'
    let file = null;
    const additional_documents = getField('work_permit_adding_data_entrist') ? 1 : 0
    if (approved) {
      file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id, approval: 1 });
    }

    const approvedFields = [
      {
        field: 'work_permit_status',
        value: getField('work_permit_status'),
      },
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name || '',
      },
      {
        field: 'work_permit_approval_date',
        value: getField('work_permit_approval_date'),
      },

      {
        field: 'labor_card_number',
        value: getField('labor_card_number'),
      },

      {
        field: 'work_permit_paid_fees',
        value: getField('work_permit_paid_fees'),
      }
    ];

    const rejectedFields = [
      {
        field: 'work_permit_status',
        value: getField('work_permit_status'),
      },
      {
        field: 'work_permit_paid_fees',
        value: getField('work_permit_paid_fees'),
      },
      {
        field: 'rejection_reason',
        value: getField('work_permit_rejection_reason',)
      },
      {
        field: 'rejection_comments',
        value: getField('rejection_comments'),
      },
      {
        field: 'work_permit_rejection_date',
        value: getField('work_permit_rejection_date'),
      },
      {
        field: 'work_permit_adding_data_entrist',
        value: getField('work_permit_adding_data_entrist') ? 1 : 0,
      }
    ];
    const fields = approved ? approvedFields : rejectedFields;
    const messages = approved ? ['Work Permit Approved'] : ['Work Permit Rejected']

    toggleConfirm();

   try {
     const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: approved ? 0 : 1, additional_documents};
     const employeePayload = {labor_card_number: getField('labor_card_number')};
     nextStep(stepPayload, employeePayload);
     clearFields(['work_permit_status', 'work_permit_rejection_date', 'work_permit_rejection_reason', 'work_permit_approval_date', 'rejection_comments', 'work_permit_adding_data_entrist', 'labor_card_number', 'work_permit_paid_fees'])
   } catch (error) {
      console.log('error => ', error);
   }
  }

  function validate(approved) {
    let error = false;

    // Rejected validations
    if (!approved && !getField('work_permit_rejection_date')) {
      addError('work_permit_rejection_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_work_permit_rejection') }));
      error = true;
    }
    if (!approved && !getField('work_permit_rejection_reason')) {
      addError('work_permit_rejection_reason', 'Required Field', t('required_field', {name: t('tracker:reason_for_rejection') }));
      error = true
    }

    // Approved validations
    if (approved && !getField('work_permit_approval_date')) {
      addError('work_permit_approval_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_work_permit_approval') }));
      error = true
    }

    if (approved && !getField('labor_card_number')) {
      addError('labor_card_number', 'Required Field', t('required_field', {name: 'Labour Card Number' }));
      error = true
    }

    if (approved && uploadedDocuments.length === 0) {
      addError('work_permit_approval_documents_error', t('tracker:please_upload_documents'))
      error = true;
    }

    if (!getField('work_permit_paid_fees')) {
      addError('work_permit_paid_fees', 'Required Field', t('required_field', {name: 'Paid fees' }));
      error = true
    }
    if (getError('work_permit_paid_fees')) {
      error = true;
    }
    return error
  }

  function onUpload(data) {
    clearError('work_permit_approval_documents_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const approved = work_permit === 'approved'
    const error = validate(approved);
    if (!error) toggleConfirm();
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={!work_permit || isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography
            variant="body1"
            style={{ fontWeight: 600 }}
          >
            {t('tracker:work_permit')}
          </Typography>
          <RadioGroup aria-label="work_permit" name="work_permit_status" value={getField('work_permit_status')} onChange={onChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'approved'} control={<Radio color="primary" />} label={t('tracker:radio_approved')} />
              <FormControlLabel value={'rejected'} control={<Radio color="primary" />} label={t('tracker:radio_rejected')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {/* APPROVED */}
        {work_permit === 'approved' &&
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_document')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              { (documents.length === 0 && uploadedDocuments.length === 0) &&
                <UploadFile/>
              }
              {/* Uploaded Documents */}
              {uploadedDocuments.map((document, index) =>
                <UploadedDocument
                  addAsset={addAsset}
                  getAsset={getAsset}
                  key={document.id}
                  label={document.name}
                  index={index}
                  size={document.size}
                  onDelete={handleDocumentDelete}
                  file={document}
                  isPromise={isPromise}
                />
              )}
              {/* Document To Be uploaded */}
              {documents.map((document, index) =>
                <AppUploadDocument
                  getAsset={getAsset}
                  addAsset={addAsset}
                  getError={getError}
                  addError={addError}
                  clearError={clearError}
                  key={document.id}
                  name={document.id}
                  label={document.name}
                  index={index}
                  size={getFileSize(document)}
                  onDelete={handleDocumentDelete}
                  onCancel={handleDocumentCancel}
                  getField={getField}
                  onChange={onChange}
                  uploadFile={onUpload}
                  file={document}
                  isLoading={isLoading}
                  isPromise={isPromise}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} clearError={clearError} getField={getField} name="work_permit_approval_date" label={t('tracker:date_of_the_work_permit_approval')} />
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <AppTextField name={'labor_card_number'} label={'Labour Card Number'} onChange={onChange} getField={getField} getError={getError} clearError={clearError}/>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <AppTextField price noNegative type={'number'} name={'work_permit_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
            </Grid>
          </Fragment>
        }
        {/* REJECTED */}
        {work_permit === 'rejected' &&
          <Fragment>
            <Grid item xs={12}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4} xl={3}>
                      <AppAutocomplete
                        getError={getError}
                        getField={getField}
                        items={rejection_reasons}
                        clearError={clearError}
                        onChange={onChange}
                        itemValueUnit={'value'}
                        name={'work_permit_rejection_reason'}
                        label={t('tracker:reason_for_rejection')}
                        getOptionLabel={(option) => option.value}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4} xl={3}>
                      <AppTextField price noNegative type={'number'} name={'work_permit_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <AppTextField name={'rejection_comments'} label={'Comments Of Rejection'} onChange={onChange} getField={getField} multiline rows={3}/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4} xl={3}>
                      <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="work_permit_rejection_date" label={t('tracker:date_of_the_work_permit_rejection')} />
                    </Grid>
                    <Grid item xs={12} lg={5} xl={4}>
                      <AppCheckbox style={{ marginTop: '6px' }} getField={getField} onChange={onChange} name={'work_permit_adding_data_entrist'} label={'Send to Data Entrist - adding additional documents'}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepWorkPermitReview
