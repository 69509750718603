import React, {Fragment} from "react";
import {Button, Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import logo from "../../assets/img/WFCH.png";
import {makeStyles} from "@material-ui/core/styles";
import useUpload from "../../Hooks/useUpload";
import Files from 'react-files'
import AppButton from "../../components/AppButton";
import {DeleteOutlineOutlined, PostAdd,} from "@material-ui/icons";
import clsx from "clsx";
import CandidateFormDone from "./CandidateFormDone";
import CandidateFormError from "./CandidateFormError";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    minHeight: 'min(100vh, 550px)',
    [theme.breakpoints.down("xs")]: {
      marginTop: '0',
      height: '100vh',
    }
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

function CandidateFormView({ onChange = () => {}, getAsset, addError, getError, getField, addAsset, clearError, submitForm }) {
  const classes = useStyles();
  const { handleUploadWithReactFiles } = useUpload(onChange, addAsset);
  const  filesToUpload = getAsset("filesToUpload", []);

  const isLoading = getAsset('isLoadingUpload', false);
  const isDone = getAsset('isDone', false);
  const isFormError = getAsset('isFormError', false);
  const isFormContract = getAsset('isFormContract', false);

  const formFields = getAsset('formFields', []);

  function deleteFile(name) {
    addAsset(name, null);
  }
  function validate() {
    let isValid = true;
    filesToUpload.every(file => {
      if (!getAsset(file.name)) {
        isValid = false;
        addError(file.name, 'Please Upload all files');
      }
      return true;
    })

    formFields.every(field => {
      if (!getField(field.name)) {
        isValid = false
        addError(field.name, `${field.label} is Required`);
      }
      return true;
    })
    return isValid;
  }
  function handleAddFiles(e, fileName) {
    clearError(fileName)
    handleUploadWithReactFiles(e, fileName);
  }
  async function handleSubmit() {
    if (!validate()) return;
    await submitForm();
  }
  function PickFile({name}) {
    return (
      <Files className='files-dropzone' onChange={(e) => handleAddFiles(e, name)} maxFileSize={10000000} minFileSize={0} clickable>
        <AppButton size={"small"} variant="contained" color="secondary" startIcon={<PostAdd />}>Upload</AppButton>
      </Files>
    )
  }
  function ShowFile({fileName, name}) {
    return (
      <Button endIcon={<DeleteOutlineOutlined />} style={{ color: "#1A97AD", paddingLeft: '3px' }} onClick={() => deleteFile(name)}>
        {fileName}
      </Button>
    )
  }

  if (isFormError) return (

    <CandidateFormError/>
  )

  if (isDone) return (
      <CandidateFormDone/>
  )

  return (
    <Card className={classes.root}>
      <Grid container justify="center" alignContent={"space-between"} spacing={3} style={{height: "100%"}}>
        <Grid item xs={12} align="center" style={{ marginTop: '30px', paddingBottom: 0 }}>
          <img src={logo} alt="logo" style={{width: '50%'}}/>
        </Grid>

        <Grid item xs={12} align="center" style={{padding: "24px"}}>
          {filesToUpload.length > 0 &&
            <Grid item xs={12}>
              <Grid container spacing={5}>
                  <Grid xs={4} item align={"center"} style={{marginBottom: '4px'}}>
                    {isFormContract ? 'Signed Contract' : 'Reason of rejection'}

                  </Grid>
                  <Grid xs={4} item>&nbsp;</Grid>
                  <Grid xs={4} item>&nbsp;</Grid>
              </Grid>
            </Grid>
          }
          {filesToUpload.map((file, index) => (
              <Fragment key={`${file.name}-${index}`}>
                {index === 0 && <Divider style={{width: '100%'}} />}
                <Grid key={`${file.name}-${index}`} container spacing={5} alignItems={"center"} justify={"center"} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                  <Grid item md={4} xl={4} align={"center"} className={clsx(({[classes.error]: getError(file.name)}))}>
                    {file.description}
                  </Grid>

                  <Grid item md={4} xl={4} className={clsx(({[classes.error]: getError(file.name)}))}>
                    <p style={{margin: 0}}>{file.name_upload_type}:</p>
                    {file.label || '-'}
                  </Grid>

                  <Grid item md={4} xl={4} align={"center"}>
                    {getAsset(file.name) ? <ShowFile fileName={getAsset(file.name, {}).name} name={file.name}/> : <PickFile name={file.name}/>}
                  </Grid>
                </Grid>
                <Divider style={{width: '100%'}} />
              </Fragment>
            ))}

          {formFields.map((field) => (
            <Grid container justify="center" alignItems={'center'} alignContent={"space-between"} spacing={3}>
              <Grid item >
                <Typography variant="body1" style={{ fontWeight: 600 }} className={clsx({[classes.error]: getError(field.name)})}>
                  {field.label}:
                </Typography>
              </Grid>

              <Grid item >
                <RadioGroup name={field.name} value={getField(field.name)} onChange={(event) => {onChange(event); clearError(field.name)}}>
                  <Grid item xs={12} >
                    {field.choices.map(choice => <FormControlLabel key={choice.value} value={choice.value} control={<Radio color="primary" />} label={choice.text} />)}
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item align={"center"} xs={12} style={{padding: "35px", paddingTop: "0"}}>
          <AppButton onClick={handleSubmit} isLoading={isLoading} style={{minWidth: '100px'}}>Submit</AppButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CandidateFormView;
