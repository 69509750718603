import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppTextField from 'components/AppTextField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppCamera from "../../../../../../components/AppCamera";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Arrange Bio Scan',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Bio Scan',
    hint: 'Current Step',
    current: true
  }
];

// STEP 20
function StepBioScan({ getAsset, addAsset, getField, onChange, addError, getError, deleteDocument, uploadFile, isLoading, nextStep, clearError, approveDocumentApi, isPromise, addToArrayInAssets, clearFields, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const [confirm, toggleConfirm] = useDialog();
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const bio_scan = getField('bio_scan');

  const uploadDocumentsError = getError('bioscan_upload');

  async function onConfirm() {
    const attendance = bio_scan === 'attend'
    let file = null;
    toggleConfirm();

    if (attendance) {
      file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id, approval: 1 });
    }

    const approvedFields = [
      {
        field: 'bio_scan',
        value: getField('bio_scan'),
      },
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name,
      },
    ];

    const rejectedFields = [
      {
        field: 'bio_scan',
        value: getField('bio_scan'),
      },
      {
        field: 'bioscan_comments',
        value: getField('bioscan_comments'),
      }
    ];
    const messages = attendance ? ['Candidate bio scan.'] : ["Candidate didn't attend bio scan. "];
    const fields = attendance ? approvedFields : rejectedFields;

    try {
      const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: attendance ? 0 : 1, additional_documents: 0}
      const employeePayload = attendance ? {take_passport: getField('take_passport')} : undefined

      nextStep(stepPayload, employeePayload);
      clearFields(['bio_scan', 'bioscan_comments']);
    } catch (e) {

    }
  }

  function validate(approved) {
    let error = false;

    if (!getField('bio_scan')) {
      addError('bio_scan', true)
      error = true;
    }

    // Rejected validations
    if (approved === false && !getField('bioscan_comments')) {
      addError('bioscan_comments', 'Required Field', t('required_field', {name: t('tracker:comments') }));
      error = true;
    }

    // Approved validations
    if (approved && uploadedDocuments.length === 0) {
      addError('bioscan_upload', t('tracker:please_upload_document'));
      error = true;
    }

    return error
  }

  function onUpload(data) {
    clearError('meet_candidate_at_center_document_upload');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function onAttendanceChange(e, name) {
    clearError('bioscan_comments');
    clearError('bio_scan');
    onChange(e, name);
  }

  function handleNextStep() {
    const attendance = bio_scan === 'attend' ? true : bio_scan === "didn't attend" ? false : undefined;
    const error = validate(attendance);
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
                {t('tracker:upload_document_button')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AppCamera onCapture={handleUpload} />
            </Grid>
          </Grid>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '50px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }} className={clsx({[classes.error]: getError('bio_scan')})}>
            {t('tracker:candidate')}
          </Typography>
          <RadioGroup aria-label="candidate_attendance" name="bio_scan" value={getField('bio_scan')} onChange={onAttendanceChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'attend'} control={<Radio color="primary" />} label={t('tracker:radio_attend')} />
              <FormControlLabel value={"didn't attend"} control={<Radio color="primary" />} label={t('tracker:radio_didnt_attend')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {/* ATTEND */}
        {bio_scan === 'attend' &&
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_document')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              { (documents.length === 0 && uploadedDocuments.length === 0) &&
                <UploadFile/>
              }
              {/* Uploaded Documents */}
              {uploadedDocuments.map((document, index) =>
                <UploadedDocument
                  addAsset={addAsset}
                  getAsset={getAsset}
                  key={document.id}
                  label={document.name}
                  index={index}
                  size={document.size}
                  onDelete={handleDocumentDelete}
                  file={document}
                  isPromise={isPromise}
                />
              )}

              {/* Document To Be uploaded */}
              {documents.map((document, index) =>
                <AppUploadDocument
                  getAsset={getAsset}
                  addAsset={addAsset}
                  getError={getError}
                  clearError={clearError}
                  key={document.id}
                  name={document.id}
                  label={document.name}
                  index={index}
                  size={getFileSize(document)}
                  onDelete={handleDocumentDelete}
                  onCancel={handleDocumentCancel}
                  getField={getField}
                  onChange={onChange}
                  uploadFile={onUpload}
                  file={document}
                  isLoading={isLoading}
                  isPromise={isPromise}
                />
              )}
            </Grid>
          </Fragment>
        }
        {/* DIDN'T ATTEND */}
        {bio_scan === "didn't attend" &&
          <Fragment>
            <Grid item xs={12}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12} lg={5}>
                  <AppTextField getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="bioscan_comments" label={t('tracker:comments')} multiline rows={6} />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm} />
    </Card>
  )
}

export default StepBioScan;
