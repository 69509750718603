import React  from 'react'
import {
  Card,
  CardHeader,
  Grid,
  Hidden,
  Paper,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppButton from 'components/AppButton';
import AppStepper from '../components/AppStepper';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import {VisibilityOutlined} from "@material-ui/icons";
import {format} from "date-fns";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import {useEffectOnce} from "react-use";
import {formatDate} from "../../../../../../utils/utilFinctions";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
  },
}));

const steps = [
  {
    label: 'Onboarding Completed!',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Restart Onboarding',
    hint: 'Current Step',
    current: true
  }
];

// STEP 28
function StepRestartOnboarding({t, getAsset, getField, onChange, addError, getError, clearError, cycleEmployee, isLoading, getAllProcesses, nextStep }) {
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(item => item.approval === 1);
  const docTypes = getAsset('documentTypes', []);

  useEffectOnce(() => {
    getAllProcesses();
  })

  function onConfirm() {
    const payload = {
      document_ids: JSON.stringify(getField('keep_documents', [])),
      process_id: getField('process_id'),
    };

    const stepPayload = {content: JSON.stringify({
        fields: [{field: "confirm_restart", value: true}],
        messages: ["Restart Process completed"]
      }), resubmission: 0, additional_documents: 0};

    toggleConfirm();
    nextStep(stepPayload, null, {skipGet: true}).then(() => {
      cycleEmployee(payload)
    })
  }

  function validate() {
    let error = false;

    if (!getField('confirm_restart')) {
      addError('confirm_restart', `Please confirm start new process `);
      error = true;
    }

    if (!getField('process_id')) {
      addError('process_id', 'Please select process');
      error = true;
    }

    return error;
  }

  function handleRestart() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function getDocType(value) {
    const type = docTypes.find(t => t.value === value);
    return (type || {}).text || '-';
  }

  function NextButton(props) {
    return (<AppButton {...props} isLoading={isLoading} onClick={handleRestart}>Restart Process</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '140px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
          <Typography variant={"body1"}>
            The candidate has completed the process. <br/> To start a new process please select the checkbox below, choose process type and mark files that should be copied to the new process.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{paddingTop: 0, paddingBottom: '5px'}}>
          <AppCheckbox getField={getField} clearError={clearError} getError={getError} name={'confirm_restart'} onChange={onChange} label={'Confirm Start New Process'}/>
        </Grid>
        <Grid item xs={12} lg={3} style={{paddingTop: 0}}>
          <AppAutocomplete
            getOptionLabel={(option) => option.title }
            items={getAsset('processes', [])}
            name={"process_id"}
            label={'Process'}
            getField={getField}
            getError={getError}
            clearError={clearError}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} style={{paddingTop: 0}}>
          <Card>
            <CardHeader  className={classes.header} title={`${t('navigation:documents')} - ${getField('first_name')} ${getField('last_name')} `} />
            {/*style={headerStyles}*/}
            {/* {isLoading && <LinearProgress />}*/}
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Document Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Approval Date</TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedDocuments.map((document) => (
                    <TableRow key={document.id}>
                      <TableCell>
                        <AppCheckbox name={'keep_documents'} value={document.id} label={document.name} onChange={onChange} getField={getField} clearError={clearError} inArray/>
                      </TableCell>
                      <TableCell>{getDocType(document.upload_type)}</TableCell>
                      <TableCell>{formatDate(document.updated_at)}</TableCell>
                      <TableCell align="right">
                        <a href={`${process.env.REACT_APP_API_URL}${document.file}`} download target="_blank" rel="noopener noreferrer">
                          <VisibilityOutlined className={classes.icon} color={"secondary"}/>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog open={confirm} onConfirm={onConfirm} onCancel={toggleConfirm} title={`Restart Process`} text={`Are you sure you want to start new process?`}/>
    </Card>
  )
}

export default StepRestartOnboarding;
