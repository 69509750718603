import {DialogContent, Typography} from "@material-ui/core";
import React, {forwardRef} from "react";
import AppUploadFile from "../../../../components/AppUploadFile";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '20px',
    marginBottom: '20px'
  },
}));

function UploadEmployeesCsv({onUpload}, ref) {
  const classes = useStyles();


  return (
    <DialogContent>
      <Typography variant="body1">
        Upload document. CSV file needs to contain: Process type ID, First Name, Last Name, Email, Passport Numbers
      </Typography>
      <Typography variant="body2">
        Allowed file types: .csv, Maximum file size: 5mb
      </Typography>
      <br/>
      <Typography variant="body2">
        Process type ID help:
      </Typography>
      <Typography variant="body2">
        1 - Onboarding
      </Typography>
      <Typography variant="body2">
        2 - UAE Nationals
      </Typography>
      <Typography variant="body2">
        3 - Visa Cancellation
      </Typography>
      <Typography variant="body2">
        4 - Visa Card Renewal
      </Typography>
      <Typography variant="body2">
        5 - Visa for government companies
      </Typography>
      <Typography variant="body2">
        6 - Labour Card Renewal
      </Typography>
      <AppUploadFile name={'importFile'} className={classes.button} ref={ref} onUpload={onUpload}/>
    </DialogContent>
  )
}

export default forwardRef(UploadEmployeesCsv);
