import {useRouteMatch} from 'react-router-dom'

function useRouteParams(path, fallback = null) {
  const match = useRouteMatch({
    path: path,
    strict: true,
    sensitive: false,
  });

  return (match || {}).params || fallback;
}

export default useRouteParams;
