import React, { Fragment } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import MuiAppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Hidden, LinearProgress, useMediaQuery, useTheme} from "@material-ui/core";
import AppButton from "components/AppButton";
import UserMenu from "./UserMenu";
import {useTranslation} from "react-i18next";
import {PersonAddOutlined} from "@material-ui/icons";
import ImportEmployeesDialog from "./ImportEmployeeDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    /* iOS Safari 11.2, Safari 11 */
    //paddingTop: 'constant(safe-area-inset-top, 12px)',

    /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
    paddingTop: 'env(safe-area-inset-top, 12px)',
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
        marginLeft: theme.variables.drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    paddingLeft: '6px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      marginRight: 0,
    }
  },
  createNewEmployeeButton: {
    marginRight: '25px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '6px',
    },
    verticalAlign: 'middle'
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  }
}))

function AppBar ({handleDrawerToggle, logout, userData, canCreate, goToNewEmployee, activeLink, isVisaAdmin, isSuperAdmin, isDataEntrist, additionalNavText, globalLoading, isRecruiter, importEmployees, exportErrorsToCsv }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { t } = useTranslation();
  const link = activeLink();

  const style = { marginBottom: globalLoading ? '-4px' : 0 }

  function AddNewEmployee() {
    return (
      <Fragment>
        <Hidden smDown>
          {
            canCreate() && (isSuperAdmin() || isVisaAdmin() || isDataEntrist()) &&
            <AppButton onClick={goToNewEmployee} className={classes.createNewEmployeeButton} variant="contained" color="secondary">
              {t('employees:create_new_employees')}
            </AppButton>
          }
        </Hidden>
        <Hidden mdUp>
          {
            canCreate() && (isSuperAdmin() || isVisaAdmin() || isDataEntrist()) &&
            <PersonAddOutlined focusable className={classes.createNewEmployeeButton} onClick={goToNewEmployee} fontSize={"large"}/>
          }
        </Hidden>
      </Fragment>
    )
  }

  return (
    <MuiAppBar style={style} position="fixed" className={classes.appBar}>
      { globalLoading && <LinearProgress className={classes.loading}/> }
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={4}>
            <Grid container alignItems="center" wrap="nowrap">
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {link && t(link.key)} {!isXs && additionalNavText}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={8} align="end">
            {
              isRecruiter() && <ImportEmployeesDialog importEmployees={importEmployees} exportErrorsToCsv={exportErrorsToCsv}/>
            }
            {
              !isRecruiter() && <AddNewEmployee/>
            }
            <UserMenu logout={logout} userData={userData} />
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar;
