import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import AppBar from './components/AppBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppDrawer from "./components/AppDrawer";
import {Drawer, useMediaQuery} from "@material-ui/core";
import {useLocation} from "react-use";
import Spinner from "../../components/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [`@media (min-width: ${theme.variables.drawerPoint + 1}px)`]: {
      width: theme.variables.drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: {
    marginTop: 'env(safe-area-inset-top, 12px)',
    ...theme.mixins.toolbar,
    minHeight: '64px !important'
  },
  drawerPaper: {
    width: theme.variables.drawerWidth,
  },
  content: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.variables.secondaryBackgroundColor,
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: `max(24px, env(safe-area-inset-bottom))`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [`@media (min-width: ${theme.variables.drawerPoint + 1}px)`]: {

      marginLeft: -theme.variables.drawerWidth,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function MainViewView(props) {
  const {children} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [regularOpen, setRegularOpen] = React.useState(false);
  const isMobile = useMediaQuery(`(max-width:${theme.variables.drawerPoint}px)`);
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if(mainContent) {
      setTimeout(() => {
        mainContent.current.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0)
    }
  }, [location.pathname])

  const handleDrawerToggle = () => {
    if (!isMobile) {
      setRegularOpen(!regularOpen)
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    setMobileOpen(false);
    setRegularOpen(!isMobile)
  }, [isMobile])

  return (
    <div className={classes.root}>
      { props.globalLoading &&  <Spinner fullScreen={true} blur={true}/> }
      <AppBar {...props} handleDrawerToggle={handleDrawerToggle}/>
      <nav className={classes.drawer} aria-label="mailbox folders">

        {/* Mobile Drawer */}
        { isMobile &&
          <Drawer variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}>
            <AppDrawer {...props} handleDrawerToggle={handleDrawerToggle}/>
          </Drawer>
        }

        {/*<Hidden xsDown implementation="css">*/}
        { !isMobile &&
          <Drawer anchor="left" open={regularOpen} classes={{paper: classes.drawerPaper}} variant="persistent">
            <AppDrawer {...props}/>
          </Drawer>
        }

      </nav>
      <main ref={mainContent} className={clsx(classes.content, {
        [classes.contentShift]: regularOpen && !isMobile,
      })}>
        <div className={classes.toolbar} />
        <div style={{overflow: "hidden"}}>
          {children}
        </div>
      </main>
    </div>
  );
}

export default MainViewView;
