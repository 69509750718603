import {transformResponse} from "./helpers";
import axios from "axios";

export const getDashboardDataService = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/dashboard/user`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDashboardDataByRoleService = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/dashboard/role`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDashboardNotVisaAdminService = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/dashboard/not-visa-admin`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDashboardCandidateActionService = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/dashboard/waiting-candidate-action`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDashboardImportCandidates = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/dashboard/import-candidates`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}
