import React from "react";
import {UsersPagesRoutes} from "../../navigation";

function UsersView(props) {
  return (
    <UsersPagesRoutes {...props}/>
  )
}

export default UsersView;
