import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}))

function AppTextArea({getField = () => {}, onChange = () => {}, name, label = 'test', rowsMin = 3}) {
  const classes = useStyles();

  return (
    <TextareaAutosize
      className={classes.root}
      name={name}
      value={getField('name')}
      onChange={onChange}
      placeholder={label}
      rowsMin={rowsMin} />
  );
}

export default AppTextArea;
