import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {DialogActions, DialogContent, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import AppButton from "../../../../components/AppButton";
import useHelpers from "../../../../Hooks/useHelpers";
import clsx from "clsx";
import useAPI from "../../../../Hooks/useAPI";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: '30px'
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  error: {
    color: '#BD1C1A'
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }
  }
}));

function ImportEmployeesResponseTable({onExport, onOk, data}) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { parseErrors, printToCSV } = useHelpers();
  const { downloadFileFromUrl } = useAPI();
 const data1 = JSON.parse(JSON.stringify(data || []));
 const columns = ['first_name', 'last_name', 'email', 'passport_number'];


  const rows = data1.map(elem => {
    const errors = parseErrors({errors: elem.errors});
    return {
      ...elem,
      errors,
    }
  })

  /*function toCsvCol(elem, field) {
    const space = elem.data[field] ? ' ' : '';
    const error = elem.errors[field] ? `${space}(${elem.errors[field]})` : '';
    return `${elem.data[field]}${error}`
  }*/

  //rowsForCsv.push([toCsvCol(row, 'first_name'), toCsvCol(row, 'last_name'), toCsvCol(row, 'email'), toCsvCol(row, 'passport_number')])

  function exportToCSV() {
    const rowsForCsv = [];
    rows.forEach(elem => {
      const row = [];
      const errors = [];
      columns.forEach(column => {
        if (elem.data[column]) row.push(elem.data[column]);
        if (elem.errors[column]) errors.push(elem.errors[column])
      })
      if (errors.length > 0) row.push(`(${errors.join(', ')})`);
      rowsForCsv.push(row);
    })

    const link = printToCSV(rowsForCsv);
    const date = moment(new Date).format('YYYY.MM.DD');
    downloadFileFromUrl(link, `Import Employees Errors - ${date}.csv`);
  }

  return (
    <Fragment>
      <DialogContent>
        <Typography className={classes.error} style={{ marginLeft: '5px' }}>These employees could not be imported!</Typography>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Passport Number</TableCell>
                {/*<TableCell align="right"></TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({data, errors}, index) => (
                <TableRow key={`${data.passport_number}-${index}`}>
                  <TableCell className={clsx({[classes.error]: errors.first_name})} component="th" scope="row">{errors.first_name || data.first_name}</TableCell>
                  <TableCell className={clsx({[classes.error]: errors.last_name})} align="right">{errors.last_name || data.last_name}</TableCell>
                  <TableCell className={clsx({[classes.error]: errors.email})} align="right">{errors.email || data.email}</TableCell>
                  <TableCell className={clsx({[classes.error]: errors.passport_number})} align="right">{errors.passport_number || data.passport_number}</TableCell>
                  {/*<TableCell align="right">{row.error}</TableCell>*/}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions className={classes.actions} style={{ marginBottom: '20px' }}>
        <AppButton onClick={onExport} onClick={exportToCSV} color="secondary">
          { smDown ? 'EXPORT TO CSV' : 'EXPORT ERRORS TO CSV FILE' }
        </AppButton>
        <AppButton onClick={onOk} color="secondary">
          OK
        </AppButton>
      </DialogActions>
    </Fragment>
  );
}

export default ImportEmployeesResponseTable;
