import { v4 as uuid } from 'uuid';

function useUpload(onChange = () => {}, addAsset = () => {}, { uploadAssetName } = {}) {

  const isImage = (file = {}) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    return validTypes.indexOf(file.type) !== -1;
  }

  const getFileSize = (file = {}) => {
    const size = file.size
    return convertSize(size);
  }

  const convertSize = (size) => {
    if (!size) return 'xx';
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const isFileGreaterThenMB = (file, MB) => {
    const fileSize = file.size;
    if (fileSize === 0) return '0 Bytes';
    const k = 1024;
    const i = Math.floor(Math.log(fileSize) / Math.log(k));
    const size = parseFloat((fileSize / Math.pow(k, i)).toFixed(2));
    return (size > MB && i>=2);
  }

  const getFileType = (file = {}) => {
    const fileName = file.name;
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || '';
  }

  const readImage = (file) => {
    return new Promise((resolve, reject) => {
      if(!file) reject(null);
      const reader = new FileReader();
      reader.onload = () => { resolve(reader.result) };
      reader.readAsDataURL(file);
    })
  }

  const handleUpload = (e, imageTaken, assetName = 'documents') => {
    console.log(assetName)
    const files = ((e || {}).target || {}).files || [imageTaken] || {};
    const filessToAdd = []
    Object.values(files).forEach(file => {
      file.id = uuid();
      addAsset(uploadAssetName || assetName, file);
      onChange(null, file.id, file.name);
      filessToAdd.push(file);
    });
    return filessToAdd;
  }

  const handleUploadWithReactFiles = (e, assetName = 'documents') => {
    const files = e;
    const filessToAdd = []
    files.forEach(file => {
      file.id = uuid();
      console.log(file);
      addAsset(uploadAssetName || assetName, file);
      onChange(null, file.id, file.name);
      filessToAdd.push(file);
    });
    return filessToAdd;
  }

  return { isImage, getFileSize, getFileType, readImage, isFileGreaterThenMB, handleUpload, convertSize, handleUploadWithReactFiles};
}

export default useUpload;
