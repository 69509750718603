import React, { Fragment, useEffect } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import AppAutocomplete from 'components/AppAutocomplete';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: '',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Labour Card Approval',
    hint: 'Current Step',
    current: true
  }
];


function StepLabourCardApproval({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, addToArrayInAssets, approveDocumentApi, clearFields, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const [confirm, toggleConfirm] = useDialog();
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const isApproved = getField('labour_card_status') === 'approved'

  useEffect(() => {
    if (isApproved) {
      onChange(null, 'labour_card_expiry_date', getField('labor_card_expiry'));
      onChange(null, 'labour_card_number', getField('labor_card_number'));
    }
  }, [isApproved]);

  const uploadFileErrorName = 'step_labour_card_approval'

  const statusName = 'labour_card_status'

  const approvedDateFieldName = 'labour_card_date';
  const approvedDateFieldLabel = 'Date of the Labour Card approval';

  const approvedDateFieldTwoName = 'labour_card_expiry_date';
  const approvedDateFieldTwoLabel = 'Labour Card Expiry Date';

  const approvedTextFieldOneName = 'labour_card_number';
  const approvedTextFieldOneLabel = 'Labour card number';

  const rejectedTextAreaName = 'rejection_comments';
  const rejectedTextAreaLabel = 'Comments of Rejection';

  const rejectedDateFieldName = 'labor_card_rejection_date';
  const rejectedDateFieldLabel = 'Date of the Job MOL Rejection';

  const addingDataEntristName = 'labor_card_adding_data_entrist';
  const addingDataEntristLabel = 'Send to Data Entrist - adding additional documents'

  const approvedMessage = '';
  const rejectedMessage = ''

  const status = getField(statusName);


  const uploadDocumentsError = getError(uploadFileErrorName);

  async function onConfirm() {
    toggleConfirm();
    const approved = status === 'approved'
    let file = null;
    const additional_documents = getField(addingDataEntristName) ? 1 : 0;
    if (approved) {
      file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id});
    }

    const approvedFields = [
      {
        field: statusName,
        value: getField(statusName),
      },
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name,
      },
      {
        field: 'document_id',
        value: (file || {}).id,
      },
      {
        field: approvedDateFieldName,
        value: getField(approvedDateFieldName),
      },
      {
        field: approvedTextFieldOneName,
        value: getField(approvedTextFieldOneName),
      },
      {
        field: approvedDateFieldTwoName,
        value: getField(approvedDateFieldTwoName),
      },
    ];

    const rejectedFields = [
      {
        field: statusName,
        value: getField(statusName),
      },
      {
        field: 'rejection_comments',
        value: getField(rejectedTextAreaName),
      },
      {
        field: rejectedDateFieldName,
        value: getField(rejectedDateFieldName),
      },
      {
        field: addingDataEntristName,
        value: getField(addingDataEntristName) ? 1 : 0,
      }
    ];
    const fields = approved ? approvedFields : rejectedFields;
    const messages = approved ? [approvedMessage] : [rejectedMessage]
    try {
      const nextStepPayload = {content: JSON.stringify({fields, messages}), resubmission: approved ? 0 : 1, additional_documents}
      const employeePayload = {labor_card_number: getField('labour_card_number'), labor_card_expiry: getField('labour_card_expiry_date') }
      nextStep(nextStepPayload, employeePayload);
      clearFields([statusName, approvedDateFieldName, approvedTextFieldOneName, approvedDateFieldTwoName, rejectedDateFieldName, addingDataEntristName])
    } catch (e) {
      console.log('error => ', e)
    }
  }

  function validate(approved) {
    let error = false;

    if (approved === undefined) return true;

    // Rejected validations
    if (approved === false && !getField(rejectedDateFieldName)) {
      addError(rejectedDateFieldName, 'Required Field', t('required_field', {name: rejectedDateFieldLabel }));
      error = true;
    }

    // Approved validations
    if (approved && !getField(approvedDateFieldName)) {
      addError(approvedDateFieldName, 'Required Field', t('required_field', {name: approvedDateFieldLabel }));
      error = true
    }
    if (approved && !getField(approvedTextFieldOneName)) {
      addError(approvedTextFieldOneName, 'Required Field', t('required_field', {name: approvedTextFieldOneLabel }));
      error = true
    }
    if (approved && !getField(approvedDateFieldTwoName)) {
      addError(approvedDateFieldTwoName, 'Required Field', t('required_field', {name: approvedDateFieldTwoLabel }));
      error = true
    }

    if (approved && uploadedDocuments.length === 0) {
      addError(uploadFileErrorName, t('tracker:please_upload_document'));
      error = true;
    }
    return error
  }

  function onUpload(data) {
    clearError(uploadFileErrorName);
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
   return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const approved = status === 'approved' ? true : status === 'rejected' ? false : undefined;
    const error = validate(approved);
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Labour Card Application
          </Typography>
          <RadioGroup aria-label={statusName} name={statusName} value={getField(statusName)} onChange={onChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'approved'} control={<Radio color="primary" />} label={t('tracker:radio_approved')} />
              <FormControlLabel value={'rejected'} control={<Radio color="primary" />} label={t('tracker:radio_rejected')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {/* APPROVED */}
        {status === 'approved' &&
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            { (documents.length === 0 && uploadedDocuments.length === 0) &&
              <UploadFile/>
            }
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                addAsset={addAsset}
                getAsset={getAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onDelete={handleDocumentDelete}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} lg={4} xl={3}>
                <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={approvedDateFieldName} label={approvedDateFieldLabel} />
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={approvedTextFieldOneName} label={approvedTextFieldOneLabel} variant="filled" fullWidth />
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <AppPickDateField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={approvedDateFieldTwoName} label={approvedDateFieldTwoLabel} />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        {/* REJECTED */}
        {status === 'rejected' &&
        <Fragment>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} lg={4} xl={3}>
                <AppTextField name={rejectedTextAreaName} label={rejectedTextAreaLabel} onChange={onChange} getField={getField} multiline rows={3}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4} xl={3}>
                    <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={rejectedDateFieldName} label={rejectedDateFieldLabel} />
                  </Grid>
                  <Grid item xs={12} lg={5} xl={4}>
                    <AppCheckbox style={{ marginTop: '6px' }} getField={getField} onChange={onChange} name={addingDataEntristName} label={addingDataEntristLabel}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepLabourCardApproval;
