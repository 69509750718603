import React, {Fragment} from 'react';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import { NavLink} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

// Logo image
import logoImg from 'assets/img/WFCH.png';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    marginTop: 'env(safe-area-inset-top, 12px)',
    [`@media (max-width: ${theme.variables.drawerPoint}px)`]: {
      minHeight: 0
    }
  },
  list: {
    height: '100vh',
    position: 'relative',
    padding: 0,
    paddingBottom: '205px',
    boxSizing: 'content-box',
    '& .MuiListItem-button': {
      paddingTop: '19px',
      paddingBottom: '19px',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '19px',
        paddingBottom: '19px',
      }
    },
    '& .logo': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      paddingBottom: '28px'
    }
  },
}))

function DrawerItem({text, param, Icon, ...rest}) {
  return (
    <Fragment>
      <ListItem component={NavLink} activeStyle={{ backgroundColor: '#DFDFDF' }} divider button key={text} {...rest}>
        <ListItemIcon><Icon/></ListItemIcon>
        <ListItemText primary={text}/>
      </ListItem>
    </Fragment>
  )
}

function AppDrawer({getLinks, handleDrawerToggle}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navLinks = getLinks();

  return (
    <Fragment>
      <div className={classes.toolbar} />
      <Divider />
      <List className={classes.list}>
        {navLinks.filter(link => !link.hidden).map(link => (
          <DrawerItem key={link.path} text={t(link.key)} to={link.path} Icon={link.icon} onClick={handleDrawerToggle}/>
        ))}
        <Grid className="logo" item align="center">
          <img src={logoImg} alt="logo" style={{width: '90%'}}/>
        </Grid>
      </List>
    </Fragment>
  )
};

export default AppDrawer;
