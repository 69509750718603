import { useState } from 'react';

function useDialog() {
  const [opened, setOpened] = useState(false);

  const toggle = () => setOpened(!opened);

  return [opened, toggle];
}

export default useDialog;
