import React, {useState, Fragment} from "react";
import DownloadView from "./DownloadView";
import useRouteParams from "../../Hooks/useRouteParams";
import {DOWNLOAD_FILE} from "../../navigation/CONSTANTS";
import {useEffectOnce} from "react-use";
import useAPI from "../../Hooks/useAPI";
import {downloadFileService} from "../../services/employeesService";

const  DownloadContainer = function() {
  const {fileSlug} = useRouteParams(`/${DOWNLOAD_FILE}`) || {};
  const { sendRequest, downloadFileFromUrl } = useAPI();
  const [downloadStatus, setDownloadStatus] = useState(0);

  useEffectOnce(() => {
    if (fileSlug) {
      downloadFile();
    }
  })


  const downloadFile = (slug = fileSlug) => sendRequest(downloadFileService,
    {download: true, params: {slug}})
    .then((res) => {
      setDownloadStatus(1);
    }).catch((err) => {
      console.log(err)
      setDownloadStatus(2);
    })

  /*const downloadFile = (slug = fileSlug) => downloadFileFromUrl(`${process.env.REACT_APP_API_URL}/api/download/${fileSlug}`)*/

  const downloadProps = {
      downloadFile,
      downloadStatus,
      fileSlug,
      downloadFileFromUrl
    }

  return (
    <Fragment>
     {/* <PreventLeaveRoute route={location} locked={true}/>*/}
      <DownloadView {...downloadProps} />
    </Fragment>

  )
}

export default DownloadContainer;
