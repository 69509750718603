import React from 'react'
import { Card, CardHeader, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppTextField from 'components/AppTextField';
import AppStepper from '../components/AppStepper';
import AppPickDateField from 'components/AppPickDateField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
}));

const steps = [
  {
    label: 'Work Permit Review',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Visa Application',
    hint: 'Current Step',
    current: true
  }
];
// STEP 12
function StepCreateVisaApplication({ getAsset, getField, onChange, getError, nextStep, clearError, addError }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const stepFields = getAsset('stepFields');
  const [confirm, toggleConfirm] = useDialog();

  function onConfirm() {
    toggleConfirm();
    const fields = stepFields.map(field => {
      return {field: field.name, value: getField(field.name)}
    });
    const messages = ['Visa Application Created'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
  }

  function validate() {
    let error = false;
    if (!getField('visa_application_submission_date')) {
      addError('visa_application_submission_date', 'Required Field',  t('required_field', {name: t('tracker:date_of_the_submission') }));
      error = true;
    }

    if (!getField('visa_application_reference_number')) {
      addError('visa_application_reference_number', 'Required Field',  t('required_field', {name: t('tracker:reference_number') }));
      error = true;
    }

    if (!getField('visa_application_paid_fees')) {
      addError('visa_application_paid_fees', 'Required Field',  t('required_field', {name: 'Paid Fees' }));
      error = true;
    }

    if (getError('visa_application_paid_fees')) {
      error = true;
    }

    return error;
  }

  function handleNextStep() {
    const error = validate();

    if (!error) toggleConfirm();
  }


  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="visa_application_submission_date" label={t('tracker:date_of_the_submission')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} onChange={onChange} getField={getField} name="visa_application_reference_number" label={'ICA Reference Number'} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField price noNegative type={'number'} getError={getError} addError={addError} clearError={clearError} onChange={onChange} getField={getField} name="visa_application_paid_fees" label={'Paid Fees'} placeholder={'Paid Fees'} variant="filled" fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepCreateVisaApplication
