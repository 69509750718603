import React, {useContext} from 'react'
import { Grid } from '@material-ui/core';
import ContactAndPassportInfoReadOnly from './components/ContactAndPassportInfoReadOnly';
import GeneralInfoReadOnly from './components/GeneralInfoReadOnly';
import SalaryInfoReadOnly from './components/SalaryInfoReadOnly';
import BasicInfoReadOnly from "./components/BasicInfoReadOnly";
import {useEffectOnce} from "react-use";
import Tracker from "./Tracker";
import OnboardingInformation from "./components/OnboardingInformation";
import {EmployeeContext} from "../../EmployeesContainer";


function EmployeeProfileReadOnly() {
  const {getCompanies, isSuperAdmin, isRecruiter} = useContext(EmployeeContext);

  useEffectOnce(() => {
    if (isSuperAdmin()) {
      getCompanies();
    }
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <BasicInfoReadOnly/>
      </Grid>
      { !isRecruiter() &&
        <Grid item xs={12}>
          <Tracker/>
        </Grid>
      }
      <Grid item xs={12}>
        <OnboardingInformation/>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <ContactAndPassportInfoReadOnly/>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <GeneralInfoReadOnly/>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <SalaryInfoReadOnly/>
      </Grid>
    </Grid>
  )
}

export default EmployeeProfileReadOnly
