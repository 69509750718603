import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '0',
    paddingLeft: '0',
    '& .MuiStepConnector-lineHorizontal': {
      minWidth: '55px',
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: '55px',
    },
    '& .MuiStepButton-vertical': {
      textAlign: 'left',
      paddingTop: 0,
    }
  },
  completed: {
    display: 'inline-block',
  },
  label: {
    lineHeight: '19px',
    margin: '0',
    fontSize: '1.2rem',
    color: '#0874BC',
    textTransform: 'uppercase',
    fontWeight: '600'
  },

  hint: {
    lineHeight: '19px',
    margin: '0',
    fontSize: '0.88rem',
    color: '#222222',
    fontWeight: '600',
  },
  grayedOut: {
    '& .MuiSvgIcon-root': {
      color: '#e0e0e0',
      borderRadius: '999999px',
      backgroundColor: '#222222'
    },
    '& .MuiStepLabel-label': {
      '& .label': {
        color: '#777777'
      },
      '& .hint': {
        color: '#777777',
      }
    }
  }
}));


function AppStepper({stepTitle = ''}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <Typography style={{textAlign: 'start'}} className={clsx([classes.label, 'label'])}>{stepTitle}</Typography>
      </Grid>
    </Grid>
  );
}

export default AppStepper;
