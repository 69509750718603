import React from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppStepper from '../components/AppStepper';
import AppButton from 'components/AppButton';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import clsx from "clsx";
import AppPickDateField from "../../../../../../components/AppPickDateField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Visa Application Review',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Confirm Employee Residency',
    hint: 'Current Step',
    current: true
  }
];

// Step 16
function StepEmployeeEnterTheCountry({ getField, onChange, getError, addError, nextStep, clearError, getAsset, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();

  async function handleNextStep() {
    toggleConfirm();
    const messages = ['Is Employee entered the country'];
    const fields = [
      {
        field: 'candidate_in_country',
        value: getField('candidate_in_country'),
      }
    ];

    if (getField('candidate_in_country') === 'entered the country') fields.push({
      field: 'arrival_date',
      value: getField('arrival_date'),
    })

    try {
      const stepPayload = {content: JSON.stringify({fields, messages}), additional_documents: 0, resubmission: 0};
      const employeePayload = {current_residency: 'in'};

      nextStep(stepPayload, employeePayload);
    } catch (error) {
      console.log('error => ', error);
    }
  }

  function handleStartConformDialog() {
    let error = false
    if (!getField('candidate_in_country')) {
      addError('candidate_in_country', 'Required Field')
      error = true;
    }

    if (getField('candidate_in_country') === 'entered the country' && !getField('arrival_date')) {
      addError('arrival_date', 'Required Field', t('required_field', {name: 'Arrival Date' }));
      error = true
    }

    if (error) return;
    toggleConfirm();
  }

  function handleOnChange(data, name) {
    clearError('candidate_in_country');
    onChange(data, name)
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleStartConformDialog}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '80px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }} className={clsx({[classes.error]: !!getError('candidate_in_country')})}>
            {t('tracker:employee_residence')}
          </Typography>
          <RadioGroup aria-label="employee_current_residence" name="candidate_in_country" value={getField('candidate_in_country')} onChange={handleOnChange}>
            <Grid item xs={12}>
              <FormControlLabel value={'entered the country'} control={<Radio color="primary" />} label={t('tracker:entered_the_country')} />
              <FormControlLabel value={'already in the country'} control={<Radio color="primary" />} label={t('tracker:already_in_the_country')} />
            </Grid>
          </RadioGroup>
        </Grid>
        { getField('candidate_in_country') === 'entered the country' &&
          <Grid style={{paddingTop: 0}} item xs={12} lg={3} xl={3}>
            <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="arrival_date" label={'Arrival Date'} />
          </Grid>
        }
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('tracker:entered_the_country_explanation')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('tracker:already_in_the_country_explanation')}
          </Typography>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')}  open={confirm} onConfirm={handleNextStep} onCancel={toggleConfirm}/>
    </Card>
  )
}

export default StepEmployeeEnterTheCountry
