import React, {Fragment} from 'react';
import {Card, Grid, Typography, Button, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import useUpload from "../../../../../../Hooks/useUpload";
import AppButton from "../../../../../../components/AppButton";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";
import AppStepper from "../components/AppStepper";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppTextField from "../../../../../../components/AppTextField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'BIO and EID',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Medical Insurance Card',
    hint: 'Current Step',
    current: true
  }
];

const insuranceCategory = [
  {
    name: 'Gold',
    value: 'Gold'
  },
  {
    name: 'Enhanced Silver',
    value: 'Enhanced Silver',
  },
  {
    name: 'Core silver',
    value: 'Core silver'
  },
  {
    name: 'Enhanced Bronze',
    value: 'Enhanced Bronze'
  },
  {
    name: 'Core bronze',
    value: 'Core bronze'
  },
]

function StepMedicalInsuranceCard({t, getAsset, addAsset, getField, onChange, uploadFile, deleteDocument, nextStep, isPromise, isLoading, addError, getError, clearError, addToArrayInAssets, approveDocumentApi, updateEmployee}) {
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const stepFields = getAsset('stepFields', []).filter(field => field.type !== 'file');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id));
  const [confirm, toggleConfirm] = useDialog();

  const dropdownOneName = 'medical_insurance_category';
  const dropdownOneLabel = 'Insurance category';

  const dateFieldOneName = 'medical_insurance_issue_date';
  const dateFieldOneLabel = 'Issue date';

  const dateFieldTwoName = 'medical_insurance_expiry_date';
  const dateFieldTwoLabel = 'Insurance expiry date';

  const textFieldOneName = 'medical_insurance_paid_fees';
  const textFieldOneLabel = 'Paid fees';

  const uploadDocumentsError = getError('documents_upload_error');

  async function onConfirm() {
    const fields = [];
    uploadedDocuments.forEach((item) => {
      approveDocumentApi({ documentId: item.id });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })

    stepFields.forEach(field => {
      if (field.name === 'file') return;
      fields.push({field: field.name, value: getField(field.name)})
    })

    const messages = ['Medical Insurance card uploaded'];

    try {

      const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0};
      const employeePayload = {insurance_expiry: getField('medical_insurance_expiry_date')};

      nextStep(stepPayload, employeePayload);
      toggleConfirm();
    } catch (e) {
      console.log(e)
    }
  }

  function onUpload(data) {
    clearError('documents_upload_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function validate() {
    let error = false

    if (uploadedDocuments.length === 0) {
      addError('documents_upload_error', t('tracker:please_upload_documents'));
      error = true
    }

    if (!getField(dropdownOneName)) {
      addError(dropdownOneName, 'Required Field',  t('required_field', {name: dropdownOneLabel }));
      error = true
    }

    if (!getField(dateFieldOneName)) {
      addError(dateFieldOneName, 'Required Field',  t('required_field', {name: dateFieldOneLabel }));
      error = true
    }

    if (!getField(dateFieldTwoName)) {
      addError(dateFieldTwoName, 'Required Field',  t('required_field', {name: dateFieldTwoLabel }));
      error = true
    }

    if (!getField(textFieldOneName)) {
      addError(textFieldOneName, 'Required Field',  t('required_field', {name: textFieldOneLabel }));
      error = true
    }

    if (getError(textFieldOneName)) {
      error = true;
    }

    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" multiple type="file" onChange={handleUpload}/>
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:button_add_documents')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_employees_documents')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadFile/>
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppAutocomplete
            getError={getError}
            getField={getField}
            items={insuranceCategory}
            clearError={clearError}
            onChange={onChange}
            itemValueUnit={'value'}
            name={dropdownOneName}
            label={dropdownOneLabel}
            getOptionLabel={(option) => option.name}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateFieldOneName} label={dateFieldOneLabel} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppPickDateField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateFieldTwoName} label={dateFieldTwoLabel} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppTextField price noNegative type={'number'} getError={getError} addError={addError} clearError={clearError} onChange={onChange} getField={getField} name={textFieldOneName} label={textFieldOneLabel} variant="filled" fullWidth />
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepMedicalInsuranceCard;
