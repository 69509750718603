import React from "react";
import EmployeesContainer from "./EmployeesContainer";

function Employees() {

  return (
    <EmployeesContainer/>
  )
}

export default Employees;
