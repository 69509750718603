import React, {useEffect} from "react";
import EmployeesView from "./EmployeesView";
import useHelpers from "../../Hooks/useHelpers";
import useAPI from "../../Hooks/useAPI";
import {getUserByIdService, getUserByRoleService, getUserByRolesService} from "../../services/userService";
import {getCompaniesService} from "../../services/companiesService";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-use";
import useForm from "../../Hooks/useForm";
import useRouteParams from "../../Hooks/useRouteParams";
import {EDIT_EMPLOYEE, VIEW_EMPLOYEE} from "../../navigation/CONSTANTS";
import useRedirect from "../../Hooks/useRedirect";
import {getFieldsService} from "../../services/fieldService";
import {
  deleteDocumentService,
  getDocumentsService,
  getDocumentUploadTypeService,
  updateDocumentService,
  uploadDocumentService
} from "../../services/documentsService";
import useUser from "../../Hooks/useUser";
import {
  addDependanceService,
  createEmployeeService,
  cycleEmployeeService,
  deleteDependanceService,
  deleteEmployeeByIdService,
  exportCandidatesService,
  exportEmployeesLabourCardExpiryService,
  exportEmployeesResidencePermitExpiryService,
  exportEmployeesService,
  exportEmployeesVisaExpiryService,
  getEmployeeByIdService,
  getEmployeesService,
  permanentRejectEmployeeService,
  toggleEmployeeLockService,
  updateDependanceImageService,
  updateDependanceService,
  updateEmployeeImageService,
  updateEmployeeService
} from "../../services/employeesService";
import {
  getAllProcessesService,
  getAllStepsService,
  getEmployeeStepService,
  updateEmployeeStepService
} from "../../services/stepService";
import useNavigation from "../../Hooks/useNavigation";
import {parseJSON} from "../../utils/utilFinctions";

export const EmployeeContext = React.createContext(null);

function EmployeesContainer() {
  // region Main Region
  const redirect  = useRedirect();
  const {goToViewEmployee} = redirect;
  const { sendRequest, isLoading, getApiError, clearApiError, cancelAllRequests, transformErrors} = useAPI();
  const { t } = useTranslation();
  const location = useLocation();
  const helperProps = useHelpers();
  const { addAsset, onChange, getAsset, getField, clearAssets, clearFields, clearErrors, setFields, fields, clearFiled, addField, getLocalError, clearLocalError, addError, addAssetGroup, addToArrayInAssets, deleteItemFromArrayByIdInAssets, updateArrayElementByObjectInAssets, deleteElementFromArrayInAssets} = useForm();
  const editPageProps = useRouteParams(`/${EDIT_EMPLOYEE}`);
  const viewPageProps = useRouteParams(`/${VIEW_EMPLOYEE}`);
  const { employeeId } = viewPageProps || editPageProps || {};
  const { user, canUpdate, canRead, isTypistSupervisor, isSuperAdmin, isVisaAdmin, isRecruiter, isPro, isTypist, checkMyRoles, isMedical, isDataEntrist, getRestrictionsFromEnv } = useUser();
  const { setNavInfo } = useNavigation();

  useEffect(() => {
    getDocumentUploadTypes().catch(error => console.error(error));
    return () => {
      cancelAllRequests();
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    clearAssets({exceptions: ['documentTypes']});
    clearFields();
    clearErrors();
    if (isDataEntrist()) {
      addField('data_entrist_id', user.id);  // Recruiter can not choose other recruiter!
      addAsset('data_entrists', [user]);
    }
    if (employeeId) getEmployeeById(employeeId, false, {globalLoading: true}).catch(error => console.error(error));
    // eslint-disable-next-line
  }, [location.pathname, employeeId]);

  function getError(field, reserved) {
    return getApiError(field) || getLocalError(field) || reserved;
  }

  function clearError(field) {
    clearLocalError(field);
    clearApiError(field);
  }

  // endregion

  // region getRequests
  const getEmployees = async ({query, params}) => {
    const {data, pagination } = await sendRequest(getEmployeesService, { query, params, loading: true });
    addAsset('employees', data);
    addAsset('totalEmployeesCount', (pagination).total);
  };

  const getEmployeeById = async (employee_id = employeeId, noCache = false, {loading, globalLoading} = {}) => {
    const {data} = await sendRequest(getEmployeeByIdService, { params: { employeeId: employee_id }, noCache, loading, globalLoading });
    const { image, users, documents, dependents, ...rest } = data;
    const hasTypistSupervisor = !!(users || []).find(user => isTypistSupervisor(user));
    const assignedRecruiter = (users || []).find(user => isRecruiter(user));
    const assignedTypist = (users || []).find(user => isTypist(user));
    const assignedPro = (users || []).find(user => isPro(user));
    const assignedSupervisor = (users || []).find(user => isTypistSupervisor(user));
    const assignedVisaAdmin = (users || []).find(user => isVisaAdmin(user));
    const assignedDataEntrist = (users || []).find(user => isDataEntrist(user));
    const assignedMedical = (users || []).find(user => isMedical(user));
    const recruiter_id = (assignedRecruiter || {}).id;
    const supervisor_id = (assignedSupervisor || {}).id;
    const typist_id = (assignedTypist || {}).id;
    const pro_id = (assignedPro || {}).id;
    const visa_admin_id = (assignedVisaAdmin || {}).id;
    const data_entrist_id = (assignedDataEntrist || {}).id;
    const medical_insurance_id = (assignedMedical || {}).id;
    const company_id = (rest.company[0] || {}).id || null;
    const employee_categorization = `${rest.employee_categorization}`;
    setNavInfo(` - ${rest.first_name} ${rest.last_name}`);
    addAssetGroup({ assignedDataEntrist, assignedSupervisor, hasTypistSupervisor, uploadedDocuments: documents, dependents: dependents || [] });
    setFields(() => ({...rest, recruiter_id,  supervisor_id, typist_supervisor_id: supervisor_id, company_id, visa_admin_id, typist_id, pro_id, data_entrist_id, medical_insurance_id, employee_categorization}));
    if (image) addAsset('image', `${process.env.REACT_APP_API_URL}${encodeURI(image)}`);
    if (isRecruiter()) addAsset('stepTitle', ((rest.step || [])[0] || {}).title || '-');
    return data;
  }

  const getRecruiters = async () => {
    const {data} = await sendRequest(getUserByRoleService, {params: { role: 'recruiter' } });
    addAsset('recruiters', data)
  };

  const getCompanies = async () => {
    const {data} = await sendRequest(getCompaniesService, {query: {limit: 9999}});
    const restrictions = getRestrictionsFromEnv();
    if (restrictions && restrictions.allowedCompanies) {
      addAsset('companies', data.filter(company => restrictions.allowedCompanies.includes(company.id)));
      return;
    }
    addAsset('companies', data)
    };

  const getStatusFields = async () => {
    const {data} = await sendRequest(getFieldsService);
    addAsset('fields', data)
  }

  const getUserById = async (id) => {
    const {data} = await sendRequest(getUserByIdService, { params: { id } });
    addAsset(`user_${id}`, data);
  };

  const getDocuments = async () => {
    const {data} = await sendRequest(getDocumentsService);
    addAsset('uploadedDocuments', data);
  }

  const getUsers = async (role) => {
    const {data} = await sendRequest(getUserByRoleService, {params: { role }});
    addAsset(`${role}s`, data)
  };

  const getStep = async () => {
    try {
      const {data} = await sendRequest(getEmployeeStepService, {params: {employeeId}});
      const stepAccess = !!(data.roles || []).find(role => checkMyRoles(role.id));
      const stepFields = data.fields || [];
      const step_id = data.id;
      const stepSlug = data.slug;
      const stepGroup = data.group;
      const stepFieldIds = {};
      const candidate_step_id = data.candidate_step_id;
      const stepTitle = data.title;
      const reasons = [ ...((data.rejection_reason || {}).reason || []), ...((data.rejection_reason || {}).comments || []) ];
      const rejectionReasons = reasons.length > 0 ? reasons : null;
      stepFields.map(field => {
        stepFieldIds[field.name] = field.id
        if (field.value) field.value = parseJSON(field.value);
        if (field.roles) field.roles = parseJSON(field.roles);
        if (field.attributes) field.attributes = parseJSON(field.attributes);
        return field
      })
      addAssetGroup({stepAccess, stepFields, step_id, stepFieldIds, stepTitle, rejectionReasons, candidate_step_id, stepSlug, stepGroup})
      return stepAccess
    } catch (e) {
      console.log('failed')
      addAsset('stepGroup', 'first');
      addAsset('stepSlug', 'default_step')
      addAsset('stepOrder', 'default_step')
      addAsset('stepAccess', false)
      return Promise.resolve();
    }
  };

  const getAllSteps = async () => {
    const {data} = await sendRequest(getAllStepsService);
    addAsset('steps', data);
  };

  const getAllProcesses = async () => {
    const {data} = await sendRequest(getAllProcessesService, {query: {limit: 100}});
    addAsset('processes', data);
  };

  const getDocumentUploadTypes = async () => {
    const {data} = await sendRequest(getDocumentUploadTypeService);
    const documentTypes = [];
    Object.keys(data).forEach(key => {
      const text = data[key];
      documentTypes.push({
        text,
        value: key,
      })
      addAsset('documentTypes', documentTypes);
    })
  };

  const getUsersByRoles = async (roles = []) => {
    const {data} = await sendRequest(getUserByRolesService, {payload: {roles: JSON.stringify(roles)}, useFormData: true })
    const assetGroup = {};
    Object.keys(data).forEach(role => assetGroup[`${role}s`] = data[role])
    addAssetGroup(assetGroup);
  };
  //endregion

  // region Requests For Submission

  const createEmployee = async () => {
    const {data} = await sendRequest(createEmployeeService, { payload: fields, loading: true, useFormData: true });
    const requests = [];
    getAsset('dependents', []).forEach(dependant => {requests.push(saveDependant(dependant, data.id));
    })
    await Promise.all(requests).then((res) => {
      const oldDependents = getAsset('dependents');
      setTimeout(() => {
        const local_dependents = []
        res.forEach((dependent, index) => {
          if (dependent.isAxiosError) {
            transformErrors(dependent, '', 'dependent_');
            local_dependents.push({ ...oldDependents[index], error: true })
          } else {
            local_dependents.push({dependent});
          }
          addToArrayInAssets('dependents', local_dependents);
        })
      }, 500)
    })
    goToViewEmployee(data.id);
  }

  const updateEmployee = async ( payload = fields) => {
    const {image, file, id, supervisor_id, visa_admin_id, typist_supervisor_id, typist_id, pro_id, recruiter_id, data_entrist_id, medical_insurance_id, ...restData} = payload; // file is employee newly uploaded image
    if (file) {
      const imageResponse = await sendRequest(updateEmployeeImageService, {payload: {file, candidate_id: id}, loading: true, useFormData: true});
      addAsset('image', imageResponse.data.image);
    }

    const {data} = await sendRequest(updateEmployeeService,{ payload: restData, params:{ employeeId }, loading: true });
    let updatedUsers = data.users;
    if (typist_id || typist_supervisor_id || pro_id || visa_admin_id || recruiter_id || data_entrist_id || medical_insurance_id) {
      updatedUsers = await sendRequest(updateEmployeeService, {payload: { typist_id, typist_supervisor_id, pro_id, visa_admin_id, recruiter_id, data_entrist_id, medical_insurance_id }, params:{ employeeId }}).then( async ({data}) => data.users);
    }
    const { users, ...rest } = data;
    const new_recruiter_id = ((users || []).find(user => isRecruiter(user)) || {}).id;
    const requests = [];
    getAsset('dependents', []).forEach(dependant => {
      if (dependant.id) {
        requests.push(updateDependant(dependant));
      } else {
        requests.push(saveDependant(dependant));
      }
    })
    getAsset('dependentsToDelete', []).forEach((dependentId) => {
      requests.push(deleteDependant(dependentId));
    })
    await Promise.all(requests);
    setFields(() => ({ ...rest, users: updatedUsers, recruiter_id: new_recruiter_id, visa_admin_id, typist_supervisor_id, typist_id, pro_id, data_entrist_id, medical_insurance_id }));
  };

  const deleteEmployee = async (employeeId) => {
    await sendRequest(deleteEmployeeByIdService, {params: { employeeId }, loading: true});
    deleteItemFromArrayByIdInAssets('employees', employeeId);
  };

  const assignUserToEmployee = async (role, user_id) => {
    let field = '';

    switch (role) {
      case 'assign_data_entrist':
        field = 'data_entrist_id';
        break
      default:
        field = role;
    }

    await sendRequest(updateEmployeeService, { payload: { [field]: user_id }, params:{ employeeId}});
  }

  const approveDocumentApi = async ({ documentId, payload = {approval: 1} }) => {
    const {data} = await sendRequest(updateDocumentService, {payload, params: {documentId}, loading: true});
    updateArrayElementByObjectInAssets('uploadedDocuments', data);
    return data;
  };

  const uploadFile = async ({file, name, upload_type, index, errorName, approveOnUpload, noStep}) => {
    const {data} = await sendRequest(uploadDocumentService, {payload: {file, name, upload_type, ...(!noStep && { candidate_step_id: getAsset('candidate_step_id', 1) }), user_id: user.id, candidate_id: employeeId}, loading: true, useFormData: true, errorName});
    if (approveOnUpload) approveDocumentApi({documentId: data.id}).catch(error => console.error(error));
    if (index || index === 0) deleteItemFromArrayByIdInAssets('documents', file.id);
    addToArrayInAssets('uploadedDocuments',  data);
    return data;
  };

  const approveOrRejectDocument = ({document, approval, rejection_reason}) => {
    document.approval = approval;
    document.rejection_reason = rejection_reason;
    document.approvedLocally = 1;
    updateArrayElementByObjectInAssets('uploadedDocuments', document);
  }

  const deleteDocument = async ({ documentId }) => {
    const res = await sendRequest(deleteDocumentService, {params: { documentId }, loading: true})
    deleteItemFromArrayByIdInAssets('uploadedDocuments', documentId);
    return res;
  };

  const goToNextStep = async (payload, skipGet = false) => {
    const {data} = await sendRequest(updateEmployeeStepService, {payload, params: { id: employeeId } });
    if (!skipGet) getEmployeeById().catch(error => console.error(error));
    return data;
  };

  const toggleEmployeeLock = async () => {
    await sendRequest(toggleEmployeeLockService, {payload: {candidate_id: employeeId}});
  }

  const permanentReject = async () => {
    await sendRequest(permanentRejectEmployeeService, {payload: { candidate_id: employeeId }});
    addField('step', []);
  };

  const cycleEmployee = async ({document_ids, process_id}) => {
    await sendRequest(cycleEmployeeService, {payload: { candidate_id: employeeId, document_ids, process_id }, loading: true });
  }

  const saveDependant = async (dependant, employee_id) => {
     try {
       const {data} = await sendRequest(addDependanceService, { payload: { ...dependant, candidate_id: employeeId || employee_id }, errorNamePrefix: 'dependent_', useFormData: true });
       updateArrayElementByObjectInAssets('dependents', { ...data, local_id: dependant.local_id });
       return data;
     } catch (err) {
       updateArrayElementByObjectInAssets('dependents', { ...dependant, error: true });
       return err;
     }
  }

  const updateDependant = async (dependant) => {
    const {file, ...rest} = dependant
    try {
      if (file) await sendRequest(updateDependanceImageService, { payload: { file, dependent_id: dependant.id }, useFormData: true });
      const {data} = await sendRequest(updateDependanceService, { payload: { ...rest, candidate_id: employeeId }, params: { dependantId: dependant.id }, errorNamePrefix: 'dependent_' });
      updateArrayElementByObjectInAssets('dependents', data);
      return data;
    } catch (err) {
      updateArrayElementByObjectInAssets('dependents', { ...dependant, error: true });
      return err;
    }
  }

  const deleteDependant = async (dependantId) => {
    await sendRequest(deleteDependanceService, { params: { dependantId } });
    deleteElementFromArrayInAssets('dependentsToDelete', dependantId);
  };

  const saveEmployee = async () => {
    if (employeeId) {
      await updateEmployee();
    } else {
      await createEmployee();
    }
  }

  const clearUserIdField =() => {
    clearFiled('user_id');
    clearError('user_id');
  };

  const exportEmployees = async (fileName, query) => {
    await sendRequest(exportEmployeesService, { download: true, fileName, query });
  }

  const exportEmployeesVisaExp = async (fileName, query) => {
    await sendRequest(exportEmployeesVisaExpiryService, { download: true, fileName, query });
  }

  const exportEmployeesResidencePermitExp = async (fileName, query) => {
    await sendRequest(exportEmployeesResidencePermitExpiryService, { download: true, fileName, query });
  }

  const exportEmployeesLabourCardExp = async (fileName, query) => {
    await sendRequest(exportEmployeesLabourCardExpiryService, { download: true, fileName, query });
  }

  const exportByCompany = () => sendRequest(exportCandidatesService, { download: true, fileName: `Export Candidates By Company.csv` })
    .catch((err) => {
      console.error(err)
    })

  // endregion

  //region Context Fillers
  const APIRequests = {
    getRecruiters,
    getStatusFields,
    getDocuments,
    getUsers,
    getUsersByRoles,
    getAllProcesses,
    saveEmployee,
    getUserById,
    getStep,
    assignUserToEmployee,
    deleteDocument,
    approveOrRejectDocument,
    approveDocumentApi,
    isLoading,
    uploadFile,
    goToNextStep,
    getAllSteps,
    updateEmployee,
    getEmployeeById,
    toggleEmployeeLock,
    permanentReject,
    cycleEmployee,
    deleteEmployee,
    exportByCompany,
    exportEmployeesVisaExp,
    exportEmployeesLabourCardExp,
    exportEmployeesResidencePermitExp,
  }

  const otherRequests = {
    getError,
    addError,
    clearError,
    location,
    addAsset,
    addField,
    onChange,
    getAsset,
    canUpdate,
    canRead,
    isSuperAdmin,
    isRecruiter,
    isVisaAdmin,
    isPro,
    isMedical,
    isTypist,
    isTypistSupervisor,
    isDataEntrist,
    user,
    clearUserIdField,
    addToArrayInAssets,
    clearFields,
    clearAssets,
    cancelAllRequests,
    exportEmployees,
  }

  const contextRequests = {
    t,
    uploadedDocuments: getAsset('uploadedDocuments', []),
    getEmployees,
    getCompanies,
    getField,
    deleteItemFromArrayByIdInAssets,
    updateArrayElementByObjectInAssets,
    ...otherRequests,
    ...redirect,
    ...helperProps,
    ...APIRequests,
  }

  //endregion

  return (
    <EmployeeContext.Provider value={contextRequests}>
      <EmployeesView/>
    </EmployeeContext.Provider>
  )
}

export default EmployeesContainer;
