import React, {useContext} from "react";
import {Grid } from '@material-ui/core';
import DashboardActionComponent from "./components/DashboardActionComponent";
import DashboardTypist from "./components/DashboardTypist";
import DashboardPro from "./components/DashboardPro";
import DashboardCandidateDocumentsVerification from "./components/DashboardCandidateDocumentsVerification";
import DashboardImportCandidates from "./components/DashboardImportCandidates";
import DashboardCandidateActionComponent from "./components/DashboardCandidateActionComponent";

const DashboardView = function ({context}) {
  const { isSuperAdmin, isVisaAdmin, isTypistSupervisor } = useContext(context);

  return (
    <Grid container spacing={3}>
      {
        (isVisaAdmin()) &&
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DashboardCandidateDocumentsVerification context={context} />
        </Grid>
      }

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DashboardActionComponent context={context}/>
      </Grid>

        {
          (isVisaAdmin() || isSuperAdmin() || isTypistSupervisor()) &&
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DashboardTypist context={context} />
          </Grid>
        }


        {
          (isVisaAdmin() || isSuperAdmin()) &&
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DashboardPro context={context} />
          </Grid>
        }

        {
          (isVisaAdmin() || isSuperAdmin()) &&
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DashboardCandidateActionComponent context={context} />
          </Grid>
        }

      {
        (isVisaAdmin()) &&
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DashboardImportCandidates context={context} />
        </Grid>
      }
    </Grid>
  )
}

export default DashboardView;
