import React from "react";
import EmployeesTableView from "./EmployeesTableView";

function EmployeesTable(props) {
  return (
    <EmployeesTableView {...props}/>
  )
}

export default EmployeesTable;
