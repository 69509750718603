import React from "react";
import { Route } from "react-router-dom";

function PublicRoute({View = <div/>, children, ...rest}) {

  return (
    <Route {...rest}>
      <View>
        {children}
      </View>
    </Route>
  )
}

export default PublicRoute;
