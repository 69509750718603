import axios from "axios";
import { transformResponse } from "./helpers";

export const getUserByRoleService = ({ data, config, params } = {}) => {
  return axios({ method: 'get', url: `/users/role/${params.role}`, data, ...(config || {}) }).then(response => {
    return transformResponse(response);
  })
}

export const getUserByRolesService = ({ data, config, } = {}) => {
  return axios({ method: 'post', url: `/users/roles`, data, ...(config || {}) }).then(response => {
    return transformResponse(response);
  })
}

export const getUserByIdService = ({ data, config, params } = {}) => {
  return axios({ method: 'get', url: `/users/${params.id}`, data, ...(config || {}) }).then(response => {
    return transformResponse(response);
  })
}

export const getUsersService = ({data, config, query} = {}) => {
  return axios({method: 'get', url: `/users`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const addUserService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/users`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateUserService = ({data, config, params} = {}) => {
  const userId = (params || {}).userId;
  if (data.password === '' || data.password_confirmation === '') {
    delete data.password;
    delete data.password_confirmation;
  }
  return axios({method: 'put', url: `/users/${userId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const deleteUserService = ({data, config, params} = {}) => {
  const userId = (params || {}).userId;
  return axios({method: 'delete', url: `/users/${userId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const pushTokenStepService = ({data, config} = {}) => {
  return axios({method: 'put', url: `/notification-token`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const logoutService = ({data, config, params} = {}) => {
  return axios({method: 'post', url: `/logout`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}


