import React, {Fragment} from "react";
import useAxios from "../Hooks/useAxios";

const LoadAxios = ({children}) => {
  useAxios();

  return (
    <Fragment>
      {children}
    </Fragment>
  )
};

export default LoadAxios;
