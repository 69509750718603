import { useHistory } from 'react-router-dom';

function useRedirect() {
  const {push} = useHistory();

  function goToDashboard() {
    push(`/dashboard`);
  }

  function goToUserProfile() {
    push(`/users/me`);
  }

  function goToNewUser(){
    push(`/users/new`);
  }

  function goToEditUser(userId) {
    push(`/users/edit/${userId}`);
  }

  function goToViewUser(userId){
    push(`/users/view/${userId}`);
  }

  function goToEmployees(){
    push(`/employees`);
  }

  function goToNewEmployee(){
    push(`/employees/new`);
  }

  function goToEditEmployee(employeeId) {
    push(`/employees/edit/${employeeId}`);
  }

  function goToViewEmployee(employeeId){
    push(`/employees/view/${employeeId}`);
  }

  function goToNewCompany() {
    push(`/companies/new`);
  }

  function goToEditCompany(companyId) {
    push(`/companies/edit/${companyId}`);
  }

  function goToViewCompany(companyId){
    push(`/companies/view/${companyId}`);
  }

  function goToEditNotification(notificationId) {
    push(`/notifications/${notificationId}`)
  }

  function goToExportByCompany() {
    push(`/export`);
  }


  return { goToNewEmployee, goToViewEmployee, goToEditEmployee, goToViewCompany, goToEditCompany, goToNewCompany, goToDashboard, goToUserProfile, goToEditUser, goToViewUser, goToNewUser, goToEmployees, goToEditNotification, goToExportByCompany };
}

export default useRedirect;
