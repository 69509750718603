import React, {useContext} from 'react'
import {Badge, Card, CardHeader, Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns'
import {EmployeeContext} from "../../../EmployeesContainer";
import {formatDate} from "../../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  },
  cardFullHeight: {
    height: "100%"
  },
  textFields: {
    padding: "0 24px 24px 24px",
    '& .MuiGrid-item': {
      marginTop: '20px',
      wordBreak: 'break-word'
    }
  },
  makeBolder: {
    fontWeight: '600',
  },
  title: {
    marginRight: '15px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  value: {
    marginRight: '15px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#000000',
  },
  noMargin: {
    marginTop: '0 !important',
  },
}));

function OnboardingInformation() {
  const {t, getField} = useContext(EmployeeContext);
  const classes = useStyles();

  const mol_job_offer = getField('mol_job_offer', {});
  const work_permit = getField('work_permit', {});
  const visa_application = getField('visa_application', {});
  const contract = getField('contract', {});
  const medical_exam = getField('medical_exam', {});

  return (
    <Card elevation={1} className={classes.cardFullHeight}>
      <CardHeader title={t('tracker:onboarding_information')} className={classes.header} />
      <Grid container className={classes.textFields}>

        <Grid item xs={12}>
          <Typography component={'span'} className={classes.title}>TOTAL FEES: </Typography>
          <Typography component={'span'} className={classes.value}> { getField('total_fees') } </Typography>
        </Grid>

        {/* JOB MOL OFFER INFORMATION */}
        <Grid item xs style={{minWidth: 280, maxWidth:400}}>
          <Badge badgeContent={ mol_job_offer.count || 0 } color="primary">
            <Typography className={classes.title}> { t('tracker:job_mol_offer') } </Typography>
          </Badge>

          <Grid container>
            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:submission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(mol_job_offer.submission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:ref_number`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {mol_job_offer.reference_number || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:approval_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(mol_job_offer.approval_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(mol_job_offer.rejection_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_reason`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {mol_job_offer.rejection_reason || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:resubmission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(mol_job_offer.resubmission_date) }
            </Grid>
          </Grid>

        </Grid>

        {/* WORK PERMIT */}
        <Grid item xs style={{minWidth: 280, maxWidth:400}}>
          <Badge badgeContent={work_permit.count || 0} color="primary">
            <span className={classes.title}> { t('tracker:work_permit') } </span>
          </Badge>

          <Grid container>
            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:submission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(work_permit.submission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:approval_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(work_permit.approval_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(work_permit.rejection_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_reason`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {work_permit.rejection_reason || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:resubmission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(work_permit.resubmission_date) }
            </Grid>
          </Grid>

        </Grid>

        {/* VISA APPLICATION */}
        <Grid item xs style={{minWidth: 380, maxWidth:400}}>
          <Badge badgeContent={visa_application.count || 0} color="primary">
            <span className={classes.title}> { t('tracker:visa_application') } </span>
          </Badge>

          <Grid container>
            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:submission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.submission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:ref_number`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {visa_application.reference_number || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:approval_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.approval_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.rejection_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_reason`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {visa_application.rejection_reason || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:resubmission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.resubmission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:change_status_procedure_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.change_procedure_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:visa_stamping_submission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.visa_stamping_submission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:visa_stamping_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(visa_application.visa_stamping_date) }
            </Grid>
          </Grid>

        </Grid>

        {/* CONTRACT */}
        <Grid item xs style={{minWidth: 280, maxWidth:400}}>
          <Badge badgeContent={ contract.count || 0 } color="primary">
            <Typography className={classes.title}> { t('tracker:contract') } </Typography>
          </Badge>

          <Grid container>
            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:submission_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(contract.submission_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:ref_number`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {contract.reference_number || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:approval_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(contract.approval_date) }
            </Grid>
          </Grid>

        </Grid>

        {/* MEDICAL EXAM */}
        <Grid item xs style={{minWidth: 280, maxWidth:400}}>
          <Badge badgeContent={medical_exam.count || 0} color="primary">
            <span className={classes.title}> { t('tracker:medical_exam') } </span>
          </Badge>

          <Grid container>
            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:scheduled_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(medical_exam.scheduled_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:scheduled_time`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {medical_exam.scheduled_time || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:address`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {medical_exam.medical_address || '-'}
            </Grid>

            <Grid item xs={8} className={classes.makeBolder}>
              {t(`tracker:contact_phone`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              {medical_exam.contact_number || '-'}
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:approved_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(medical_exam.approval_date) }
            </Grid>

            <Grid item xs={12} className={classes.makeBolder}>
              {t(`tracker:rejection_date`)}
            </Grid>
            <Grid item xs={12} className={classes.noMargin}>
              { formatDate(medical_exam.rejection_date) }
            </Grid>

          </Grid>

        </Grid>

      </Grid>
    </Card>
  )
}

export default OnboardingInformation;
