import React from "react";
import ResetPasswordContainer from "./ResetPasswordContainer";

function ResetPassword() {
  return (
    <ResetPasswordContainer />
  )
}

export default ResetPassword;
