import React from 'react';
import Login from "../../pages/Login";
import useAuth from "../../Hooks/useAuth";
import Spinner from "../../components/Spinner";
import { Route } from "react-router-dom";
import useNavigation from "../../Hooks/useNavigation";

function PrivateRoute ({children, roleProtection, View = <div/>, ...rest}) {
  const {getAllowedPaths, getLinks} = useNavigation();
  const {isLoggedIn} = useAuth();

  if (isLoggedIn && getAllowedPaths().includes(rest.path)) {
    return (
      <Route {...rest}>
        <View>
          {children}
        </View>
      </Route>
    )
  } else if (isLoggedIn && !getAllowedPaths().includes(rest.path)) {
    const links = getLinks();
    setTimeout(() => {
      if (links && links[0]) {
        links[0].redirect();
      }
    }, 100)
    return <Spinner fullScreen={true}/>
  } else if (isLoggedIn === null) {
   return <Spinner fullScreen={true}/>
  }
  return <Login/>
}

export default PrivateRoute;
