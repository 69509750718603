import axios from "axios";
import { transformResponse } from "./helpers";

export const authorize = ({ data, config } = {}) => {
  return axios({ method: "post", url: "/login", data, ...(config || {}) })
    .then((response) => {
      return transformResponse(response);
    })
    .catch((err) => {
      console.log("err => ", err);
      console.log("response => ", JSON.stringify(err.response));
      return err;
    });
};

export const getMe = ({ data, config } = {}) => {
  return axios({ method: "get", url: "/me", data, ...(config || {}) }).then(
    (response) => {
      return transformResponse(response);
    }
  );
};

export const forgotPasswordService = ({ data, config } = {}) => {
  return axios({ method: 'post', url: '/password/email', data, ...(config || {}) }).then(response => {
    return transformResponse(response);
  })
};

export const resetPasswordService = ({ data, config, params } = {}) => {
  return axios({method: "post", url: "/password/reset", data, ...(config || {}), }).then((response) => {
    return transformResponse(response);
  });
};
