import React from "react";
import {Card, Grid, Typography} from "@material-ui/core";
import logo from "../../assets/img/WFC.svg";
import {makeStyles} from "@material-ui/core/styles";
import AppButton from "../../components/AppButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    minHeight: 'min(100vh, 550px)',
    [theme.breakpoints.down("xs")]: {
      marginTop: '0',
      height: '100vh',
    }
  }
}));

function MaintenanceView() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container justify="center" spacing={3}>
        <Grid style={{ marginTop: '70px' }} item xs={12} align="center">
          <img style={{ marginBottom: '20px' }} src={logo} alt="logo"/>
          <div>
            <Typography>
              Maintenance is in progress, please be patient.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  )
}

export default MaintenanceView;
