import React, {useContext} from 'react';
import { makeStyles, AppBar, Tabs, Tab, Box } from '@material-ui/core'
import EmployeeProfilePage from "../pages/EmployeeProfilePage";
import EmployeeDocumentsPage from "../pages/EmployeeDocumentsPage";
import EmployeeProfileReadOnly from "../pages/EmployeeProfilePage/EmployeeProfileReadOnly";
import {EmployeeContext} from "../EmployeesContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    marginBottom: '24px',
  },
  tabs: {
    backgroundColor: '#ffffff',
    '& .MuiTab-root': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .Mui-selected': {
      color: theme.palette.secondary.main
    },
  }
}));

function a11yProps(index) {  // Props for tabs
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) { // Tab panels (pages)
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function EmployeeFormNavigator({readOnly}) {
  const {t, addAsset, getAsset} = useContext(EmployeeContext);
  const classes = useStyles();
  const tabValue = getAsset('employeesNavigatorTabs', 0);

  const handleChange = (event, newValue) => {
    addAsset('employeesNavigatorTabs', newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs className={classes.tabs} value={tabValue} onChange={handleChange} aria-label="employee navigation">
          <Tab label={t('navigation:profile')} {...a11yProps(0)} />
          <Tab label={t('navigation:documents')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>  {/* TavPanel props index and value are required */}
        { readOnly ? <EmployeeProfileReadOnly/> : <EmployeeProfilePage/> }
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <EmployeeDocumentsPage/>
      </TabPanel>
    </div>
  );
}

export default EmployeeFormNavigator;
