import React from 'react';
import {Card, Grid, Typography, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import useUpload from "../../../../../../Hooks/useUpload";
import AppButton from "../../../../../../components/AppButton";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";
import RejectionReasons from "../components/RejectionReasons";
import AppSimpleUploadFile from "../../../../../../components/AppSimpleUploadFile";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

function StepUploadDocuments({t, getAsset, addAsset, getField, onChange, uploadFile, deleteDocument, nextStep, isPromise, isLoading, addError, getError, clearError, addToArrayInAssets}) {
  const classes = useStyles();
  const documents = getAsset('documents', []);
  const step_id = getAsset('candidate_step_id');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id && document.approval !== 0 && document.approval !== 1);
  const [confirm, toggleConfirm] = useDialog();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);

  const uploadDocumentsError = getError('step_1_upload_documents_required');

  function onConfirm() {
    const fields = [];
    uploadedDocuments.forEach((item) => {
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })
    const messages = ['Candidate documents uploaded'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    addAsset('RejectionReasons', null);
    toggleConfirm();
  }

  function onUpload(data) {
    clearError('step_1_upload_documents_required');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
     return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function validate() {
    let error = false
    if (uploadedDocuments.length === 0) {
      addError('step_1_upload_documents_required', 'Please Upload Documents');
      error = true
    }
    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function UploadFile() {
    return (
      <AppSimpleUploadFile handleUpload={handleUpload}/>
    )
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        { getAsset('rejectionReasons') &&
          <Grid item xs={12} md={6} lg={5}>
            <RejectionReasons rejectionReasons={getAsset('rejectionReasons')}/>
          </Grid>
        }
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_employees_documents')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={"auto"}>
                <NextButton disabled={isLoading} style={{marginTop: '5px'}}/>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadFile/>
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton disabled={isLoading} fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepUploadDocuments;
