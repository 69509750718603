import React from "react";
import EmployeeProfileForm from "./EmployeeProfileForm";

function EmployeeProfilePage() {
  return (
    <EmployeeProfileForm/>
  )
}

export default React.memo(EmployeeProfilePage);
