import React from "react";
import DashboardContainer from "./DashboardContainer";

const Dashboard = function () {
  return (
    <DashboardContainer/>
  )
}

export default Dashboard;
