import React, {useEffect} from 'react'
import useAPI from 'Hooks/useAPI';
import { useTranslation } from "react-i18next";
import useForm from 'Hooks/useForm';
import { getMe } from 'services/loginService';
import {
  addUserService,
  updateUserService,
  deleteUserService,
  getUsersService,
  getUserByIdService
} from 'services/userService';
import useRedirect from "../../Hooks/useRedirect";
import UsersView from "./UsersView";
import useRouteParams from "../../Hooks/useRouteParams";
import {EDIT_USER, VIEW_USER} from "../../navigation/CONSTANTS";
import {useLocation} from "react-use";
import useUser from "../../Hooks/useUser";
import useHelpers from "../../Hooks/useHelpers";

function UsersContainer() {
  const { sendRequest, isLoading, getApiError, clearApiError } = useAPI();
  const { t } = useTranslation();
  const { addAsset, getAsset, getField, onChange, fields, setFields, addError, getLocalError, clearLocalError, deleteItemFromArrayByIdInAssets, deleteField, clearAssets, clearFields } = useForm();
  const { goToEditUser, goToViewUser, goToNewUser } = useRedirect();
  const editUserProps = useRouteParams(`/${EDIT_USER}`);
  const viewUserProps = useRouteParams(`/${VIEW_USER}`);
  const { userId } = editUserProps || viewUserProps || {};
  const location = useLocation();
  const { roles } = useUser();
  const {validatePassword} = useHelpers();

  useEffect(() => {
    clearAssets();
    clearFields();
    if (userId) getUserById();
    //eslint-disable-next-line
  }, [userId, location.pathname]);

  function getError(field, reserved) {
    return getApiError(field) || getLocalError(field) || reserved;
  }

  function clearError(field) {
    clearLocalError(field);
    clearApiError(field);
  }

  /*  GET API-S  */

  const getUsers = ({query}) => sendRequest(getUsersService, { query, loading: true })
    .then(({data, pagination}) => {
      addAsset('users', data);
      addAsset('totalUsersCount', (pagination).total);
    });

  const getUser = () => sendRequest(getMe).then(({ data }) => {
    const { role, ...rest } = data
    addAsset('role', role);
   /* if (data.company_id !== 0) {
      data.company = data.company[0].title;
    } */
    setFields(() => ({ ...rest, role: role[0].id }));
    return data;
  });

  const getUserById = (id = userId) => sendRequest(getUserByIdService, { params: { id } }).then(({data}) => {
    const { role, ...rest } = data
    addAsset('role', role);

    setFields(() => ({ ...rest, role: role[0].id }));
  })


  /*  SUBMIT API-S  */

  const addUser = () => sendRequest(addUserService, {payload: fields, loading: true}).then(({data}) => {
    goToViewUser(data.id)
  })

  //const updateUserServiceProfile = () => sendRequest(updateUserService, { payload: fields, loading: true }).then(({ data }) => getUser());
  const updateUser = ( user_id = userId, payload = fields) => sendRequest(updateUserService, {payload, params: { userId: user_id }, loading: true });
  const deleteUser = (user_id = userId) => sendRequest(deleteUserService, {params: { userId: user_id }}).then(() => {
    deleteItemFromArrayByIdInAssets('users', userId);
  });

  const saveUser = () => {
    const id = getField('id');
    if (id) {
      updateUser();
    } else {
      addUser();
    }
  }

  const APIRequests = {
    getUser
  }

  return (
    <UsersView
      getError={getError}
      addError={addError}
      clearError={clearError}
      {...APIRequests}
      getAsset={getAsset}
      addAsset={addAsset}
      getField={getField}
      onChange={onChange}
      isLoading={isLoading}
      goToEditUser={goToEditUser}
      goToViewUser={goToViewUser}
      goToNewUser={goToNewUser}
      t={t}
      addUser={addUser}
      updateUser={updateUser}
      deleteUser={deleteUser}
      getUsers={getUsers}
      saveUser={saveUser}
      getUserById={getUserById}
      clearField={deleteField}
      roles={roles}
      validatePassword={validatePassword}
    />
  )
}

export default UsersContainer
