import React from "react";
import AppTextField from "../../../../../../components/AppTextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: theme.variables.errorTextColor,
    }
  }
}))

function RejectionReasons({rejectionReasons = []}) {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <AppTextField error variant="outlined" label={'Rejection Reasons'} multiline value={(rejectionReasons || []).join("\r\n\n") || ' '}/>
    </div>
  )
}

export default RejectionReasons;
