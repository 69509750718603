import React from 'react'
import { Card, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textFields: {
    padding: "24px"
  },
  makeBolder: {
    fontWeight: '600'
  },
  span: {
    fontWeight: 'normal'
  }
}));

function ViewUser({getField, t, roles, getAsset}) {
  const classes = useStyles();

  function getRole(userRole) {
    if (!userRole) return '';
    const role = roles.find(role => role.id === userRole[0].id)
    return (role || {}).title || '-';
  }

  return (
    <Card className={classes.textFields}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ marginBottom: '8px' }} variant="h4" className={classes.makeBolder}>
            {getField('first_name')} {getField('last_name')}
          </Typography>
          <Divider />
        </Grid>
      </Grid>
     <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`employees:first_name`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('first_name')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`employees:last_name`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('last_name')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`user:role`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getRole(getAsset('role'))}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`employees:email`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('email')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`employees:phone`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('phone')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`employees:address`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('address')}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ViewUser;
