import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from "./redux";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import 'fontsource-roboto';
import theme from "./styles/styles";
import LoadUser from "./hoc/LoadUser";
import {PrivateRoutesInMainView, PublicRoutesInEmptyView} from "./navigation";
import LoadAxios from "./hoc/LoadAxios";
import {SnackbarProvider} from "notistack";
import LoadPushNotifications from "./hoc/LoadPushNotifications";

export default function App() {

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={10} hideIconVariant preventDuplicate>
        <Provider store={store}>
          <CssBaseline/>
          <BrowserRouter>
            <LoadPushNotifications/>
            <LoadUser/>
            <LoadAxios/>
            <PublicRoutesInEmptyView/>
            <PrivateRoutesInMainView/>
          </BrowserRouter>
        </Provider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}
