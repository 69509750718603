import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AppTextField from "./AppTextField";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  small_NoLabel: {
    marginBottom: '-15px',
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '1px',
      '& .MuiAutocomplete-input': {
        paddingBottom: '5px !important'
      }
    }
  }
}))

function AppAutocomplete({label, onChange = () => {}, name, getError, clearError = () => {}, getField = () => {}, itemValueUnit = 'id', items = [], size, noLabel, placeholder, ...rest}) {
  const classes = useStyles();
  const { getOptionLabel = (option) => option.name || '' } = rest;

  function handleChange(e, value) {
    clearError(name);
    onChange(e, name, value ? value[itemValueUnit] : null);
  }

  const additionalTextFieldProps = {
    ...(size && { size }),
    ...(size === 'small' && noLabel && { className: clsx({[classes.small_NoLabel]: size === 'small' && noLabel}) }),
  }

  return (
    <Autocomplete
      options={items}
      value={items.find(item => (item || {})[itemValueUnit] === getField(name, null)) || null}
      onChange={handleChange}
      name={name}
      getOptionLabel={getOptionLabel}
      {...rest}
      renderInput={(params) =>
        <AppTextField
          {...params}
          getError={getError}
          name={name}
          label={label}
          placeholder={placeholder}
          {...additionalTextFieldProps}
        />}
    />
  )
}

export default AppAutocomplete;
