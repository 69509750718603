import React, { useEffect } from 'react'
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppTextField from 'components/AppTextField';
import AppButton from 'components/AppButton';
import AppAutocomplete from "../../../components/AppAutocomplete";


const useStyles = makeStyles((theme) => ({
  cardPadding: {
    padding: "10px 30px 24px 30px"
  },
  gridContainer: {
    marginTop: "20px"
  }
}));

function EditUser({ t, onChange, getField, getError, clearError, addError, saveUser, isLoading, roles, validatePassword }) {
  const classes = useStyles();

  function validate() {
    let error = false;
    let fields = ['email', 'first_name', 'last_name'];

    if (!getField('id')) {
      fields.push('password');
    }

    const messages = {};
    fields.forEach(field => {
      if (!getField(field)) {
        let displayField = field.replaceAll('_', ' ');
        displayField = displayField.charAt(0).toUpperCase() + displayField.slice(1) + ' is required';
        console.log(`${messages[field] || displayField}`)
        addError(field, `${messages[field] || displayField}`);
        error = true;
      }
    })

    const passwordValidation = validatePassword(getField('password'), getField('password_confirmation'));
    if (passwordValidation.error) {
      error = true;
      if (passwordValidation.type === 'equality') {
        addError('password_confirmation', passwordValidation.message);
      } else {
        addError('password', passwordValidation.message);
      }
    }

    return error;
  }

  function onUpdateProfile() {
    const error = validate();
    if (!error) saveUser();
  }

  return (
    <Card elevation={1} className={classes.cardPadding}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="first_name" label={t('employees:first_name')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="last_name" label={t('employees:last_name')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="phone" label={t('employees:phone')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="address" label={t('companies:address')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="email" label={t('employees:email')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppAutocomplete
            items={roles}
            getOptionLabel={(option) => option.title}
            clearError={clearError}
            getError={getError}
            getField={getField}
            onChange={onChange}
            name="role"
            label={t('role')}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField type="password" clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="password" label={t('password')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <AppTextField type="password" clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="password_confirmation" label={t('password_confirmation')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppButton fullWidth onClick={onUpdateProfile} isLoading={isLoading}>
            {t('user:save_user')}
          </AppButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default EditUser;
