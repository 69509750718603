import React, {useEffect} from "react";
import useForm from "../../Hooks/useForm";
import CompaniesView from "./CompaniesView";
import {useTranslation} from "react-i18next";
import useAPI from "../../Hooks/useAPI";
import {
  createCompanyService, deleteCompanyService,
  getCompaniesService,
  getCompanyByIdService,
  updateCompanyService
} from "../../services/companiesService";
import useRedirect from "../../Hooks/useRedirect";
import {useLocation} from "react-use";
import useRouteParams from "../../Hooks/useRouteParams";
import {EDIT_COMPANY, VIEW_COMPANY} from "../../navigation/CONSTANTS";
import {getUserByRoleService} from "../../services/userService";
import useUser from "../../Hooks/useUser";
import useNavigation from "../../Hooks/useNavigation";

function CompaniesContainer() {
  const {addAsset, getAsset, getField, onChange, fields, setFields, clearFields, clearAssets, deleteItemFromArrayByIdInAssets} = useForm();
  const { t } = useTranslation();
  const { canCreate, canUpdate, canRead, isRecruiter } = useUser();
  const { sendRequest, isLoading, getError, clearError, cancelAllRequests } = useAPI();
  const { goToViewCompany, goToEditCompany, goToNewCompany } = useRedirect();
  const { setNavInfo } = useNavigation();
  const location = useLocation();
  const editPageProp = useRouteParams(`/${EDIT_COMPANY}`);
  const viewPageProp = useRouteParams(`/${VIEW_COMPANY}`);
  const { companyId } = editPageProp || viewPageProp || {};


  useEffect(() => {
    return () => {
      cancelAllRequests();
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    clearAssets();
    clearFields();
    if (companyId) getCompanyById();
    //eslint-disable-next-line
  }, [location.pathname, companyId])


  // GET Requests
  const getCompanies = ({query}) => sendRequest(getCompaniesService, {loading: true, query}).then(({data, pagination}) => {
    addAsset('companies', data);
    addAsset('totalCompaniesCount', pagination.total);
  })
  const getRecruiters = () => sendRequest(getUserByRoleService, {params: { role: 'recruiter' } }).then(({data}) => addAsset('recruiters', data));
  const getCompanyById = (id = companyId) => sendRequest(getCompanyByIdService, {params: {companyId: id}, globalLoading: true}).then(({data}) => {
    const recruiter_id = ((data.users || []).find(user => isRecruiter(user)) || {}).id;
    setNavInfo(` - ${data.title}`)
    setFields(() => ({...data, recruiter_id}));
    return data;
  });

  // Submit Requests
  const createCompany = () => sendRequest(createCompanyService, {payload: fields, loading: true}).then(({data}) => {
    goToViewCompany(data.id)
  });
  const updateCompany = () => sendRequest(updateCompanyService, {payload: fields, params: {companyId}, loading: true}).then(({data}) => getCompanyById(data.id));
  const deleteCompany = (companyId) => sendRequest(deleteCompanyService, {params: {companyId}, loading: true}).then(() => {
    deleteItemFromArrayByIdInAssets('companies', companyId)
  })

  const APIRequests = {
    saveCompany: () => {
      if (companyId) updateCompany();
      else createCompany();
    },
    getRecruiters,
    getCompanies,
    deleteCompany,
    getCompanyById,
    isLoading
  }

  return (
    <CompaniesView
      goToViewCompany={goToViewCompany}
      goToEditCompany={goToEditCompany}
      goToNewCompany={goToNewCompany}
      {...APIRequests}
      getError={getError}
      addAsset={addAsset}
      getAsset={getAsset}
      getField={getField}
      onChange={onChange}
      clearError={clearError}
      canCreate={canCreate}
      canUpdate={canUpdate}
      canRead={canRead}
      t={t}/>
  )
}

export default CompaniesContainer;
