import React, {useState, Fragment, useRef, useEffect, useContext} from 'react'
import { Card, CardHeader, Grid, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Hidden, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, EditOutlined, VisibilityOutlined } from "@material-ui/icons";
import ExpandableTableRow from 'components/ExpandableTableRow';
import AppTextWithHint from 'components/AppTextWithHint';
import AppSeparator from 'components/AppSeparator';
import User from 'pages/Employees/pages/EmployeesTable/components/User';
import usePagination from "../../../Hooks/usePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import useDialog from "../../../Hooks/useDialog";
import AppAlertDialog from "../../../components/Dialogs/AppAlertDialog";
import AppIconWithLoading from "../../../components/Icons/AppIconWithLoading";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#BD1C1A30',
  },
  header: {
    backgroundColor: '#A7A7A7 !important',
    color: '#ffffff',
    '& .MuiTypography-root': {
      fontSize: '1.2rem'
    }
  },
  tableCellFirstOfKind: {
    paddingTop: '5px',
    paddingBottom: '5px',
    width: '50px'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      borderBottom: '1px solid white'
    }
  },
  tableCell: {
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid white'
  },
  tableControls: {
    borderBottom: '1px solid white'
  },
  icon: {
    cursor: 'pointer',
    '&:first-of-type': {
      marginRight: '20px',
    }
  },
  mobileControls: {
    paddingTop: '8px'
  },
  userImage: {
    width: '40px',
    height: '40px',
  },
}));

function DashboardImportCandidates({context}) { // DashboardContext
  const {t, getAsset, goToEditEmployee, goToViewEmployee, canRead, canUpdate, isTypist, isPro, getEmployeeById, getImportCandidates} = useContext(context);
  const [state, setState] = useState({});
  const { page, limit, nextPage, changeLimit, getPaginationQuery } = usePagination({initLimit: 100});
  const classes = useStyles();
  const updatePermission = canUpdate() && !isTypist() && !isPro();
  const readPermission = canRead();
  const total = getAsset(`totalImportCandidatesTable`, 0);
  const employeesForImport = getAsset(`employeesForImportTable`, []);
  const [isLoading, setIsLoading] = useState(false)
  const interval = useRef(null);
  const [alert, toggleAlert] = useDialog();

  useEffect(() => {
    runSearch();
    interval.current = setInterval(() => {
      runSearch();
    }, 300000)
    return () => {
      clearInterval(interval.current);
    }
    //eslint-disable-next-line
  }, [])

  function runSearch(_page, _limit) {
    setIsLoading(true);
    getImportCandidates({ query: getPaginationQuery({_page, _limit}) }).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
  }

  function onChangeRowsPerPage(e) {
    const newRowsPerPage = parseInt(e.target.value, 10);
    nextPage(0);
    changeLimit(newRowsPerPage);
    runSearch(0, newRowsPerPage);
  }

  function onChangePage(e, newPage) {
    nextPage(newPage);
    runSearch(newPage);
  }

  async function handleGoToEdit(id) {
    const res = await getEmployeeById(id, true);
    goToEditEmployee(id);
    return res;
  }

  async function handleGoToView(id) {
    const res = await getEmployeeById(id);
    goToViewEmployee(id);
    return res;
  }

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  return (
    <Card className={classes.background}>
      <CardHeader className={classes.header} style={headerStyles} title={'Import Candidates'} />
      {isLoading && <LinearProgress />}
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>{t('dashboard:sn_#')}</TableCell>
              <TableCell>{t('dashboard:employee')}</TableCell>
              <Hidden mdDown>
                <TableCell>{t('dashboard:company')}</TableCell>
                <TableCell>{t('dashboard:assigned_date')}</TableCell>
                <TableCell align='right' style={{ paddingRight: '40px' }}>{t('dashboard:actions')}</TableCell>
              </Hidden>
              <Hidden lgUp>
                <TableCell> </TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeesForImport.map((row) => {
              return (
                <Fragment key={row.id}>
                  <TableRow hover key={row.company} onClick={() => setState({ ...state, [row.id]: !state[row.id] })}>
                    <TableCell className={classes.tableCell}>{row.id}</TableCell>
                    <Hidden lgUp>
                      <TableCell><User baseUrl={process.env.REACT_APP_API_URL} imageUrl={row.image} first_name={row.first_name} last_name={row.last_name}/></TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell className={classes.tableCell}>
                        <User baseUrl={process.env.REACT_APP_API_URL} imageUrl={row.image} first_name={row.first_name} last_name={row.last_name} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>{row.company[0].title}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {((row.step[0] || {}).date_diff_days && `${(row.step[0] || {}).date_diff_days} ${t('dashboard:day_ago')}`) || '-'}
                      </TableCell>
                      <TableCell className={classes.tableControls}  align='right'>
                        {updatePermission && <AppIconWithLoading onClick={() => handleGoToEdit(row.id)} loading={false}> <EditOutlined  className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                        {readPermission && <AppIconWithLoading onClick={() => handleGoToView(row.id)}> <VisibilityOutlined className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                      </TableCell>
                    </Hidden>

                    <Hidden lgUp>
                      <TableCell>
                        <ArrowDropDown color={"secondary"} />
                      </TableCell>
                    </Hidden>
                  </TableRow>
                  <ExpandableTableRow hiddenProps={{ lgUp: true }} setKey={`${row.id}`} expanded={state[row.id]} colSpan={3}>
                    <Grid container alignItems={"center"} justify={"center"}>
                      <Grid item xs={8}>
                        <AppTextWithHint align={'left'} text={'Company'} hint={row.company[0].title} textColor={"rgba(0, 0, 0, 0.56)"} />
                        <AppSeparator />
                      </Grid>
                      <Grid className={classes.mobileControls} item xs={4} align={'end'}>
                        {updatePermission && <AppIconWithLoading onClick={() => handleGoToEdit(row.id)} loading={false}> <EditOutlined  className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                        {readPermission && <AppIconWithLoading onClick={() => handleGoToView(row.id)}> <VisibilityOutlined className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                        <AppSeparator />
                      </Grid>
                      <Grid item xs={12}>
                        <AppTextWithHint hintNoneWord={'-'} align={'left'} text={t('dashboard:assigned_date')} hint={(row.step[0] || {}).date_diff_days && `${(row.step[0] || {}).date_diff_days} ${ t('dashboard:day_ago')}`} textColor={"rgba(0, 0, 0, 1)"} />
                        <AppSeparator />
                      </Grid>
                    </Grid>
                  </ExpandableTableRow>
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[200, 100 ,50]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
      <AppAlertDialog open={alert} onOk={toggleAlert} title={'Candidate is locked'} text={'The candidate has been currently edited by other user.'} />
    </Card>
  )
}

export default DashboardImportCandidates;
