import React, {useEffect} from 'react'
import {
  Card,
  CardHeader,
  Grid,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppButton from 'components/AppButton';
import AppStepper from '../components/AppStepper';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import {useEffectOnce} from "react-use";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
  },
}));

const steps = [
  {
    label: 'Onboarding Completed!',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Send data to archive',
    hint: 'Current Step',
    current: true
  }
];

// STEP 28
function StepRestartOnboarding({t, getAsset, getField, onChange, addError, nextStep, getError, clearError, isLoading, getAllProcesses }) {
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(item => item.approval === 1);
  const documentsLength = uploadedDocuments.length;

  useEffectOnce(() => {
    getAllProcesses();
  })

  useEffect(() => {
    setTimeout(() => {
      (uploadedDocuments || []).forEach(document => {
        console.log(`${document.id}-doc-type`, document.upload_type)
        onChange(null, `${document.id}-doc-type`, document.upload_type);
      })
    }, 0)
  }, [documentsLength])

  function onConfirm() {
    const messages = ['Send Data to Archive completed'];
    const fields = [{field: "confirm_archiving", value: true}];
    const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0};
    nextStep(stepPayload);
  }

  function validate() {
    let error = false;

    if (!getField('confirm_archiving')) {
      addError('confirm_archiving', `Please confirm sending data to archive`);
      error = true;
     }

    return error;
  }

  function handleArchive() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} isLoading={isLoading} onClick={handleArchive}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={'Send Data To Archive'} steps={steps} />
        </Grid>

        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '140px' }}>
            <NextButton />
          </Grid>
        </Hidden>

        <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
          <AppCheckbox getField={getField} clearError={clearError} getError={getError} name={'confirm_archiving'} onChange={onChange} label={'Confirm sending data to archive'}/>
        </Grid>

        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog open={confirm} onConfirm={onConfirm} onCancel={toggleConfirm} title={`Archive Process`} text={`Are you sure you want to archive this process?`}/>
    </Card>
  )
}

export default StepRestartOnboarding;
