import axios from "axios";
import {transformResponse} from "./helpers";

export const getEmployeesService = ({data, config, query, params} = {}) => {
  let expiry = '';
  if (params.visa_expiry) expiry = '-visa-expiry';
  if (params.labor_card_expiry) expiry = '-labour-card-expiry';
  if (params.residence_permit_expiry) expiry = '-residence-permit-expiry';
  return axios({method: 'get', url: `/candidates${expiry}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getEmployeeByIdService = ({data, config, params} = {}) => {
  const employeeId = (params || {}).employeeId;
  return axios({method: 'get', url: `/candidates/${employeeId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const createEmployeeService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const deleteEmployeeByIdService = ({data, config, params} = {}) => {
  const employeeId = (params || {}).employeeId;
  return axios({method: 'delete', url: `/candidates/${employeeId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const assignUserToEmployeeService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidate/user`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateEmployeeService = ({data, config, params} = {}) => {
  const employeeId = (params || {}).employeeId;
  return axios({method: 'put', url: `/candidates/${employeeId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateEmployeeImageService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates/profile-image`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const toggleEmployeeLockService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates/lock-unlock`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const permanentRejectEmployeeService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates/permanent-rejected`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const cycleEmployeeService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates/cycle`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const addDependanceService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/dependents-info`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateDependanceService = ({data, config, params} = {}) => {
  const dependantId = (params || {}).dependantId;
  return axios({method: 'put', url: `/dependents-info/${dependantId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const deleteDependanceService = ({data, config, params} = {}) => {
  const dependantId = (params || {}).dependantId;
  return axios({method: 'delete', url: `/dependents-info/${dependantId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateDependanceImageService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/dependents-info/profile-image`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const exportEmployeesService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/candidates-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesVisaExpiryService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/candidates-visa-expiry-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesLabourCardExpiryService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/candidates-labour-card-expiry-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesResidencePermitExpiryService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/candidates-residence-permit-expiry-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesByUserService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/dashboard/user-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesByRoleService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/dashboard/role-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesByNotVisaAdminService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/dashboard/not-visa-admin-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportWaitingForCandidatesActionsService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/dashboard/waiting-candidate-action-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportEmployeesByImportedService = ({data, config} = {}) => {
  return axios({method: 'get', responseType: 'blob', url: `/dashboard/import-candidates-export`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const downloadFileService = ({data, config, params} = {}) => {
  const slug = (params || {}).slug;
  return axios({method: 'get', responseType: 'blob', url: `/download/${slug}`, data, ...(config || {})}).then(response => {
    return response
  })
}

export const exportCandidatesService = ({data, config} = {}) => {
  return axios({ method: 'get', responseType: 'blob', url: `/export-by-company`, data, ...(config || {}), timeout: 300000 }).then(response => {
    return response
  })
}

export const importEmployeesService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidates/import-csv`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const candidateUploadInfoService = ({data, config, params} = {}) => {
  const candidateId = (params || {}).candidate_id;
  const token = (params || {}).token;
  return axios({method: 'get', url: `/candidate-form/${candidateId}/${token}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const candidateUploadService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/candidate-form-submit`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}
