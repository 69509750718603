import React, {Fragment, useEffect, useRef} from 'react'
import { Grid, Card, CardHeader, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EditOutlined, VisibilityOutlined, ArrowDropDown, DeleteOutlined} from "@material-ui/icons";
import ExpandableTableRow from 'components/ExpandableTableRow';
import AppTextWithHint from 'components/AppTextWithHint';
import AppSeparator from 'components/AppSeparator';
import AppButton from "../../../components/AppButton";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppConfirmDialog from "../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../Hooks/useDialog";
import AppIconWithLoading from "../../../components/Icons/AppIconWithLoading";


const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '& .MuiTypography-root': {
      fontSize: '1.2rem'
    },
    '& .MuiCardHeader-action': {
      marginTop: '-2px',
      marginBottom: '-2px',
      marginRight: 0,
    }
  },
  tableCell: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  tableHeadTitles: {
    color: "rgba(0, 0, 0, 0.56)"
  },
  companyName: {
    color: "#1A97AD"
  },
  icon: {
    cursor: 'pointer',
    '&:nth-of-type(1), &:nth-of-type(2)': {
      marginRight: '20px',
    }
  },
  mobileControls: {
    paddingTop: '8px'
  },
}));

function CompaniesTable({getAsset, addAsset, t, getCompanies, isLoading, goToEditCompany, goToNewCompany, goToViewCompany, canCreate, canUpdate, canRead, deleteCompany, getCompanyById}) {
  const classes = useStyles();
  const companies = getAsset('companies', []);
  const page = getAsset('page', 0)
  const rowsPerPage = getAsset('rowsPerPage', 25)
  const readPermission = canRead();
  const updatePermission = canUpdate();
  const [confirm, toggleConfirm] = useDialog();
  const deleteParam = useRef();

  function handleDeleteCompany(company) {
    deleteParam.current = company;
    toggleConfirm();
  }

  function onConfirmDelete() {
    deleteCompany(deleteParam.current.id);
    toggleConfirm();
  }

  useEffect(() => {
    getCompanies({query: {page: page + 1, limit: rowsPerPage}})
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  function onChangeRowsPerPage(e) {
    addAsset('page', 0);
    addAsset('rowsPerPage', parseInt(e.target.value, 10));
  }

  function handleEditCompany(id) {
    // const res = await getCompanyById(id);
    goToEditCompany(id);
    // return res;
  }

  function handleViewCompany(id) {
    // const res = await getCompanyById(id);
    goToViewCompany(id);
    // return res;
  }

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  return (
    <Card>
      <CardHeader className={classes.header} style={headerStyles} title={t('companies:companies')} action={
        canCreate() && <AppButton onClick={() => goToNewCompany()}> New Company </AppButton>
      } />
      {isLoading && <LinearProgress />}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadTitles}>Company</TableCell>
              <Hidden smDown>
                <TableCell className={classes.tableHeadTitles}>Recruiter</TableCell>
                <TableCell className={classes.tableHeadTitles}>Phone</TableCell>
                <TableCell className={classes.tableHeadTitles}>Email</TableCell>
              </Hidden>
              {(updatePermission || readPermission) && <TableCell> </TableCell>}
              {
                !(updatePermission || readPermission) &&
                <Hidden mdUp>
                  <TableCell> </TableCell>
                </Hidden>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <Fragment key={company.id}>
                <TableRow hover key={company.id} onClick={() => addAsset(`${company.id}Expanded`, !getAsset(`${company.id}Expanded`) )}>
                  <TableCell className={clsx([classes.tableCell, classes.companyName])}>{company.title}</TableCell>

                  {/* Desktop view */}
                  <Hidden smDown>
                    <TableCell className={classes.tableCell}>
                      {((company.users || [])[0] || {}).first_name || ''} {((company.users || [])[0] || {}).last_name || '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{company.phone}</TableCell>
                    <TableCell style={{wordBreak: 'break-word', whiteSpace: 'normal'}}>{company.email}</TableCell>
                    {
                      (readPermission || updatePermission) &&
                      <TableCell align={"right"} style={{minWidth: '150px'}}>
                        {updatePermission && <DeleteOutlined onClick={() => handleDeleteCompany(company)} className={classes.icon} color={"secondary"}/>}
                        {updatePermission && <AppIconWithLoading marginLeft={'4px'} onClick={() => handleEditCompany(company.id)}> <EditOutlined className={classes.icon} color={"secondary"} /> </AppIconWithLoading> }
                        {readPermission && <AppIconWithLoading onClick={() => handleViewCompany(company.id)}> <VisibilityOutlined  className={classes.icon} color={"secondary"} /> </AppIconWithLoading> }
                      </TableCell>
                    }
                  </Hidden>

                  {/* Mobile View Controls */}
                  <Hidden mdUp>
                    <TableCell>
                      <ArrowDropDown color={"secondary"} />
                    </TableCell>
                  </Hidden>

                </TableRow>

                {/* Mobile View */}
                <ExpandableTableRow hiddenProps={{ mdUp: true }} setKey={`${company.company}`} expanded={getAsset(`${company.id}Expanded`)} colSpan={3}>
                  <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item xs={6} align={'start'}>
                      <AppTextWithHint align={'start'} grayText text={'Recruiter'} hint={ `${((company.users || [])[0] || {}).first_name || ''} ${((company.users || [])[0] || {}).last_name || '-'}` }/>
                      <AppSeparator />
                    </Grid>
                    <Grid className={classes.mobileControls} item xs={6} align={'end'}>
                      {updatePermission && <DeleteOutlined onClick={() => handleDeleteCompany(company)} className={classes.icon} color={"secondary"}/>}
                      {updatePermission && <EditOutlined onClick={() => goToEditCompany(company.id)} className={classes.icon} color={"secondary"}/>}
                      {readPermission && <VisibilityOutlined onClick={() => goToViewCompany(company.id)} className={classes.icon} color={"secondary"}/>}
                      <AppSeparator />
                    </Grid>
                    <Grid item xs={6} align={'start'}>
                      <AppTextWithHint align={'start'} grayText text={'Email'} hint={<a style={{wordBreak: 'break-all'}} href={`mailto:${company.email}`}>{company.email}</a>} />
                      <AppSeparator />
                    </Grid>
                    <Grid item xs={6} align={'end'}>
                      <AppTextWithHint align={'end'} grayText text={'Phone'} hint={<a href={`tel:${company.phone}`}>{company.phone}</a>} />
                      <AppSeparator />
                    </Grid>
                  </Grid>
                </ExpandableTableRow>

              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 10, 5]}
        component="div"
        count={getAsset('totalCompaniesCount', 10)}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => addAsset('page', newPage)}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
      <AppConfirmDialog open={confirm} title={'Delete company'} text={`Are you sure you want to delete ${(deleteParam.current || {}).title} company?`} onCancel={toggleConfirm} onConfirm={onConfirmDelete}/>
    </Card>
  )
}

export default CompaniesTable
