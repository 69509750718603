import React from "react";
import ForgotPasswordView from './ForgotPasswordView';
import useForm from "Hooks/useForm";
import useAPI from "Hooks/useAPI";
import { forgotPasswordService } from "services/loginService";


function ForgotPasswordContainer() {
  const { onChange, getField, fields, addError, getLocalError, clearLocalError, addAsset, getAsset } = useForm();
  const { sendRequest, isLoading, getApiError, clearApiError } = useAPI();

  function getError(field, reserved) {
    return getApiError(field) || getLocalError(field) || reserved;
  }

  function clearError(field) {
    clearLocalError(field);
    clearApiError(field);
  }

  const confirmForgotPassword = () => (sendRequest(forgotPasswordService, { payload: fields, loading: true, }).then(({ data }) => {
    addAsset('infoMessage', 'Please, check your email for further instructions.')
  }));

  return (
    <ForgotPasswordView
      getError={getError}
      addError={addError}
      clearError={clearError}
      isLoading={isLoading}
      onChange={onChange}
      getField={getField}
      confirmForgotPassword={confirmForgotPassword}
      addAsset={addAsset}
      getAsset={getAsset}
    />
  )
}

export default ForgotPasswordContainer;
