import React, {Fragment, useRef, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardHeader, Grid, Button } from '@material-ui/core';
import {DeleteOutlined, EditOutlined, PostAdd, VisibilityOutlined} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppEnterDocumentName from "../../../../components/Dialogs/AppEnterDocumentName";
import useUpload from "../../../../Hooks/useUpload";
import {useEffectOnce} from "react-use";
import AppConfirmDialog from "../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../Hooks/useDialog";
import {EmployeeContext} from "../../EmployeesContainer";
import {formatDate} from "../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
  },
  addButton: {
    marginTop: '-4px',
    marginBottom: '20px'
  },
  inputDisplay: {
    display: 'none',
  },
  icon: {
    cursor: 'pointer',
    marginRight: '15px',
    marginTop: '2px',
    marginBottom: '2px',
  },
}));

function EmployeeDocuments() {
  const {t, getField, getAsset, addAsset, uploadFile, approveDocumentApi, onChange, addToArrayInAssets, addError, clearError, deleteItemFromArrayByIdInAssets, isSuperAdmin, isVisaAdmin, isDataEntrist, getError, user, deleteDocument} = useContext(EmployeeContext);
  const classes = useStyles();
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(item => item.approval === 1);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);
  const { handleUpload } = useUpload(onChange, addToArrayInAssets);
  const documents = getAsset('documents', []);
  const docTypes = getAsset('documentTypes', []);
  const uploadedTypes = uploadedDocuments.map(doc => doc.upload_type);
  const [confirm, toggleConfirm] = useDialog();
  const deleteParam = useRef();

  useEffectOnce(() => {
    addAsset('documents', []);
  })

  const doUploadToApi = () => {
    setIsLoading(true);
    documents.forEach(file => {
      uploadFile({ file: file, name: getField(file.id), upload_type: getField(`${file.id}-doc-type`), noStep: true, index: 0 }).then((data) => {
       approveDocumentApi({ documentId: data.id });
       setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    });
  }

  async function doUpdateOfUploadType({ document, upload_type }) {
   try {
     setIsLoading(true);
     await approveDocumentApi({ documentId: document.id, payload: {upload_type} });
     setIsLoading(false);
   } catch (e) {
     setIsLoading(false);
   }
  }

  function handleClose() {
    documents.forEach(file => {
      deleteItemFromArrayByIdInAssets('documents', file.id);
    });
  }

  function upload(e) {
     const files = handleUpload(e);
    modalRef.current.open({name: files[0].id});
  }

  function handleUpdateDocument(document) {
    modalRef.current.open({name: document.id, document, disableName: true, setName: true, update: true});
  }

  function getDocType(value) {
    const type = docTypes.find(t => t.value === value);
    return (type || {}).text || '-';
  }

  function isDocTypeUnique(value) {
    return !(uploadedTypes.filter(type => type === value).length > 1 && value !== 'others')
  }

  function canDelete(document) {
    if (isSuperAdmin() || isVisaAdmin()) return true;
    return user.id === document.user_id
  }

  function handleDeleteDocument(document) {
    deleteParam.current = document;
    toggleConfirm();
  }

  function onConfirmDelete() {
    deleteDocument({documentId: deleteParam.current?.id});
    toggleConfirm();
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={upload}/>
        <label htmlFor="upload-file-button">
          <Button className={classes.addButton} variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  return (
    <Fragment>
      <Grid container justify={"flex-end"}>
        { (isVisaAdmin() || isDataEntrist() || isSuperAdmin()) &&
          <UploadFile/>
        }
        {/*<AppButton className={classes.addButton} >{t('employees:add_documents')}</AppButton>*/}
      </Grid>
      <Card>
        <CardHeader style={headerStyles} className={classes.header} title={`${t('navigation:documents')} - ${getField('first_name')} ${getField('last_name')} `} />
        {isLoading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Approval Date</TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedDocuments.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>{getDocType(document.upload_type)}</TableCell>
                  <TableCell>{formatDate(document.updated_at)}</TableCell>
                  <TableCell style={{paddingRight: 0}} align="right">
                    <Grid style={{marginBottom: '-5px'}} container align={'flex-end'} justify={'flex-end'}>
                      { canDelete(document) &&
                        <DeleteOutlined onClick={() => handleDeleteDocument(document)} className={classes.icon} color={"secondary"}/>
                      }

                      { (!document.upload_type || !isDocTypeUnique(document.upload_type)) &&
                        <EditOutlined onClick={() => handleUpdateDocument(document)} className={classes.icon} color={"secondary"}/>
                      }

                        <a href={`${process.env.REACT_APP_API_URL}${document.file}`} download target="_blank" rel="noopener noreferrer">
                          <VisibilityOutlined className={classes.icon} color={"secondary"}/>
                        </a>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <AppEnterDocumentName
        ref={modalRef}
        uploadedTypes={uploadedTypes}
        getField={getField}
        addError={addError}
        onConfirm={doUploadToApi}
        onUpdate={doUpdateOfUploadType}
        onClose={handleClose}
        onChange={onChange}
        clearError={clearError}
        getAsset={getAsset}
        getError={getError}
      />

      <AppConfirmDialog
        open={confirm}
        title={'Delete Document'}
        text={`Are you sure you want to delete ${(deleteParam.current || {}).name} ?`}
        onCancel={toggleConfirm} onConfirm={onConfirmDelete}/>
    </Fragment>
  );
}

export default EmployeeDocuments;
