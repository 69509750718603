import React, {Fragment, useEffect} from 'react';
import {Grid} from "@material-ui/core";
import AppPickDateField from "../AppPickDateField";
import AppTextField from "../AppTextField";
import AppAutocomplete from "../AppAutocomplete";
import {getFieldAttributes, getSelectFieldValues} from "../../utils/utilFinctions";
import AppTimePickerField from "../AppTimePickerField";
import AppCheckbox from "../AppCheckbox";

function DynamicsRegularField({field, getError, clearError, onChange, addError, getField, name, label, type, noDatesInFuture = false, noDatesInPast = false, price = false, noNegative = false}) {
  const isPrice = getFieldAttributes(field, 'price', false);
  const isDateField = type === 'date';
  const isTextField = type === 'text' && !isPrice;
  const isTextArea = type === 'textarea';
  const isNumberType = type === 'number' || isPrice;
  const isSelect = type === 'select';
  const isTime = type === 'time';
  const isCheckbox = type === 'checkbox'

  useEffect(() => {
    setTimeout(() => {
      if (field.value) onChange(null, name, field.value);
    }, 1000)
  }, [])

  let selectItems = getSelectFieldValues(field, [], getField);


  return (
    <Fragment>
      <Grid item alihn={"center"} xs={12} lg={3}>
        { isDateField &&
          <AppPickDateField noDatesInFuture={noDatesInFuture} noDatesInPast={noDatesInPast} getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={name} label={label} />
        }

        { isTextField &&
          <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={name} label={label} variant="filled" fullWidth />
        }

        { isTextArea &&
          <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={name} label={label} variant="filled" fullWidth multiline rows={3} />
        }

        { isNumberType &&
          <AppTextField price={price} noNegative={noNegative} type={'number'} getError={getError} addError={addError} clearError={clearError} onChange={onChange} getField={getField} name={name} label={label} variant="filled" fullWidth />
        }

        { isSelect &&
          <AppAutocomplete label={label} getOptionLabel={(option) => option.text } itemValueUnit={'value'} onChange={onChange} getField={getField} items={selectItems} getError={getError} name={name} clearError={clearError}/>
        }

        { isTime &&
          <AppTimePickerField ampm={false} getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={name} label={label} />
        }

        { isCheckbox &&
          <AppCheckbox getError={getError} clearError={clearError} getField={getField} onChange={onChange} name={name} label={label} />
        }
      </Grid>
    </Fragment>
  )
}

export default DynamicsRegularField;
