import React, { Fragment } from "react";
import {EmployeesPageRoutes} from "../../navigation";

function EmployeesView() {

  return (
    <Fragment>
      <EmployeesPageRoutes/>
    </Fragment>
  )
}

export default EmployeesView;
