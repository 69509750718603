import React from "react";
import { Card, CardHeader, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppStepper from "../components/AppStepper";
import AppButton from "components/AppButton";
import AppTextField from "components/AppTextField";
import AppPickDateField from 'components/AppPickDateField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
}));

const steps = [
  {
    label: 'Documents Review - Job MOL Offer Resubmission',
    hint: 'Previous Step',
    completed: true,
  },
  {
    label: 'Job MOL Offer Resubmission',
    hint: 'Current Step',
    current: true,
  }
]

// STEP 6c
function StepMolJobOfferResubmission({t, getAsset, addError, getError, clearError, clearFields, getField, onChange, nextStep }) {
  const classes = useStyles();
  const stepFields = getAsset('stepFields');
  const [confirm, toggleConfirm] = useDialog();

  function onConfirm() {
    toggleConfirm();
    const fields = stepFields.map(step => {
      return {field: step.name, value: getField(step.name)}
    });
    const messages = ['Job mol offer resubmitted'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    clearFields(['job_mol_offer_submission_date', 'job_mol_offer_reference_number'])

  }

  function validate() {
    let error = false;
    if (!getField('job_mol_offer_submission_date')) {
      addError('job_mol_offer_submission_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_submission') }));
      error = true
    }

    if (!getField('job_mol_offer_comments')) {
      addError('job_mol_offer_comments', 'Required Field', t('required_field', {name: t('tracker:comments') }));
      error = true;
    }
    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8}>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={12} lg={4}>
              <AppPickDateField noDatesInFuture={true} getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="job_mol_offer_submission_date" label={t('tracker:date_of_the_submission')} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <AppTextField getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="job_mol_offer_comments" label={t('tracker:comments')} multiline rows={6} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepMolJobOfferResubmission
