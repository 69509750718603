import React from 'react';
import {Card, Grid, CardHeader} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    marginTop: 0,
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  text: {
    fontSize: '1rem',
    fontWeight: '600',
  }
}));

function StepDefault({t}) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={5} alignItems={"center"} justify={"center"}>
        <span className={classes.text}>
          Your Step Has Ended
        </span>
      </Grid>
    </Card>
  )
}

export default StepDefault;
