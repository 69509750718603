import React, {useEffect} from "react";
import DashboardView from "./DashboardView";
import useForm from "../../Hooks/useForm";
import {useTranslation} from "react-i18next";
import useAPI from "../../Hooks/useAPI";
import {
  getDashboardCandidateActionService,
  getDashboardDataByRoleService,
  getDashboardDataService, getDashboardImportCandidates,
  getDashboardNotVisaAdminService
} from "../../services/dashboardService";
import useUser from "../../Hooks/useUser";
import useRedirect from "../../Hooks/useRedirect";
import useHelpers from "../../Hooks/useHelpers";
import {
  exportEmployeesByNotVisaAdminService, exportEmployeesByRoleService,
  exportEmployeesByUserService, exportWaitingForCandidatesActionsService,
  getEmployeeByIdService,
  getEmployeesService
} from "../../services/employeesService";
import {getCompaniesService} from "../../services/companiesService";
import {getAllStepsService} from "../../services/stepService";

export const DashboardContext = React.createContext(null);

const DashboardContainer = function () {
  // region Main
  const { getAsset, addAsset } = useForm();
  const { t } = useTranslation();
  const { sendRequest, isLoading, cancelAllRequests } = useAPI();
  const { getRoleSlug, getAssignedTypist, getAssignedRecruiter, getAssignedPro, isVisaAdmin, isSuperAdmin, canUpdate, canRead, isTypist, isTypistSupervisor, isPro, isMedical, isDataEntrist, getRestrictionsFromEnv } = useUser();
  const { goToViewEmployee, goToEditEmployee } = useRedirect();
  const { makeAsync } = useHelpers();

  useEffect( () => {
    getCompanies()
    getAllSteps()
    return () => {
      cancelAllRequests();
    }
  }, [])

  // endregion

  // region Get Requests
  const getTablesData = async ({query}) => {
    const {data, pagination} = await sendRequest(getDashboardDataService, {query, loading: true});
    addAsset('WaitingForAction', data);
    addAsset('totalMainTable', pagination.total);
    return data;
  }

  const getTablesByRole = async ({query}) => {
    const {data, pagination} = await sendRequest(getDashboardDataByRoleService, { query, loading: true });
    addAsset(`waitingFor${query.role}sAction`, data);
    addAsset(`total${query.role}Table`, pagination.total);
    return data;
  }

  const getTablesNotVisaAdmin = async ({query}) => {
    const {data, pagination} = await sendRequest(getDashboardNotVisaAdminService, { query, loading: true });
    addAsset(`waitingForDocumentsVerification`, Object.values(data || {}));
    addAsset(`totalDocumentsVerificationTable`, pagination.total);
    return Object.values(data || {});
  }

  const getTablesCandidatesActions = async ({query}) => {
    const {data, pagination} = await sendRequest(getDashboardCandidateActionService, { query, loading: true });
    addAsset(`waitingForCandidatesActions`, Object.values(data || {}));
    addAsset(`totalDocumentsCandidatesActionsTable`, pagination.total);
    return Object.values(data || {});
  }

  const getImportCandidates = async ({query}) => {
    const {data, pagination} = await sendRequest(getDashboardImportCandidates, { query, loading: true });
    addAsset(`employeesForImportTable`, Object.values(data || {}));
    addAsset(`totalImportCandidatesTable`, pagination.total);
    return Object.values(data || {});
  }

  const getEmployees = async ({query}) => {
    const {data, pagination} = await sendRequest(getEmployeesService, { query: {...query, status: 2}, loading: true });
    addAsset('employees', data);
    addAsset('totalEmployeesCount', (pagination).total);
  }

  const getEmployeeById = async (employeeId, noCache = false, {loading} = {}) => {
    const {data} = await sendRequest(getEmployeeByIdService, { params: { employeeId }, noCache, loading });
    return data;
  }

  const getCompanies = async () => {
    const {data} = await sendRequest(getCompaniesService, {query: {limit: 9999}});
    const restrictions = getRestrictionsFromEnv();
    if (restrictions && restrictions.allowedCompanies) {
      addAsset('companies', data.filter(company => restrictions.allowedCompanies.includes(company.id)));
      return;
    }
    addAsset('companies', data)
  }

  const getAllSteps = async () => {
    const {data} = await sendRequest(getAllStepsService);
    addAsset('steps', data);
  }

  //endregion

  // region Export Requests
  const exportEmployeesByUser = (fileName, { query }) => sendRequest(exportEmployeesByUserService, { download: true, fileName, query });
  const exportEmployeesByNotVisaAdmin = (fileName, { query }) => sendRequest(exportEmployeesByNotVisaAdminService, { download: true, fileName, query });
  const exportEmployeesByRole = (fileName, { query }) => sendRequest(exportEmployeesByRoleService, { download: true, fileName, query });
  const exportWaitingForCandidatesActions = (fileName, { query }) => sendRequest(exportWaitingForCandidatesActionsService, { download: true, fileName, query });
  // endregion

  // region Context API

  const apiRequests = {
    isLoading,
    getTablesData,
    getTablesByRole,
    getEmployeeById,
    getEmployees,
    exportEmployeesByUser,
    exportEmployeesByNotVisaAdmin,
    exportEmployeesByRole,
    exportWaitingForCandidatesActions,
    getTablesNotVisaAdmin,
    getImportCandidates,
    getTablesCandidatesActions,
  }

  const contextData = {
    t,
    getAsset,
    addAsset,
    getRoleSlug,
    isVisaAdmin,
    isSuperAdmin,
    isTypist,
    isTypistSupervisor,
    isPro,
    isMedical,
    isDataEntrist,
    getAssignedTypist,
    getAssignedRecruiter,
    getAssignedPro,
    goToViewEmployee,
    goToEditEmployee,
    canUpdate,
    canRead,
    makeAsync,
    companies: getAsset('companies', []),
    steps: getAsset('steps', []),
    ...apiRequests
  }

  // endregion

  return (
    <DashboardContext.Provider value={contextData}>
      <DashboardView context={DashboardContext}/>
    </DashboardContext.Provider>
  )
}

export default DashboardContainer;
