import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

function AppConfirmDialog({open = false, text, title, onCancel = () => {}, onConfirm = () => {}}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const displayTitle = title || t('tracker:dialog_title');
  const displayText = text || 'Are You Sure?'
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={fullScreen} aria-labelledby="dialog-title" disableBackdropClick disableEscapeKeyDown PaperProps={{style: {minWidth: '400px'}}} >
      <DialogTitle className={classes.dialogTitle}>{displayTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {displayText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('tracker:dialog_button_no')}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {t('tracker:dialog_button_yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppConfirmDialog;
