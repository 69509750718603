import React from "react";
import CandidateFormContainer from "./CandidateFormContainer";

function CandidateFormPage () {
  return (
    <CandidateFormContainer/>
  )
}

export default CandidateFormPage;
