import React from 'react'
import { Card, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textFields: {
    padding: "24px"
  },
  makeBolder: {
    fontWeight: '600'
  },
  subtitle: {
    margin: "10px 0px",
    fontWeight: '600'
  },
  span: {
    fontWeight: 'normal'
  }
}));

function SingleCompany({getField, t}) {
  const classes = useStyles();

  return (
    <Card className={classes.textFields}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.makeBolder}>
            {getField('title')}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            Recruiter: <span className={classes.span}>{((getField('users') || [])[0] || {}).first_name} {((getField('users') || [])[0] || {}).last_name}</span>
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:email`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('email')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:phone`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('phone')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:website`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('website')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:address`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('address')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:address2`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('address2')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:city`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('city')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:postal_code`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('postal_code')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:state`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('state')}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2} className={classes.makeBolder}>
          {t(`companies:description`)}
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          {getField('description')}
        </Grid>
      </Grid>
    </Card>
  )
}

export default SingleCompany
