import React, { Fragment } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core"
import useStyles from "./styles";
import logo from 'assets/img/WFCH.png'
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppTextField from 'components/AppTextField';


export default function ForgotPasswordView({ confirmForgotPassword, isLoading, onChange, getField, addError, getError, clearError, getAsset }) {
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const buttonSize = isXs ? 'large' : 'medium';
  const { t } = useTranslation();
  const infoMessage = getAsset('infoMessage');

  function onForgotPassword() {
    let error = false;
    if (!getField('email')) {
      addError('email', 'Email is required');
      error = true;
    }
    if (!error) confirmForgotPassword();
  }


  return (
    <Fragment>
      <Card elevation={1} className={classes.root}>
        <CardHeader title={t('forgot_password')} />
        <CardContent>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid className={classes.logo} item xs={12} align="center">
              <img src={logo} alt="logo" style={{width: '50%'}}/>
            </Grid>
            {infoMessage &&
              <Grid item xs={12} sm={9}>
                <Typography
                  variant="subtitle1"
                >
                  {infoMessage}
                </Typography>
              </Grid>
            }
            {!infoMessage &&
            <React.Fragment>
              <Grid item xs={12} sm={9}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name={"email"} label={t('email')} />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Grid container>
                  <Grid item xs={12} sm={3} className={classes.resetButton}>
                    <AppButton isLoading={isLoading} size={buttonSize} fullWidth variant="contained" color="secondary" onClick={onForgotPassword}>{t('reset')}</AppButton>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
            }
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
