import { Plugins, Capacitor } from '@capacitor/core'
import useAPI from "./useAPI";
import {pushTokenStepService} from "../services/userService";

const { PushNotifications } = Plugins;

function usePushNotifications() {
  const { sendRequest } = useAPI();

  const saveToken = (token) => sendRequest(pushTokenStepService,
    {payload: { notification_token: token}})

  function initPush() {
    if (Capacitor.platform !== 'web') {
      registerPush();
    } else {

    }
  }

  function registerPush() {

    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        PushNotifications.register();
      } else {
        // No permission for push notifications
      }
    })

    PushNotifications.addListener('registration', (token) => {
      saveToken(token.value);
      console.log('My Token: ', JSON.stringify(token));
    })

    PushNotifications.addListener('registrationError', (error) => {
      console.log('My Token: ', JSON.stringify(error));
    })

    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      console.log('Push receieved', JSON.stringify(notification));
    })

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      const data = notification.notification.data;
      console.log('Action preformed', JSON.stringify(notification.notification));
      // Add action when user clicks on push notification
    })

  }

  return { initPush };
}

export default usePushNotifications;

