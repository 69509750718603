import React, {Fragment, useState, useRef, useContext} from 'react';
import {TableCell, TableBody, Grid, Hidden, TablePagination, Tabs, Tab, useTheme, useMediaQuery} from "@material-ui/core";
import {Card, CardHeader, Table, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AppTextWithHint from "components/AppTextWithHint";
import {ArrowDropDown, DeleteOutlined, EditOutlined, VisibilityOutlined} from "@material-ui/icons";
import ExpandableTableRow from "components/ExpandableTableRow";
import AppSeparator from "components/AppSeparator";
import User from "./components/User";
import EmployeesFilter from "./components/EmployeesFilter";
import {useEffectOnce} from "react-use";
import LinearProgress from '@material-ui/core/LinearProgress';
import AppIconWithLoading from "../../../../components/Icons/AppIconWithLoading";
import useDialog from "../../../../Hooks/useDialog";
import AppAlertDialog from "../../../../components/Dialogs/AppAlertDialog";
import {format} from "date-fns";
import AppConfirmDialog from "../../../../components/Dialogs/AppConfirmDialog";
import AppButton from "../../../../components/AppButton";
import clsx from "clsx";
import {EmployeeContext} from "../../EmployeesContainer";
import {formatDate} from "../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '& .MuiCardHeader-action': {
      marginTop: '-2px',
      marginBottom: '-2px',
      marginRight: 0,
    }
  },
  buttonYellow: {
    backgroundColor: '#ED9936',
    '&:hover': {
      backgroundColor: '#9e5d13 !important'
    }
  },
  buttonRed: {
    backgroundColor: "#df3c3a",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#9f3e3d !important'
    }
  },
  buttonMint: {
    backgroundColor: "#33bbc3",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#2bacb4 !important'
    }
  },
  tableCell: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  icon: {
    cursor: 'pointer',
    '&:first-of-type': {
      marginRight: '20px',
    }
  },
  mobileControls: {
    paddingTop: '8px',
  },
  tableTabs: {
    backgroundColor: '#ffffff',
    color: '#757575',
    '& .MuiTabs-indicator': {
      display: 'none !important',
    },
    '& .MuiButtonBase-root': {
      fontWeight: 600,
    }
  },
  tableTabs_all: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  },
  tableTabs_visaExpiration: {
    backgroundColor: '#BD1C1A',
    color: '#ffffff'
  },
  tableTabs_labourCardExpiration: {
    backgroundColor: '#da6d3f',
    color: '#ffffff'
  },
  tableTabs_residencePermitExpiry: {
    backgroundColor: '#4dabb0',
    color: '#ffffff'
  },
  loading_orange: {
    backgroundColor: '#DA6D3F66',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#DA6D3F',
    }
  },
  loading_red: {
    backgroundColor: '#BD1C1A66',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#BD1C1A',
    }
  },
  loading_mint: {
    backgroundColor: '#4dabb066',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#4dabb0',
    }
  }

}))

function EmployeesTableView() {
  const {getEmployees, getCompanies, getAsset, addAsset, daysRemaining, isLoading, t, goToEditEmployee, goToViewEmployee, canUpdate, canRead, isSuperAdmin, getAllSteps, isTypist, isPro, isTypistSupervisor, isVisaAdmin, isDataEntrist, isMedical, getUsersByRoles, isRecruiter, deleteEmployee, user, exportEmployees, exportByCompany, exportEmployeesVisaExp, exportEmployeesLabourCardExp, exportEmployeesResidencePermitExp, getAllProcesses} = useContext(EmployeeContext);
  const classes = useStyles();
  const employees = getAsset('employees', []);
  const page = getAsset('page', 0)
  const rowsPerPage = getAsset('rowsPerPage', 100);
  const isEmployeesFilterRunning = getAsset('isEmployeesFilterRunning');
  const readPermission = canRead();
  const updatePermission = canUpdate() && !isTypist() && !isPro() && !isMedical() && !isRecruiter();
  const [alert, toggleAlert] = useDialog();
  const [confirm, toggleConfirm] = useDialog();
  const deleteParam = useRef();
  const employeeTableNumber = getAsset('employeeTableNumber', 0);
  const [loadingExport, setLoadingExport] = useState(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery('@media (min-width:1600px)');
  const isMediumScreen = useMediaQuery('@media (min-width:1415px)');

  useEffectOnce(() => {
    if (isSuperAdmin() || isVisaAdmin() || isDataEntrist() || isTypistSupervisor() || isTypist() || isPro() || isMedical()) {
      getCompanies();
    } else {
      setTimeout(() => {
        addAsset('companies', user['company'] || []);
      }, 100)
    }
    getAllSteps();
    getAllProcesses();
    getUsersByRoles(['data_entrist', 'typist', 'typist_supervisor', 'pro', 'medical_insurance', 'visa_admin']);
  });

  function runSearch(newPage = page, newRowsPerPage = rowsPerPage) {
    const params = {
      visa_expiry: employeeTableNumber === 1,
      labor_card_expiry: employeeTableNumber === 2,
      residence_permit_expiry: employeeTableNumber === 3,
    };
    getEmployees({query: { page: newPage + 1, limit: newRowsPerPage, ...(isEmployeesFilterRunning ? getAsset('query') : {}) }, params});
  }

  function onChangeRowsPerPage(e) {
    const newRowsPerPage = parseInt(e.target.value, 10);
    addAsset('page', 0);
    addAsset('rowsPerPage',  newRowsPerPage);
    runSearch(0, newRowsPerPage);
  }

  function onPageChange(e, newPage) {
    addAsset('page', newPage);
    runSearch(newPage);
  }

  function runFilter() {
    addAsset('page', 0);
    runSearch(0);
  }

  const getColorByDate = (date) => {
    const days = daysRemaining(date);
    switch (true) {
      case (days < 90 && days > 30):
        return '#FFA000'
      case (days <= 30):
        return '#BD1C1A'
      default:
        return '#222222'
    }
  }

  async function handleGoToEdit(id) {
      goToEditEmployee(id);
  }

  function handleGoToView(id) {
    goToViewEmployee(id);
  }

  function handleDeleteEmployee(company) {
    deleteParam.current = company;
    toggleConfirm();
  }

  function onConfirmDelete() {
    deleteEmployee(deleteParam.current.id);
    toggleConfirm();
  }

  function canExport() {
    return (isSuperAdmin() || isVisaAdmin());
  }

  function handleExportEmployees() {
    if (employeeTableNumber === 0) return exportEmployees;
    if (employeeTableNumber === 1) return exportEmployeesVisaExp;
    if (employeeTableNumber === 2) return exportEmployeesLabourCardExp;
    if (employeeTableNumber === 3) return exportEmployeesResidencePermitExp;
    return () => {}
  }

  function onExportEmployees() {
    setLoadingExport(true);
    handleExportEmployees()(`Employees-${format(new Date(), 'dd-MM-yyyy')}.csv`, {limit:9999999, page:1,  ...(isEmployeesFilterRunning ? getAsset('query') : {}) }).then(() => {
      setLoadingExport(false)
    }).catch(() => {
      setLoadingExport(false);
    })
  }

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  const handleChangeTable = (event, newValue) => {
    addAsset('employeeTableNumber', newValue);
  };

   function expiredColWidth() {
     if (isLargeScreen) return 3;
     if (isMediumScreen) return 6
     return 12
   }

  const cardHeaderClass = clsx({
    [classes.header]: true,
    [classes.tableTabs_visaExpiration]: employeeTableNumber === 1,
    [classes.tableTabs_labourCardExpiration]: employeeTableNumber === 2,
    [classes.tableTabs_residencePermitExpiry]: employeeTableNumber === 3
  })

  const loadingClasses = clsx({
    [classes.loading_red]: employeeTableNumber === 1,
    [classes.loading_orange]: employeeTableNumber === 2,
    [classes.loading_mint]: employeeTableNumber === 3,
  })

  const buttonClasses = clsx({
    [classes.buttonRed]: employeeTableNumber === 1,
    [classes.buttonYellow]: employeeTableNumber === 2,
    [classes.buttonMint]: employeeTableNumber === 3,
  })

  return (
    <Fragment>
      <EmployeesFilter t={t} runSearch={runFilter} getAsset={getAsset} addAsset={addAsset} employeeTableNumber={employeeTableNumber} exportByCompany={exportByCompany} canExport={canExport}/>

      <Card color={'primary'}>
        <Tabs className={classes.tableTabs} value={employeeTableNumber} onChange={handleChangeTable}>
          <Tab className={clsx(({ [classes.tableTabs_all]: employeeTableNumber === 0}))} label="All"/>
          <Tab className={clsx(({ [classes.tableTabs_visaExpiration]: employeeTableNumber === 1}))} label="Visa Expiry"/>
          <Tab className={clsx(({ [classes.tableTabs_labourCardExpiration]: employeeTableNumber === 2}))} label="Labour card expiry"/>
          <Tab className={clsx(({ [classes.tableTabs_residencePermitExpiry]: employeeTableNumber === 3}))} label="Residence permit expiry"/>
        </Tabs>
        <CardHeader className={cardHeaderClass} style={headerStyles} title={t('employees:employees')} action={
         <Fragment>
           {canExport() && <AppButton className={buttonClasses} isLoading={loadingExport} onClick={onExportEmployees}> Export </AppButton>}
         </Fragment>
        }/>
        {isLoading && <LinearProgress className={loadingClasses} />}
        <TableContainer>
          <Table className={classes.table} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap'}}>{t('employees:sn_#')}</TableCell>
                <TableCell>{t('employees:employee')}</TableCell>
                <Hidden mdDown>
                  <TableCell>{t('employees:status')}</TableCell>
                  <TableCell>{'Process'}</TableCell>
                  <TableCell>{t('employees:company')}</TableCell>
                  <TableCell>{'Business Unit'}</TableCell>
                  <TableCell>{t('dashboard:assigned_date')}</TableCell>
                  <TableCell>{t('employees:document_expiry')}</TableCell>
                </Hidden>
                { (updatePermission || readPermission) &&  <TableCell> </TableCell> }
                {
                  !(updatePermission || readPermission) &&
                  <Hidden lgUp>
                    <TableCell> </TableCell>
                  </Hidden>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <Fragment key={employee.id}>
                  <TableRow hover key={employee.id} onClick={() => addAsset(`${employee.id}Expanded`, !getAsset(`${employee.id}Expanded`) )}>
                    <TableCell className={classes.tableCell}>{employee.id}</TableCell>
                    <TableCell width={isMd ? undefined : '150px'} className={clsx([classes.tableCell])}><User first_name={employee.first_name} last_name={employee.last_name} imageUrl={employee.image} baseUrl={process.env.REACT_APP_API_URL}/></TableCell>
                    <Hidden mdDown>
                      <TableCell className={classes.tableCell}>{(employee.step[0] || {}).title || '-'}</TableCell>
                      <TableCell className={classes.tableCell}>{(employee.process[0] || {}).title || '-'}</TableCell>
                      <TableCell className={classes.tableCell}>{((employee.company || [])[0] || {}).title}</TableCell>
                      <TableCell className={classes.tableCell}>{employee.business_unit || '-'}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {((employee.step[0] || {}).date_diff_days && `${(employee.step[0] || {}).date_diff_days} ${t('dashboard:day_ago')}`) || '-'}
                      </TableCell>
                      <TableCell size={"small"} className={clsx([classes.tableCell, classes.breakWord])}>
                        <Grid container spacing={1}>
                          { employee.passport_expiry &&
                            <Grid item xs={12} lg={expiredColWidth()}  align={"center"}>
                              <AppTextWithHint textWeight={700} textColor={getColorByDate(employee.passport_expiry)} text={'Passport'} hint={formatDate(employee.passport_expiry)}/>
                            </Grid>
                          }
                          { employee.residence_permit_expiry &&
                            <Grid item xs={12} lg={expiredColWidth()} align={"center"}>
                              <AppTextWithHint textWeight={700} textColor={getColorByDate(employee.residence_permit_expiry)} text={'Residence Permit Expire Date'} hint={formatDate(employee.residence_permit_expiry)}/>
                            </Grid>
                          }
                          { employee.labor_card_expiry &&
                            <Grid item xs={12} lg={expiredColWidth()}  align={"center"}>
                              <AppTextWithHint textWeight={700} textColor={getColorByDate(employee.labor_card_expiry)} text={'Labour Card'} hint={formatDate(employee.labor_card_expiry)}/>
                            </Grid>
                          }
                          { employee.insurance_expiry &&
                            <Grid item xs={12} lg={expiredColWidth()}  align={"center"}>
                              <AppTextWithHint textWeight={700} textColor={getColorByDate(employee.insurance_expiry)} text={'Insurance'} hint={formatDate(employee.insurance_expiry)}/>
                            </Grid>
                          }
                        </Grid>
                      </TableCell>
                      {
                        (updatePermission || readPermission) &&
                        <TableCell>
                          {(isSuperAdmin() || isVisaAdmin()) && <DeleteOutlined onClick={() => handleDeleteEmployee(employee)} className={classes.icon} color={"secondary"}/> }
                          {updatePermission && <AppIconWithLoading onClick={() => handleGoToEdit(employee.id)} loading={false}> <EditOutlined  className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                          {readPermission && <AppIconWithLoading onClick={() => handleGoToView(employee.id)}> <VisibilityOutlined className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                        </TableCell>
                      }
                    </Hidden>

                    <Hidden lgUp>
                      <TableCell>
                        <ArrowDropDown color={"secondary"}/>
                      </TableCell>
                    </Hidden>
                  </TableRow>

                  <ExpandableTableRow hiddenProps={{lgUp: true}} setKey={`${employee.id}-1`} expanded={getAsset(`${employee.id}Expanded`)} colSpan={3}>
                    <Grid container alignItems={"center"} justify={"center"}>
                      <Grid item xs={6} align={'start'}>
                        <AppTextWithHint align={'start'} grayText text={'Status:'} hint={(employee.step[0] || {}).title || '-'}/>
                        <AppSeparator/>
                      </Grid>
                      {
                        (updatePermission || readPermission) &&
                        <Grid className={classes.mobileControls} item xs={6} align={'end'}>
                          {(isSuperAdmin() || isVisaAdmin()) && <DeleteOutlined onClick={() => handleDeleteEmployee(employee)} className={classes.icon} color={"secondary"}/> }
                          {updatePermission && <AppIconWithLoading onClick={() => handleGoToEdit(employee.id)} loading={false}> <EditOutlined  className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                          {readPermission && <AppIconWithLoading onClick={() => handleGoToView(employee.id)}> <VisibilityOutlined className={classes.icon} color={"secondary"}/> </AppIconWithLoading> }
                          <AppSeparator/>
                        </Grid>
                      }
                      <Grid item xs={12} align={'start'}>
                        <AppTextWithHint align={'start'} grayText text={'Process:'} hint={(employee.process[0] || {}).title || '-'}/>
                        <AppSeparator/>
                      </Grid>
                      <Grid item xs={12} align={'start'}>
                        <AppTextWithHint align={'start'} text={'Company:'} hint={((employee.company || [])[0] || {}).title}/>
                        <AppSeparator/>
                      </Grid>
                      <Grid item xs={12} align={'start'}>
                        <AppTextWithHint align={'start'} text={'Buisness Unit:'} hint={employee.business_unit || '-'}/>
                        <AppSeparator/>
                      </Grid>
                      <Grid item xs={12}>
                        <AppTextWithHint hintNoneWord={'-'} align={'left'} text={t('dashboard:assigned_date')} hint={(employee.step[0] || {}).date_diff_days && `${(employee.step[0] || {}).date_diff_days} ${ t('dashboard:day_ago')}`} textColor={"rgba(0, 0, 0, 1)"} />
                        <AppSeparator />
                      </Grid>
                      <Grid item xs={12} align={'start'}>
                        {t('employees:document_expiry')}:
                      </Grid>
                      <Grid item xs={12} align={'start'}>
                        <Grid container>
                          { employee.passport_expiry &&
                            <Grid item xs={6} sm={3} align={'start'}>
                                <AppTextWithHint textWeight={700} align={'start'} textColor={getColorByDate(employee.passport_expiry)} text={'Passport'} hint={formatDate(employee.passport_expiry)}/>
                            </Grid>
                          }
                          { employee.visa_expiry &&
                            <Grid item xs={6} sm={3} align={'start'}>
                              <AppTextWithHint textWeight={700} align={'start'} textColor={getColorByDate(employee.visa_expiry)} text={'Residence Permit Expire Date'} hint={formatDate(employee.visa_expiry)}/>
                            </Grid>
                          }
                          { employee.labor_card_expiry &&
                            <Grid item xs={6} sm={3} align={'start'}>
                              <AppTextWithHint textWeight={700} align={'start'} textColor={getColorByDate(employee.labor_card_expiry)} text={'Labor Card'} hint={formatDate(employee.labor_card_expiry)}/>
                            </Grid>
                          }
                          { employee.insurance_expiry &&
                            <Grid item xs={6} sm={3} align={'start'}>
                                <AppTextWithHint textWeight={700} align={'start'} textColor={getColorByDate(employee.insurance_expiry)} text={'Insurance'} hint={formatDate(employee.insurance_expiry)}/>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </ExpandableTableRow>

                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[200, 100, 50]}
          component="div"
          count={getAsset('totalEmployeesCount', 10)}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Card>
      <AppAlertDialog open={alert} onOk={toggleAlert} title={'Candidate is locked'} text={'The candidate has been currently edited by other user.'} />
      <AppConfirmDialog open={confirm} title={'Delete Employee'} text={`Are you sure you want to delete ${(deleteParam.current || {}).first_name} ${(deleteParam.current || {}).last_name}?`} onCancel={toggleConfirm} onConfirm={onConfirmDelete}/>
    </Fragment>
  )
}
export default EmployeesTableView;
