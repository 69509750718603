import React, {useEffect, Fragment, useContext} from 'react'
import { Card, Grid, FormControlLabel, Radio, Typography, RadioGroup, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppTextField from "../../../../../components/AppTextField";
import AppPickDateField from "../../../../../components/AppPickDateField";
import AppCheckbox from "../../../../../components/AppCheckbox";
import {EmployeeContext} from "../../../EmployeesContainer";


const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bolder",
    fontSize: "14px",
    color: "#222222",
    padding: "10px 10px",
    textTransform: "uppercase"
  },
  textFields: {
    padding: "24px"
  },
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  }
}));

function ContactAndPassportInfo() {
  const {t, getField, onChange, getError, clearError} = useContext(EmployeeContext);
  const classes = useStyles();

  useEffect(() => {
    clearError('eid_number');
    clearError('uid_number');
  }, [getField('current_residency')]);

  return (
    <Card elevation={1}>
      <CardHeader className={classes.header} title={t('employees:contact_and_passport_info')} />
      <Grid className={classes.textFields} container spacing={3}>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="phone" label={t('employees:phone')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="email" label={t('employees:email')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="passport_number" label={t('employees:passport_number')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} noDatesInFuture getError={getError} getField={getField} onChange={onChange} name="passport_issue" label={t('employees:passport_issue_date')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="passport_expiry" label={t('employees:passport_expiry_date')} />
        </Grid>
        <Grid style={{paddingTop: '5px'}} item xs={12}>
          <AppCheckbox offValue={0} value={1} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name={'take_passport'} label={t('tracker:take_passport_from_candidate')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="visa_expiry" label={t('employees:visa_expiry_date')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="labor_card_number" label={'Labour Card Number'} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="labor_card_expiry" label={t('employees:labor_card_expiry_date')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="insurance_expiry" label={t('employees:insurance_expiry_date')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="residence_permit_expiry" label={'Residence Permit Expire Date'} />
        </Grid>


        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{fontWeight: 'bolder'}}>
              Employee categorization
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup name="employee_categorization" value={getField('employee_categorization')} onChange={onChange}>
              <Grid item xs={12} >
                <FormControlLabel value="1" control={<Radio color="primary" />} label={'Skilled'} />
                <FormControlLabel value="0" control={<Radio color="primary" />} label={'Unskilled'} />
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{fontWeight: 'bolder ' }}>
              {t('employees:employee_current_residency')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup aria-label="employee-current-residency" name="current_residency" value={getField('current_residency')} onChange={onChange}>
              <Grid item xs={12} >
                <FormControlLabel value={'out'} control={<Radio color="primary" />} label={t('employees:employee_current_residency_out')} />
                <FormControlLabel value={'in'} control={<Radio color="primary" />} label={t('employees:employee_current_residency_in')} />
              </Grid>
            </RadioGroup>
          </Grid>
          { (getField('current_residency') === 'in' || getField('current_residency') === 'IN the Country') &&
            <Fragment>
              <Grid item xs={12} sm={12} lg={12}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="uid_number" label={t('employees:uid_no')} helperText={t('employees:uid_no_helper_text')} />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="eid_number" label={t('employees:eid_no')} helperText={t('employees:uid_no_helper_text')} />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <AppPickDateField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="eid_expiry" label={t('employees:eid_expiry_date')} helperText={t('employees:uid_no_helper_text')} />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="employee_number" label={t('employees:employee_number')} helperText={t('employees:uid_no_helper_text')} />
              </Grid>
            </Fragment>
          }
        </Grid>
      </Grid>
    </Card>
  )
}

export default ContactAndPassportInfo;
