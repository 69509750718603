import React, {useContext} from 'react'
import {Card, CardHeader, Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EmployeeContext} from "../../../EmployeesContainer";
import {formatDate} from "../../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  },
  cardFullHeight: {
    height: "100%"
  },
  textFields: {
    padding: "0 24px 24px 24px",
    '& .MuiGrid-item': {
      marginTop: '20px'
    }
  },
  dividerMargin: {
    marginTop: "-10px"
  },
  makeBolder: {
    fontWeight: '600',
  },
  makeTopMargin: {
    marginTop: '10px',
  }
}));

function GeneralInfoReadOnly() {
  const {t, getField, getAsset} = useContext(EmployeeContext);
  const classes = useStyles();
  const dependents = getAsset('dependents', []);

  return (
    <Card elevation={1} className={classes.cardFullHeight}>
      <CardHeader title={t('employees:general_info')} className={classes.header} />
      <Grid container className={classes.textFields}>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:gender`)}
          </Grid>
          <Grid item xs={6}>
            {getField('gender', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:date_of_birth`)}
          </Grid>
          <Grid item xs={6}>
            {formatDate(getField('date_of_birth'))}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:place_of_birth`)}
          </Grid>
          <Grid item xs={6}>
            {getField('place_of_birth', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:mothers_name`)}
          </Grid>
          <Grid item xs={6}>
            {getField('mothers_name', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:nationality`)}
          </Grid>
          <Grid item xs={6}>
            {getField('nationality', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:religion`)}
          </Grid>
          <Grid item xs={6}>
            {getField('religion', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:sect`)}
          </Grid>
          <Grid item xs={6}>
            {getField('sect', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:martial_status`)}
          </Grid>
          <Grid item xs={6}>
            {getField('marital_status', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            Dependents:
          </Grid>
          <Grid item xs={6}>
            {dependents.map(dependant => (
              <Typography  key={dependant.id} variant="subtitle2"> - {dependant.first_name} {dependant.last_name}</Typography>
            ))}
          </Grid>
      </Grid>
    </Card>
  )
}

export default GeneralInfoReadOnly
