import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import AppAutocomplete from 'components/AppAutocomplete';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Visa Application',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Visa Application Review',
    hint: 'Current Step',
    current: true
  }
];

const rejection_reasons = [
  {
    value: 'Visit Immigration Department',
  },
  {
    value: 'Multiple UID’s, requires to be combined',
  },
  {
    value: 'Visa for this person cannot be issued',
  },
  {
    value: 'Black list',
  },
  {
    value: 'Rejected (reason not mentioned, usually when it is related to CID or security)',
  },
  {
    value: 'Others',
  },
]


// STEP 9bc
function StepVisaApplicationReview({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, addToArrayInAssets, approveDocumentApi, clearFields, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const [confirm, toggleConfirm] = useDialog();
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const work_permit = getField('visa_status');

  const uploadDocumentsError = getError('visa_approve_document_error');

  async function onConfirm() {
    toggleConfirm();
    const approved = work_permit === 'approved'
    let file = null;
    const additional_documents = getField('visa_adding_data_entrist') ? 1 : 0;
    if (approved) {
      file = uploadedDocuments[0];
      approveDocumentApi({ documentId: file.id});
    }

    const approvedFields = [
      {
        field: 'visa_status',
        value: getField('visa_status'),
      },
      {
        field: 'file',
        upload_type: (file || {}).upload_type,
        value: (file || {}).name,
      },
      {
        field: 'document_id',
        value: (file || {}).id,
      },
      {
        field: 'visa_date_approve',
        value: getField('visa_date_approve'),
      },
      {
        field: 'visa_issued_date',
        value: getField('visa_issued_date'),
      },
      {
        field: 'visa_expired_date',
        value: getField('visa_expired_date'),
      },
      {
        field: 'uid_number',
        value: getField('uid_number'),
      },
    ];

    const rejectedFields = [
      {
        field: 'visa_status',
        value: getField('visa_status'),
      },
      {
        field: 'rejection_reason',
        value: getField('visa_rejection_reason',)
      },
      {
        field: 'rejection_comments',
        value: getField('rejection_comments'),
      },
      {
        field: 'visa_rejection_date',
        value: getField('visa_rejection_date'),
      },
      {
        field: 'visa_adding_data_entrist',
        value: getField('visa_adding_data_entrist') ? 1 : 0,
      }
    ];
    const fields = approved ? approvedFields : rejectedFields;
    const messages = approved ? ['Visa application approved'] : ['Visa application rejected']
    try {
      const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: approved ? 0 : 1, additional_documents};
      const employeePayload = approved ? {visa_expiry: getField('visa_expired_date'), uid_number: getField('uid_number')} : undefined;

      nextStep(stepPayload, employeePayload);
      clearFields(['visa_status', 'visa_date_approve', 'visa_issued_date', 'visa_expired_date', 'visa_rejection_reason', 'visa_rejection_date', 'visa_adding_data_entrist', 'uid_number'])
    } catch (e) {

    }
  }

  function validate(approved) {
    let error = false;

    if (approved === undefined) return true;

    // Rejected validations
    if (approved === false && !getField('visa_rejection_date')) {
      addError('visa_rejection_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_visa_application_rejection') }));
      error = true;
    }
    if (approved === false && !getField('visa_rejection_reason')) {
      addError('visa_rejection_reason', 'Required Field', t('required_field', {name: t('tracker:reason_for_rejection') }));
      error = true
    }

    // Approved validations
    if (approved && !getField('visa_date_approve')) {
      addError('visa_date_approve', 'Required Field', t('required_field', {name: t('tracker:date_of_the_visa_application_approval') }));
      error = true
    }
    if (approved && !getField('visa_issued_date')) {
      addError('visa_issued_date', 'Required Field', t('required_field', {name: t('tracker:visa_issued_date') }));
      error = true
    }
    if (approved && !getField('visa_expired_date')) {
      addError('visa_expired_date', 'Required Field', t('required_field', {name: t('tracker:visa_expired_date') }));
      error = true
    }
    if (approved && !getField('uid_number')) {
      addError('uid_number', 'Required Field', t('required_field', {name: 'UID No.' }));
      error = true
    }

    if (approved && uploadedDocuments.length === 0) {
      addError('visa_approve_document_error', t('tracker:please_upload_document'));
      error = true;
    }
    return error
  }

  function onUpload(data) {
    clearError('visa_approve_document_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
   return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const approved = work_permit === 'approved' ? true : work_permit === 'rejected' ? false : undefined;
    const error = validate(approved);
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {t('tracker:visa_application')}
          </Typography>
          <RadioGroup aria-label="work_permit" name="visa_status" value={getField('visa_status')} onChange={onChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'approved'} control={<Radio color="primary" />} label={t('tracker:radio_approved')} />
              <FormControlLabel value={'rejected'} control={<Radio color="primary" />} label={t('tracker:radio_rejected')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {/* APPROVED */}
        {work_permit === 'approved' &&
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            { (documents.length === 0 && uploadedDocuments.length === 0) &&
              <UploadFile/>
            }
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                addAsset={addAsset}
                getAsset={getAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onDelete={handleDocumentDelete}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} lg={3} xl={3}>
                <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="visa_date_approve" label={t('tracker:date_of_the_visa_application_approval')} />
              </Grid>
              <Grid item xs={12} lg={3} xl={3}>
                <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="visa_issued_date" label={t('tracker:visa_issued_date')} />
              </Grid>
              <Grid item xs={12} lg={3} xl={3}>
                <AppPickDateField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="visa_expired_date" label={t('tracker:visa_expired_date')} />
              </Grid>
              <Grid item xs={12} lg={3} xl={3}>
                <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="uid_number" label={'UID No.'} />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        {/* REJECTED */}
        {work_permit === 'rejected' &&
        <Fragment>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} lg={4} xl={3}>
                <AppAutocomplete
                  getError={getError}
                  getField={getField}
                  items={rejection_reasons}
                  clearError={clearError}
                  onChange={onChange}
                  itemValueUnit={'value'}
                  name={'visa_rejection_reason'}
                  label={t('tracker:reason_for_rejection')}
                  getOptionLabel={(option) => option.value}
                />
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <AppTextField name={'rejection_comments'} label={'Comments Of Rejection'} onChange={onChange} getField={getField} multiline rows={3}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4} xl={3}>
                    <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="visa_rejection_date" label={t('tracker:date_of_the_visa_application_rejection')} />
                  </Grid>
                  <Grid item xs={12} lg={5} xl={4}>
                    <AppCheckbox style={{ marginTop: '6px' }} getField={getField} onChange={onChange} name={'visa_adding_data_entrist'} label={'Send to Data Entrist - adding additional documents'}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        }
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepVisaApplicationReview;
