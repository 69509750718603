import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import AppPickDateField from 'components/AppPickDateField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import UploadedDocument from "../components/UploadedDocument";
import clsx from "clsx";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  },
  note: {
    color: theme.variables.stepNoteColor,
  }
}));

const steps = [
  {
    label: 'Confirm Employee Residency',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Change Visa Status',
    hint: 'Current Step',
    current: true
  }
];


// STEP 9bc
function StepAdviseVisaAdminToInitiateChangeStatusProcedure({ getAsset, addAsset, getField, onChange, addError, getError, nextStep, uploadFile, isLoading, deleteDocument, clearError, isPromise, approveDocumentApi, addToArrayInAssets, }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getFileSize } = useUpload();
  const [confirm, toggleConfirm] = useDialog();
  const { handleUpload } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id);
  const stepFields = getAsset('stepFields', []);

  const uploadDocumentsError = getError('candidate_procedure_status_document_error');

  function onConfirm() {
    toggleConfirm();
    let file = uploadedDocuments[0];
    approveDocumentApi({ documentId: file.id, approval: 1 });


    const fields = stepFields.map(step => {
      if (step.name === 'file') return { field: step.name, value: file.name, upload_type: file.upload_type}
      return {field: step.name, value: getField(step.name)}
    });

    const messages = ['visa Application changed'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
  }

  function validate() {
    let error = false;

    if (!getField('status_procedure_visa_status_change_date')) {
      addError('status_procedure_visa_status_change_date', 'Required Field', t('required_field', {name: 'Date of Visa Status Change'}));
      error = true
    }

    if (!getField('status_procedure_visa_amandmant_date')) {
      addError('status_procedure_visa_amandmant_date', 'Required Field', t('required_field', {name: 'Visa amandmant date'}));
      error = true
    }

    if (!getField('status_procedure_paid_fines')) {
      addError('status_procedure_paid_fines', 'Required Field', t('required_field', {name: 'Paid Fines if any'}));
      error = true
    }

    if (!getField('status_procedure_fine_reason')) {
      addError('status_procedure_fine_reason', 'Required Field', t('required_field', {name: 'Paid Fines if any'}));
      error = true
    }

    if (uploadedDocuments.length === 0) {
      addError('candidate_procedure_status_document_error', t('tracker:please_upload_document'))
      error = true;
    }

    if (getError('status_procedure_paid_fines')) {
      error = true;
    }

    return error
  }

  function onUpload(data) {
    clearError('candidate_procedure_status_document_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm()
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '20px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Fragment>
          <Grid item xs={12}>
            <Typography className={classes.note}>
              {t('tracker:add_labor_card_note')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
              {t('tracker:upload_document')}
            </Typography>
            <Typography variant="body2">
              {t('tracker:allowed_file_types')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UploadFile/>
            {/* Uploaded Documents */}
            {uploadedDocuments.map((document, index) =>
              <UploadedDocument
                addAsset={addAsset}
                getAsset={getAsset}
                key={document.id}
                label={document.name}
                index={index}
                size={document.size}
                onDelete={handleDocumentDelete}
                file={document}
                isPromise={isPromise}
              />
            )}
            {/* Document To Be uploaded */}
            {documents.map((document, index) =>
              <AppUploadDocument
                getAsset={getAsset}
                addAsset={addAsset}
                getError={getError}
                addError={addError}
                clearError={clearError}
                key={document.id}
                name={document.id}
                label={document.name}
                index={index}
                size={getFileSize(document)}
                onDelete={handleDocumentDelete}
                onCancel={handleDocumentCancel}
                getField={getField}
                onChange={onChange}
                uploadFile={onUpload}
                file={document}
                isLoading={isLoading}
                isPromise={isPromise}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={3} style={{ marginTop: '20px' }}>
            <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} clearError={clearError} getField={getField} name="status_procedure_visa_status_change_date" label={'Date of Visa Status Change'} />
          </Grid>
          <Grid item xs={12} lg={3} style={{ marginTop: '20px' }}>
            <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} clearError={clearError} getField={getField} name="status_procedure_visa_amandmant_date" label={'Visa amandmant date'} />
          </Grid>
          <Grid item xs={12} lg={3} style={{ marginTop: '20px' }}>
            <AppTextField price noNegative type={'number'} getError={getError} addError={addError} onChange={onChange} clearError={clearError} getField={getField} name="status_procedure_paid_fines" label={t('Paid Fines if any')} />
          </Grid>
          <Grid item xs={12} lg={3} style={{ marginTop: '20px' }}>
            <AppTextField getError={getError} onChange={onChange} clearError={clearError} getField={getField} name="status_procedure_fine_reason" label={t('Fine Reason')} />
          </Grid>
        </Fragment>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepAdviseVisaAdminToInitiateChangeStatusProcedure;
