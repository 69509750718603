import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline-block',
  },
  grayText: {
    color: 'rgba(0, 0, 0, 0.56)'
  },
  primaryText: {
    color: theme.palette.primary,
  }
}));

function AppTextWithHint({text, hint, textColor, hintColor, textWeight, hintStyle = {}, textStyle = {}, noUppercase, lineHeight, align='center', style, breakWord, hintNoneWord = '' }) {
  const classes = useStyles();
  const _textStyle = {color: textColor, fontWeight: textWeight, ...textStyle, ...(noUppercase && {textTransform: 'none'}), ...(breakWord && {wordBreak: 'break-word'})};
  const _hintStyle = {color: hintColor, ...hintStyle, ...(noUppercase && {textTransform: 'none'})}
  return (
    <div className={classes.inline} style={style}>
      <Grid container justify={'center'} alignItems="center" style={{...(lineHeight && {lineHeight})}}>
        <Grid style={_textStyle} align={align} item xs={12}>
          {text}
        </Grid>
        <Grid style={_hintStyle} align={align} item xs={12}>
          {hint || hintNoneWord}
        </Grid>
      </Grid>
    </div>
  )
}

export default AppTextWithHint;
