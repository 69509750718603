import React from 'react'
import { Card, CardHeader, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppTextField from 'components/AppTextField';
import AppStepper from '../components/AppStepper';
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppPickDateField from "../../../../../../components/AppPickDateField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
}));

const steps = [
  {
    label: '',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'LABOUR CARD  RESUBMISSION',
    hint: 'Current Step',
    current: true
  }
];


// STEP 11c
function SubstepWorkPermitResubmission({ getAsset, getField, onChange, getError, nextStep, addError, clearError, clearFields }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const stepFields = getAsset('stepFields');
  const [confirm, toggleConfirm] = useDialog();

  const message = 'Labour Card Resubmitted'

  const dateFieldName = 'labour_card_submission_date';
  const dateFieldLabel = 'Date of the Submission';

  const textAreaName = 'labour_card_submission_comments';
  const textAreaLabel = 'Comments';

  function onConfirm() {
    toggleConfirm();
    const fields = stepFields.map(field => {
      return {field: field.name, value: getField(field.name)}
    });
    const messages = [message];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    clearFields([dateFieldName, textAreaName]);
  }

  function validate() {
    let error = false;
    if (!getField(dateFieldName)) {
      addError(dateFieldName, 'Required Field', t('required_field', {name: dateFieldLabel }));
      error = true
    }

    if (!getField(textAreaName)) {
      addError(textAreaName, 'Required Field', t('required_field', {name: textAreaLabel }));
      error = true;
    }
    return error;
  }

  function handleNextStep() {
    const error = validate();

    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={9}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8}>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={12} lg={4}>
              <AppPickDateField noDatesInFuture getError={getError} onChange={onChange} getField={getField} clearError={clearError} name={dateFieldName} label={dateFieldLabel} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <AppTextField getError={getError} getField={getField} onChange={onChange} clearError={clearError} name={textAreaName} label={textAreaLabel} multiline rows={6} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default SubstepWorkPermitResubmission
