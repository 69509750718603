import React from "react";
import MainViewView from "./MainViewView";
import useAuth from "../../Hooks/useAuth";
import useUser from "../../Hooks/useUser";
import useRedirect from "../../Hooks/useRedirect";
import useNavigation from "../../Hooks/useNavigation";
import {useSelector} from "react-redux";
import useForm from "../../Hooks/useForm";
import useAPI from "../../Hooks/useAPI";
import {importEmployeesService} from "../../services/employeesService";

function MainViewContainer(props) {
  const { userData, logout } = useAuth();
  const { canCreate, isSuperAdmin, isVisaAdmin, isTypist, isPro, isRecruiter, isDataEntrist } = useUser();
  const { goToNewEmployee } = useRedirect();
  const navigation = useNavigation();
  const globalLoading = useSelector((state) => state.info.globalLoading);
  const { onChange, addField, getField } = useForm();
  const { sendRequest } = useAPI();

  function importEmployees(payload) {
    return sendRequest(importEmployeesService,
      { payload, useFormData: true })
  }

  function exportErrorsToCsv() {

  }

  return(
    <MainViewView
      canCreate={canCreate}
      userData={userData}
      logout={logout}
      isTypist={isTypist}
      isSuperAdmin={isSuperAdmin}
      isVisaAdmin={isVisaAdmin}
      isPro={isPro}
      isRecruiter={isRecruiter}
      goToNewEmployee={goToNewEmployee}
      globalLoading={globalLoading}
      isDataEntrist={isDataEntrist}
      onChange={onChange}
      addField={addField}
      getField={getField}
      importEmployees={importEmployees}
      exportErrorsToCsv={exportErrorsToCsv}
      {...navigation}
      {...props}/>
  )
}

export default MainViewContainer;
