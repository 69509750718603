import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from "@material-ui/core";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  },
}));

function AppAlertDialog({open = false, text, title, onOk = () => {}}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const titleText = title || t('tracker:tracker_step_2_b_dialog_title');
  const displayText = text || 'This is an alert!'

  return (
    <Dialog fullScreen={fullScreen} open={open} aria-labelledby="dialog-title" disableBackdropClick disableEscapeKeyDown >
      <DialogTitle className={classes.dialogTitle}>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {displayText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk} color="primary">
          {t('tracker:tracker_step_2_b_dialog_button_yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppAlertDialog
