import React, { Fragment } from 'react'
import { Card, CardHeader, Grid, Hidden, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { PostAdd } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import AppStepper from '../components/AppStepper';
import useUpload from 'Hooks/useUpload';
import useDialog from "../../../../../../Hooks/useDialog";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import clsx from "clsx";
import AppCamera from "../../../../../../components/AppCamera";
import AppPickDateField from "../../../../../../components/AppPickDateField";



const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  },
}));

const steps = [
  {
    label: 'Visa Stamping',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Send to Immigration',
    hint: 'Current Step',
    current: true
  },
];

// STEP 26
function StepSendPassportToImmigration({ getAsset, addAsset, getField, onChange, addError, getError, deleteDocument, uploadFile, isLoading, nextStep, clearError, addToArrayInAssets, approveDocumentApi, isPromise }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const take_original_passport_status = getField('take_original_passport_status');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id));
  const [confirm, toggleConfirm] = useDialog();

  const uploadDocumentsError = getError('documents_upload_error');

  function onConfirm() {
    const fields = [];

    uploadedDocuments.forEach((item) => {
      approveDocumentApi({ documentId: item.id, approval: 1 });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name
      })
    })

    fields.push({
      field: 'take_original_passport_status',
      value: getField('take_original_passport_status')
    })

    fields.push({
      field: 'ica_approval_date',
      value: getField('ica_approval_date')
    })
    const messages = ['Original passport taken'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    toggleConfirm();
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index, 1);
    addAsset('documents', items);
  }

  function onUpload(data) {
    clearError('documents_upload_error');
    return uploadFile(data);
  }

  function handleStatusChange(e) {
    clearError('take_original_passport_status');
    onChange(e);
  }

  function validate() {
    let error = false

    if (uploadedDocuments.length === 0) {
      addError('documents_upload_error', t('tracker:please_upload_documents'));
      error = true
    }

    if (!getField('take_original_passport_status')) {
      addError('take_original_passport_status', 'Required Field', 'Please Select Required Method');
      error = true
    }

    if (!getField('ica_approval_date')) {
      addError('ica_approval_date', 'Required Field',  t('required_field', {name: 'ICA Approval Date' }));
      error = true
    }

    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Grid container spacing={3}>
            <Grid item>
              <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
                {t('tracker:button_add_documents')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AppCamera onCapture={handleUpload} />
            </Grid>
          </Grid>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '100px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={5}>
          <Typography variant="body1" style={{ fontWeight: 600 }} className={clsx({[classes.error]: getError('take_original_passport_status')})}>
            {t('tracker:visa_application')}
          </Typography>
          <RadioGroup name="take_original_passport_status" value={getField('take_original_passport_status')} onChange={handleStatusChange}>
            <Grid item xs={12} >
              <FormControlLabel value={'mail'} control={<Radio color="primary" />} label={t('tracker:radio_mail')} />
              <FormControlLabel value={'in person'} control={<Radio color="primary" />} label={t('tracker:radio_in_person')} />
            </Grid>
          </RadioGroup>
        </Grid>
        {/* MAIL */}
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_employees_documents')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <UploadFile/>
              {/* Uploaded Documents */}
              {uploadedDocuments.map((document, index) =>
                <UploadedDocument
                  getAsset={getAsset}
                  addAsset={addAsset}
                  key={document.id}
                  label={document.name}
                  index={index}
                  size={document.size}
                  onDelete={handleDocumentDelete}
                  file={document}
                  isPromise={isPromise}
                />
              )}
              {/* Document To Be uploaded */}
              {documents.map((document, index) =>
                <AppUploadDocument
                  getAsset={getAsset}
                  addAsset={addAsset}
                  getError={getError}
                  addError={addError}
                  clearError={clearError}
                  key={document.id}
                  name={document.id}
                  label={document.name}
                  index={index}
                  size={getFileSize(document)}
                  onDelete={handleDocumentDelete}
                  onCancel={handleDocumentCancel}
                  getField={getField}
                  onChange={onChange}
                  uploadFile={onUpload}
                  file={document}
                  isLoading={isLoading}
                  isPromise={isPromise}
                />
              )}
            </Grid>
          </Fragment>
        <Grid item xs={12} lg={3}>
          <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="ica_approval_date" label={'ICA Approval Date'} />
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onConfirm={onConfirm} onCancel={toggleConfirm} />
    </Card>
  )
}

export default StepSendPassportToImmigration
