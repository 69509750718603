import axios from "axios";
import {transformResponse} from "./helpers";

export const getStepNotificationsService = ({ data, config } = {}) => {
  return axios({ method: "get", url: "/step-notifications", data, ...(config || {}) }).then(
    (response) => {
      return transformResponse(response);
    }
  );
};

export const getSingleNotificationService = ({data, config, params} = {}) => {
  const notification_id = (params || {}).notification_id;
  return axios({method: 'get', url: `/notifications/${notification_id}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getNotificationsListService = ({ data, config } = {}) => {
  return axios({ method: "get", url: "/notifications", data, ...(config || {}) }).then(
    (response) => {
      return transformResponse(response);
    }
  );
};

export const createNotificationService = ({ data, config } = {}) => {
  return axios({ method: "post", url: "/notifications", data, ...(config || {}) }).then(
    (response) => {
      return transformResponse(response);
    }
  );
};

export const updateNotificationService = ({ data, config, params } = {}) => {
  const notification_id = (params || {}).notification_id;
  return axios({ method: "put", url: `/notifications/${notification_id}`, data, ...(config || {}) }).then(
    (response) => {
      return transformResponse(response);
    }
  );
};
