import {useSelector} from "react-redux";
import {developmentRestrictions, productionRestrictions, stagingRestrictions} from "../Constants/Restrictions";

function useUser() {
  const loggedInUser = useSelector((state) => state.auth.userData);

  const isSuperAdmin = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 1);
    return returnObject ? (role || {}) : !!role;
  };
  const isDataEntrist = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 2);
    return returnObject ? (role || {}) : !!role;
  };
  const isMedical = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 3);
    return returnObject ? (role || {}) : !!role;
  };
  const isVisaAdmin = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 4);
    return returnObject ? (role || {}) : !!role;
  };
  const isPro = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 5);
    return returnObject ? (role || {}) : !!role;
  };
  const isTypistSupervisor= (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 6);
    return returnObject ? (role || {}) : !!role;
  }
  const isTypist = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 7);
    return returnObject ? (role || {}) : !!role;
  };
  const isRecruiter = (user = loggedInUser, returnObject = false) => {
    const role = (user.role || []).find(role => role.id === 8);
    return returnObject ? (role || {}) : !!role;
  };

  const canCreate = (user = loggedInUser) => {
    return !!(user.permissions || []).find(permission => permission.title === 'Create' );
  };
  const canRead = (user = loggedInUser) => {
    return !!(user.permissions || []).find(permission => permission.title === 'Read');
  };
  const canUpdate = (user = loggedInUser) => {
    return !!(user.permissions || []).find(permission => permission.title === 'Update');
  };

  const checkMyRoles = (roleId) => {
    return (loggedInUser.role || []).find(role => role.id === roleId);
  }

  const getRoleSlug = (user = loggedInUser) => {
    return ((user.role || [])[0] || {}).slug;
  }

  function getAssignedTypist(users) {
    return (users || []).find(us => isTypist(us)) || {};
  }

  function getAssignedPro(users) {
    return (users || []).find(us => isPro(us)) || {};
  }

  function getAssignedRecruiter(users) {
    return (users || []).find(us => isRecruiter(us)) || {};
  }

  function getRestrictions() {
    const env = process.env.REACT_APP_ENVIRONMENT;

    if (env === 'development') return developmentRestrictions;
    if (env === 'staging') return stagingRestrictions;
    if (env === 'production') return productionRestrictions;
    return [];
  }

  function getRestrictionsFromEnv() {
    const restrictions = getRestrictions();
    return (restrictions || []).find(restriction => restriction.candidateId == loggedInUser.id);
  }

  const roles = [
    {
      id: 1,
      title: 'Super Admin',
    },
    {
      id: 2,
      title: 'Data Entrist',
    },
    {
      id: 3,
      title: 'Medical Insurance',
    },
    {
      id: 4,
      title: 'Visa Admin',
    },
    {
      id: 5,
      title: 'PRO',
    },
    // {
    //   id: 6,
    //   title: 'Typist Supervisor',
    // },
    {
      id: 7,
      title: 'Typist',
    },
    {
      id: 8,
      title: 'Recruiter',
    }
  ]

  return {
    user: loggedInUser,
    isSuperAdmin,
    isDataEntrist,
    isRecruiter,
    isVisaAdmin,
    isPro,
    isTypistSupervisor,
    isTypist,
    canCreate,
    canRead,
    canUpdate,
    checkMyRoles,
    getRoleSlug,
    getAssignedTypist,
    getAssignedPro,
    getAssignedRecruiter,
    isMedical,
    roles,
    getRestrictionsFromEnv
  }
}

export default useUser;
