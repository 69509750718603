import React from "react";
import {Card, Grid, Typography} from "@material-ui/core";
import logo from "../../assets/img/WFC.svg";
import {makeStyles} from "@material-ui/core/styles";
import AppButton from "../../components/AppButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    minHeight: 'min(100vh, 550px)',
    [theme.breakpoints.down("xs")]: {
      marginTop: '0',
      height: '100vh',
    }
  }
}));

function DownloadView({ downloadStatus, downloadFile, fileSlug }) {
  const classes = useStyles();
  function printStatusMessage() {
    console.log(downloadStatus)
    switch (downloadStatus) {
      case 1:
        try {
          return (
            <div>
              <Typography style={{ marginBottom: '10px' }}>Your download should start, if not</Typography>
              <AppButton onClick={() => downloadFile()} style={{ marginLeft: '5px' }} >Click Here</AppButton>
            </div>
          )
        } catch (e) {
          console.log(e)
        }
      case 2:
        return (
          <div>
            <Typography>Sorry, something went wrong</Typography>
          </div>
        )
    }
  }


  return (
    <Card className={classes.root}>
      <Grid container justify="center" spacing={3}>
        <Grid style={{ marginTop: '70px' }} item xs={12} align="center">
          <img style={{ marginBottom: '20px' }} src={logo} alt="logo"/>
          { printStatusMessage() }
        </Grid>
      </Grid>
    </Card>
  )
}

export default DownloadView;
