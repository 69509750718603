import React from 'react';
import {useEffectOnce} from "react-use";
import AppAutocomplete from "../AppAutocomplete";
import {Grid} from "@material-ui/core";
import {getFieldRoles} from "../../utils/utilFinctions";

function AssignUserToEmployee({getUsers, clearUserIdField, onChange, getField, getAsset, getError, clearError, field, label}) {
  const role = getFieldRoles(field, 'assign_user', null);

  useEffectOnce(() => {
    if (role) getUsers(role);
    clearUserIdField();
  })

  return (
    <Grid item xs={12} md={6} lg={3}>
      <AppAutocomplete
        label={label}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name} - ${option.candidate_user_count || "#"}` }
        onChange={onChange}
        getField={getField}
        items={getAsset(`${role}s`, [])}
        getError={getError}
        name={'user_id'}
        clearError={clearError}/>
    </Grid>
  )
}

export default AssignUserToEmployee;
