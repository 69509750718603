import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

// App Info Dialog made from Lazar's AppConfirmDialog logic
function AppInfoDialog({ t, getAsset, addAsset, dialogTitle = 'Info', name, contentText }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function show() {
    addAsset(name, !getAsset(name));
  }

  return (
    <Dialog fullScreen={fullScreen} open={!!getAsset(name)} aria-labelledby="dialog-title" disableBackdropClick disableEscapeKeyDown >
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => show()} color="primary" autoFocus>
          {t('tracker:tracker_step_2_b_dialog_button_yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppInfoDialog;
