import React from 'react'
import { Card, Grid } from '@material-ui/core';
import AppTextField from "../../../../../components/AppTextField";

function Allowance({t, getField, onChange, getError, clearError, addError}) {

  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="housing_allowance" label={t('employees:housing_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="education_allowance" label={t('employees:education_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="transportation_allowance" label={t('employees:transportation_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="utility_allowance" label={t('employees:utillity_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="mobile_allowance" label={t('employees:mobile_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="technical_allowance" label={t('employees:technical_allowance')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="social_allowance" label={t('employees:social_allowance')} helperText={t('employees:social_allowance_helper_text')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="child_allowance" label={t('employees:child_allowance')} helperText={t('employees:child_allowance_helper_text')}/>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} convertToString noNegative min={0} clearError={clearError} type={'number'} getError={getError} onChange={onChange} getField={getField} name="other_allowance" label={t('employees:other_allowance')}/>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Allowance
