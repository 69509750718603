import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Hidden, TableCell, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none'
  },
  tableCell: {
    paddingTop: '10px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    paddingRight: '8px'
  },
}))

function ExpandableTableRow({setKey, expanded = false, children, colSpan = 1, hiddenProps = {}}) {
  const classes = useStyles();

  return (
    <Hidden {...hiddenProps}>
      <TableRow key={setKey} style={{visibility: expanded ? '' : 'collapse'}}>
        <TableCell className={classes.tableCell} colSpan={colSpan}>
          <Accordion className={classes.accordion} expanded={expanded}>
            <AccordionSummary style={{display: 'none'}}/>
            <AccordionDetails>
              {children}
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </Hidden>
  )
}

export default React.memo(ExpandableTableRow)
