import { LOGIN, LOGOUT } from "../CONSTANTS";

const initialState = {
  token: null,
  refreshToken: null,
  userData: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        userData: action.payload.userData,
      };
    case LOGOUT:
      return {
        ...initialState,
        token: ''
      };
    default: return state;
  }
}

export default authReducer;
