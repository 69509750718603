import React from "react";
import LoginContainer from "./LoginContainer";

function Login () {
  return (
    <LoginContainer/>
  )
}

export default Login;
