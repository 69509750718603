import React, {Fragment} from 'react'
import { Card, CardHeader, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EditOutlined} from "@material-ui/icons";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppIconWithLoading from "../../../components/Icons/AppIconWithLoading";


const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '& .MuiTypography-root': {
      fontSize: '1.2rem'
    },
    '& .MuiCardHeader-action': {
      marginTop: '-2px',
      marginBottom: '-2px',
      marginRight: 0,
    }
  },
  tableCell: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  tableHeadTitles: {
    color: "rgba(0, 0, 0, 0.56)"
  },
  companyName: {
    color: "#1A97AD"
  },
  icon: {
    cursor: 'pointer',
    '&:nth-of-type(1), &:nth-of-type(2)': {
      marginRight: '20px',
    }
  },
  mobileControls: {
    paddingTop: '8px'
  },
}));

function NotificationsTable({getAsset, addAsset, t, isLoading, getNotificationSteps, goToEditNotification }) {
  const classes = useStyles();
  const steps = getAsset('steps', []);
  const notifications = getAsset('notifications', []);

  const notificationStepIds = notifications.map(not => not.step_id);

  /*useEffectOnce(() => {
    getNotificationSteps();
  })*/

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  return (
    <Card>
      <CardHeader className={classes.header} style={headerStyles} title={t('notifications:notifications')}/>
      {isLoading && <LinearProgress />}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadTitles}>{t('notifications:step')}</TableCell>
               <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {steps.filter(step => notificationStepIds.includes(step.id)).map((step) => (
              <Fragment key={step.id}>
                <TableRow hover key={step.id}>
                  <TableCell className={clsx([classes.tableCell, classes.companyName])}> {step.title} </TableCell>
                  <TableCell align={"right"} style={{minWidth: '150px'}}>
                    <AppIconWithLoading marginLeft={'4px'} onClick={() => goToEditNotification(step.id) }> <EditOutlined className={classes.icon} color={"secondary"} /> </AppIconWithLoading>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default NotificationsTable;
