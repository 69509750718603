import {lazy, useEffect} from "react";
import useUser from "./useUser";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setNavInfo as setNavInfoAction} from "../redux/actions/infoActions";
import useRedirect from "./useRedirect";


function useNavigation() {
  const dispatch = useDispatch();
  const { isSuperAdmin, isVisaAdmin, isRecruiter, isTypistSupervisor, isPro, isTypist, isMedical, isDataEntrist, getRestrictionsFromEnv } = useUser();
  const location = useLocation();
  const {goToDashboard, goToEmployees,} = useRedirect();
  const additionalNavText = useSelector((state) => state.info.additionalNavText);

  useEffect(() => {
    setNavInfo('');
    //eslint-disable-next-line
  }, [location.pathname]);

  const setNavInfo = (payload) => {
    dispatch(setNavInfoAction(payload))
  }

  const navLinks = [
    {
      key: 'navigation:dashboard',
      path: '/dashboard',
      redirect: goToDashboard,
      icon: lazy(() =>
        import(`@material-ui/icons/DashboardOutlined`)
      ),
    },
    {
      key: 'navigation:employees',
      path: '/employees',
      redirect: goToEmployees,
      icon: lazy(() =>
        import(`@material-ui/icons/AssessmentOutlined`)
      ),
    },
    {
      key: 'navigation:companies',
      path: '/companies',
      redirect: () => {},
      icon: lazy(() =>
        import(`@material-ui/icons/BusinessOutlined`)
      ),
    },
    {
      key: 'navigation:activity_logs',
      path: '/activity-logs',
      redirect: () =>{},
      icon: lazy(() =>
        import(`@material-ui/icons/TrackChangesOutlined`)
      ),
    },
    {
      key: 'navigation:users',
      path: '/users',
      icon: lazy(() =>
        import(`@material-ui/icons/SupervisorAccount`)
      ),
    },
    {
      key: 'notifications:notifications',
      path: '/notifications',
      icon: lazy(() =>
        import(`@material-ui/icons/ChatBubbleOutline`)
      ),
    },
    {
      hidden: true,
      key: 'export',
      path: '/export',
    }
  ]

  const linksForPermission = [
    {
      key: 'navigation:users',
      path: '/users',
      icon: lazy(() =>
          import(`@material-ui/icons/SupervisorAccount`)
      ),
    },
  ]

  function getLinks() {
    switch (true) {
      case isSuperAdmin():
        return navLinks
      case isVisaAdmin():
        return [navLinks[0], navLinks[1], navLinks[2], navLinks[3]]
      case isDataEntrist():
        return [navLinks[0], navLinks[1]]
      case isRecruiter():
        return [navLinks[1]]
      case isMedical():
        return [navLinks[0], navLinks[1]]
      case isTypistSupervisor():
        return [navLinks[0], navLinks[1]]
      case isTypist() || isPro():
        return [navLinks[0], navLinks[1]]
      default:
        return [];
    }
  }

  function getPermittedLinks() {
    const restrictions = getRestrictionsFromEnv();
    if (restrictions) {
      return getLinks().filter(link => !(restrictions.forbiddenPages || []).includes(link.key));
    }
    return getLinks();
  }

  function activeLink() {
   return navLinks.find(link => location.pathname.includes(link.path));
  }

  function getAllowedPaths() {
    return [...getLinks(), ...linksForPermission].map(link => link.path);
  }

  return {getLinks: getPermittedLinks, activeLink, additionalNavText, setNavInfo, getAllowedPaths}
}

export default useNavigation;
