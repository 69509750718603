import React from "react";
import ExportContainer from "./ExportContainer";

function ExportPage () {
  return(
    <ExportContainer/>
  )
}

export default ExportPage;
