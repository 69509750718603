import React from "react";
import {Card, CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  }
}));

function AppCard({title, children = '', ...rest}) {
  const classes = useStyles();
  return (
    <Card {...rest} elevation={1}>
      {title && <CardHeader className={classes.header} title={title} />}
      {children}
    </Card>
  )
}

export default AppCard;
