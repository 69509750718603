import React, {useState} from 'react';
import {Card, Grid, CardHeader} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppStepper from "../components/AppStepper";
import AppButton from "../../../../../../components/AppButton";
import AppAutocomplete from "../../../../../../components/AppAutocomplete";
import {useEffectOnce} from "react-use";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppAlertDialog from "../../../../../../components/Dialogs/AppAlertDialog";
import useDialog from "../../../../../../Hooks/useDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
}));

function StepAssignDataEntrist73({t, onChange, getField, getError, nextStep, getUsers, getAsset, assignDataEntrist = () => {}, addError, clearError, isPromise, clearUserIdField}) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [confirm, toggleConfirm] = useDialog();
  const [alert, toggleAlert] = useDialog();

  useEffectOnce(() => {
    getUsers('data_entrist');
    clearUserIdField();
  })

  function onConfirm() {
    toggleConfirm();
    setLoading(true);
    const result = assignDataEntrist('data_entrist_id' ,getField('user_id'));
     if (isPromise(result)) {
       result.then(() => {
         const fields = [{
           field: 'assign_data_entrist',
           value: getField('user_id'),
         }];
         const messages = ['Data Entrist Assigned'];

         nextStep({content: JSON.stringify({fields, messages}) , resubmission: 0, additional_documents: 0 });
       }).catch((err) => {
         console.log(err)
         setLoading(false);
       });
     }
  }

  function validate() {
    let error = false;
    if (!getField('user_id')) {
      addError('user_id', 'Required Field', t('required_field', {name: 'Assign Data Entrist' }));
      error = true
    }

    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={5} alignItems={"center"} justify={"center"}>
        <Grid item xs={12} md={12} lg={7}> <AppStepper stepTitle={getAsset('stepTitle', '')}/> </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <AppAutocomplete
            label={'Assign Data Entrist'}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
            onChange={onChange}
            getField={getField}
            items={getAsset('data_entrists', [])}
            getError={getError}
            name={'user_id'}
            clearError={clearError}/>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <AppButton isLoading={isLoading} fullWidth onClick={handleNextStep}>
            {t('tracker:button_go_to_next_step')}
          </AppButton>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
      <AppAlertDialog open={alert} onOk={toggleAlert} text={'You can’t go to the next step because you didn’t assign Data Entrist.'}/>
    </Card>
  )
}

export default StepAssignDataEntrist73;
