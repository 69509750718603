import React, { Fragment } from 'react'
import {Card, CardHeader, Grid, Hidden, FormControlLabel, Checkbox, Button, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppStepper from '../components/AppStepper';
import AppTextField from 'components/AppTextField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppCheckbox from "../../../../../../components/AppCheckbox";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import {PostAdd} from "@material-ui/icons";
import useUpload from "../../../../../../Hooks/useUpload";
import clsx from "clsx";
import UploadedDocument from "../components/UploadedDocument";
import AppUploadDocument from "../components/AppUploadDocument";
import AppCamera from "../../../../../../components/AppCamera";
import {isPlatform} from "@ionic/react";
import AppPickDateField from "../../../../../../components/AppPickDateField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  inputDisplay: {
    display: 'none',
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Send to Immigration',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Collect EID',
    hint: 'Current Step',
    current: true
  }
];

// STEP 27
function StepCollectEid({ getAsset, addError, getField, onChange, getError, nextStep, clearError, approveDocumentApi, uploadFile, addToArrayInAssets, deleteDocument, addAsset, isLoading, isPromise, updateEmployee }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id));
  const stepFields = getAsset('stepFields', []);
  const [confirm, toggleConfirm] = useDialog();

  const textField1Name = 'eid_number';
  const textField1Label = 'EID Number';

  const textField2Name = 'eid_card_number';
  const textField2Label= 'EID Card Number';

  const dateFieldName = 'eid_expiry_date';
  const dateFieldLabel = 'EID Expiry date';

  const uploadDocumentsError = getError('documents_upload_error');

  async function onConfirm() {
    toggleConfirm();

    const fields = stepFields.filter(step => step.name !== 'file').map(step => {
      return {field: step.name, value: getField(step.name)}
    });

    uploadedDocuments.forEach((item) => {
      approveDocumentApi({ documentId: item.id, approval: 1 });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })

    const messages = ['EID collected'];

    try {
      const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0}
      const employeePayload = {eid_number: getField('eid_number'), eid_expiry: getField('eid_expiry_date'), current_residency: 'in', }

      nextStep(stepPayload, employeePayload);
    } catch (error) {
      console.log('error => ', error);
    }
  }


  function onUpload(data) {
    clearError('documents_upload_error');
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function validate() {
    let error = false;

    if (uploadedDocuments.length === 0) {
      addError('documents_upload_error', t('tracker:please_upload_documents'));
      error = true
    }

    if (!getField('confirm_eid')) {
      addError('confirm_eid', 'Confirm Collected EID');
      error = true;
    }

    if (!getField(dateFieldName)) {
      addError(dateFieldName, 'Required Field',  t('required_field', {name: dateFieldLabel }));
      error = true
    }

    if (!getField(textField1Name)) {
      addError(textField1Name, 'Required Field',  t('required_field', {name: textField1Label }));
      error = true
    }

    if (!getField(textField2Name)) {
      addError(textField2Name, 'Required Field',  t('required_field', {name: textField2Label }));
      error = true
    }

    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }



  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload} />
        <label htmlFor="upload-file-button">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
                {t('tracker:button_add_documents')}
              </Button>
            </Grid>
            { isPlatform("cordova") &&
              <Grid item xs={12}>
                <AppCamera onCapture={handleUpload} />
              </Grid>
            }
          </Grid>
        </label>
      </Fragment>
    )
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2} style={{ marginLeft: '140px' }}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <AppCheckbox getError={getError} clearError={clearError} getField={getField} onChange={onChange} name={'confirm_eid'} label={t('tracker:confirm_collected_eid')} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_employees_documents')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadFile/>
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={textField1Name} label={textField1Label} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={textField2Name} label={textField2Label} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppPickDateField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateFieldName} label={dateFieldLabel} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepCollectEid
