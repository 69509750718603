import React from "react";
import DownloadContainer from "./DownloadContainer";

function DownloadPage () {
  return(
    <DownloadContainer/>
  )
}

export default DownloadPage;
