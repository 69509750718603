import React, {useContext, useEffect} from 'react'
import {Card, Chip, Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppImageUpload from "../../../../../components/AppImageUpload";
import {EmployeeContext} from "../../../EmployeesContainer";
import {formatDate} from "../../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 20px',
  },
  makeBolder: {
    fontWeight: 600
  },
  marginMdDown: {
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    }
  },
  centerMdDown: {
    [theme.breakpoints.down('md')]: {
      justifyContent: "center"
    }
  },
  outOfCountry: {
    backgroundColor: "#A7A7A7",
    color: "#fff"
  },
  dividerLine: {
    borderLeft: "2px solid #E0E0E0;",
    height: "120px",
    marginRight: '-25px',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      borderBottom: "2px solid #E0E0E0;",
      height: 0,
      margin: '20px 0'
    },
  }
}));

function BasicInfoReadOnly() {
  const {t, getField, getAsset, getUserById, isSuperAdmin} = useContext(EmployeeContext);
  const classes = useStyles();
  const data_entrist_id = getField('data_entrist_id');
  const stepTitle = getAsset('stepTitle');
  const assign = getField('assign');
  const role = ((assign || {}).role || [])[0];

  useEffect(() => {
    if (isSuperAdmin() && data_entrist_id) getUserById(data_entrist_id);
    // eslint-disable-next-line
  }, [data_entrist_id])

  const dataEntrist = getAsset('assignedDataEntrist', {});

  return (
    <Card className={classes.root}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} lg={5}>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={3} style={{minWidth: '170px'}}>
              <AppImageUpload imageUrl={getAsset('image')} readOnly/>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" style={{marginBottom: '10px'}}>
                {getField('first_name')} {getField('last_name')}
              </Typography>
              {stepTitle && <Chip color="primary" label={stepTitle} style={{marginBottom: '5px', maxWidth: '100%'}}/>}
              <br/>
              {/*{ (getField('current_residency') === 'out ') && <Chip className={classes.outOfCountry} label="OUT of the Country" />  }*/}
              { assign && role && <Typography> {role.title}: {assign.first_name} {assign.last_name} </Typography> }
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} lg={3} className={classes.marginMdDown}>
          <Grid container direction="row">

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              Process
            </Grid>
            <Grid item xs={6} lg={6}>
              {(getField('process', [])[0] || {}).title || '-'}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              EID Received
            </Grid>
            <Grid item xs={6} lg={6}>
              {getField('eid_received') ? 'YES' : 'NO'}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:passport_taken')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {getField('take_passport') ? 'YES' : 'NO'}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:passport_expiry')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {formatDate(getField('passport_expiry'))}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:visa_expiry')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {formatDate(getField('visa_expiry'))}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:labor_card_expiry')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {formatDate(getField('labor_card_expiry'))}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:insurance_expiry')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {formatDate(getField('insurance_expiry'))}
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12} lg={1} className={classes.dividerLine}> </Grid>

        <Grid item xs={12} md={8} lg={3}>
          <Grid container direction="row">
            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:sn_#')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {getField('id')}
            </Grid>

            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:company')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {/*{findById(companies, getField('company_id'))['title'] || '-'}*/}
              {(getField('company', [])[0] || {}).title || '-'}
            </Grid>


            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:business_unit')}
            </Grid>
            <Grid item xs={6} lg={6}>
              {getField('business_unit', '-')}
            </Grid>


            <Grid item xs={6} lg={6} className={classes.makeBolder}>
              {t('employees:data_entrist')}
            </Grid>
            <Grid item xs={6} lg={6}>
              { dataEntrist['first_name'] || '-' }
              &nbsp;
              { dataEntrist['last_name'] || '' }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card >
  )
}

export default BasicInfoReadOnly
