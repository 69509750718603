export const developmentRestrictions = [
  {
    "candidateId": 50,
    "allowedCompanies": [1,2],
    "forbiddenPages": ["navigation:employees"]
  }
]

export const stagingRestrictions = [
  {
    "candidateId": 222,
    "allowedCompanies": [1,2,3],
    "forbiddenPages": ["navigation:employees"]
  }
]

export const productionRestrictions = [
  {
    "candidateId": 85,
    "allowedCompanies": [52],
    "forbiddenPages": ["navigation:employees"]
  },
  {
    "candidateId": 86,
    "allowedCompanies": [52],
    "forbiddenPages": ["navigation:employees"]
  },
]


