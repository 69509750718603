import React, {useState, useRef, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import AddIcon from '@material-ui/icons/Add';
import {useDropArea} from "react-use";
import useUpload from "../Hooks/useUpload";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '150px',
    height: '150px',
    margin: 0,
    padding: 0,
    backgroundColor: '#e6e6e6',
    borderRadius: '99999px',
    backgroundSize: 'cover',
  },
  photoIcon: {
    marginTop: 0,
    paddingTop: '5px',
    '& .MuiSvgIcon-root': {
      margin: 'auto !important',
    },
  },
  addImage: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    bottom: '2px',
    right: '5px',
    borderRadius: '99999px',
  },
  error: {
    boxShadow: '0px 0px 19px 0px #F44336',
  }
}))

function AppImageUpload({imageUrl, readOnly, onChange = () => {}, name, imageFile, baseUrl = '', clearError = () => {}, getError = () => {}}) {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const inputEl = useRef(null, );
  const { isImage, readImage } = useUpload();

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = function() {
        setImage(reader.result);
      }
      reader.readAsDataURL(imageFile);
    }
  }, [])



  const[bond] = useDropArea({
    onFiles: async (files) => takeImage(files[0])
  })

  const onInputChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    takeImage(file)
  };

  const AddImageButton = () => {
    return (
      <Button color="secondary" variant="contained" className={classes.addImage}>
        <AddIcon onClick={() => inputEl.current.click()}/>
      </Button>
    )
  }

  const takeImage = async (file) => {
    if (!isImage(file)) return;
    const image =  await readImage(file);
    setImage(image)
    onChange(null, name, file);
    clearError(name)
  }

  const getImageUrl = () => {
    return `${baseUrl}${imageUrl}`
  }

  const dropZone = readOnly ? {} : bond;
  const userImage = (image || imageUrl) ? {backgroundImage: `url(${image || getImageUrl()})`} : {};

  return (
    <Grid className={clsx(classes.root,{[classes.error]: getError(name)})} container justify="center" alignItems="center" style={userImage} {...dropZone}>
      <Grid
        className={classes.photoIcon}
        item
        xs={12}
        align="center">
          {!image && !imageUrl && <PhotoCameraOutlinedIcon fontSize="large"/>}
          {!readOnly && <AddImageButton/>}
        <input type="file" ref={inputEl} hidden={true} onInput={onInputChange}/>
      </Grid>
    </Grid>
  )
}

export default AppImageUpload;
