import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import { AccountCircleOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userImage: {
    marginRight: '11px',
    width: '40px',
    height: '40px',
  },
}))

function User({ first_name, last_name, imageUrl, step, baseUrl, width }) {
  const classes = useStyles();
  const stepStyle = step ? { display: 'flex', alignItems: 'center' } : { display: "flex", alignItems: 'center' };
  const widthStyle = width ? { maxWidth: width } : {};
  return (
    <div style={{...stepStyle, ...widthStyle}}>
      {imageUrl && <Avatar onError={(e) => {
        e.target.onerror = null
        e.currentTarget.onerror = null; // prevents looping
        e.currentTarget.src="image_path_here";
      }} alt="user-image" src={`${baseUrl}${imageUrl}`} className={classes.userImage} />}
      {!imageUrl && <AccountCircleOutlined fontSize={"large"} className={classes.userImage} />}
      <div>
        <Typography color={"secondary"}>
          {`${first_name} ${last_name}`}
        </Typography>
        <Typography>
          {step}
        </Typography>
      </div>
    </div>
  )
}

export default User;
