import React, {forwardRef, useImperativeHandle} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography,
  useMediaQuery
} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import useDialog from "Hooks/useDialog";
import AppCheckbox from "../../../../../components/AppCheckbox";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

function PermanentRejectDialog({text, title, onClose = () => {}, onConfirm = () => {}, onChange, addError, clearError, getField, getError}, ref) {
  const {t} = useTranslation();
  const classes = useStyles();
  const displayTitle = title || 'Permanent Rejection';
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, toggle] = useDialog();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    confirm: handleSubmit
  }));

  function handleOpen() {
    onChange(null, 'conformPermanentReject', false);
    clearError('conformPermanentReject');
    toggle()
  }

  function handleClose() {
    toggle();
    onClose();
  }

  function handleSubmit() {
    const error = validate();

    if (!error) {
      toggle();
      onConfirm();
    }
  }

  function validate() {
    let error = false;

    if (!getField('conformPermanentReject')) {
      addError('conformPermanentReject', true);
      error = true;
    }

    return error;
  }



  return (
    <Dialog open={open} fullScreen={fullScreen} aria-labelledby="dialog-title" PaperProps={{style: {minWidth: '400px'}}} >
      <DialogTitle className={classes.dialogTitle}>{displayTitle}</DialogTitle>
      <DialogContent>
        <Typography variant={'body1'}>
          Are you SURE you want to permanently reject this candidate?
        </Typography>
        <AppCheckbox
          getError={getError}
          label={'Please check this box to CONFIRM'}
          onChange={onChange}
          clearError={clearError}
          name={'conformPermanentReject'}
          getField={getField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('no')}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default forwardRef(PermanentRejectDialog);
