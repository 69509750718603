import React, {Fragment} from 'react';
import {Card, Grid, Typography, Button, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import useUpload from "Hooks/useUpload";
import AppButton from "components/AppButton";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "components/Dialogs/AppConfirmDialog";
import useDialog from "Hooks/useDialog";
import clsx from "clsx";
import AppStepper from "../components/AppStepper";
import RejectionReasons from "../components/RejectionReasons";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Work Permit Review',
    hint: 'Previous Step',
    completed: true,
  },
  {
    label: 'Require Work Permit Resubmission',
    hint: 'Current Step',
    current: true,
  }
]

function SubstepRequireWorkPermitResubmission({t, getAsset, addAsset, getField, onChange, uploadFile, deleteDocument, nextStep, isPromise, isLoading, addError, getError, clearError, addToArrayInAssets}) {
  const classes = useStyles();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id && document.approval !== 0 && document.approval !== 1));
  const [confirm, toggleConfirm] = useDialog();

  const uploadDocumentsError = getError('resubmit_mol_job_odder_sign');

  function onConfirm() {
    const fields = [];
    uploadedDocuments.forEach((item) => {
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })
    const messages = ['Resubmit signed job mol offer'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    toggleConfirm();
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function onUpload(data) {
    clearError('resubmit_mol_job_odder_sign')
    return uploadFile(data);
  }

  function validate() {
    let error = false
    if (uploadedDocuments.length === 0) {
      addError('resubmit_mol_job_odder_sign', t('tracker:please_upload_document'));
      error = true
    }
    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload}/>
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            { getAsset('rejectionReasons') &&
              <Fragment>
                <Grid item xs={12} md={6} lg={5}>
                  <RejectionReasons rejectionReasons={getAsset('rejectionReasons')}/>
                </Grid>
                <Grid item md={6} lg={7}> </Grid>
              </Fragment>
            }
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_document')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          { (documents.length === 0 && uploadedDocuments.length === 0) &&
            <UploadFile/>
          }
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default SubstepRequireWorkPermitResubmission;
