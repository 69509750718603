import React, {useEffect, useContext} from 'react'
import { Card, Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons/';
import Allowance from './Allowance';
import AppTextField from "../../../../../components/AppTextField";
import {EmployeeContext} from "../../../EmployeesContainer";


const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bolder",
    fontSize: "14px",
    color: "#222222",
    padding: "10px 10px",
    textTransform: "uppercase",
  },
  textFields: {
    padding: "24px"
  },
  accordion: {
    boxShadow: "none !important",
  },
  accordionTitle: {
    fontWeight: 'bolder',
    '@media (max-width:960px)': {
      fontSize: "0.8rem"
    }
  },
  accordionDetailsNoPadding: {
    "&.MuiAccordionDetails-root": {
      padding: "0 0 0 0"
    }
  },
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  }
}));

function SalaryInfo() {
  const {t, addAsset, getAsset, onChange, getField, getError, clearError, convertToFloatAndCalculate, addError} = useContext(EmployeeContext);
  const classes = useStyles();

  const housing_allowance = getField('housing_allowance', '');
  const education_allowance = getField('education_allowance', '');
  const transportation_allowance = getField('transportation_allowance', '');
  const utility_allowance = getField('utility_allowance', '');
  const mobile_allowance = getField('mobile_allowance', '');
  const technical_allowance = getField('technical_allowance', '');
  const social_allowance = getField('social_allowance', '');
  const child_allowance = getField('child_allowance', '');
  const other_allowance = getField('other_allowance', '');
  const basic_salary = getField('basic_salary', '');

  useEffect(() => {
    const total_package = convertToFloatAndCalculate(housing_allowance, education_allowance, transportation_allowance, utility_allowance,  mobile_allowance, technical_allowance, social_allowance, child_allowance, other_allowance, basic_salary);
    onChange(null, 'total_package', total_package === '' ? ' ' : total_package);
  }, [housing_allowance, education_allowance, transportation_allowance, utility_allowance, mobile_allowance, technical_allowance, social_allowance, child_allowance, other_allowance, basic_salary])

  return (
    <Card elevation={1}>
      <CardHeader className={classes.header} title={t('employees:salary_info')} />
      <Grid container spacing={3} className={classes.textFields}>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="position" label={t('employees:position')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField addError={addError} noNegative type={'number'} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="basic_salary" label={t('employees:basic_salary')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField disabled type={'number'} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="total_package" label={t('employees:total_package')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="notice_period" label={t('employees:notice_period')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField noNegative type={'number'} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="no_of_days_off" label={t('employees:no_of_days_off')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField noNegative type={'number'} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="probation_period" label={t('employees:probation_period')} helperText={t('employees:probation_period_helper_text')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <AppTextField noNegative type={'number'} getError={getError} clearError={clearError} getField={getField} onChange={onChange} name="annual_leave" label={t('employees:annual_leave')} helperText={t('employees:annual_leave_helper_text')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Accordion expanded={getAsset('salaryExpand', false)} onChange={() => addAsset('salaryExpand', !getAsset('salaryExpand', false))} className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMore />} id="accordion">
              <Typography variant="body1" className={classes.accordionTitle}>
                {t('employees:accordion_title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetailsNoPadding}>
              <Allowance t={t} getField={getField} onChange={onChange} getError={getError} addError={addError} clearError={clearError} />
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Grid>
      </Grid>
    </Card>
  )
}

export default SalaryInfo
