import React from "react";
import {CompaniesPageRoutes} from "../../navigation";

function CompaniesView(props) {
  return (
    <CompaniesPageRoutes {...props}/>
  )
}

export default CompaniesView;
