import axios from "axios";
import {transformResponse} from "./helpers";

export const uploadDocumentService = ({data, config} = {}) => {
  // user_id, step_id, candidate_id, name and file are required fields in body
  return axios({method: 'post', url: `/documents`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDocumentsService = ({data, config} = {}) => {
  return axios({method: 'get', url: `/documents`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateDocumentService = ({data, config, params} = {}) => {
  const documentId = (params || {}).documentId;
  return axios({method: 'put', url: `/documents/${documentId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const deleteDocumentService = ({data, config, params} = {}) => {
  const documentId = (params || {}).documentId;
  return axios({method: 'delete', url: `/documents/${documentId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getDocumentUploadTypeService = ({data, config} = {}) => {
  return axios({method: 'get', url: `/documents-upload-type`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}
