import {format} from "date-fns";
import SelectDependOnFields from "../Constants/SelectDependOnFields";
import selectDependOnFields from "../Constants/SelectDependOnFields";

export function formatDate(date) {
  if (!date) return '-'
  date = new Date(date);
  if (!isDateValid(date)) return '-'
  return format(date, 'dd/MM/yyyy');
}

export function isDateValid(date) {
  return date instanceof Date && !isNaN(date);
}

export function snakeCaseFormat(str, upper = true) {
  const words = str.split("_");
  if (!upper) {
    const str = words.join(' ');
    return  str[0].toUpperCase() + str.substring(1);
  }

  return words.map((word) => {
    return word[0].toUpperCase() + word.substring(1);
  }).join(" ");
}

export function getFieldAttributes(field, attribute, fallback) {
  return (field?.attributes || {})[attribute] ?? fallback;
}

export function getFieldRoles(field, role, fallback) {
  return (field?.roles || {})[role] ?? fallback;
}

export function hasFieldRole(field, role) {
  return !!(field?.roles || {})[role];
}

export function getSelectFieldValues(field, fallback = [], getField = () => {}) {
  if (field.type !== 'select') return fallback;
  const dependOnField = getFieldAttributes(field, 'depend_on_field', null);
  let values = field?.value
  if (!Array.isArray(values)) return []

  let dependValues = [];
  let allDependValues = [];

  if (dependOnField) {
    const depSpecifiedValuesObj = selectDependOnFields[field.name][dependOnField] || {}
    const depFieldValue = getField(dependOnField, null);

    if (depFieldValue) dependValues = depSpecifiedValuesObj[depFieldValue] || [];
    Object.keys(depSpecifiedValuesObj).forEach(key => {
      allDependValues = [...allDependValues, ...(depSpecifiedValuesObj[key] || [])]
    })
    values = values.filter(value => dependValues.includes(value) || !allDependValues.includes(value));
  }

  if (!Array.isArray(values)) return []
  return values.map(value => ({text: value, value: value}))
}

export function getAllRadioControlledFields(radioFields) {
  if (!radioFields && radioFields.length === 0) return null;
  let fields = [];
  const roles = radioFields.reduce((acc, radioField) => ({...acc, ...(radioField.roles || {})}), {});
  const values = radioFields.reduce((acc, radioField) => [...acc, ...(radioField.value || [])], []);
  values.forEach((fieldValue) => {
    const fieldsToShow = roles[fieldValue] || [];
    fields = [...fields, ...fieldsToShow];
  })
  return fields
}

export function parseJSON(str) {
  let json = null;
  try {
    json = JSON.parse(str);
  } catch (e) {
    return str
  }
  return json;
}

export function closeApp() {
  const myWindow = window.open("", "_self");
  myWindow.document.write("");
  setTimeout (function() {myWindow.close();},1000);
}
