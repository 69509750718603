import axios from "axios";
import {transformResponse} from "./helpers";

export const getEmployeeStepService = ({data, config, params} = {}) => {
  const employeeId = (params || {}).employeeId;
  return axios({method: 'get', url: `/step/candidate/${employeeId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getAllStepsService = ({data, config} = {}) => {
  return axios({method: 'get', url: `/steps`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getSingleStepService = ({data, config, params} = {}) => {
  const stepId = (params || {}).stepId;
  return axios({method: 'get', url: `/steps/${stepId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateEmployeeStepService = ({data, config, params} = {}) => {
  return axios({method: 'put', url: `/step/candidate/${params.id}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getAllProcessesService = ({data, config} = {}) => {
  return axios({method: 'get', url: `/processes`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}


