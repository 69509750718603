import React from "react";
import EmployeeDocuments from "./EmployeeDocuments";

function EmployeeDocumentsPage() {
  return (
    <EmployeeDocuments/>
  )
}

export default EmployeeDocumentsPage;
