import React, { Fragment, useState } from 'react'
import {Grid, Typography, Button, useMediaQuery, Hidden} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {VisibilityOutlined, Done, CancelOutlined, RemoveCircleOutlineOutlined} from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import AppButton from '../../../../../../components/AppButton';
import AppTextField from '../../../../../../components/AppTextField';
import clsx from "clsx";
import AppTextWithHint from "../../../../../../components/AppTextWithHint";
import {usePromise} from "react-use";
import useUpload from "../../../../../../Hooks/useUpload";
import useDialog from "../../../../../../Hooks/useDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
    // paddingBottom: '25px',
    borderBottom: '1px solid #E0E0E0',
  },
  buttonReject: {
    backgroundColor: '#ED9936',
    '&:hover': {
      backgroundColor: '#9e5d13 !important'
    }
  },
  buttonDelete: {
    padding: 0,
    color: '#BD1C1A',
    '&:hover': {
      backgroundColor: 'rgba(173, 60, 26, 0.05)',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: 'rgba(173, 60, 26, 0.5)',
    }
  },
  rejected: {
    backgroundColor: '#EDAF67 !important',
    boxShadow: 'inset 0px 0px 10px #8C4646 !important',
    border: '1px solid #B98D8C !important',
    color: '#ffffff !important',
  },
  approved: {
    backgroundColor: '#C6DBDF !important',
    boxShadow: 'inset 0px 0px 10px #4E757C !important',
    border: '1px solid #92B3B9 !important',
    color: '#6A9198 !important',
  },
  dialogPosition: {
    position: 'absolute',
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  textField: {
    minWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '200px',
    }
  },
  'grid-el': {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  }
}));


function AppApproveDocument({ clearError, onChange, getField, name, size, approved, rejection_reason, disabled, file = {}, approve = () => { }, reject = () => { }, onDelete = () => {},  isPromise = (data) => {}, getError = () => {}, addError = () => {}, onReview = () => {}, getAsset }) {
  const { t } = useTranslation();
  const { convertSize } = useUpload();
  const classes = useStyles();
  const [confirm, toggleConform] = useDialog();
  const [isApproveLoading, setApproveLoading] = useState(false);
  const [isRejectLoading, setRejectLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const rejectionKey = `${file.id}DocumentRejection`

  const docTypes = getAsset('documentTypes', []);

  function getDocType(value) {
    const type = docTypes.find(t => t.value === value);
    return (type || {}).text || '-';
  }

  const approveDocument = () => {
    onReview(file);
    const result = approve({ document: file, approval: 1 });

    if (isPromise(result)) {
      setApproveLoading(true);
      result.then(() => {
        setApproveLoading(false);
      })
    }
  }

  const rejectDocument = () => {
    onReview(file);
    const rejection_reason = getField(rejectionKey);

    if (!rejection_reason) {
      addError(rejectionKey, 'Required Field', `${t('required_field', { name: t('tracker:rejection_reason') })}`)
      return
    }

    const result = reject({ document: file, approval: 0, rejection_reason });

    if (isPromise(result)) {
      setRejectLoading(true);
      result.then(() => {
        setRejectLoading(false);
      })
    }
  }

  const deleteDocument = () => {
    const result = onDelete({ documentId: file.id });
    toggleConform();

    if (isPromise(result)) {
      setIsDeleteLoading(true);
      result.catch(() => {
        setIsDeleteLoading(false);
      })
    }
  }

  return (
    <Fragment>
      <Grid className={classes.root} container spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={12} lg={"auto"} className={classes.padding} style={{ paddingBottom: isMdDown ? '0' : '18px' }}>
          <Grid container alignItems={"center"} className={classes["grid-el"]} spacing={3}>
            <Grid item xs={12} sm={"auto"} className={classes.textPadding}>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={12} sm={'auto'}>
                  <Button
                    component={'a'}
                    target="_blank"
                    href={`${process.env.REACT_APP_API_URL}${file.file}`}
                    startIcon={<VisibilityOutlined />}
                    style={{ color: '#1A97AD', paddingLeft: '3px' }}>
                    {name}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <span> Type: {getDocType(file.upload_type)} </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{paddingBottom: 0, paddingTop: 0}} item xs={12} sm={"auto"} align={isXsDown ? 'start' : 'end'} className={classes.textPadding}>
              <Typography variant="subtitle1">
                <Hidden smUp>
                  <span>Size: </span>
                </Hidden>
                {convertSize(size)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              {
                (approved !== 0) &&
                <AppTextField
                  style={{marginBottom: '-18px'}}
                  className={classes.textField}
                  getError={getError}
                  onChange={onChange}
                  getField={getField}
                  clearError={clearError}
                  name={rejectionKey}
                  multiline
                  label={t('tracker:reason_for_rejection')} />
              }
              {
                approved === 0 &&
                <AppTextField
                  style={{marginBottom: '-18px'}}
                  className={classes.textField}
                  value={rejection_reason || ''}
                  disabled
                  variant={'standard'}
                  multiline
                  label={t('tracker:reason_for_rejection')} />
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={"auto"}>
          <Grid container justify={isMdDown ? "center" : "flex-start"} alignItems={"center"} spacing={2} style={{ marginBottom: '3px' }}>
            <Grid item>
              <AppButton
                isLoading={isApproveLoading}
                disabled={approved === 1 || (disabled && !isApproveLoading)}
                className={clsx({
                  [classes.approved]: approved === 1
                })}
                startIcon={<Done />}
                onClick={approveDocument}>
                {t('tracker:button_approve')}
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton
                isLoading={isRejectLoading}
                // !!getError(rejectionKey)
                disabled={approved === 0 || (disabled && !isRejectLoading)}
                className={clsx({
                  [classes.rejected]: approved === 0
                }, classes.buttonReject)}
                onClick={rejectDocument}
                startIcon={<RemoveCircleOutlineOutlined />}>
                {t('tracker:button_reject')}
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton disabled={disabled} isLoading={isDeleteLoading} variant={'text'} className={classes.buttonDelete} onClick={toggleConform} >
               <Grid container justify={'center'} align={'center'}>
                <Grid item>
                  <CancelOutlined style={{ marginRight: '10px', marginLeft: '5px', marginTop: '8px'}}/>
                </Grid>
                 <Grid item>
                   <AppTextWithHint style={{marginRight: '-30px'}} align={'start'} justify={'flex-start'} noUppercase={true} lineHeight={'18px'} text={'Delete'} hint={'I don’t need this file'}/>
                 </Grid>
               </Grid>
              </AppButton>
            </Grid>
          </Grid>
        </Grid>
        <AppConfirmDialog open={confirm} text={`Are you sure you want to delete ${file.name}`} onCancel={toggleConform} onConfirm={deleteDocument} />
      </Grid>
    </Fragment>
  )
}

export default React.memo(AppApproveDocument);
