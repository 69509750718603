import axios from "axios";
import {useState, useRef} from "react";
import { serialize } from 'object-to-formdata';
import {isString} from "lodash";
import {useDispatch} from "react-redux";
import {setGlobalLoading} from "../redux/actions/infoActions";
import {useSnackbar} from "notistack";
import { FilesystemDirectory, Capacitor } from "@capacitor/core";
import { writeFile } from "capacitor-blob-writer";
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import {snakeCaseFormat} from "../utils/utilFinctions";

function useAPI() {
  const dispatch = useDispatch();
  const loadingCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const cancelTokenSource = axios.CancelToken;
  const cancelTokens = useRef([]);
  const { enqueueSnackbar } = useSnackbar();

  const createCancelToken = (tokenName) => {
    const cancelToken = cancelTokenSource.source();
    cancelToken.name = tokenName;
    cancelTokens.current =  [...cancelTokens.current, cancelToken]
    return cancelToken.token;
  }

  const transformErrors = (err, errorName, error_name_prefix = '') => {
    console.log(err, errorName)
    const message = ((err.response || {}).data || {}).message;
    const isMessageString = isString(message);
    const isMessageArray = Array.isArray(message);
    const errors = {};

    if (isMessageString) {
      let msg = message;
      msg = msg.replaceAll('_', ' ');
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
      setError(() => ({globalMessage: msg}));
      enqueueSnackbar(msg, { variant: "error" });
    }

    if(isMessageArray && errorName && message.length === 1) {
      errors[errorName] = snakeCaseFormat(message[0].message, false);
      setError(errors);
    }

    if(isMessageArray && !errorName) {
      message.forEach(item => {
        let msg = item.message;
        if (msg) {
          msg = msg.replaceAll('_', ' ');
          msg = msg.charAt(0).toUpperCase() + msg.slice(1);
          enqueueSnackbar(msg, { variant: "error" });
        }
        errors[`${error_name_prefix}${item.field}`] = msg
      })
      setError(errors);
    }
  }

  const getError = (field) => {
    return error[field]
  }

  const clearError = (field) => {
    const errors = {...error};
    delete errors[field];
    setError(errors);
  }

  const deleteCancelToken = (sourceName) => {
    let newSources = [...cancelTokens.current];
    newSources = newSources.filter( source => source.name !== sourceName);
    cancelTokens.current = newSources || [];
  }

  const manageLoading = (add, global) => {
    if (add) loadingCount.current ++;
    if (!add && loadingCount.current > 0) loadingCount.current --;
    if (loadingCount.current > 0) {
      if (global) {
        dispatch(setGlobalLoading(true))
      } else {
        setIsLoading(true);
      }
    } else {
      if (global) {
        dispatch(setGlobalLoading(false))
      } else {
        setIsLoading(false);
      }
    }
  }

  const sendRequest = async (apiEvent, { payload, cancelable = true, params, query = {}, loading, useFormData, errorName, noCache, globalLoading, namePrefix, errorNamePrefix, download, fileName } = {}) => {
    const cancelToken = createCancelToken(apiEvent.name)
     if (loading || globalLoading) manageLoading(true, globalLoading);

    const config = {
      cancelToken,
      params: {...query, ...(noCache && {'cache-control': 'no-cache'})},
    }

    if (namePrefix) {
      const newPayload = {};
      Object.keys(payload).forEach(key => {
        newPayload[`${namePrefix}${key}`] = payload[key];
      })
      payload = newPayload;
    }

    try {
      const data  = useFormData ? serialize(payload) : payload;
      const responseData = await apiEvent({ data, config, params });
      deleteCancelToken(cancelToken.name);
      if (loading || globalLoading) manageLoading(false, globalLoading);
      if (download) {
        if (responseData.data.message) throw new Error(responseData.data.message);
          downloadFile(responseData.data, fileName, responseData.headers);
      }
      return responseData;
    } catch (err) {
      if(err.message === 'canceled') {
        return Promise.reject()
      }
      transformErrors(err, errorName, errorNamePrefix);
      if (loading || globalLoading) manageLoading(false, globalLoading);
      deleteCancelToken(cancelToken.name);
      throw err;
    }
  }

  const downloadCapacitorFile = async (blob, fileName) => {

     const res = await writeFile({
      path: fileName,
      directory: Capacitor.platform === 'ios' ? FilesystemDirectory.Documents : FilesystemDirectory.ExternalStorage,
      data: blob,
      recursive: true,
    });

    let type = blob.type;
    if (Capacitor.platform === 'ios') {
      type = blob.type.split(';')[0];
    }

    FileOpener.open(res.uri, type)
      .then(() => console.log('File is opened'))
      .catch(e => console.log('Error opening file', e));
     return res.uri;
  }

  const downloadFile = async (response, fileName, headers) => {
    const contentDisposition = headers['content-disposition']
    const realFileName = ((contentDisposition || '').split('filename=')[1] || '').replaceAll('"', '');

    if (Capacitor.platform !== 'web') {
      await downloadCapacitorFile(response, `${realFileName || fileName}`);
      return response;
    }
    const uri = URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = uri;
    link.setAttribute('download', `${realFileName || fileName}`);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(uri);
    link.remove();
    return response;
  }

  const downloadFileFromUrl = (url, name) => {
    try {
      const container = document.createElement('div');
      container.innerHTML = `<a id="js-download-link" href="${url + 1}" download="${name}" rel="noopener noreferrer"></a>`
      const link =container.querySelector('#js-download-link');
      document.body.appendChild(container);
      link.addEventListener('click', (e) => {
        console.log(e)
        e.stopPropagation();
      })
      link.click();
      URL.revokeObjectURL(url);
      container.remove();
    } catch (e) {

    }
  }

  const cancelAllRequests = (tokens = cancelTokens.current) => {
    tokens.forEach(token => token.cancel('canceled'));
  }

  return {
    isLoading,
    error,
    sendRequest,
    clearError,
    cancelTokens: cancelTokens.current,
    getError,
    cancelAllRequests,
    getApiError: getError,
    clearApiError: clearError,
    transformErrors,
    downloadFileFromUrl,
  };
}

export default useAPI;

