import React, { Fragment } from 'react'
import { Camera } from '@ionic-native/camera';
import AppButton from "./AppButton";
import {PhotoCameraOutlined} from "@material-ui/icons";
import { isPlatform } from '@ionic/react'

function AppCamera({onCapture = () => {}, ...rest}) {
  function onTakePicture() {
    const options = {
      quality: 100,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE
    }

    Camera.getPicture(options).then((imageData) => {
      const url = "data:image/jpeg;base64," + imageData;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "File name.jpeg",{ type: "image/jpeg" })
          console.log('Image Data => ', file, blob);
          onCapture(null, file);
        })
      // Do something with the new photo
      // console.log('Image Data => ', imageData);
      // onCapture(null, imageData);

    }, (err) => {
      // Handle error
      console.log("Camera issue: " + err);
    });
  }

  return (
    <Fragment>
      { isPlatform("cordova") &&
        <AppButton {...rest} startIcon={<PhotoCameraOutlined />} onClick={onTakePicture}>Take A Picture</AppButton>
      }
    </Fragment>
  )
}

export default AppCamera;
