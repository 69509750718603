import React from "react";
import AppImportEmployeesDialog from "./ImportEmployeesDialog";

function ImportEmployeesDialog(props) {
  return (
    <AppImportEmployeesDialog {...props}/>
  )
};

export default ImportEmployeesDialog;
