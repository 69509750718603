import React from 'react';
import { Card, CardHeader, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import AppButton from 'components/AppButton';
import AppInfoDialog from 'components/Dialogs/AppInfoDialog';
import AppStepper from '../components/AppStepper';
import AppPickDateField from 'components/AppPickDateField';
import useDialog from "../../../../../../Hooks/useDialog";
import AppAlertDialog from "../../../../../../components/Dialogs/AppAlertDialog";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import AppTextField from "../../../../../../components/AppTextField";


const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
}));

const steps = [
  {
    label: 'Job MOL Offer Signature Review',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Work Permit Approval',
    hint: 'Current Step',
    current: true
  }
];
// STEP 9
function StepCreateWorkPermitApprovalOnMohre({ getAsset, addAsset, getError, addError, onChange, getField, nextStep, clearError }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const [alert, toggleAlert] = useDialog();
  const stepFields = getAsset('stepFields', []);

  function onConfirm() {
    const error = validate();

    if (!error) {
      const fields = stepFields.map(step => {
        return {field: step.name, value: getField(step.name)}
      });
      const messages = ['Work Permit on MOHRE craeted'];

      nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    }
    toggleConfirm();
  }

  function validate() {
    let error = false;

    if (!getField('work_permit_mb_number')) {
      addError('work_permit_mb_number', 'Required Field', t('required_field', {name: 'MB Number' }));
      error = true
    }

    if (!getField('work_permit_paid_fees')) {
      addError('work_permit_paid_fees', 'Required Field', t('required_field', {name: 'Paid fees' }));
      error = true
    }

    if (!getField('date_create_work_permit')) {
      addError('date_create_work_permit', 'Required Field', t('required_field', {name: t('tracker:date_of_creating_work_permit') }));
      error = true
    }

    if (getError('work_permit_paid_fees')) {
      error = true;
    }

    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={7}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} lg={2}>
            <NextButton />
          </Grid>
        </Hidden>
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="date_create_work_permit" label={t('tracker:date_of_creating_work_permit')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={'work_permit_mb_number'} label={'MB Number'} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField price noNegative type={'number'} getError={getError} addError={addError} clearError={clearError} onChange={onChange} getField={getField} name={'work_permit_paid_fees'} label={'Paid fees'} variant="filled" fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>
      {/* Dialog */}
      <AppAlertDialog open={alert} onOk={toggleAlert} contentText={t('tracker:tracker_step_9_dialog_content_text')}/>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepCreateWorkPermitApprovalOnMohre
