import React, { Fragment } from 'react';
import {Grid, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    background: 'rgba(0,0,0,0.05)',
    margin: 0
  },
  view: {
    padding: '0 !important',
    [theme.breakpoints.up("sm")]: {
      '&.MuiGrid-item': {
        marginTop: '30px',
        marginBottom: '30px'
      }
    }
  }
}))

function EmptyView({children}) {
  const theme = useTheme();
  const classes = useStyles();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const alignItems = isLargerScreen ? 'center' : 'flex-start';

  return (
    <Fragment>
      <Grid className={classes.root} container alignItems={alignItems} justify="center" spacing={1}>
         <Grid className={classes.view} item xs={12} sm={8} md={6} lg={4} xl={3}>
           {children}
         </Grid>
      </Grid>
    </Fragment>
  );
}

export default EmptyView;
