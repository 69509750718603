import React, {Fragment, useState} from 'react';
import {Card, CardHeader, FormControlLabel, Grid, Hidden, Radio, RadioGroup, Typography} from "@material-ui/core";
import AppStepper from "./components/AppStepper";
import AppConfirmDialog from "../../../../../components/Dialogs/AppConfirmDialog";
import {makeStyles} from "@material-ui/core/styles";
import AppButton from "../../../../../components/AppButton";
import useDialog from "../../../../../Hooks/useDialog";
import AssignUserToEmployee from "../../../../../components/Dynamics/DynamicsAssignUserToEmployee";
import {
  getAllRadioControlledFields,
  getFieldAttributes,
  getFieldRoles,
  hasFieldRole,
  snakeCaseFormat
} from "../../../../../utils/utilFinctions";
import UploadedDocument from "./components/UploadedDocument";
import AppUploadDocument from "./components/AppUploadDocument";
import AppSimpleUploadFile from "../../../../../components/AppSimpleUploadFile";
import useUpload from "../../../../../Hooks/useUpload";
import clsx from "clsx";
import AppApproveDocument from "./components/AppApproveDocument";
import DynamicsRegularField from "../../../../../components/Dynamics/DynamicsRegularField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
  },
  error: {
    color: theme.variables.errorTextColor,
  },

  warning: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    textTransform: "uppercase",
    color: "#bd0808",
    marginBottom: '15px'
  }
}));

function DynamicStep({t, getAsset, getField, getUsers, onChange, addError, handleNextStep, getError, clearError, approveDocumentApi, assignUserToEmployee, clearUserIdField, addAsset, isPromise, addToArrayInAssets, uploadFile, deleteDocument, approveOrRejectDocument}) {
  const classes = useStyles();
  const stepFields = getAsset('stepFields', []);
  const stepTitle = getAsset('stepTitle', '');
  const documents = getAsset('documents', []);
  const step_id = getAsset('candidate_step_id');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => document.candidate_step_id == step_id && document.approval !== 0 && document.approval !== 1);
  const documentsToReview = getAsset('uploadedDocuments', []).filter((item) => (item.approval !== 1 || item.approvedLocally === 1));
  const [isLoading, setLoading] = useState(false);
  const [confirm, toggleConfirm] = useDialog();
  const { handleUpload, getFileSize } = useUpload(onChange, addToArrayInAssets);

  const uploadDocumentFieldName = `step_${step_id}_upload_documents_required`;
  const hasUploadError = getError(uploadDocumentFieldName);

  const radioFields = stepFields.filter(field => field.type === 'radio').filter(radioField => {
    const processes = getFieldRoles(radioField, 'processes', null);
    if (processes && processes.show) {
      const processId = getField('process_id', null);
      if (!processes.show.includes(processId)) return false;
    }
    const hiddenForField = getFieldRoles(radioField, 'hidden_for_field', null);
    if (!hiddenForField) return true;
    const hiddenForFieldValue = getFieldRoles(radioField, 'hidden_for_field_value', null);
    const employeeFieldValue = getField(hiddenForField);
    return hiddenForFieldValue != employeeFieldValue;
  });
  const radioShownFields = radioFields.reduce((acc, radioField) => [...acc, ...((radioField?.roles || {})[getField(radioField?.name)] || [])], []);
  const allRadioButtonControlledFields = getAllRadioControlledFields(radioFields);

  const assignFields = stepFields.filter(field => hasFieldRole(field, 'assign_user'));

  const fileField = stepFields.find(field => {
    if (radioFields.length > 0 && radioShownFields && allRadioButtonControlledFields.includes(field.name) && !radioShownFields.includes(field.name)) return false;
    const isFileType = field.type === 'file';
    if (!isFileType) return false;
    const hiddenForField = getFieldRoles(field, 'hidden_for_field', null);
    if (!hiddenForField) return true;
    const hiddenForFieldValue = getFieldRoles(field, 'hidden_for_field_value', null);
    const employeeFieldValue = getField(hiddenForField);
    return hiddenForFieldValue != employeeFieldValue;
  });
  const fileFieldWarning = fileField ? getFieldRoles(fileField, 'warning', null) : null;

  const reviewField = stepFields.find(field => field.type === 'documents_review');

  const regularFields = stepFields
    .filter(field => !field.name.includes('assign_') && field.type !== 'file' && field.type !== 'documents_review' && field.type !== 'radio')
    .filter(field => {
      if (radioFields.length > 0 && radioShownFields && allRadioButtonControlledFields.includes(field.name) && !radioShownFields.includes(field.name)) return false;
      const hiddenForField = getFieldRoles(field, 'hidden_for_field', null);
      if (!hiddenForField) return true;
      const hiddenForFieldValue = getFieldRoles(field, 'hidden_for_field_value', null);
      const employeeFieldValue = getField(hiddenForField);
      return hiddenForFieldValue != employeeFieldValue;
    })

  async function onConfirm() {
    toggleConfirm();
    try {
      setLoading(true);
      const [assignUsersFields, assignedUsersMessages] = await prepareAssignUsers();
      const [uploadedFilesFields, uploadedFilesMessages] = prepareUploadedFiles();
      const [reviewFields, reviewMessages, reviewPassed] = await prepareReviewFields();
      const [radioFields, radioMessages, radioPayload] = prepareRadioField();
      const [regularFields, regularFieldsMessages, regularFieldsPayload] = await prepareRegularFields();

      const fields = [...regularFields, ...assignUsersFields, ...uploadedFilesFields, ...reviewFields, ...radioFields];

      const messages = [stepTitle, ...assignedUsersMessages, ...uploadedFilesMessages, ...reviewMessages, ...radioMessages, ...regularFieldsMessages];

      const preparePayload = {...regularFieldsPayload, ...radioPayload};
      const payload = Object.keys(preparePayload).length > 0 ? preparePayload : null;
      await handleNextStep({content: JSON.stringify({fields, messages}), resubmission: reviewPassed ? 0 : 1, additional_documents: 0}, payload);
      setLoading(false);
    } catch (e) {
      console.log('error => ', e)
      setLoading(false);
    }
  }

  async function prepareRegularFields() {
    const fields = [];
    const messages = [];
    const payload = {};

    regularFields.forEach(field => {
      fields.push({field: field.name, value: getField(field.name)})
      const saveToField = getFieldAttributes(field, 'save_to_field', null);
      const saveCustomValues = getFieldAttributes(field, 'save_custom_values', {});

      if (saveToField) {
        payload[saveToField] = saveCustomValues[getField(field.name)] || getField(field.name);
      }
    });

    return [fields, messages, payload];
  }

  async function prepareAssignUsers() {
    if (assignFields.length === 0) return [[], []];
    const mapFieldNames = {
      assign_data_entrist: "data_entrist_id",
      assign_typist: 'typist_id',
      assign_insurance_person: 'medical_insurance_id',
      assign_pro: 'pro_id',
    }
    const mappedName = mapFieldNames[assignFields[0].name];
    await assignUserToEmployee(mappedName || assignFields[0].name, getField('user_id'));
    const fields = [{
      field: assignFields[0].name,
      value: getField('user_id'),
    }]

    const role = getFieldRoles(assignFields[0], 'assign_user', null);

    const messages = [`${snakeCaseFormat(role)} Assigned`];

    return [fields, messages];
  }

  function prepareUploadedFiles() {
    const fields = [];
    const messages = [];

    uploadedDocuments.forEach((item) => {
      if (getFieldAttributes(fileField, 'auto_approve', true)) approveDocumentApi({ documentId: item.id, approval: 1 });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })

    addAsset('RejectionReasons', null);
    return [fields, messages];
  }

  async function prepareReviewFields() {
    let fields = [];
    let messages = [];
    let passed = true;
    if(!reviewField) return [fields, messages, passed];

    documentsToReview.forEach(item => {
      console.log('review item => ', item);
      if (item.approval === 0) {
        passed = false;
        fields.push({
          field: 'file',
          upload_type: item.upload_type,
          file_name: item.name,
          rejection_reason: `${item.rejection_reason}`,
        })
      } else {
        fields.push({
          field: 'document_id',
          value: item.id,
        })
      }
    })

    const status = passed ? 'approved' : 'rejected';
    messages.push(`${stepTitle} ${status}`);

    const requests = [];
    documentsToReview.filter(document => document.approval === 0).forEach((file) => {
      requests.push(deleteDocument({ documentId: file.id }));
    })

    documentsToReview.filter(document => document.approval === 1).forEach((file) => {
      requests.push(approveDocumentApi({ documentId: file.id }));
    })

    await Promise.all(requests);

    fields.push({
      field: reviewField.name,
      value: status,
    })
    return [fields, messages, passed]
  }

  function prepareRadioField() {
    const fields = [];
    const messages = [];
    const payload = {};
    radioFields.forEach((radioField) => {
      if (!radioField) return [fields, messages]

      fields.push({
        field: radioField.name,
        value: getField(radioField.name)
      })

      messages.push(`${radioField.label} - ${snakeCaseFormat(getField(radioField.name))}`)

      const saveToField = getFieldAttributes(radioField, 'save_to_field', null);
      const saveCustomValues = getFieldAttributes(radioField, 'save_custom_values', {});

      if (saveToField) {
        payload[saveToField] = saveCustomValues[getField(radioField.name)] ?? getField(radioField.name);
      }
    })

    return [fields, messages, payload];
  }

  function goToNextStep() {
    const fieldsError = areFieldsValid();
    if (fieldsError) toggleConfirm();
  }

  function areFieldsValid() {
    let isValid = true;

    regularFields.forEach((field) => {
      if (field.required !== 1) return;
      if (!getField(field.name)) {
        addError(field.name, 'Required Field',  t('required_field', {name: field.label }));
        isValid = false;
      }
    })

    if (assignFields.length > 0) {
      const assignField = assignFields[0];
      const isRequired = assignField.required === 1;
      if (!getField('user_id') && isRequired) {
        addError('user_id', 'Required Field',  t('required_field', {name: assignField.label }));
        isValid = false;
      }
    }

    if (fileField) {
      if (fileField.required !== 1) return;
      const isRequired = fileField.required === 1;
      if (uploadedDocuments.length === 0 && isRequired) {
        addError(uploadDocumentFieldName, 'Please Upload Documents');
        isValid = false;
      }
    }

    if (reviewField) {
      const notReviewedFile = documentsToReview.find(item => item.approval !== 0 && item.approval !== 1);
      if (notReviewedFile) {
        addError(uploadDocumentFieldName, t('tracker:please_review_all_documents'));
        isValid = false;
      }
    }

    if (radioFields.length >0) {
      radioFields.forEach(radioField => {
        if (!radioField.value.includes(getField(radioField.name))) {
          addError(radioField.name, 'Required Field',  t('required_field', {name: radioField.label }));
          isValid = false;
        }
      })
    }

    return isValid;
  }

  function onUpload(data) {
    clearError(uploadDocumentFieldName);
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function PickFile() {
    return (
      <AppSimpleUploadFile single={getFieldAttributes(fileField, "single", false)} handleUpload={handleUpload}/>
    )
  }

  function UploadFileInfo() {
    return (
      <Grid item xs={"auto"} style={{paddingTop: 0, paddingBottom: 0}}>
        { fileFieldWarning &&
            <Typography variant="body1" className={classes.warning}>
              {fileFieldWarning}
            </Typography>
        }
        <Typography variant="body1" className={clsx({[classes.error]: hasUploadError})}>
          {fileField.label}
        </Typography>
        <Typography variant="body2">
          {t('tracker:allowed_file_types')}
        </Typography>
      </Grid>
    )
  }

  function NextButton(props) {
    return (<AppButton {...props} fullWidth isLoading={isLoading} onClick={goToNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={
        <Grid container alignItems={"center"} justify={"space-between"}>
          <Grid align={"center"} item xs={"auto"}>
            {t('tracker:tracker-uppercase')}
          </Grid>
          <Hidden mdDown>
            <Grid item xs={"auto"}>
              <NextButton/>
            </Grid>
          </Hidden>
        </Grid>
      }/>
      <Grid  alignItems={"center"} className={classes.gridPadding} container spacing={5}>
        <Grid item xs={12} lg={12}>
          <AppStepper stepTitle={stepTitle}/>
        </Grid>

        {/* region Assign User to Employee */}
        {assignFields.map(field => {
          return (<AssignUserToEmployee key={field.id} field={field}
                                        label={field.label} getField={getField}
                                        getError={getError} getAsset={getAsset}
                                        clearError={clearError} getUsers={getUsers}
                                        onChange={onChange}
                                        clearUserIdField={clearUserIdField} />)
        })}
        {/* endregion */}

        {/*region Radio Button*/}
        { radioFields.map((radioField, index) =>
            <Grid key={radioField.name} item xs={12} style={{paddingTop: 0, paddingBottom: '10px'}}>
              <Typography variant="body1" style={{ fontWeight: 600 }} className={clsx({[classes.error]: getError(radioField.name)})}>
                {radioField.label}
              </Typography>
              <RadioGroup name={radioField.name} value={getField(radioField.name)} onChange={(event) => {onChange(event); clearError(radioField.name)}}>
                <Grid item xs={12} >
                  {radioField.value.map(value => <FormControlLabel key={value} value={value} control={<Radio color="primary" />} label={snakeCaseFormat(value)} />)}
                </Grid>
              </RadioGroup>
            </Grid>
        )}
        {/*endregion*/}

        {/*region File Upload*/}
        { fileField &&
          <Fragment>
            <UploadFileInfo/>
            <Grid item xs={12} style={{paddingBottom: '5px'}}>
              { (!getFieldAttributes(fileField, 'single', false) || (documents.length === 0 && uploadedDocuments.length === 0)) && <PickFile/>}
              {/* Uploaded Documents */}
              { uploadedDocuments.map((document, index) =>
                <UploadedDocument key={document.id} getAsset={getAsset}
                                  addAsset={addAsset} label={document.name}
                                  index={index} size={document.size}
                                  onDelete={handleDocumentDelete} getField={getField}
                                  file={document} isPromise={isPromise}/>
              )
              }
              {/* Document To Be uploaded */}
              {documents.map((document, index) =>
                <AppUploadDocument getAsset={getAsset} addAsset={addAsset}
                                   addError={addError} key={document.id}
                                   name={document.id} label={document.name}
                                   index={index} size={getFileSize(document)}
                                   onDelete={handleDocumentDelete} onCancel={handleDocumentCancel}
                                   getField={getField} onChange={onChange}
                                   uploadFile={onUpload} file={document}
                                   getError={getError} clearError={clearError}
                                   isPromise={isPromise}/>
              )}
            </Grid>
          </Fragment>
        }
        {/*endregion*/}

        {/*region Review File*/}
        { reviewField &&
          <Fragment>
            <Grid item xs={12} className={'pt-0'} style={{paddingBottom: 0, paddingTop: '5px'}}>
              <Typography variant="body1" className={clsx({[classes.error]: hasUploadError})}>
                {t('tracker:check_the_uploaded_documents')}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 0}}>
              {documentsToReview.map((document) =>
                <AppApproveDocument approve={approveOrRejectDocument} reject={approveOrRejectDocument}
                                    file={document} key={document.id} name={document.name}
                                    size={document.size} getAsset={getAsset}
                                    addAsset={addAsset} onChange={onChange}
                                    getField={getField} approved={document.approval}
                                    rejection_reason={document.rejection_reason} getError={getError}
                                    addError={addError} clearError={clearError}
                                    isPromise={isPromise} onDelete={deleteDocument}
                                    onReview={() => (clearError(uploadDocumentFieldName))}
                />
              )}
            </Grid>
          </Fragment>
        }
        {/*endregion*/}

        {/*region Regular Field - Text, Number, TextArea, Select, Checkbox*/}
        { regularFields.map((field) =>
            <DynamicsRegularField key={field.id} getField={getField}
                                  getError={getError} addError={addError}
                                  clearError={clearError} onChange={onChange}
                                  name={field.name} type={field.type}
                                  label={field.label} field={field}
                                  noDatesInFuture={getFieldAttributes(field, 'no_future_date', false)} // Attribute for date type
                                  noDatesInPast={getFieldAttributes(field, 'no_past_date', false)} // Attribute for date type
                                  noNegative={getFieldAttributes(field, 'no_negative', false)} // Attribute for number type
                                  price={getFieldAttributes(field, 'price', false)}/> // Attribute for number type
        )}
        {/*endregion*/}

        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>

      </Grid>
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default DynamicStep;
