import React, {useRef} from 'react';
import EmptyView from "../../views/EmptyView";
import AppButton from "../../components/AppButton";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { FormControlLabel, Checkbox, Link, useTheme, useMediaQuery } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./styles";

import logo from 'assets/img/WFCH.png'
import {useTranslation} from "react-i18next";
import AppTextField from "../../components/AppTextField";


export default function LoginView({login, isLoading, rememberMe, checkRememberMe, onChange, getField, error}) {
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const buttonSize = isXs ? 'large' : 'medium';
  const { t } = useTranslation();
  const emailHelperText = error ? t('errorMsg:auth') : ' ';
  const passwordInput = useRef(null);

  return (
    <EmptyView>
      <Card elevation={1} className={classes.root}>
        <CardHeader title={t('login')} />
        <CardContent>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid className={classes.logo} item xs={12} align="center">
              <img src={logo} alt="logo" style={{width: '50%'}}/>
            </Grid>
            <Grid item xs={12} sm={9}>
              <AppTextField onEnter={() => passwordInput.current.focus()} error={error} helperText={emailHelperText} getField={getField} onChange={onChange} name={"email"} label={t('email')}/>
            </Grid>
            <Grid item xs={12} sm={9}>
              <AppTextField ref={passwordInput} onEnter={login} type={'password'} helperText={''} getField={getField} onChange={onChange} name="password" label={t('password')} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container>
                <Grid item align="start" xs={6}><FormControlLabel control={<Checkbox color="primary" onChange={checkRememberMe} checked={rememberMe}/>} label={t('remember_me')}/></Grid>
                <Grid item align="end" xs={6} className={classes.forgotPassword}><Link component={RouterLink} to={'forgot-password'}>{t('forgot_password')}</Link></Grid>
                <Grid item xs={12} sm={3} className={classes.loginButton}>
                  <AppButton isLoading={isLoading} size={buttonSize} fullWidth variant="contained" color="secondary" onClick={login}>{t('login')}</AppButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
         </CardContent>
      </Card>
    </EmptyView>
  );
}
