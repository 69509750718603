import React from "react";
import {Grid, Typography, TextField} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { format } from 'date-fns'
import DateFnsUtils from "@date-io/date-fns";

function AppPickDates({getField = () => {}, onChange = () => {}, name, label}) {
  const maxDate = { ...(getField(`${name}_to`, null) && { maxDate: getField(`${name}_to`, null) }) }
  const minDate = { ...(getField(`${name}_from`, null) && { minDate: getField(`${name}_from`, null) }) }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Typography>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{paddingTop: 0}}>
          <DatePicker
            fullWidth
            name={name}
            disableToolbar
            variant="fill"
            format="dd/MM/yyyy"
            {...maxDate}
            margin="normal"
            label="From"
            value={getField(`${name}_from`, null)}
            TextFieldComponent={(props) => <TextField {...props} variant={"filled"}/>}
            onChange={(event,value) => onChange(event, `${name}_from`, format(event, 'yyyy/MM/dd') )}/>
        </Grid>
        <Grid item xs={6} style={{paddingTop: 0}}>
          <DatePicker
            fullWidth
            name={name}
            disableToolbar
            variant="fill"
            format="dd/MM/yyyy"
            {...minDate}
            margin="normal"
            label="to"
            value={getField(`${name}_to`, null)}
            TextFieldComponent={(props) => <TextField {...props} variant={"filled"}/>}
            onChange={(event,value) => onChange(event, `${name}_to`, format(event, 'yyyy/MM/dd') )}/>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default AppPickDates;
