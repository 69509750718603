import React, {Fragment, useEffect} from 'react';
import {Card, Grid, Typography, Button, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import useUpload from "../../../../../../Hooks/useUpload";
import AppButton from "../../../../../../components/AppButton";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";
import AppStepper from "../components/AppStepper";
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppCheckbox from "../../../../../../components/AppCheckbox";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: '',
    hint: 'Previous Step',
    completed: true,
  },
  {
    label: 'COLLECT RESIDENCE PERMIT',
    hint: 'Current Step',
    current: true,
  }
]

function StepCollectResidencePermitId64({t, getAsset, addAsset, getField, onChange, uploadFile, deleteDocument, nextStep, isPromise, addError, getError, clearError, addToArrayInAssets, isLoading,  approveDocumentApi}) {
  const classes = useStyles();
  const { handleUpload, getFileSize} = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const stepFields = getAsset('stepFields');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id));
  const [confirm, toggleConfirm] = useDialog();
  const residence_permit_expiry = getField('residence_permit_expiry');

  useEffect(() => {
    onChange(null, 'residence_permit_expire_date', getField('residence_permit_expiry'));
  }, [residence_permit_expiry])

  const uploadErrorFieldName = 'step_labour_card_submission.jsx';

  const checkboxName = 'confirm_residence_permit';
  const checkboxLabel = 'Confirm collected residence permit';

  const dateFieldName = 'issue_date_residence_permit';
  const dateFieldLabel = 'Issue Date of Residence Permit';

  const dateField2Name = 'residence_permit_expire_date';
  const dateField2Label = 'Residence Permit Expiry Date';

  const message = 'Residence permit collected'


  const uploadDocumentsError = getError(uploadErrorFieldName);

  function onConfirm() {
    const fields = [];

    stepFields.forEach(field => {
      if (field === 'file') return;
      fields.push({ field: field.name, value: getField(field.name)})
    });

    uploadedDocuments.forEach((item) => {
      approveDocumentApi({ documentId: item.id, approval: 1 });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })
    const messages = [message]

    const stepPayload = {content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0}
    const employeePayload = {residence_permit_expiry: getField('residence_permit_expire_date')}

    nextStep(stepPayload, employeePayload);
    toggleConfirm();
  }

  function onUpload(data) {
    clearError(uploadErrorFieldName);
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function validate() {
    let error = false
    if (uploadedDocuments.length === 0) {
      addError(uploadErrorFieldName, 'Please Upload Document');
      error = true
    }

    if (!getField(dateFieldName)) {
      addError(dateFieldName, 'Required Field',  t('required_field', {name: dateFieldLabel }));
      error = true
    }

    if (!getField(dateField2Name)) {
      addError(dateField2Name, 'Required Field',  t('required_field', {name: dateField2Label }));
      error = true
    }

    if (!getField(checkboxName)) {
      addError(checkboxName, `Confirm ${checkboxLabel}`);
      error = true;
    }

    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" multiple type="file" onChange={handleUpload}/>
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:button_add_documents')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3}>
          <AppCheckbox getError={getError} clearError={clearError} getField={getField} onChange={onChange} name={checkboxName} label={checkboxLabel} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_document')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadFile/>
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateFieldName} label={dateFieldLabel} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppPickDateField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateField2Name} label={dateField2Label} />
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepCollectResidencePermitId64;
