import React from "react";
import MainViewContainer from "./MainViewContainer";

function MainView(props) {
  return (
    <MainViewContainer {...props}/>
  )
}

export default MainView;
