import React from "react";
import ForgotPasswordContainer from "./ForgotPasswordContainer";

function ForgotPassword() {
  return (
    <ForgotPasswordContainer/>
  )
}

export default ForgotPassword;
