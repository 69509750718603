import React from "react";
import {Card, Grid, Typography} from "@material-ui/core";
import logo from "../../assets/img/WFCH.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    minHeight: 'min(100vh, 550px)',
    height: 'min(100vh, 550px)',
    [theme.breakpoints.down("xs")]: {
      marginTop: '0',
      height: '100vh',
    }
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));
function CandidateFormDone() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container justify="center" alignItems={"center"} align={"center"} spacing={3} style={{height: "100%", overflow: 'auto'}}>
        <Grid item xs={12} align="center" style={{paddingBottom: 0 }}>
          <img src={logo} alt="logo" style={{width: '50%'}}/>
          <Typography variant={"h6"} style={{marginTop: '75px', paddingLeft: '50px', paddingRight: '50px'}}>
            Thank you for submitting the documents. Our team will be in touch with you if any further information is required.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CandidateFormDone;
