import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {format, parse, toDate} from 'date-fns'
import DateFnsUtils from "@date-io/date-fns";
import AppTextField from "./AppTextField";

function AppPickDateField({ getField = () => { }, onChange = () => { }, clearError = () => { }, name, label, getError = () => {}, noDatesInFuture = false, noDatesInPast = false, helperText = ' ' }) {
  const error = getError(name);

  function handleChange(event) {
    onChange(event, name, format(event, 'yyyy-MM-dd'))
    if (error) {
      clearError(name);
    }
  }

  const optional = {
    ...(noDatesInFuture && {maxDate: new Date()}),
    ...(noDatesInPast && {minDate: new Date()})
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...optional}
        style={{ marginTop: 0, }}
        fullWidth
        name={name}
        variant="fill"
        format="dd/MM/yyyy"
        margin="normal"
        label={label}
        value={getField(name, null)}
        onChange={handleChange}
        helperText={error || ' '}
        error={!!error}
        TextFieldComponent={(props) => <AppTextField {...props} helperText={helperText} getError={getError} name={name} variant={"filled"} />} />
    </MuiPickersUtilsProvider>
  )
}

export default AppPickDateField;
