import React from 'react'
import { Card, CardHeader, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightOutlined } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import AppTextField from 'components/AppTextField';
import AppStepper from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppStepper';
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px'
  },
}));

const steps = [
  {
    label: 'Assign Typist',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'Create Job MOL Offer',
    hint: 'Current Step',
    current: true
  }
];

function StepCreateMolJobOffer({t, getAsset, getField, onChange, getError, addError, clearError, nextStep = () => {}, clearFields }) {
  const classes = useStyles();
  const stepFields = getAsset('stepFields');
  const [confirm, toggleConfirm] = useDialog();

  function onConfirm() {
    toggleConfirm();
    const fields = stepFields.map(step => {
      return {field: step.name, value: getField(step.name)}
    });
    const messages = ['Job Mol Offer Created'];

    nextStep({content: JSON.stringify({ fields, messages }), resubmission: 0, additional_documents: 0});
    clearFields(['job_mol_offer_submission_date', 'job_mol_offer_reference_number'])

  }

  function validate() {
    let error = false;
    if (!getField('job_mol_offer_submission_date')) {
      addError('job_mol_offer_submission_date', 'Required Field', t('required_field', {name: t('tracker:date_of_the_submission') }));
      error = true
    }

    if (!getField('job_mol_offer_reference_number')) {
      addError('job_mol_offer_reference_number', 'Required Field', t('required_field', {name: t('tracker:reference_number') }));
      error = true;
    }
    return error;
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')} />
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12} lg={5}>
          <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps} />
        </Grid>
        <Hidden mdDown>
          <Grid item lg={2}>
            <NextButton/>
          </Grid>
        </Hidden>
        <Grid item xs={12} style={{ marginTop: '50px' }}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="job_mol_offer_submission_date" label={t('tracker:date_of_the_submission')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="job_mol_offer_reference_number" label={'MOL Transaction Ref Number'} placeholder={'MOL Transaction Ref Number'} variant="filled" fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth />
          </Grid>
        </Hidden>
      </Grid>

      {/* Dialog */}
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepCreateMolJobOffer;
