import React from "react";
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import AppTextField from "./AppTextField";
import {format, parse} from "date-fns";

function AppTimePickerField({ getField = () => { }, onChange = () => { }, clearError = () => { }, name, label, getError = () => {}, ...rest }) {
  const error = getError(name);

  function handleChange(event, a, b) {
    onChange(null, name, format(event, 'HH:mm'))
    if (error) {
      clearError(name);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        style={{ marginTop: 0, }}
        fullWidth
        name={name}
        variant="fill"
        margin="normal"
        label={label}
        format={'HH:mm'}
        value={ getField(name, null) ? parse(getField(name, null), 'HH:mm', new Date()) : null}
        onChange={handleChange}
        helperText={error || ' '}
        error={!!error}
        {...rest}
        TextFieldComponent={(props) => <AppTextField {...props} getField={getField} getError={getError} name={name} variant={"filled"} />} />

    </MuiPickersUtilsProvider>
  )
}

export default AppTimePickerField;
