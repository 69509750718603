import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import AppTextField from "../../../components/AppTextField";
import { Accordion,  AccordionDetails,  AccordionSummary,  Grid,  makeStyles,  useMediaQuery,  useTheme } from "@material-ui/core";
import AppButton from "../../../components/AppButton";
import AppAutocomplete from "../../../components/AppAutocomplete";
import {FilterList} from "@material-ui/icons";
import useForm from "../../../Hooks/useForm";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  dropdown: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
  },
  searchButtonGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  filterList: {
    transition: 'all 0.3s'
  },
  rotate: {
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)',
  }
}))

function TableHeaderFilter({ title, onSearch = () => {}, onExport = () => {}, companies = [], steps = [], isExportLoading = false }, ref) {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { addField, getField, onChange, } = useForm();

  useEffect(() => {
    if (smDown) addField('expanded', false);
    if (!smDown) addField('expanded', true);
  }, [smDown]);

  useImperativeHandle(ref, () => ({
    isExpanded: getField('expanded'),
    getPayload: () => {
      const fullName = getField('fullName', null);
      const status = getField('status', null);
      const companyId = getField('companyId', null);
      return {
        ...(fullName && { full_name: fullName }),
        ...(status && { status }),
        ...(companyId && { company_id: companyId }),
      }
    }
  }));

  const customSteps = [];
  const stepsDropdown = [...(steps || []), ...customSteps];

  return (

    <Grid container>
      <Grid item xs={12}>
        <Accordion className={classes.dropdown} expanded={getField('expanded', false)}>
          <AccordionSummary style={{display: "none"}}/>
          <AccordionDetails className={classes.dropdown}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid className={classes.root} container spacing={1} direction={smDown ? 'column' : 'row'}>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <AppTextField  size={'small'} noLabel placeholder={'Search'} onChange={onChange} getField={getField} name={'fullName'}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <AppAutocomplete
                          size={'small'}
                          noLabel={true}
                          placeholder={'Status'}
                          onChange={onChange}
                          getField={getField}
                          name={'status'}
                          items={stepsDropdown}
                          getOptionLabel={(option) => option.title }/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <AppAutocomplete
                          size={'small'}
                          noLabel={true}
                          placeholder={'Company'}
                          onChange={onChange}
                          getField={getField}
                          name={'companyId'}
                          items={companies}
                          getOptionLabel={(option) => option.title }/>
                      </Grid>
                      <Grid className={classes.searchButtonGrid} item xs={12} sm={12} md={'auto'} align={'end'}>

                        { smDown &&
                          <AppButton style={{marginRight: '12px'}} isLoading={isExportLoading} onClick={onExport} size={'small'}>
                            Export
                          </AppButton>
                        }

                        <AppButton onClick={onSearch} size={'small'}>
                          Search
                        </AppButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              { !smDown &&
                <Grid item xs={12} sm={2}>
                  <Grid container>
                    <Grid className={classes.searchButtonGrid} item xs={12} align={'end'}>
                      <AppButton isLoading={isExportLoading} onClick={onExport} size={'small'}>
                        Export
                      </AppButton>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify={'space-between'}>
          <Grid item>
            {title}
          </Grid>
          { smDown &&
            <Grid item>
              <FilterList className={clsx({ [classes.filterList]: true, [classes.rotate]: getField('expanded') })} onClick={() => addField('expanded',!getField('expanded', false))}/>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default forwardRef(TableHeaderFilter);
