import axios from "axios";
import {transformResponse} from "./helpers";


export const getCompaniesService = ({data, config} = {}) => {
  return axios({method: 'get', url: `/companies`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const getCompanyByIdService = ({data, config, params} = {}) => {
  const companyId = (params || {}).companyId;
  return axios({method: 'get', url: `/companies/${companyId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const createCompanyService = ({data, config} = {}) => {
  return axios({method: 'post', url: `/companies`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const updateCompanyService = ({data, config, params} = {}) => {
  const companyId = (params || {}).companyId;
  return axios({method: 'put', url: `/companies/${companyId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

export const deleteCompanyService = ({data, config, params} = {}) => {
  const companyId = (params || {}).companyId;
  return axios({method: 'delete', url: `/companies/${companyId}`, data, ...(config || {})}).then(response => {
    return transformResponse(response);
  })
}

