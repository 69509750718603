import React from "react";
import LoginView from "./LoginView";
import useAuth from "../../Hooks/useAuth";
import useForm from "../../Hooks/useForm";
import {useEffectOnce} from "react-use";
import useRedirect from "../../Hooks/useRedirect";
import useUser from "../../Hooks/useUser";

function LoginContainer() {
  const {goToDashboard, goToEmployees} = useRedirect();
  /*useEffectOnce(() => {
    goToDashboard();
  })*/

  const {login, isLoading, rememberMe, setRememberMe, error} = useAuth();

  const { onChange, getField, fields } = useForm();
  const { isRecruiter } = useUser();

  const checkRememberMe = (event) => {
    setRememberMe(event.target.checked);
  }

  function onLogin() {
    login(fields).then((res) => {
      if (res) {
       if (isRecruiter(res)) goToEmployees();
      }
    })
  }

  return (
    <LoginView
      error={error}
      getField={getField}
      login={onLogin}
      isLoading={isLoading}
      rememberMe={rememberMe}
      checkRememberMe={checkRememberMe}
      onChange={onChange}/>
  )
}

export default LoginContainer
