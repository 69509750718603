import React, {Suspense} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainView from "../views/MainView";
import EmptyView from "../views/EmptyView";
import Dashboard from "../pages/Dashboard";
import Employees from "../pages/Employees";
import Companies from "../pages/Companies";
import Users from '../pages/Users';
import ActivityLogs from "../pages/ActivityLogs";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

// Employees Pages
import EmployeeFormNavigator from "../pages/Employees/components/EmployeeFormNavigator";
import EmployeesTable from "../pages/Employees/pages/EmployeesTable";
import EmployeeProfileForm from "../pages/Employees/pages/EmployeeProfilePage/EmployeeProfileForm";

// Company routes
import CompaniesTable from "../pages/Companies/components/CompaniesTable";
import CreateCompany from "../pages/Companies/components/CreateCompany";
import SingleCompany from "../pages/Companies/components/SingleCompany";

//User Routes
import UserProfile from '../pages/Users/pages/UserProfile';

// Constants
import {
  DASHBOARD,
  EMPLOYEES,
  COMPANIES,
  ACTIVITY_LOGS,
  FORGOT_PASSWORD,
  NEW_EMPLOYEE,
  EDIT_EMPLOYEE,
  NEW_COMPANY,
  EDIT_COMPANY,
  VIEW_COMPANY,
  VIEW_EMPLOYEE,
  PASSWORD_RESET_TOKEN,
  USERS,
  EDIT_USER,
  VIEW_USER,
  NEW_USER,
  NOTIFICATIONS,
  EDIT_NOTIFICATION,
  DOWNLOAD_FILE,
  EXPORT, CANDIDATE_UPLOAD, CANDIDATE_FORM
} from "./CONSTANTS";
import UsersTable from "../pages/Users/pages/UsersTable";
import EditUser from "../pages/Users/pages/EditUser";
import ViewUser from "../pages/Users/pages/ViewUser";
import Notifications from "../pages/Notifications";
import NotificationsTable from "../pages/Notifications/pages/NotificationsTable";
import NotificationForm from "../pages/Notifications/pages/NotificationForm";
import DownloadPage from "../pages/Download";
import ExportPage from "../pages/Export";
import MaintenancePage from "../pages/Mainenance";
import CandidateFormPage from "../pages/CandidateForm";
import WideEmptyView from "../views/WideEmptyView";


export const PrivateRoutesInMainView = ({View = MainView}) => {
  return (
  <Suspense fallback={<div/>}>
    <Switch>
      <PrivateRoute View={View} name={DASHBOARD} path={`/${DASHBOARD}`}>
        <Dashboard/>
      </PrivateRoute>
      <PrivateRoute View={View} name={EMPLOYEES} path={`/${EMPLOYEES}`}>
        <Employees/>
      </PrivateRoute>
      <PrivateRoute View={View} name={COMPANIES} path={`/${COMPANIES}`}>
        <Companies/>
      </PrivateRoute>
      <PrivateRoute View={View} name={ACTIVITY_LOGS} path={`/${ACTIVITY_LOGS}`}>
        <ActivityLogs/>
      </PrivateRoute>
      <PrivateRoute View={View} name={USERS} path={`/${USERS}`}>
        <Users/>
      </PrivateRoute>
      <PrivateRoute View={View} name={NOTIFICATIONS} path={`/${NOTIFICATIONS}`}>
        <Notifications/>
      </PrivateRoute>
      <PrivateRoute View={View} name={EXPORT} path={`/${EXPORT}`}>
        <ExportPage/>
      </PrivateRoute>
      <Route path="/" exact>
        <Redirect to={DASHBOARD}/>
      </Route>
    </Switch>
  </Suspense>
  )
}

export const PublicRoutesInEmptyView = ({View = EmptyView}) => {
  return (
  <Suspense fallback={<div/>}>
    <Switch>
      { process.env.REACT_APP_MAINTENANCE &&
      <PublicRoute View={View} name={'maintenance'} path={`/*`}>
        <MaintenancePage/>
      </PublicRoute>
      }
      <PublicRoute View={View} name={FORGOT_PASSWORD} path={`/${FORGOT_PASSWORD}`}>
        <ForgotPassword />
      </PublicRoute>
      <PublicRoute View={View} name={PASSWORD_RESET_TOKEN} path={`/${PASSWORD_RESET_TOKEN}`}>
        <ResetPassword />
      </PublicRoute>
      <PublicRoute View={View} name={DOWNLOAD_FILE} path={`/${DOWNLOAD_FILE}`}>
        <DownloadPage/>
      </PublicRoute>
      {/* Candidate Upload route should be removed, but we need to make sure that it is not in use anymore */}
      <PublicRoute View={WideEmptyView} name={CANDIDATE_UPLOAD} path={`/${CANDIDATE_UPLOAD}`}>
        <CandidateFormPage/>
      </PublicRoute>
      <PublicRoute View={WideEmptyView} name={CANDIDATE_FORM} path={`/${CANDIDATE_FORM}`}>
        <CandidateFormPage/>
      </PublicRoute>
    </Switch>
  </Suspense>
  )
}

export const EmployeesPageRoutes = (props) => {
  return (
    <Switch>
      <Route exact={true} path={`/${EMPLOYEES}`}>
        <EmployeesTable {...props}/>
      </Route>
      <Route exact path={`/${NEW_EMPLOYEE}`}>
        <EmployeeProfileForm standAlone {...props}/>
      </Route>
      <Route exact path={`/${EDIT_EMPLOYEE}`}>
        <EmployeeFormNavigator {...props}/>
      </Route>
      <Route exact path={`/${VIEW_EMPLOYEE}`}>
        <EmployeeFormNavigator readOnly {...props}/>
      </Route>
    </Switch>
)
}

export const CompaniesPageRoutes = (props) => {
  return (
    <Switch>
      <Route exact={true} path={`/${COMPANIES}`}>
        <CompaniesTable {...props}/>
      </Route>
      <Route exact path={`/${NEW_COMPANY}`}>
        <CreateCompany {...props}/>
      </Route>
      <Route exact path={`/${EDIT_COMPANY}`}>
        <CreateCompany {...props}/>
      </Route>
      <Route exact path={`/${VIEW_COMPANY}`}>
        <SingleCompany {...props}/>
      </Route>
    </Switch>
  )
}

export const UsersPagesRoutes = (props) => {
  return (
    <Switch>
      <Route exact path={`/${USERS}/me`}>
        <UserProfile {...props} />
      </Route>
      <Route exact path={`/${USERS}`}>
        <UsersTable {...props}/>
      </Route>
      <Route exact path={`/${EDIT_USER}`}>
        <EditUser {...props}/>
      </Route>
      <Route exact path={`/${VIEW_USER}`}>
        <ViewUser {...props}/>
      </Route>
      <Route exact path={`/${NEW_USER}`}>
        <EditUser {...props}/>
      </Route>
    </Switch>
  )
}

export const NotificationsPageRoutes = (props) => {
  return (
    <Switch>
      <Route exact path={`/${NOTIFICATIONS}`}>
        <NotificationsTable {...props} />
      </Route>
      <Route exact path={`/${EDIT_NOTIFICATION}`}>
        <NotificationForm {...props} />
      </Route>
    </Switch>
  )
}


