import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '35px',
    [theme.breakpoints.down("xs")]: {
      minHeight: '100vh',
    },
   /* '& .MuiTextField-root': {
      margin: `${theme.spacing(1)}px 0`,
      width: '100%',
    },*/
    '& .MuiCardHeader-root': {
      paddingTop: 'max(16px, env(safe-area-inset-top, 12px))',
      borderBottom: '1px solid #E0E0E0',
    },
    '& .MuiTypography-root.MuiCardHeader-title': {
      fontSize: '14px',
      textTransform: 'uppercase'
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    }
  },
  logo: {
    marginBottom: '38px',
    marginTop: '38px'
  },
  forgotPassword: {
    marginTop: '10px'
  },
  loginButton: {
    marginTop: '5px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '90px',
    }
  },
}));

export default useStyles;
