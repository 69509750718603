import React from 'react'
import ResetPasswordView from './ResetPasswordView';
import { useTranslation } from "react-i18next";
import useForm from 'Hooks/useForm';
import useAPI from 'Hooks/useAPI';
import useRouteParams from 'Hooks/useRouteParams';
import { PASSWORD_RESET_TOKEN } from 'navigation/CONSTANTS';
import { resetPasswordService } from 'services/loginService';
import useRedirect from 'Hooks/useRedirect';


// $2y$10$6Q9plspnAXI7wkvaT1wPN.6B7efmHH46z9wUzzEIzCuWM5668xN8G

function ResetPasswordContainer() {
  const { t } = useTranslation();
  const { getField, onChange, fields, addError, getLocalError, clearLocalError } = useForm();
  const { sendRequest, isLoading, getApiError, clearApiError } = useAPI();
  const { token } = useRouteParams(`/${PASSWORD_RESET_TOKEN}`);
  const { goToDashboard } = useRedirect();
  
  function getError(field, reserved) {
    return getApiError(field) || getLocalError(field) || reserved;
  }

  function clearError(field) {
    clearLocalError(field);
    clearApiError(field);
  }

  const confirmResetPassword = () => sendRequest(resetPasswordService, { payload: {...fields, token}, loading: true, }).then(({ data }) => {
    // ako je potrebna neka logika Markova ovde, ako ne direktno na Login
    goToDashboard()
  });
  
  return (
    <ResetPasswordView 
      getError={getError}
      addError={addError}
      clearError={clearError}
      confirmResetPassword={confirmResetPassword}
      getField={getField}
      onChange={onChange}
      isLoading={isLoading}
      t={t}
      token={token}
    />
  )
}

export default ResetPasswordContainer
