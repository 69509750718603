import React, {useRef, useContext} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  useTheme,
  useMediaQuery,
  Hidden
} from "@material-ui/core";
import AppImageUpload from "components/AppImageUpload";
import {makeStyles} from "@material-ui/core/styles";
import AppButton from "components/AppButton";
import AppAutocomplete from "components/AppAutocomplete";
import AppTextField from "../../../../../components/AppTextField";
import {ExpandMore} from "@material-ui/icons";
import PermanentRejectDialog from "./PermanentRejectDialog";
import clsx from "clsx";
import {EmployeeContext} from "../../../EmployeesContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '24px 25px',
    '@media (max-width: 800px)': {
      paddingTop: '75px'
    }
  },
  imageItem: {
    paddingRight: '0 !important',
  },
  saveButton: {
    position: 'absolute',
    top: '15px',
    right: '24px',
    minWidth: '140px',
    '@media (max-width: 800px)': {
      width: 'calc(100% - 48px)'
    }
  },
  expandButton: {
    '& .MuiButtonBase-root': {
      height: '56px',
      width: '56px',
      minWidth: '56px',
      transition: 'all 0.3s'
    }
  },
  expanded: {
    transform: 'rotate(180deg)'
  },
  dropdown: {
    boxShadow: 'none',
    padding: 0,
  },
  permanent_rejection: {
    backgroundColor: "#BD1C1A",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#941513 !important'
    }
  }
}))

function BasicInfo() {
  const {t, onChange, getAsset, getField, saveEmployee, isLoading, getError, isRecruiter, clearError, addError, addAsset, isVisaAdmin, isSuperAdmin, permanentReject, isEmail, isDataEntrist, isTypistSupervisor} = useContext(EmployeeContext);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const companies =  getAsset('companies', []);
  const basicInfoExpanded = getAsset('basicInfoExpanded', false);
  const step_id = (getField('step', [])[0] || {}).step_id ?? (getField('id', null) ? 9999 : -1);

  const isProcessFieldDisabled = !!getField('id', false);

  const permanentDialog = useRef(null);

  function validate() {
    let error = false;
    const fields = ['passport_number', 'first_name', 'last_name', 'company_id', 'basic_salary', 'housing_allowance', 'education_allowance', 'transportation_allowance', 'utility_allowance', 'mobile_allowance', 'technical_allowance', 'social_allowance', 'child_allowance', 'other_allowance', 'file' ];
    const priceFields = ['basic_salary', 'housing_allowance', 'education_allowance', 'transportation_allowance', 'utility_allowance', 'mobile_allowance', 'technical_allowance', 'social_allowance', 'child_allowance', 'other_allowance'];
    const messages = {
      company_id: `${t('required_field')} Company`,
      data_entrist_id: 'Data Entrist is Required',
      file: 'Employee Picture Is required',
    }

    if (getField('current_residency') === 'in') {
      fields.push('uid_number');
      fields.push('eid_number');
      fields.push('eid_expiry');
      fields.push('employee_number');
    }

    // Expand salary info because of possible validation errors
    addAsset('salaryExpand', true);

    if (!getField('email') || !isEmail(getField('email'))) {
      addError('email', 'Please enter a valid email');
      error = true;
    }

    if (isSuperAdmin() || isVisaAdmin()) {
      fields.push('data_entrist_id');
    }

    fields.forEach(field => {
      const value = getField(field);
      if (!value && value !== 0) {
        let displayField = field.replaceAll('_', ' ');
        displayField = `${t('required_field')} ${displayField.charAt(0).toUpperCase() + displayField.slice(1)}`;

        if (field === 'file' && !getAsset('image')) {
          addError(field, `${messages[field]}`);
          error = true;
        }

        if (field !== 'file') {
          addError(field, `${messages[field] || displayField}`, 'Fill All Required Fields');
          error = true;
        }
      }
    })


    priceFields.forEach(field => {
      if (getError(field)) {
        error = true;
      }
    })


    return error;
  }

  function onChangeCompany(e, name, value) {
    onChange(e, name, value);
  }

  function onSaveButton() {
    const error = validate();
    if (!error) saveEmployee();
  }

  function openPermanentRejectDialog() {
    return ((permanentDialog.current || {}).open || (() => {}))();
  }

  return (
    <Card className={classes.root} elevation={2}>
      <Grid container spacing={4} justify="center" alignItems="center">

        <AppButton onClick={onSaveButton} isLoading={isLoading} className={classes.saveButton} size="medium" variant="contained" color="secondary">
          {t('employees:save_employee')}
        </AppButton>
        <Grid className={classes.imageItem} item xs={12} lg={2} align="center">
          <AppImageUpload getError={getError} clearError={clearError} onChange={onChange} onBlur={onChange} name="file" imageUrl={getAsset('image')}/>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Grid container spacing={2} style={{marginBottom: 0}}>
            <Grid item xs={12} md={6} lg={3}>
              <AppAutocomplete
                getOptionLabel={(option) => option.title }
                items={getAsset('processes', [])}
                name={"process_id"}
                label={'Process'}
                getField={getField}
                getError={getError}
                clearError={clearError}
                onChange={onChange}
                disabled={isProcessFieldDisabled}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="last_name" label={t('employees:last_name')} />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={6} lg={3}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="first_name" label={t('employees:first_name')} />
              </Grid>
            </Hidden>
          </Grid>
          <Grid container spacing={2} style={{marginTop: 0}}>
            <Hidden lgUp>
              <Grid item xs={12} md={6} lg={3}>
                <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="first_name" label={t('employees:first_name')} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} lg={3}>
             <AppAutocomplete
               clearError={clearError}
               disabled={isRecruiter()}
               getError={getError}
               getOptionLabel={(option) => option.title }
               getField={getField}
               onChange={onChangeCompany}
               items={companies}
               name="company_id"
               label={t('employees:company')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppTextField clearError={clearError} getError={getError} getField={getField} onChange={onChange} name="business_unit" label={t('employees:business_unit')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppAutocomplete
                getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
                items={getAsset('data_entrists', [])}
                name={"data_entrist_id"}
                label={t('tracker:assign_data_entrist')}
                getField={getField}
                getError={getError}
                clearError={clearError}
                onChange={onChange}
                disabled={isDataEntrist() || isTypistSupervisor()}/>
            </Grid>

            {
              (isVisaAdmin() || isSuperAdmin()) &&
              <Grid align={isLgUp ? 'end' : 'middle'} item xs={12} md={12} lg={3} className={classes.expandButton}>
                <AppButton size={"small"} color={"primary"} onClick={() => addAsset('basicInfoExpanded', !basicInfoExpanded)}>
                  <ExpandMore className={clsx({[classes.expanded]: getAsset('basicInfoExpanded')})} style={{marginTop: '5px', transition: 'all 0.3s'}}/>
                </AppButton >
              </Grid>
            }

            {/* Dropdown */}
            {
              (isVisaAdmin() || isSuperAdmin()) &&
              <Grid item xs={12}>
                <Accordion className={classes.dropdown} expanded={basicInfoExpanded}>
                  <AccordionSummary style={{display: "none"}}/>
                  <AccordionDetails className={classes.dropdown}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <AppAutocomplete
                          disabled={step_id < 25}
                          getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
                          items={getAsset('pros', [])}
                          name={"pro_id"}
                          label={t('tracker:assign_pro')}
                          getField={getField}
                          onChange={onChange}/>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <AppAutocomplete
                          getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
                          items={getAsset('visa_admins', [])}
                          name={"visa_admin_id"}
                          label={t('tracker:assign_visa_admin')}
                          getField={getField}
                          onChange={onChange}/>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <AppAutocomplete
                          disabled={step_id < 35}
                          getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
                          items={getAsset('medical_insurances', [])}
                          name={"medical_insurance_id"}
                          label={t('tracker:assign_medical_insurance')}
                          getField={getField}
                          onChange={onChange}/>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <AppAutocomplete
                          disabled={step_id <= 4 || (!isSuperAdmin() && !isVisaAdmin() && !isTypistSupervisor())}
                          getOptionLabel={(option) => `${option.first_name} ${option.last_name}` }
                          items={getAsset('typists', [])}
                          name={"typist_id"}
                          label={t('tracker:assign_typist')}
                          getField={getField}
                          onChange={onChange}/>
                      </Grid>
                      {
                        step_id >= 0 &&
                        <Grid item xs={12} align={'right'}>
                          <AppButton className={classes.permanent_rejection} onClick={openPermanentRejectDialog}>
                            {t('employees:permanent_rejection')}
                          </AppButton>
                        </Grid>
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            }

          </Grid>
        </Grid>
      </Grid>
      <PermanentRejectDialog
        ref={permanentDialog}
        onConfirm={permanentReject}/**/
        onChange={onChange}
        getField={getField}
        clearError={clearError}
        addError={addError}
        getError={getError}/>
    </Card>
  )
}

export default BasicInfo
