import React, {useContext} from 'react'
import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EmployeeContext} from "../../../EmployeesContainer";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  },
  cardFullHeight: {
    height: "100%"
  },
  textFields: {
    padding: "0 24px 24px 24px",
    '& .MuiGrid-item': {
      marginTop: '20px'
    }
  },
  makeBolder: {
    fontWeight: '600',
    paddingRight: '10px',
  },
}));

function SalaryInfoReadOnly() {
  const {t, getField} = useContext(EmployeeContext);
  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.cardFullHeight}>
      <CardHeader title={t('employees:salary_info')} className={classes.header}/>
      <Grid container className={classes.textFields}>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:position`)}
          </Grid>
          <Grid item xs={6}>
            {getField('position', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:basic_salary`)}
          </Grid>
          <Grid item xs={6}>
            {getField('basic_salary', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:housing_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('housing_allowance', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:education_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('education_allowance', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:transportation_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('transportation_allowance', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:utillity_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('utility_allowance', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:mobile_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('mobile_allowance', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:technical_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('technical_allowance', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:social_allowance`)}
            <Typography variant="subtitle2" color="secondary" className={classes.makeBolder}>
              ({t(`employees:social_allowance_helper_text`)})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {getField('social_allowance', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:child_allowance`)}
            <Typography variant="subtitle2" color="secondary" className={classes.makeBolder}>
              ({t(`employees:child_allowance_helper_text`)})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {getField('child_allowance', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:other_allowance`)}
          </Grid>
          <Grid item xs={6}>
            {getField('other_allowance', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:total_package`)}
          </Grid>
          <Grid item xs={6}>
            {getField('total_package', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:notice_period`)}
          </Grid>
          <Grid item xs={6}>
            {getField('notice_period', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:no_of_days_off`)}
          </Grid>
          <Grid item xs={6}>
            {getField('no_of_days_off', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:probation_period`)}
            <Typography variant="subtitle2" color="secondary" className={classes.makeBolder}>
              ({t(`employees:probation_period_helper_text`)})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {getField('probation_period', '-')}
          </Grid>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:annual_leave`)}
            <Typography variant="subtitle2" color="secondary" className={classes.makeBolder}>
              ({t(`employees:annual_leave_helper_text`)})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {getField('annual_leave', '')}
          </Grid>

        </Grid>
    </Card>
  )
}

export default SalaryInfoReadOnly
