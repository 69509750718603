import React, {Fragment} from 'react';
import {Card, Grid, Typography, Button, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import AppUploadDocument from 'pages/Employees/pages/EmployeeProfilePage/Tracker/components/AppUploadDocument';
import useUpload from "../../../../../../Hooks/useUpload";
import AppButton from "../../../../../../components/AppButton";
import UploadedDocument from "../components/UploadedDocument";
import AppConfirmDialog from "../../../../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../../../../Hooks/useDialog";
import clsx from "clsx";
import AppStepper from "../components/AppStepper";
import AppPickDateField from "../../../../../../components/AppPickDateField";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: '',
    hint: 'Previous Step',
    completed: true,
  },
  {
    label: 'ISSUE LOCAL CONTRACT',
    hint: 'Current Step',
    current: true,
  }
]

function StepIssueLocalContractId72({t, getAsset, addAsset, getField, onChange, uploadFile, deleteDocument, nextStep, isPromise, addError, getError, clearError, addToArrayInAssets, isLoading,  approveDocumentApi}) {
  const classes = useStyles();
  const { handleUpload, getFileSize} = useUpload(onChange, addToArrayInAssets);
  const step_id = getAsset('candidate_step_id');
  const documents = getAsset('documents', []);
  const stepFields = getAsset('stepFields');
  const uploadedDocuments = getAsset('uploadedDocuments', []).filter(document => (document.candidate_step_id == step_id));
  const [confirm, toggleConfirm] = useDialog();

  const uploadErrorFieldName = 'step_issue_local_contract';

  const dateFieldName = 'local_contract_issue_date'
  const dateFieldLabel = 'Issue contract Date'

  const uploadDocumentsError = getError(uploadErrorFieldName);

  function onConfirm() {
    const fields = [];

    stepFields.forEach(field => {
      if (field.name === 'file') return;
      fields.push({field: field.name, value: getField(field.name)})
    });

    uploadedDocuments.forEach((item) => {
      approveDocumentApi({ documentId: item.id, approval: 1 });
      fields.push({
        field: 'file',
        upload_type: item.upload_type,
        value: item.name,
      })
    })
    const messages = ['Sponsor cancellation form submit']

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    toggleConfirm();
  }

  function onUpload(data) {
    clearError(uploadErrorFieldName);
    return uploadFile(data);
  }

  function handleDocumentDelete(data) {
    return deleteDocument(data);
  }

  function handleDocumentCancel(index) {
    const items = [...documents];
    items.splice(index,1);
    addAsset('documents', items);
  }

  function validate() {
    let error = false
    if (uploadedDocuments.length === 0) {
      addError(uploadErrorFieldName, 'Please Upload Document');
      error = true
    }

    if (!getField(dateFieldName)) {
      addError(dateFieldName, 'Required Field',  t('required_field', {name: dateFieldLabel }));
      error = true
    }
    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} disabled={isLoading} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  function UploadFile() {
    return (
      <Fragment>
        <input hidden={true} id="upload-file-button" type="file" onChange={handleUpload}/>
        <label htmlFor="upload-file-button">
          <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
            {t('tracker:upload_document_button')}
          </Button>
        </label>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={"auto"}>
              <Typography variant="body1" className={clsx({[classes.error]: uploadDocumentsError})}>
                {t('tracker:upload_document')}
              </Typography>
              <Typography variant="body2">
                {t('tracker:allowed_file_types')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          { (documents.length === 0 && uploadedDocuments.length === 0) &&
          <UploadFile/>
          }
          {/* Uploaded Documents */}
          { uploadedDocuments.map((document, index) =>
            <UploadedDocument
              key={document.id}
              getAsset={getAsset}
              addAsset={addAsset}
              label={document.name}
              index={index}
              size={document.size}
              onDelete={handleDocumentDelete}
              getField={getField}
              file={document}
              isPromise={isPromise}
            />
          )
          }
          {/* Document To Be uploaded */}
          {documents.map((document, index) =>
            <AppUploadDocument
              getAsset={getAsset}
              addAsset={addAsset}
              addError={addError}
              key={document.id}
              name={document.id}
              label={document.name}
              index={index}
              size={getFileSize(document)}
              onDelete={handleDocumentDelete}
              onCancel={handleDocumentCancel}
              getField={getField}
              onChange={onChange}
              uploadFile={onUpload}
              file={document}
              getError={getError}
              clearError={clearError}
              isPromise={isPromise}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <AppPickDateField noDatesInFuture getError={getError} clearError={clearError} onChange={onChange} getField={getField} name={dateFieldName} label={dateFieldLabel} />
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepIssueLocalContractId72;
