import React, { forwardRef } from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useEffectOnce} from "react-use";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '-15px',
    '& .MuiFilledInput-underline.Mui-error:after': {
      transform: 'scaleX(0)',
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFilledInput-underline.Mui-focused:after': {
      transform: 'scaleX(1)',
      borderBottomColor: theme.palette.primary,
    },
    '& .MuiFormLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: '45px', //do not overlap icon
      bottom: '0px', //maximize container height so label wont be cut horizontaly
    },

    '& .MuiInputLabel-shrink': {
      right: 'unset', //show complete label if shrinked
    },
  },
  hidden: {
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    '& .MuiFormHelperText-root': {
      display: 'none'
    },
  },
  small_NoLabel: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '6px'
    }
  }
}))

function AppTextField({name, getError = () => {}, addError = () => {}, getField = () => {}, clearError = () => {}, onChange = () => {}, onEnter= () => {}, defaultValue, noNegative, convertToString, errorNamePrefix = '', noLabel = false, price= false, priceMessage, ...rest}, ref) {
  const classes = useStyles();
  const error = getError(`${errorNamePrefix}${name}`);

  function handleChange(e) {
    let eventValue = e.target.value;
    if (noNegative && eventValue && !isNaN(eventValue)) eventValue = Math.abs(eventValue);
    if (convertToString && eventValue !== null && eventValue !== undefined) eventValue = `${eventValue}`

    onChange(null, name, eventValue);

    if (error) {
      clearError(`${errorNamePrefix}${name}`);
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      onEnter();
    }
  }

  function validatePrice (priceValue) {
    const regex = new RegExp(/^([0-9]{0,10}((.)[0-9]{0,6}))$/);
    return regex.test(priceValue)
  }

  function formatPrice(priceValue) {
    const formatter = new Intl.NumberFormat('en-us', {
      style: 'decimal',
      useGrouping: false,
      maximumFractionDigits: 6,
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  function validateAndFormatPrice(e) {
    if (!price) return;
    let eventValue = e.target.value;
    if (noNegative && eventValue) eventValue = Math.abs(eventValue);
    if (eventValue && !validatePrice(eventValue)) {
      addError(`${errorNamePrefix}${name}`, priceMessage || 'Price is not valid (ex. 25.00)');
      return;
    }
    eventValue = formatPrice(eventValue);
    setTimeout(() => {
      onChange(null, name, eventValue);
    }, 150)
    return e;
  }

  const value = getField(name);

  useEffectOnce(() => {
    if (defaultValue) {
      setTimeout(() => {
        onChange(null, name, defaultValue);
      }, 0)
    }
  })

  return (
    <TextField
      inputRef={ref}
      className={clsx([classes.root], {[classes.hidden]: rest.type === 'hidden', [classes.small_NoLabel]: rest.size === 'small' && noLabel })}
      value={value}
      onBlur={validateAndFormatPrice}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth variant="filled"
      helperText={error || ' '}
      error={!!error}
      name={name}
      {...rest}
    />
  )
}

export default forwardRef(AppTextField);
