import React, {Fragment} from "react";
import {Button} from "@material-ui/core";
import {PostAdd} from "@material-ui/icons";
import {useTranslation} from "react-i18next";

function AppSimpleUploadFile({handleUpload = () => {}, single = false}) {
  const {t} = useTranslation();

  return (
    <Fragment>
      <input hidden={true} id="upload-file-button" multiple={!single} type="file" onChange={handleUpload}/>
      <label htmlFor="upload-file-button">
        <Button variant="contained" color="secondary" component="span" startIcon={<PostAdd />} >
          {single ? t('tracker:upload_document_button') : t('tracker:button_add_documents')}
        </Button>
      </label>
    </Fragment>
  )
}

export default AppSimpleUploadFile
