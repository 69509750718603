import React from 'react';
import {Card, Grid, CardHeader, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppButton from "components/AppButton";
import AppConfirmDialog from "components/Dialogs/AppConfirmDialog";
import useDialog from "Hooks/useDialog";
import AppStepper from "../components/AppStepper";
import AppPickDateField from "../../../../../../components/AppPickDateField";
import AppTextField from "../../../../../../components/AppTextField";
import AppTimePickerField from "../../../../../../components/AppTimePickerField";
import AppCheckbox from "../../../../../../components/AppCheckbox";

const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: "0.88rem",
      color: "#222222",
    }
  },
  inputDisplay: {
    display: 'none',
  },
  gridPadding: {
    padding: '24px',
    paddingBottom: '52px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  error: {
    color: theme.variables.errorTextColor,
  }
}));

const steps = [
  {
    label: 'Complete BIO Application',
    hint: 'Previous Step',
    completed: true
  },
  {
    label: 'ReArrange BIO Scan',
    hint: 'Current Step',
    current: true
  }
];

function StepReArrangeBioScan({t, getField, onChange, nextStep, addError, getError, clearError, clearFields, getAsset}) {
  const classes = useStyles();
  const [confirm, toggleConfirm] = useDialog();
  const isSkipped = getField('skip_this_step', false);

  function onConfirm() {
    toggleConfirm();
    const fields = [];

    if (!isSkipped) {
      fields.push({
        field: 'date_bio_scan',
        value: getField('date_bio_scan'),
      })

      fields.push({
        field: 'time_bio_scan',
        value: getField('time_bio_scan'),
      })

      fields.push({
        field: 'bio_scan_address',
        value: getField('bio_scan_address'),
      })

      fields.push({
        field: 'bio_scan_phone',
        value: getField('bio_scan_phone'),
      })

      fields.push({
        field: 'bio_scan_paid_fees',
        value: getField('bio_scan_paid_fees'),
      })
    }

    fields.push({
      field: 'skip_this_step',
      value: getField('skip_this_step', false),
    })
    const messages = ['BIO Scan appointed'];

    nextStep({content: JSON.stringify({fields, messages}), resubmission: 0, additional_documents: 0});
    clearFields(['date_bio_scan', 'bio_scan_address' ,'bio_scan_phone' , 'time_bio_scan', 'bio_scan_paid_fees']);

  }

  function validate() {
    let error = false;
    if(isSkipped) return error;

    if (!getField('date_bio_scan')) {
      addError('date_bio_scan', 'Field Required', t('required_field', {name: t('tracker:date_of_biometric_scan') }));
      error = true;
    }
    if (!getField('bio_scan_address')) {
      addError('bio_scan_address', 'Field Required', t('required_field', {name: t('tracker:address') }));
      error = true;
    }
    if (!getField('bio_scan_phone')) {
      addError('bio_scan_phone', 'Field Required', t('required_field', {name: t('tracker:phone') }));
      error = true;
    }
    if (!getField('time_bio_scan')) {
      addError('time_bio_scan', 'Field Required', t('required_field', {name: t('tracker:time') }));
      error = true;
    }
    if (!getField('bio_scan_paid_fees')) {
      addError('bio_scan_paid_fees', 'Field Required', t('required_field', {name: 'Paid fees' }));
      error = true;
    }
    if (getError('bio_scan_paid_fees')) {
      error = true;
    }

    return error
  }

  function handleNextStep() {
    const error = validate();
    if (!error) toggleConfirm();
  }

  function NextButton(props) {
    return (<AppButton {...props} onClick={handleNextStep}>{t('tracker:button_go_to_next_step')}</AppButton>)
  }

  return (
    <Card>
      <CardHeader className={classes.heading} title={t('tracker:tracker-uppercase')}/>
      <Grid className={classes.gridPadding} container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"} justify={"center"}>
            <Grid item xs={12} md={12} lg={8}> <AppStepper stepTitle={getAsset('stepTitle', '')} steps={steps}/> </Grid>
            <Hidden mdDown> <Grid item xs={12} md={4}> <NextButton/> </Grid> </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} lg={3}>
              <AppPickDateField noDatesInPast getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="date_bio_scan" label={t('tracker:date_of_biometric_scan')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTimePickerField ampm={false} getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="time_bio_scan" label={t('tracker:time')} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="bio_scan_address" label={t('tracker:address')}  variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField getError={getError} clearError={clearError} onChange={onChange} getField={getField} name="bio_scan_phone" label={t('tracker:phone')} variant="filled" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppTextField price noNegative type={'number'} name={'bio_scan_paid_fees'} label={'Paid fees'} onChange={onChange} getField={getField} getError={getError} addError={addError} clearError={clearError}/>
            </Grid>
            <Grid item xs={12}>
              <AppCheckbox getError={getError} addError={addError} clearError={clearError} getField={getField} onChange={onChange} name={'skip_this_step'} label={'Candidate already did Medical Exam - skip this step'} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <NextButton fullWidth/>
          </Grid>
        </Hidden>
      </Grid >
      <AppConfirmDialog title={t('tracker:go_to_next_step')} open={confirm} onCancel={toggleConfirm} onConfirm={onConfirm}/>
    </Card>
  )
}

export default StepReArrangeBioScan;
