// General
export const DASHBOARD = "dashboard";
export const ACTIVITY_LOGS = "activity-logs";
export const FORGOT_PASSWORD = "forgot-password";
export const DOWNLOAD_FILE = "download/:fileSlug";
export const CANDIDATE_UPLOAD = "candidate-upload/:candidate_id/:token"
export const CANDIDATE_FORM = "candidate-form/:candidate_id/:token"
export const EXPORT = 'export'

// Employees
export const EMPLOYEES = "employees";
export const NEW_EMPLOYEE = "employees/new";
export const EDIT_EMPLOYEE = "employees/edit/:employeeId";
export const VIEW_EMPLOYEE = "employees/view/:employeeId";

// Companies
export const COMPANIES = "companies";
export const NEW_COMPANY = "companies/new";
export const VIEW_COMPANY = "companies/view/:companyId";
export const EDIT_COMPANY = "companies/edit/:companyId";

// User
export const PROFILE = "profile";
export const PASSWORD_RESET_TOKEN = "password/reset/:token";
export const USERS = "users"
export const NEW_USER = "users/new"
export const VIEW_USER = "users/view/:userId"
export const EDIT_USER = "users/edit/:userId"

// Notifications
export const NOTIFICATIONS = "notifications"
export const EDIT_NOTIFICATION = "notifications/:stepId"

