import React, {Fragment, useContext} from 'react'
import { Card, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EmployeeContext} from "../../../EmployeesContainer";
import {formatDate} from "../../../../../utils/utilFinctions";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: '24px',
    '& .MuiTypography-root': {
      fontWeight: 'bolder',
      fontSize: '0.88rem',
      textTransform: 'uppercase'
    }
  },
  cardFullHeight: {
    height: "100%"
  },
  textFields: {
    padding: "0 24px 24px 24px",
    '& .MuiGrid-item': {
      marginTop: '20px'
    }
  },
  makeBolder: {
    fontWeight: '600',
  },
}));

function ContactAndPassportInfoReadOnly() {
  const {t, getField} = useContext(EmployeeContext);
  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.cardFullHeight}>
      <CardHeader title={t('employees:contact_and_passport_info')} className={classes.header} />
      <Grid container className={classes.textFields}>

          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:phone`)}
          </Grid>
          <Grid item xs={6}>
            {getField('phone', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:email`)}
          </Grid>
          <Grid item xs={6}>
            {getField('email', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:passport_number`)}
          </Grid>
          <Grid item xs={6}>
            {getField('passport_number', '-')}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:passport_issue_date`)}
          </Grid>
          <Grid item xs={6}>
            {formatDate(getField('passport_issue'))}
          </Grid>


          <Grid item xs={6} className={classes.makeBolder}>
            {t(`employees:passport_expiry`)}
          </Grid>
          <Grid item xs={6}>
            {formatDate(getField('passport_expiry'))}
          </Grid>

        {  getField('visa_expiry') &&
        <Fragment>
          <Grid item xs={6} className={classes.makeBolder}>
            {'Visa Expiry Date'}
          </Grid>
          <Grid item xs={6}>
            {getField('visa_expiry')}
          </Grid>
        </Fragment>
        }


        {  getField('labor_card_number') &&
        <Fragment>
          <Grid item xs={6} className={classes.makeBolder}>
            {'Labour Card Number'}
          </Grid>
          <Grid item xs={6}>
            {getField('labor_card_number')}
          </Grid>
        </Fragment>
        }

        {  getField('labor_card_expiry') &&
        <Fragment>
          <Grid item xs={6} className={classes.makeBolder}>
            {'Labour Card Expiry Date'}
          </Grid>
          <Grid item xs={6}>
            {getField('labor_card_expiry')}
          </Grid>
        </Fragment>
        }

        {  getField('insurance_expiry') &&
        <Fragment>
          <Grid item xs={6} className={classes.makeBolder}>
            {'Insurance Expiry Date'}
          </Grid>
          <Grid item xs={6}>
            {getField('insurance_expiry')}
          </Grid>
        </Fragment>
        }

        {  getField('residence_permit_expiry') &&
        <Fragment>
          <Grid item xs={6} className={classes.makeBolder}>
            {'Residence Permit Expire Date'}
          </Grid>
          <Grid item xs={6}>
            {getField('residence_permit_expiry')}
          </Grid>
        </Fragment>
        }

          {  getField('employee_categorization') &&
              <Fragment>
                  <Grid item xs={6} className={classes.makeBolder}>
                      {'Employee Categorization'}
                  </Grid>
                  <Grid item xs={6}>
                      {getField('employee_categorization') == "1" ? 'Skilled' : 'Unskilled'}
                  </Grid>
              </Fragment>
          }

        {getField('current_residency') === 'in' &&
          <Fragment>
            <Grid item xs={6} className={classes.makeBolder}>
              {t(`employees:uid_no`)}
            </Grid>
            <Grid item xs={6}>
              {getField('uid_number', '-')}
            </Grid>

            <Grid item xs={6} className={classes.makeBolder}>
              {t(`employees:eid_no`)}
            </Grid>
            <Grid item xs={6}>
              {getField('eid_number', '-')}
            </Grid>

            <Grid item xs={6} className={classes.makeBolder}>
              {t(`employees:eid_expiry_date`)}
            </Grid>
            <Grid item xs={6}>
              {formatDate(getField('eid_expiry'))}
            </Grid>

            <Grid item xs={6} className={classes.makeBolder}>
              {t(`employees:employee_number`)}
            </Grid>
            <Grid item xs={6}>
              {getField('employee_number')}
            </Grid>
          </Fragment>
        }

      </Grid>
    </Card>
  )
}

export default ContactAndPassportInfoReadOnly
