import React, {Fragment, useEffect, useRef} from 'react'
import { Grid, Card, CardHeader, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {EditOutlined, VisibilityOutlined, ArrowDropDown, DeleteOutlined} from "@material-ui/icons";
import ExpandableTableRow from 'components/ExpandableTableRow';
import AppTextWithHint from 'components/AppTextWithHint';
import AppSeparator from 'components/AppSeparator';
import AppButton from "../../../components/AppButton";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppConfirmDialog from "../../../components/Dialogs/AppConfirmDialog";
import useDialog from "../../../Hooks/useDialog";
import AppIconWithLoading from "../../../components/Icons/AppIconWithLoading";


const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '& .MuiTypography-root': {
      fontSize: '1.2rem'
    },
    '& .MuiCardHeader-action': {
      marginTop: '-2px',
      marginBottom: '-2px',
      marginRight: 0,
    }
  },
  tableCell: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  tableHeadTitles: {
    color: "rgba(0, 0, 0, 0.56)"
  },
  userName: {
    color: "#1A97AD"
  },
  icon: {
    cursor: 'pointer',
    '&:nth-of-type(1), &:nth-of-type(2)': {
      marginRight: '20px',
    }
  },
  mobileControls: {
    paddingTop: '8px'
  },
}));

function UsersTable({getAsset, addAsset, t, getUsers, isLoading, deleteUser, goToNewUser, goToViewUser, goToEditUser, roles}) {
  const classes = useStyles();
  const users = getAsset('users', []);
  const page = getAsset('page', 0)
  const rowsPerPage = getAsset('rowsPerPage', 10)
  const [confirm, toggleConfirm] = useDialog();
  const deleteParam = useRef();

  function handleDeleteUser(user) {
    deleteParam.current = user;
    toggleConfirm();
  }

  function onConfirmDelete() {
    deleteUser(deleteParam.current.id);
    toggleConfirm();
  }

  useEffect(() => {
    getUsers({query: {page: page + 1, limit: rowsPerPage}})
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  function onChangeRowsPerPage(e) {
    addAsset('page', 0);
    addAsset('rowsPerPage', parseInt(e.target.value, 10));
  }

  function handleEditUser(id) {
    goToEditUser(id);
  }

  function handleViewUser(id) {
    goToViewUser(id);
  }

  function getRole(user) {
    const role = roles.find(role => role.id === user.role[0].id)
    return (role || {}).title || '-';
  }

  const headerStyles = {paddingBottom: isLoading ? '12px': '16px'};

  return (
    <Card>
      <CardHeader className={classes.header} style={headerStyles} title={'Users'} action={
       <AppButton onClick={() => goToNewUser()}> New User </AppButton>
      } />
      {isLoading && <LinearProgress />}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap'}}>{t('employees:sn_#')}</TableCell>
              <TableCell className={classes.tableHeadTitles}>Full Name</TableCell>
              <Hidden smDown>
                <TableCell className={classes.tableHeadTitles}>Email</TableCell>
                <TableCell className={classes.tableHeadTitles}>Role</TableCell>
              </Hidden>
             <TableCell> </TableCell>
               {/* <Hidden mdUp>
                  <TableCell> </TableCell>
                </Hidden> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <Fragment key={user.id}>
                <TableRow hover key={user.id} onClick={() => addAsset(`${user.id}Expanded`, !getAsset(`${user.id}Expanded`) )}>
                  <TableCell className={classes.tableCell}>{user.id}</TableCell>
                  <TableCell className={clsx([classes.tableCell, classes.userName])}>{user.first_name} {user.last_name}</TableCell>

                  {/* Desktop view */}
                  <Hidden smDown>
                    <TableCell style={{wordBreak: 'break-word', whiteSpace: 'normal'}}>{user.email}</TableCell>
                    <TableCell style={{wordBreak: 'break-word', whiteSpace: 'normal'}}>{getRole(user)}</TableCell>
                      <TableCell align={"right"} style={{minWidth: '150px'}}>
                        <DeleteOutlined onClick={() => handleDeleteUser(user)} className={classes.icon} color={"secondary"}/>
                        <AppIconWithLoading marginLeft={'4px'} onClick={() => handleEditUser(user.id)}> <EditOutlined className={classes.icon} color={"secondary"} /> </AppIconWithLoading>
                        <AppIconWithLoading onClick={() => handleViewUser(user.id)}> <VisibilityOutlined  className={classes.icon} color={"secondary"} /> </AppIconWithLoading>
                      </TableCell>
                  </Hidden>

                  {/* Mobile View Controls */}
                  <Hidden mdUp>
                    <TableCell>
                      <ArrowDropDown color={"secondary"} />
                    </TableCell>
                  </Hidden>

                </TableRow>

                {/* Mobile View */}
                <ExpandableTableRow hiddenProps={{ mdUp: true }} setKey={`${user.id}`} expanded={getAsset(`${user.id}Expanded`)} colSpan={3}>
                  <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item xs={6} align={'start'}>
                      <AppTextWithHint align={'start'} grayText text={'Email:'} hint={<a style={{wordBreak: 'break-all'}} href={`mailto:${user.email}`}>{user.email}</a>} />
                      <AppTextWithHint align={'start'} grayText text={'Role:'} hint={getRole(user)} />
                      <AppSeparator />
                    </Grid>
                    <Grid className={classes.mobileControls} item xs={6} align={'end'}>
                      <DeleteOutlined onClick={() => handleDeleteUser(user)} className={classes.icon} color={"secondary"}/>
                      <EditOutlined onClick={() => goToEditUser(user.id)} className={classes.icon} color={"secondary"}/>
                      <VisibilityOutlined onClick={() => goToViewUser(user.id)} className={classes.icon} color={"secondary"}/>
                      <AppSeparator />
                    </Grid>
                  </Grid>
                </ExpandableTableRow>

              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={getAsset('totalUsersCount', 10)}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => addAsset('page', newPage)}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
      <AppConfirmDialog open={confirm} title={'Delete user'} text={`Are you sure you want to delete ${(deleteParam.current || {}).first_name} ${(deleteParam.current || {}).last_name}'s account?`} onCancel={toggleConfirm} onConfirm={onConfirmDelete}/>
    </Card>
  )
}

export default UsersTable;
