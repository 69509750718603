const maritalStatuses = [
  {
    name: 'Married',
  },
  {
    name: 'Single',
  },
  {
    name: 'Divorced',
  },
  {
    name: 'Separated',
  },
  {
    name: 'Widow'
  }
];

const countryItems = [
  {
    "Country": "Afghanistan"
  },
  {
    "Country": "Albania"
  },
  {
    "Country": "Algeria"
  },
  {
    "Country": "Argentina"
  },
  {
    "Country": "Australia"
  },
  {
    "Country": "Austria"
  },
  {
    "Country": "Bangladesh"
  },
  {
    "Country": "Belgium"
  },
  {
    "Country": "Bolivia"
  },
  {
    "Country": "Botswana"
  },
  {
    "Country": "Brazil"
  },
  {
    "Country": "Bulgaria"
  },
  {
    "Country": "Cambodia"
  },
  {
    "Country": "Cameroon"
  },
  {
    "Country": "Canada"
  },
  {
    "Country": "Chile"
  },
  {
    "Country": "China"
  },
  {
    "Country": "Colombia *"
  },
  {
    "Country": "Costa Rica"
  },
  {
    "Country": "Croatia"
  },
  {
    "Country": "Cuba"
  },
  {
    "Country": "Czech Republic"
  },
  {
    "Country": "Denmark"
  },
  {
    "Country": "Dominican Republic"
  },
  {
    "Country": "Ecuador"
  },
  {
    "Country": "Egypt"
  },
  {
    "Country": "El Salvador"
  },
  {
    "Country": "Estonia"
  },
  {
    "Country": "Ethiopia"
  },
  {
    "Country": "Fiji"
  },
  {
    "Country": "Finland"
  },
  {
    "Country": "France"
  },
  {
    "Country": "Germany"
  },
  {
    "Country": "Ghana"
  },
  {
    "Country": "Greece"
  },
  {
    "Country": "Guatemala"
  },
  {
    "Country": "Haiti"
  },
  {
    "Country": "Honduras"
  },
  {
    "Country": "Hungary"
  },
  {
    "Country": "Iceland"
  },
  {
    "Country": "India"
  },
  {
    "Country": "Indonesia"
  },
  {
    "Country": "Iran"
  },
  {
    "Country": "Iraq"
  },
  {
    "Country": "Ireland"
  },
  {
    "Country": "Israel"
  },
  {
    "Country": "Italy"
  },
  {
    "Country": "Jamaica"
  },
  {
    "Country": "Japan"
  },
  {
    "Country": "Jordan"
  },
  {
    "Country": "Kenya"
  },
  {
    "Country": "Kuwait"
  },
  {
    "Country": "Laos"
  },
  {
    "Country": "Latvia"
  },
  {
    "Country": "Lebanon"
  },
  {
    "Country": "Libya"
  },
  {
    "Country": "Lithuania"
  },
  {
    "Country": "Madagascar"
  },
  {
    "Country": "Malaysia"
  },
  {
    "Country": "Mali"
  },
  {
    "Country": "Malta"
  },
  {
    "Country": "Mexico"
  },
  {
    "Country": "Mongolia"
  },
  {
    "Country": "Morocco"
  },
  {
    "Country": "Mozambique"
  },
  {
    "Country": "Namibia"
  },
  {
    "Country": "Nepal"
  },
  {
    "Country": "Netherlands"
  },
  {
    "Country": "New Zealand"
  },
  {
    "Country": "Nicaragua"
  },
  {
    "Country": "Nigeria"
  },
  {
    "Country": "Norway"
  },
  {
    "Country": "Pakistan"
  },
  {
    "Country": "Panama"
  },
  {
    "Country": "Paraguay"
  },
  {
    "Country": "Peru"
  },
  {
    "Country": "Philippines"
  },
  {
    "Country": "Poland"
  },
  {
    "Country": "Portugal"
  },
  {
    "Country": "Romania"
  },
  {
    "Country": "Russia"
  },
  {
    "Country": "Saudi Arabia"
  },
  {
    "Country": "Scotland"
  },
  {
    "Country": "Senegal"
  },
  {
    "Country": "Serbia"
  },
  {
    "Country": "Singapore"
  },
  {
    "Country": "Slovakia"
  },
  {
    "Country": "South Africa"
  },
  {
    "Country": "South Korea"
  },
  {
    "Country": "Spain"
  },
  {
    "Country": "Sri Lanka"
  },
  {
    "Country": "Sudan"
  },
  {
    "Country": "Sweden"
  },
  {
    "Country": "Switzerland"
  },
  {
    "Country": "Syria"
  },
  {
    "Country": "Taiwan"
  },
  {
    "Country": "Tajikistan"
  },
  {
    "Country": "Thailand"
  },
  {
    "Country": "Tonga"
  },
  {
    "Country": "Tunisia"
  },
  {
    "Country": "Turkey"
  },
  {
    "Country": "Ukraine"
  },
  {
    "Country": "United Arab Emirates"
  },
  {
    "Country": "United Kingdom"
  },
  {
    "Country": "United States "
  },
  {
    "Country": "Uruguay"
  },
  {
    "Country": "Venezuela"
  },
  {
    "Country": "Vietnam"
  },
  {
    "Country": "Wales"
  },
  {
    "Country": "Zambia"
  },
  {
    "Country": "Zimbabwe"
  }
];

const nationalityItems = [
  {
    "Nationality": "Afghan"
  },
  {
    "Nationality": "Albanian"
  },
  {
    "Nationality": "Algerian"
  },
  {
    "Nationality": "Argentine"
  },
  {
    "Nationality": "Argentinian"
  },
  {
    "Nationality": "Australian"
  },
  {
    "Nationality": "Austrian"
  },
  {
    "Nationality": "Bangladeshi"
  },
  {
    "Nationality": "Belgian"
  },
  {
    "Nationality": "Bolivian"
  },
  {
    "Nationality": "Batswana"
  },
  {
    "Nationality": "Brazilian"
  },
  {
    "Nationality": "Bulgarian"
  },
  {
    "Nationality": "Cambodian"
  },
  {
    "Nationality": "Cameroonian"
  },
  {
    "Nationality": "Canadian"
  },
  {
    "Nationality": "Chilean"
  },
  {
    "Nationality": "Chinese"
  },
  {
    "Nationality": "Colombian"
  },
  {
    "Nationality": "Costa Rican"
  },
  {
    "Nationality": "Croatian"
  },
  {
    "Nationality": "Cuban"
  },
  {
    "Nationality": "Czech"
  },
  {
    "Nationality": "Danish"
  },
  {
    "Nationality": "Dominican"
  },
  {
    "Nationality": "Ecuadorian"
  },
  {
    "Nationality": "Egyptian"
  },
  {
    "Nationality": "Salvadorian"
  },
  {
    "Nationality": "Estonian"
  },
  {
    "Nationality": "Ethiopian"
  },
  {
    "Nationality": "Fijian"
  },
  {
    "Nationality": "Finnish"
  },
  {
    "Nationality": "French"
  },
  {
    "Nationality": "German"
  },
  {
    "Nationality": "Ghanaian"
  },
  {
    "Nationality": "Greek"
  },
  {
    "Nationality": "Guatemalan"
  },
  {
    "Nationality": "Haitian"
  },
  {
    "Nationality": "Honduran"
  },
  {
    "Nationality": "Hungarian"
  },
  {
    "Nationality": "Icelandic"
  },
  {
    "Nationality": "Indian"
  },
  {
    "Nationality": "Indonesian"
  },
  {
    "Nationality": "Iranian"
  },
  {
    "Nationality": "Iraqi"
  },
  {
    "Nationality": "Irish"
  },
  {
    "Nationality": "Israeli"
  },
  {
    "Nationality": "Italian"
  },
  {
    "Nationality": "Jamaican"
  },
  {
    "Nationality": "Japanese"
  },
  {
    "Nationality": "Jordanian"
  },
  {
    "Nationality": "Kenyan"
  },
  {
    "Nationality": "Kuwaiti"
  },
  {
    "Nationality": "Lao"
  },
  {
    "Nationality": "Latvian"
  },
  {
    "Nationality": "Lebanese"
  },
  {
    "Nationality": "Libyan"
  },
  {
    "Nationality": "Lithuanian"
  },
  {
    "Nationality": "Malagasy"
  },
  {
    "Nationality": "Malaysian"
  },
  {
    "Nationality": "Malian"
  },
  {
    "Nationality": "Maltese"
  },
  {
    "Nationality": "Mexican"
  },
  {
    "Nationality": "Mongolian"
  },
  {
    "Nationality": "Moroccan"
  },
  {
    "Nationality": "Mozambican"
  },
  {
    "Nationality": "Namibian"
  },
  {
    "Nationality": "Nepalese"
  },
  {
    "Nationality": "Dutch"
  },
  {
    "Nationality": "New Zealand"
  },
  {
    "Nationality": "Nicaraguan"
  },
  {
    "Nationality": "Nigerian"
  },
  {
    "Nationality": "Norwegian"
  },
  {
    "Nationality": "Pakistani"
  },
  {
    "Nationality": "Panamanian"
  },
  {
    "Nationality": "Paraguayan"
  },
  {
    "Nationality": "Peruvian"
  },
  {
    "Nationality": "Philippine"
  },
  {
    "Nationality": "Polish"
  },
  {
    "Nationality": "Portuguese"
  },
  {
    "Nationality": "Romanian"
  },
  {
    "Nationality": "Russian"
  },
  {
    "Nationality": "Saudi"
  },
  {
    "Nationality": "Scottish"
  },
  {
    "Nationality": "Senegalese"
  },
  {
    "Nationality": "Serbian"
  },
  {
    "Nationality": "Singaporean"
  },
  {
    "Nationality": "Slovak"
  },
  {
    "Nationality": "South African"
  },
  {
    "Nationality": "Korean"
  },
  {
    "Nationality": "Spanish"
  },
  {
    "Nationality": "Sri Lankan"
  },
  {
    "Nationality": "Sudanese"
  },
  {
    "Nationality": "Swedish"
  },
  {
    "Nationality": "Swiss"
  },
  {
    "Nationality": "Syrian"
  },
  {
    "Nationality": "Taiwanese"
  },
  {
    "Nationality": "Tajikistani"
  },
  {
    "Nationality": "Thai"
  },
  {
    "Nationality": "Tongan"
  },
  {
    "Nationality": "Tunisian"
  },
  {
    "Nationality": "Turkish"
  },
  {
    "Nationality": "Ukrainian"
  },
  {
    "Nationality": "Emirati"
  },
  {
    "Nationality": "British"
  },
  {
    "Nationality": "American"
  },
  {
    "Nationality": "Uruguayan"
  },
  {
    "Nationality": "Venezuelan"
  },
  {
    "Nationality": "Vietnamese"
  },
  {
    "Nationality": "Welsh"
  },
  {
    "Nationality": "Zambian"
  },
  {
    "Nationality": "Zimbabwean"
  },
  {
    "Nationality": "Kosovan"
  },
  {
    "Nationality": "Kyrgyz"
  },
  {
    "Nationality": "Liberian"
  },
  {
    "Nationality": "Liechtenstein citizen"
  },
  {
    "Nationality": "Luxembourger"
  },
  {
    "Nationality": "Macanese"
  },
  {
    "Nationality": "Macedonian"
  },
  {
    "Nationality": "Malawian"
  },
  {
    "Nationality": "Maldivian"
  },
  {
    "Nationality": "Marshallese"
  },
  {
    "Nationality": "Martiniquais"
  },
  {
    "Nationality": "Mauritanian"
  },
  {
    "Nationality": "Mauritian"
  },
  {
    "Nationality": "Micronesian"
  },
  {
    "Nationality": "Moldovan"
  },
  {
    "Nationality": "Monegasque"
  },
  {
    "Nationality": "Montenegrin"
  },
  {
    "Nationality": "Montserratian"
  },
  {
    "Nationality": "Mosotho"
  },
  {
    "Nationality": "Nauruan"
  },
  {
    "Nationality": "Niuean"
  },
  {
    "Nationality": "North Korean"
  },
  {
    "Nationality": "Northern Irish"
  },
  {
    "Nationality": "Omani"
  },
  {
    "Nationality": "Palauan"
  },
  {
    "Nationality": "Palestinian"
  },
  {
    "Nationality": "Papua New Guinean"
  },
  {
    "Nationality": "Pitcairn Islander"
  },
  {
    "Nationality": "Prydeinig"
  },
  {
    "Nationality": "Puerto Rican"
  },
  {
    "Nationality": "Qatari"
  },
  {
    "Nationality": "Rwandan"
  },
  {
    "Nationality": "Sammarinese"
  },
  {
    "Nationality": "Samoan"
  },
  {
    "Nationality": "Sao Tomean"
  },
  {
    "Nationality": "Sierra Leonean"
  },
  {
    "Nationality": "Slovenian"
  },
  {
    "Nationality": "Solomon Islander"
  },
  {
    "Nationality": "Somali"
  },
  {
    "Nationality": "South Korean"
  },
  {
    "Nationality": "South Sudanese"
  },
  {
    "Nationality": "St Helenian"
  },
  {
    "Nationality": "St Lucian"
  },
  {
    "Nationality": "Stateless"
  },
  {
    "Nationality": "Surinamese"
  },
  {
    "Nationality": "Swazi"
  },
  {
    "Nationality": "Tanzanian"
  },
  {
    "Nationality": "Togolese"
  },
  {
    "Nationality": "Trinidadian"
  },
  {
    "Nationality": "Tristanian"
  },
  {
    "Nationality": "Turkmen"
  },
  {
    "Nationality": "Turks and Caicos Islander"
  },
  {
    "Nationality": "Tuvaluan"
  },
  {
    "Nationality": "Ugandan"
  },
  {
    "Nationality": "Uzbek"
  },
  {
    "Nationality": "Vatican citizen"
  },
  {
    "Nationality": "Vincentian"
  },
  {
    "Nationality": "Wallisian"
  },
  {
    "Nationality": "Yemeni"
  },
  {
    "Nationality": "Azerbaijan"
  },
  {
    "Nationality": "Myanmar (Burma)"
  },
  {
    "Nationality": "Antiguans"
  },
  {
    "Nationality": "Bahraini"
  },
  {
    "Nationality": "Belarusian"
  },
  {
    "Nationality": "Burundian"
  },
  {
    "Nationality": "Chadian"
  },
  {
    "Nationality": "Comorans"
  },
  {
    "Nationality": "Congolese"
  },
  {
    "Nationality": "Djiboutian"
  },
  {
    "Nationality": "Estonian"
  },
  {
    "Nationality": "Gambians"
  },
  {
    "Nationality": "Kazakh"
  },
  {
    "Nationality": "Monegasques"
  },
  {
    "Nationality": "Seychellois"
  },
  {
    "Nationality": "Ivorian"
  },
];

const religionItems = [
  {
    "Religion": "Christianity",
  },
  {
    "Religion": "Islam",
  },
  {
    "Religion": "Secular[a]/Nonreligious[b]/Agnostic/Atheist",
  },
  {
    "Religion": "Hinduism",
  },
  {
    "Religion": "Buddhism"
  },
  {
    "Religion": "Chinese traditional religion[c]"
  },
  {
    "Religion": "African traditional religions"
  },
  {
    "Religion": "Sikhism"
  },
  {
    "Religion": "Spiritism"
  },
  {
    "Religion": "Judaism"
  },
  {
    "Religion": "Baháʼí"
  },
  {
    "Religion": "Jainism"
  },
  {
    "Religion": "Shinto"
  },
  {
    "Religion": "Cao Dai"
  },
  {
    "Religion": "Zoroastrianism"
  },
  {
    "Religion": "Tenrikyo"
  },
  {
    "Religion": "Animism"
  },
  {
    "Religion": "Neo-Paganism"
  },
  {
    "Religion": "Unitarian Universalism"
  },
  {
    "Religion": "Rastafari"
  },
];

const sectItems = [
  {
    "Sect": "Roman Catholic"
  },
  {
    "Sect": "Protestant"
  },
  {
    "Sect": "Sunni"
  },
  {
    "Sect": "Shi'a"
  },
];

const sortedNationalityItems = nationalityItems.sort(function(a, b){
  if(a.Nationality < b.Nationality) { return -1; }
  if(a.Nationality > b.Nationality) { return 1; }
  return 0;
})


export {
  maritalStatuses,
  countryItems,
  sortedNationalityItems as nationalityItems,
  religionItems,
  sectItems
}
